// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { MapStateToProps, Connector } from 'react-redux';
import { filter } from 'ramda';
import colors from '../../styles/colors';

import PieChart from '../../components/charts/pie';
import ToggleButton from '../../components/toggle-button';
import '../style.css';

type Props = {};

type State = {
  selectedFilter: string,
};

const styles: Object = {
  widget: {
    width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  filterContainer: {
    marginTop: '1rem',
  },
  emptydataContainer: {
    height: '430px',
    textAlign: 'center',
    paddingTop: 50,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const ColorConfig: Object = {
  MainContractor: colors.themeSecondaryColorNew,
  client: colors.themePrimaryColorNew,
  unexpectedConditions: colors.newYellow,
  supplier: colors.cLevelGray,
  others: colors.cinderella,
};

const getOrderedList: Function = function getOrderedList(data: any): any[] {
  const filteredList: any = filter((item: Object): boolean => item.value * 100 > 0, data);
  const list: any[] = Object.keys(filteredList).map((key: string): any => {
    const projectDelay: number = Object.keys(data[key].project_delay)
      .reduce((acc: number, delay: number): number => acc + data[key].project_delay[delay], 0);

    return {
      label: key,
      value: data[key].value,
      text: `Project delayed by ${projectDelay} day(s)`,
    };
  });
  return list.sort((item1: any, item2: any): number => (
    item1.value < item2.value ? 1 : -1
  ));
};

const dummyData: any = {
  MainContractor: {
    project_delay: {
      first_lookahead: 689,
    },
    value: 0.5,
  },
  client: {
    project_delay: {
      first_lookahead: 10,
    },
    value: 0.02,
  },
  unexpectedConditions: {
    project_delay: {
      first_lookahead: 20,
    },
    value: 0.04,
  },
  supplier: {
    project_delay: {
      first_lookahead: 40,
    },
    value: 0.06,
  },
};

const dummyDataOccurrence: any = {
  MainContractor: {
    project_delay: {
      first_lookahead: 689,
    },
    value: 0.4,
  },
  client: {
    project_delay: {
      first_lookahead: 10,
    },
    value: 0.05,
  },
  unexpectedConditions: {
    project_delay: {
      first_lookahead: 20,
    },
    value: 0.04,
  },
  supplier: {
    project_delay: {
      first_lookahead: 40,
    },
    value: 0.09,
  },
  others: {
    project_delay: {
      approval_permits: 3,
    },
    value: 0.0377,
  },
};

class DelayResponsibilitiesWidget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'occurences',
  };

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState(
      {
        selectedFilter: val,
      },
      this.fetchData,
    );
  }

  getChartData(data: Array<Object>): ViewModel[] {
    return data.map(
      (item: Object): Object => ({
        label: item.label,
        value: item.value,
        color: ColorConfig[item.label],
        text: item.text,
      }),
    );
  }

  getWidget(): React.Node {
    return getOrderedList(this.state.selectedFilter === 'occurences' ? dummyDataOccurrence : dummyData).length !== 0 ? (
      <div>
        <PieChart data={this.getChartData(getOrderedList(this.state.selectedFilter === 'occurences' ? dummyDataOccurrence : dummyData))} />
        {/* <div style={{ fontSize: '0.8rem' }}>* Information accuracy available from Feb 8, 2018</div> */}
      </div>
    ) : (
      <span style={styles.noData}>No data available</span>
    );
  }


  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={getOrderedList(this.state.selectedFilter === 'occurences' ? dummyDataOccurrence : dummyData).length !== 0 ? { ...styles.widget, height: 536 } : styles.widget}>
        <div className="c-level__flex">
          <h1>
            Delay Responsibility
          </h1>
          <div style={styles.filterContainer}>
            <ToggleButton
              items={[{ value: 'occurences', label: 'Occurences' }, { value: 'days', label: 'Total Project Delay (days)' }]}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
          </div>
        </div>
        {this.getWidget()}
      </section>
    );
  }
}

type TMapStateToFilter = MapStateToProps<Reducers, any, { data: any }>;

const mapStateToProps: TMapStateToFilter = (state: any): Object => ({ data: state.delayResponsibility });

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(DelayResponsibilitiesWidget);
