// @flow

export type MilestoneDetailPropTypes = {
  GET_MILESTONE_DETAIL: 'getMilestoneDetail',
  GET_MILESTONE_DETAIL_SUCCESS: 'getMilestoneDetailSuccess',
  GET_MILESTONE_DETAIL_FAIL: 'getMilestoneDetailFail',
  CLEAR_MILESTONE_DETAIL: 'clearMilestoneDetail',
}

const actionTypes: MilestoneDetailPropTypes = {
  GET_MILESTONE_DETAIL: 'getMilestoneDetail',
  GET_MILESTONE_DETAIL_SUCCESS: 'getMilestoneDetailSuccess',
  GET_MILESTONE_DETAIL_FAIL: 'getMilestoneDetailFail',
  CLEAR_MILESTONE_DETAIL: 'clearMilestoneDetail',
};

export default actionTypes;
