// @flow
import moment from 'moment';
import ActionTypes from './action-types';
import type {
  PROJECT_ACTIONS_TYPE, PROJECT_RESPONSE_TYPE, PROJECT_ALL_WEEKS_RESPONSE_TYPE,
} from './sagas';

type CompanyItem = {
  id: string,
  name: string,
};
export type CompanyListType = ?(CompanyItem[]);

export type ProjectFeatures = {[id: string]: boolean};

export type ProjectState = {
  companies: any | null,
  loading: boolean | null,
  projectDetails: PROJECT_RESPONSE_TYPE | null,
  projectAllWeeks: PROJECT_ALL_WEEKS_RESPONSE_TYPE,
  shortWeeks: any,
  versionChangeWeeks: any,
  versionDates: string | null,
  features: ProjectFeatures,
  lookahead: Object | null
};

// const getSortedCompanyList: Function = function getSortedCompanyList(data: COMPANY_LIST_RESPONSE_TYPE): CompanyListType {
//   return Object.keys(data).map((id: string): Object => ({ id, name: data[id] }));
// };

const initialState: ProjectState = {
  companies: null,
  loading: null,
  projectDetails: null,
  projectAllWeeks: [],
  shortWeeks: {},
  versionChangeWeeks: {},
  versionDates: null,
  features: {},
  lookahead: null,
};

type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

function getShortWeeks(weeks: PROJECT_ALL_WEEKS_RESPONSE_TYPE): Object {
  const shortWeeks: any = {};
  weeks.forEach((item: any) => {
    if (item.shortWeek) {
      shortWeeks[item.end] = true;
    }
  });
  return shortWeeks;
}

function getVersionChangeWeeks(weeks: PROJECT_ALL_WEEKS_RESPONSE_TYPE): Object {
  const versioChange: any = {};
  weeks.forEach((item: any) => {
    if (item.versionChange) {
      versioChange[item.end] = true;
    }
  });
  return versioChange;
}

function getVersionDetails(data: any): any {
  if (!data) return null;
  const versionDetails: any[] = data.map((item: any): any => moment(item.changedOn).format('DD MMM YYYY'));
  return versionDetails.join(versionDetails.length < 2 ? '' : ', ');
}

export default function (state: ProjectState = initialState, action: PROJECT_ACTIONS_TYPE): Exact<ProjectState> {
  switch (action.type) {
    case ActionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.data,
        loading: false,
      };
    case ActionTypes.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetails: action.data.project || action.data,
        loading: false,
      };
    case ActionTypes.GET_ALL_WEEKS_SUCCESS:
      return {
        ...state,
        projectAllWeeks: action.data,
        loading: false,
        shortWeeks: getShortWeeks(action.data),
        versionChangeWeeks: getVersionChangeWeeks(action.data),
      };
    case ActionTypes.GET_VERSION_DETAILS_SUCCESS:
      return {
        ...state,
        versionDates: getVersionDetails(action.data.history),
        loading: false,
      };
    case ActionTypes.GET_PROJECT_FEATURES_SUCCESS:
      return {
        ...state,
        features: action.data,
      };
    case ActionTypes.GET_LOOKAHEAD_META_SUCCESS:
      return {
        ...state,
        lookahead: action.data,
      };
    default:
      return state;
  }
}
