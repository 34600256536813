// @flow
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Loader from '../../components/loader';
import LineChart from '../../components/charts/line';

import colors from '../../styles/colors';

import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import { CostsByPeriod as CostsByPeriodType } from '../cost-progress/sagas';

import { CostProgressState } from '../cost-progress/reducer';
import type { Reducers } from '../../reducers';

import withProjectContext from '../../auth/with-project-context';

type Props = {
  data: CostProgressState,
};

type State = {
  selectedFilter: string,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    overflow: 'auto',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

// const ColorConfig: Object = {
//   'Avoidable by getting valid commitments': colors.themePrimaryColor,
//   'Avoidable by making plan reliable and robust': colors.themeSecondaryColor,
//   'Unforeseen / external conditions': colors.yellow,
// };

// const TextConstants: Object = {
//   'Avoidable by getting valid commitments': 'Preventable by getting valid commitments',
//   'Avoidable by making plan reliable and robust': 'Preventable by making plan reliable and robust',
//   'Unforeseen / external conditions': 'Unforeseen / external conditions',
// };

class CostPerformance extends React.Component<Props, State> {
  getData= (): ViewModel[] => {
    const labels: string[] = [];

    const spis: number[] = [];

    const ideal: number[] = [];

    this.props.data.chartData.costsByPeriod.forEach((d: CostsByPeriodType) => {
      labels.push(moment(d.periodEndDate).format('DD MMM YYYY'));
      spis.push(d.spi);
      ideal.push(1);
    });

    return [
      {
        labels,
        values: spis,
        name: 'CPI',
        color: colors.themePrimaryColor,
        type: 'scatter',
      },
      {
        labels,
        values: ideal,
        name: 'Base Performance',
        color: colors.cLevelGreen,
      },
    ];
  }

  getWidget(): React.Node {
    if (!this.props.data.chartData || !this.props.data.chartData.costsByPeriod || this.props.data.chartData.costsByPeriod.length === 0) {
      return <span style={styles.noData}> No data available</span>;
    }

    return (
      <div>
        <LineChart data={this.getData()} options={{ yaxis: { title: 'CPI', showline: true }, xaxis: { title: 'Time', showline: true } }} />
      </div>
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 200}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
         Cost Performance
          <img
            data-tip="Displays the cost performance in terms of project schedule as an index"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>

        <ReactTooltip place="top" type="dark" effect="solid" border multiline />

        {this.props.data.loading === null ? <Loader /> : this.getWidget()}
      </section>
    );
  }
}

const component: any = compose(
  connect((state: Reducers): { data: CostProgressState, } => (
    {
      data: state.CostProgress,
    }
  )),
  withProjectContext,
)(CostPerformance);

component.style = {
  width: '98%',
};

export default component;
