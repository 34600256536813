// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import { keyPerformanceIndicatorData } from './data';
import '../style.css';

type Props = {}

const styles: Object = {
  widget: {
    width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
};


class KeyPerformerIndicatorWidget extends React.PureComponent<Props> {
  renderTable = (): React.Element<any> => (
    <table className="c-level__table">
      <thead>
        <tr>
          <th>Key Performance Indicator</th>
          <th>Target</th>
          <th>Value @ cut-off</th>
        </tr>
      </thead>
      <tbody>
        {keyPerformanceIndicatorData.map((item: any, id: number): React.Node => (
          <tr key={id}>
            <td>{item.indicator}</td>
            <td>{item.target}</td>
            <td>{item.cutoff}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <div>{this.renderTable()}</div>
      </section>
    );
  }
}

const component: any = connect()(KeyPerformerIndicatorWidget);

// component.style = {
//   width: '98%',
// };

export default component;
