// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { map } from 'ramda';
import colors from '../../styles/colors';
import ResponsivePieChart from '../../components/charts/pie';
import SelectBox from '../../components/select-box';
import '../style.css';


type Props = {}

type State = {
  work: string,
}

type ViewModel = {
  label: string,
  value: number,
  color?: string,
  text?: string,
}

const LabelStrings: Object = {
  achieved: 'Achieved',
  remaining: 'Remaining',
  planCompleted: 'Plan Completed',
  planRemaining: 'Plan Remaining',
};

const styles: Object = {

  widget: {
    width: '50%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  selectBoxContainer: {
    width: '350px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const formatData: Function = function formatData(data: any): any[] {
  return map((key: string): any => (
    {
      id: key,
      value: data[key].value,
      percentage: data[key].percentage,
      totalDays: data[key].days,
    }
  ), Object.keys(data));
};

const dummyData: any = {
  achieved: {
    value: 10,
    percentage: 99.6,
    totalDays: 10,
  },
  remaining: {
    value: 8,
    percentage: 0.2,
    totalDays: 8,
  },
};

const dummyData01: any = {
  planCompleted: {
    value: 10,
    percentage: 98.6,
    totalDays: 10,
  },
  planRemaining: {
    value: 8,
    percentage: 0.5,
    totalDays: 8,
  },
};

const ColorConfig: Object = {
  Achieved: colors.themeSecondaryColorNew,
  Remaining: colors.themePrimaryColorNew,
  'Plan Completed': colors.themeSecondaryColorNew,
  'Plan Remaining': colors.themePrimaryColorNew,
};

class OtherWorksWidget extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      work: 'Installation works',
    };
  }

  onCompanyChange = this.onCompanyChange.bind(this);

  onCompanyChange(e: Object) {
    this.setState({
      work: e.value,
    });
  }

  getFormattedData(data: Array<Object>): ViewModel[] {
    return data.map((item: Object): ViewModel => ({
      label: LabelStrings[item.id],
      value: item.percentage,
    }));
  }

  getWorks(companies: Object[]): React.Node {
    return (

      <SelectBox
        options={companies}
        value={this.state.work}
        onChange={this.onCompanyChange}
      />
    );
  }

  getChartData(data: Array<Object>): ViewModel[] {
    return data.map(
      (item: Object): Object => ({
        label: item.label,
        value: item.value,
        color: ColorConfig[item.label],
        // text: item.text,
      }),
    );
  }

  getWidget(): React.Node {
    if (formatData(dummyData).length !== 0) {
      return (
        <div className="c-level__flex">
          <div style={{ width: '50%' }}>
            <ResponsivePieChart
              data={this.getChartData(this.getFormattedData(formatData(dummyData)))}
              dataOptions={{
                pull: [0.1, 0, 0],
              }}
            />
          </div>
          <div style={{ width: '50%' }}>
            <ResponsivePieChart
              data={this.getChartData(this.getFormattedData(formatData(dummyData01)))}
              dataOptions={{
                pull: [0.1, 0, 0],
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <span style={styles.noData}>
      No data available
      </span>
    );
  }


  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    const data: any[] = [{ label: 'Installation works' }, { label: 'Plumbing works' }, { label: 'Dismantling works' }];
    const works: Object[] = data.map(
      (c: any): Object => ({
        label: c.label,
        value: c.label,
      }),
    );
    return (
      <section style={styles.widget}>
        <div className="c-level__flex">
          <h1>
            Other Works
          </h1>
          <div style={styles.selectBoxContainer}>
            {this.getWorks(works)}
          </div>
        </div>
        {
          this.getWidget()
        }
      </section>
    );
  }
}

const Component: any = connect()(OtherWorksWidget);

// Component.style = {
//   width: '98%',
// };

export default Component;
