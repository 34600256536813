// @flow

export const ProjectProgress: any = [
  {
    monthly_planned: 0.05,
    monthly_actual: 0.000,
    cumilative_planned: 0,
    month: 'Jan-16',
  },
  {
    monthly_planned: 0.08,
    monthly_actual: 0,
    cumilative_planned: 0.03 * 10,
    month: 'Feb-16',
  },
  {
    monthly_planned: 0.14,
    monthly_actual: 0,
    cumilative_planned: 0.09 * 10,
    month: 'Mar-16',
  },
  {
    monthly_planned: 0.29,
    monthly_actual: 0.02,
    cumilative_planned: 0.1 * 10,
    cumilative_actual: 0 * 10,
    month: 'Apr-16',
  },
  {
    monthly_planned: 0.48,
    monthly_actual: 0.06,
    cumilative_planned: 0.2 * 10,
    cumilative_actual: 0 * 10,
    month: 'May16',
  },
  {
    monthly_planned: 0.67,
    monthly_actual: 0.04,
    cumilative_planned: 0.3 * 10,
    cumilative_actual: 0.01 * 10,
    month: 'Jun-16',
  },
  {
    monthly_planned: 1.02,
    monthly_actual: 0.10,
    cumilative_planned: 0.5 * 10,
    cumilative_actual: 0.04 * 10,
    month: 'Jul-16',
  },
  {
    monthly_planned: 2.19,
    monthly_actual: 0.33,
    cumilative_planned: 0.6 * 10,
    cumilative_actual: 0.08 * 10,
    month: 'Aug-16',
  },
  {
    monthly_planned: 3.09,
    monthly_actual: 0.39,
    cumilative_planned: 0.9 * 10,
    cumilative_actual: 0.1 * 10,
    month: 'Sep-16',
  },
  {
    monthly_planned: 3.37,
    monthly_actual: 0.28,
    cumilative_planned: 1 * 10,
    cumilative_actual: 0.18 * 10,
    month: 'Oct-16',
  },
  {
    monthly_planned: 3.78,
    monthly_actual: 0.40,
    cumilative_planned: 1.3 * 10,
    cumilative_actual: 0.25 * 10,
    month: 'Nov-16',
  },
  {
    monthly_planned: 5.73,
    monthly_actual: 0.96,
    cumilative_planned: 1.8 * 10,
    cumilative_actual: 0.34 * 10,
    month: 'Dec-16',
  },
  {
    monthly_planned: 5.06,
    monthly_actual: 1.23,
    cumilative_planned: 2.3 * 10,
    cumilative_actual: 0.48 * 10,
    month: 'Jan-17',
  },
  {
    monthly_planned: 5.99,
    monthly_actual: 1.21,
    cumilative_planned: 2.97 * 10,
    cumilative_actual: 0.62 * 10,
    month: 'Feb-17',
  },
  {
    monthly_planned: 8.33,
    monthly_actual: 2.53,
    cumilative_planned: 3.7 * 10,
    cumilative_actual: 0.87 * 10,
    month: 'Mar-17',
  },
  {
    monthly_planned: 7.32,
    monthly_actual: 2.36,
    cumilative_planned: 4.5 * 10,
    cumilative_actual: 1.05 * 10,
    month: 'Apr-17',
  },
  {
    monthly_planned: 8.18,
    monthly_actual: 2.10,
    cumilative_planned: 5.3 * 10,
    cumilative_actual: 1.36 * 10,
    month: 'May-17',
  },
  {
    monthly_planned: 9.49,
    monthly_actual: 2.00,
    cumilative_planned: 6.2 * 10,
    cumilative_actual: 1.45 * 10,
    month: 'Jun-17',
  },
  {
    monthly_planned: 5.34,
    monthly_actual: 2.92,
    cumilative_planned: 6.8 * 10,
    cumilative_actual: 1.7 * 10,
    month: 'Jul-17',
  },
  {
    monthly_planned: 5.26,
    monthly_actual: 3.91,
    cumilative_planned: 7.4 * 10,
    cumilative_actual: 2.1 * 10,
    month: 'Aug-17',
  },
  {
    monthly_planned: 8.41,
    monthly_actual: 4.81,
    cumilative_planned: 8.2 * 10,
    cumilative_actual: 2.4 * 10,
    month: 'Sep-17',
  },
  {
    monthly_planned: 5.52,
    monthly_actual: 4.21,
    cumilative_planned: 8.9 * 10,
    cumilative_actual: 2.97 * 10,
    month: 'Oct-17',
  },
  {
    monthly_planned: 4.00,
    monthly_actual: 4.06,
    cumilative_planned: 9.3 * 10,
    cumilative_actual: 3.4 * 10,
    month: 'Nov-17',
  },
  {
    monthly_planned: 3.42,
    monthly_actual: 3.92,
    cumilative_planned: 9.6 * 10,
    cumilative_actual: 3.8 * 10,
    month: 'Dec-17',
  },
  {
    monthly_planned: 1.98,
    cumilative_planned: 9.7 * 10,
    month: 'Jan-18',
  },
  {
    monthly_planned: 1.39,
    cumilative_planned: 9.8 * 10,
    month: 'Feb-18',
  },
  {
    monthly_planned: 0.58,
    cumilative_planned: 9.93 * 10,
    month: 'Mar-18',
  },
  {
    monthly_planned: 0.18,
    cumilative_planned: 9.999 * 10,
    month: 'Apr-18',
  },
];

export const ProjectProgressByArea: any = [
  {
    monthly_planned: 0.05,
    monthly_actual: 0.000,
    cumilative_planned: 0,
    month: 'Jan-16',
  },
  {
    monthly_planned: 0.08,
    monthly_actual: 0,
    cumilative_planned: 0.03 * 10,
    month: 'Feb-16',
  },
  {
    monthly_planned: 0.14,
    monthly_actual: 0,
    cumilative_planned: 0.09 * 10,
    month: 'Mar-16',
  },
  {
    monthly_planned: 0.29,
    monthly_actual: 0.02,
    cumilative_planned: 0.1 * 10,
    cumilative_actual: 0 * 10,
    month: 'Apr-16',
  },
  {
    monthly_planned: 0.48,
    monthly_actual: 0.06,
    cumilative_planned: 0.2 * 10,
    cumilative_actual: 0 * 10,
    month: 'May16',
  },
  {
    monthly_planned: 0.67,
    monthly_actual: 0.04,
    cumilative_planned: 0.3 * 10,
    cumilative_actual: 0.01 * 10,
    month: 'Jun-16',
  },
  {
    monthly_planned: 1.02,
    monthly_actual: 0.10,
    cumilative_planned: 0.5 * 10,
    cumilative_actual: 0.04 * 10,
    month: 'Jul-16',
  },
  {
    monthly_planned: 1.19,
    monthly_actual: 0.33,
    cumilative_planned: 0.6 * 10,
    cumilative_actual: 0.08 * 10,
    month: 'Aug-16',
  },
  {
    monthly_planned: 3.09,
    monthly_actual: 0.39,
    cumilative_planned: 0.9 * 10,
    cumilative_actual: 0.1 * 10,
    month: 'Sep-16',
  },
  {
    monthly_planned: 3.37,
    monthly_actual: 0.28,
    cumilative_planned: 1 * 10,
    cumilative_actual: 0.18 * 10,
    month: 'Oct-16',
  },
  {
    monthly_planned: 3.78,
    monthly_actual: 0.40,
    cumilative_planned: 1.3 * 10,
    cumilative_actual: 0.25 * 10,
    month: 'Nov-16',
  },
  {
    monthly_planned: 5.73,
    monthly_actual: 0.96,
    cumilative_planned: 1.8 * 10,
    cumilative_actual: 0.34 * 10,
    month: 'Dec-16',
  },
  {
    monthly_planned: 5.06,
    monthly_actual: 1.23,
    cumilative_planned: 2.3 * 10,
    cumilative_actual: 0.48 * 10,
    month: 'Jan-17',
  },
  {
    monthly_planned: 5.99,
    monthly_actual: 1.21,
    cumilative_planned: 2.97 * 10,
    cumilative_actual: 0.62 * 10,
    month: 'Feb-17',
  },
  {
    monthly_planned: 9.33,
    monthly_actual: 2.53,
    cumilative_planned: 3.7 * 10,
    cumilative_actual: 0.87 * 10,
    month: 'Mar-17',
  },
  {
    monthly_planned: 6.32,
    monthly_actual: 2.36,
    cumilative_planned: 4.5 * 10,
    cumilative_actual: 1.05 * 10,
    month: 'Apr-17',
  },
  {
    monthly_planned: 9.18,
    monthly_actual: 2.10,
    cumilative_planned: 5.3 * 10,
    cumilative_actual: 1.36 * 10,
    month: 'May-17',
  },
  {
    monthly_planned: 9.49,
    monthly_actual: 2.00,
    cumilative_planned: 6.2 * 10,
    cumilative_actual: 1.45 * 10,
    month: 'Jun-17',
  },
  {
    monthly_planned: 6.34,
    monthly_actual: 2.92,
    cumilative_planned: 6.8 * 10,
    cumilative_actual: 1.7 * 10,
    month: 'Jul-17',
  },
  {
    monthly_planned: 7.26,
    monthly_actual: 3.91,
    cumilative_planned: 7.4 * 10,
    cumilative_actual: 2.1 * 10,
    month: 'Aug-17',
  },
  {
    monthly_planned: 9.41,
    monthly_actual: 5.81,
    cumilative_planned: 8.2 * 10,
    cumilative_actual: 2.4 * 10,
    month: 'Sep-17',
  },
  {
    monthly_planned: 5.52,
    monthly_actual: 4.21,
    cumilative_planned: 8.8 * 10,
    cumilative_actual: 2.97 * 10,
    month: 'Oct-17',
  },
  {
    monthly_planned: 4.40,
    monthly_actual: 4.56,
    cumilative_planned: 9.3 * 10,
    cumilative_actual: 3.4 * 10,
    month: 'Nov-17',
  },
  {
    monthly_planned: 4.42,
    monthly_actual: 4.92,
    cumilative_planned: 9.6 * 10,
    cumilative_actual: 3.8 * 10,
    month: 'Dec-17',
  },
  {
    monthly_planned: 1.98,
    cumilative_planned: 9.7 * 10,
    month: 'Jan-18',
  },
  {
    monthly_planned: 1.39,
    cumilative_planned: 9.8 * 10,
    month: 'Feb-18',
  },
  {
    monthly_planned: 0.58,
    cumilative_planned: 9.93 * 10,
    month: 'Mar-18',
  },
  {
    monthly_planned: 0.18,
    cumilative_planned: 9.999 * 10,
    month: 'Apr-18',
  },
];
