/* eslint-disable flowtype/require-return-type */
/* eslint-disable flowtype/require-parameter-type */
// @flow
import React from 'react';

import { getCriticalWorkflowCountApi, getProjectDelayCountApi, getPotentailDelayCountApi } from '../../apis';

import warn from '../../../assets/warn.png';
import delay from '../../../assets/delay.png';
import criticalBar from '../../../assets/criticalBar.png';

import './index.css';
import '../../index.css';

type Props = {
  projectId: number;
  disciplineId: string;
}

type State = {
  data: {
    projectDelay: number,
    criticalWorkflows: number,
    potentialdelaycount: number,
};
  criticalLoading: boolean;
  projectLoading: boolean;
  potentialLoading: boolean;
}

class FlowStatus extends React.Component<Props, State> {
  state = {
    data: {
      projectDelay: null,
      criticalWorkflows: null,
      potentialdelaycount: null,
    },
    criticalLoading: false,
    projectLoading: false,
    potentialLoading: false,
  }

  componentDidMount() {
    this.fetchCriticalData();
    this.fetchProjectDelayData();
    this.fetchPotentialDelayData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.disciplineId !== prevProps.disciplineId) {
      this.fetchCriticalData();
      this.fetchProjectDelayData();
      this.fetchPotentialDelayData();
    }
  }

  fetchCriticalData = async (): any => {
    try {
      this.setState({
        criticalLoading: true,
      });

      const criticalworkflowResponse: any = await (getCriticalWorkflowCountApi(
        { projectId: this.props.projectId, companyId: this.props.companyId, disciplineId: this.props.disciplineId },
      ));

      if (criticalworkflowResponse.status === 200) {
        this.setState(prevState => ({
          criticalLoading: false,
          data: {
            ...prevState.data,
            criticalworkflow: criticalworkflowResponse.data.criticalWorkflows,
          },
        }));
      }
    } catch (e) {
      this.setState({
        criticalLoading: false,
      });
    }
  }

  fetchProjectDelayData = async (): any => {
    try {
      this.setState({
        projectLoading: true,
      });

      const projectDelayResponse: any = await (getProjectDelayCountApi(
        { projectId: this.props.projectId, companyId: this.props.companyId, disciplineId: this.props.disciplineId },
      ));
      if (projectDelayResponse.status === 200) {
        this.setState(prevState => ({
          projectLoading: false,
          data: {
            ...prevState.data,
            projectDelay: projectDelayResponse.data.projectDelay,
          },
        }));
      }
    } catch (e) {
      this.setState({
        projectLoading: false,
      });
    }
  }

  fetchPotentialDelayData = async (): any => {
    try {
      this.setState({
        potentialLoading: true,
      });

      const potentailDelayResponse: any = await (getPotentailDelayCountApi(
        { projectId: this.props.projectId, companyId: this.props.companyId, disciplineId: this.props.disciplineId },
      ));

      if (potentailDelayResponse.status === 200) {
        this.setState(prevState => ({
          potentialLoading: false,
          data: {
            ...prevState.data,
            potentialdelaycount: potentailDelayResponse.data.potentialDelay,
          },
        }));
      }
    } catch (e) {
      this.setState({
        potentialLoading: false,
      });
    }
  }


  renderLoading(): React.ReactNode {
    return <div className="wigt-container">Loading...</div>;
  }

  renderContent(): React.ReactNode {
    if (this.state.projectLoading || this.state.potentialLoading || this.state.criticalLoading) {
      return this.renderLoading();
    }

    if (!this.state.data) {
      return <div>No data available</div>;
    }

    return (
      <div className="fs-content">
        <div className="fs-card">
         <img
           width="20"
           height="25"
           src={warn}
           alt="i"
           className="c-level__icon"
         />
         <div>
          <div className="fs-title">{this.state.data.potentialdelaycount ? `${this.state.data.potentialdelaycount} days` : 'No'}</div>
          <div className="fs-subtitle">Potentially Delay Found</div>
         </div>
        </div>
        <div className="fs-card">
          <img
            width="20"
            height="25"
            src={delay}
            alt="i"
            className="c-level__icon"
          />
          <div>
            <div className="fs-title">{this.state.data.projectDelay ? `${this.state.data.projectDelay} days delayed` : 'No'}</div>
            <div>Project Delays by workflows</div>
          </div>
        </div>
        <div className="fs-card">
          <img
            width="20"
            height="25"
            src={criticalBar}
            alt="i"
            className="c-level__icon"
          />
          <div>
            <div className="fs-title">{this.state.data.criticalworkflow ? `${this.state.data.criticalworkflow} Critical` : 'No'}</div>
            <div>Critical Workflows</div>
          </div>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    return <div className="fs-container">{this.renderContent()}</div>;
  }
}

export default FlowStatus;
