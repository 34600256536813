// @flow

import ActionTypes from './action-types';
import type { ConstraintDetailResponseType, ConstraintDetailAction } from './sagas';

export type ConstraintDetailState = {
  constraint: ConstraintDetailResponseType | null,
  isLoading: boolean,
};

const initialState: ConstraintDetailState = {
  isLoading: true,
  constraint: null,
};


type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: ConstraintDetailState = initialState, action: ConstraintDetailAction): Exact<ConstraintDetailState> {
  switch (action.type) {
    case ActionTypes.GET_CONSTRAINT_DETAIL_SUCCESS:
      return {
        ...state,
        constraint: action.data,
      };
    case ActionTypes.CLEAR_CONSTRAINT_DETAIL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
