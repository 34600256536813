// @flow

import * as React from 'react';
// import { connect } from 'react-redux';

import colors from '../../../styles/colors';
import Chart from './chart';
import { ProjectProgress, ProjectProgressByArea } from '../data';
import ToggleButton from '../../../components/toggle-button';

type Props = {};

type State = {
  selectedFilter: string,
}

type ViewModel = {
  labels: string[],
  values: number[],
  texts: string[],
  name: string,
  color: string,
  textColor: ?string,
};

const styles: Object = {
  widget: {
    width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  toggleButton: {
    float: 'right',
  },
  chart: {
    marginTop: '40px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class WeeklyDelayMitigation extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'overall_progress',
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState({
      selectedFilter: val,
    });
  }

  getChartData(data: any[]): Object {
    const monthlyPlanned: number[] = [];
    const monthlyActual: number[] = [];
    const cumilativePlanned: number[] = [];
    const cumilativeActual: number[] = [];

    const label: string[] = [];

    const monthlyPlannedHoverTexts: string[] = [];
    const monthlyActualHoverTexts: string[] = [];
    const cumilativePlannedHoverTexts: string[] = [];
    const cumilativeActualHoverTexts: string[] = [];

    data.forEach((item: any) => {
      monthlyPlanned.push(item.monthly_planned);
      monthlyActual.push(item.monthly_actual);
      cumilativePlanned.push(item.cumilative_planned);
      cumilativeActual.push(item.cumilative_actual);

      label.push(item.month);

      monthlyPlannedHoverTexts.push(item.monthly_planned);
      monthlyActualHoverTexts.push(item.monthly_actual);
      cumilativePlannedHoverTexts.push(item.cumilative_planned);
      cumilativeActualHoverTexts.push(item.cumilative_actual);
    });
    const traces: ViewModel[] = [
      {
        labels: label,
        values: monthlyPlanned,
        texts: monthlyPlannedHoverTexts,
        name: 'Monthly early planned',
        color: colors.themePrimaryColor,
        textColor: colors.cLevelGray,
      },
      {
        labels: label,
        values: monthlyActual,
        texts: monthlyActualHoverTexts,
        name: 'Monthly actual',
        color: colors.themeSecondaryColor,
        textColor: colors.cLevelGray,
      },
    ];

    const totalLine: ViewModel[] = [
      {
        labels: label,
        values: cumilativePlanned,
        texts: cumilativePlannedHoverTexts,
        name: 'Cumulative early planned',
        color: colors.themePrimaryColor,
        textColor: null,
      },
      {
        labels: label,
        values: cumilativeActual,
        texts: cumilativeActualHoverTexts,
        name: 'Cumulative actual',
        color: colors.themeSecondaryColor,
        textColor: null,
      },
    ];

    return {
      traces,
      totalLine,
    };
  }

  renderWidget(chartData: Object): React.Node {
    return ProjectProgress.length !== 0 ? (
      <React.Fragment>
        <Chart barData={chartData.traces} lineData={chartData.totalLine} />
      </React.Fragment>
    ) : (
      <span style={styles.noData}>No data available</span>
    );
  }

  render(): React.Node {
    const chartData: Object = this.getChartData(this.state.selectedFilter === 'overall_progress' ? ProjectProgress : ProjectProgressByArea);
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    const toggleItems: Array<*> = [
      { value: 'overall_progress', label: 'Overall Progress' },
      { value: 'by_construction_area', label: 'By Construction Area' },
    ];
    return (
      <section style={styles.widget}>
        <div style={styles.toggleButton}>
            <ToggleButton
              items={toggleItems}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
        </div>
        <div style={styles.chart}>
          {this.renderWidget(chartData)}
        </div>
      </section>
    );
  }
}

const Component: any = (WeeklyDelayMitigation);

// Component.style = {
//   width: '50%',
// };
export default Component;
