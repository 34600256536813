// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getCriticalMilestoneChangesApi from './apis';


export type CriticalMilestoneScheduleChange = {
    id: number,
    old_start_date: string,
    new_start_date: string,
    changed_on: string,
    state: string,
}
export type CriticalMilestoneChanges = {
  id: number,
  name: string,
  wbs: string,
  schedule_changes: CriticalMilestoneScheduleChange[],
};

type CriticalMilestoneChangesResponse = {
  critical_milestones: Array<CriticalMilestoneChanges>,
};

export type CriticalMilestoneChangesAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CRITICAL_MILESTONES_CHANGES_SUCCESS'>,
  data: CriticalMilestoneChangesResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CRITICAL_MILESTONES_CHANGES_FAIL'>,
|};

function* getCriticalMilestoneChanges(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: CriticalMilestoneChangesResponse } = yield call(getCriticalMilestoneChangesApi, action.data.projectId);
    const actionData: CriticalMilestoneChangesAction = { type: ActionTypes.GET_CRITICAL_MILESTONES_CHANGES_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: CriticalMilestoneChangesAction = { type: ActionTypes.GET_CRITICAL_MILESTONES_CHANGES_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_CRITICAL_MILESTONES_CHANGES, getCriticalMilestoneChanges);
}
