// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { map } from 'ramda';

import GroupedBar from '../../components/charts/grouped-bar';
import { overallManpower } from './data';
import colors from '../../styles/colors';

type Props = {}

type ViewModel = Array<{
  labels: string[],
  values: number[],
  text: string,
  color?: string,
}>;

const styles: Object = {
  widget: {
    width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    // height: 501,
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem 0.5rem 0 0.5rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const formatData: Function = function formatData(data: any): any[] {
  return map((item: any): any => (
    {
      month: item.month,
      actual: item.actual,
      planned: item.planned,
    }
  ), data);
};

class CivilWorksWidget extends React.PureComponent<Props> {
  getFormattedData(data: any[]): ViewModel {
    const actual: number[] = [];
    const planned: number[] = [];
    const month: string[] = [];
    data.forEach((item: any) => {
      actual.push(item.actual);
      planned.push(item.planned);
      month.push(item.month);
    });
    const traces: ViewModel = [
      {
        labels: month,
        values: actual,
        text: 'Overall manpower actual',
        color: colors.themePrimaryColorNew,
      },
      {
        labels: month,
        values: planned,
        text: 'Overall manpower planned',
        color: colors.themeSecondaryColorNew,
      },
    ];

    return traces;
  }

  renderWidget(): React$Element<*> {
    if (formatData(overallManpower).length === 0) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <GroupedBar
        data={this.getFormattedData(formatData(overallManpower))}
        dataOptions={{
          // textposition: 'outside',
          hoverinfo: 'none',
        }}
      />
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1>
          Civil Works
        </h1>
        {
          this.renderWidget()
        }
      </section>
    );
  }
}

const Component: any = connect()(CivilWorksWidget);

Component.style = {
  width: '98%',
};

export default Component;
