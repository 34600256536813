// @flow
import axios from 'axios';
import { getFlowBaseUrl, getBaseUrl } from '../config/config';

/* eslint-disable */
export function getDisciplineMasterListApi(
  projectContext: { companyId: number, projectId: number }
): AxiosPromise {
  return axios.get(
    `${getFlowBaseUrl()}projects/${projectContext.projectId}/discipline/masterlist`, {
      withCredentials: true
    }
  );
}

export function getCriticalWorkflowCountApi(
  projectContext: {companyId: number, projectId: number , disciplineId: number}
): AxiosPromise {
  return axios.get(
    `${getBaseUrl()}projects/${projectContext.projectId}/insights/criticalworkflowcount${projectContext.disciplineId ? `?disciplineId=${projectContext.disciplineId}` : ''}` 
  );
}

export function getCriticalWorkflowListApi(
  projectContext: {companyId: number, projectId: number , disciplineId: number}
): AxiosPromise {
  return axios.get(
    `${getBaseUrl()}projects/${projectContext.projectId}/insights/criticalworkflow?disciplineId=${projectContext.disciplineId}`
  );
}

export function getProjectDelayCountApi(
  projectContext: {companyId: number, projectId: number , disciplineId: number}
): AxiosPromise {
  return axios.get(
    `${getBaseUrl()}projects/${projectContext.projectId}/insights/projectdelaycount${projectContext.disciplineId ? `?disciplineId=${projectContext.disciplineId}` : ''}`
  );
}

export function getProjectDelayListApi(
  projectContext: {companyId: number, projectId: number , disciplineId: number}
): AxiosPromise {
  return axios.get(
    `${getBaseUrl()}projects/${projectContext.projectId}/insights/projectdelay?disciplineId=${projectContext.disciplineId}`
  );
}

export function getPotentailDelayCountApi(
  projectContext: {companyId: number, projectId: number , disciplineId: number}
): AxiosPromise {
  return axios.get(
    `${getFlowBaseUrl()}projects/${projectContext.projectId}/insights/potentialdelaycount${projectContext.disciplineId ? `?disciplineId=${projectContext.disciplineId}` : ''}`, {
      withCredentials: true,
    }
  );
}

export function getPotentailDelayListApi(
  projectContext: {companyId: number, projectId: number , disciplineId: number}
): AxiosPromise {
  return axios.get(
    `${getFlowBaseUrl()}projects/${projectContext.projectId}/insights/potentialdelay?disciplineId=${projectContext.disciplineId}`, {
      withCredentials: true,
    }
  );
}

export function getTopDelayReasonsListApi(
  projectContext: {companyId: number, projectId: number , disciplineId: number}
): AxiosPromise {
  return axios.get(
    `${getFlowBaseUrl()}projects/${projectContext.projectId}/dashboard/delay?disciplineId=${projectContext.disciplineId}&groupBy=reason`, {
      withCredentials: true,
    }
  );
}