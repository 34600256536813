// @flow

import ActionTypes from './action-types';
import type { MilestoneDetailResponseType, MilestoneDetailAction } from './sagas';

export type MilestoneDetailState = {
  milestone: MilestoneDetailResponseType | null,
  isLoading: boolean,
};

const initialState: MilestoneDetailState = {
  isLoading: true,
  milestone: null,
};


type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: MilestoneDetailState = initialState, action: MilestoneDetailAction): Exact<MilestoneDetailState> {
  switch (action.type) {
    case ActionTypes.GET_MILESTONE_DETAIL_SUCCESS:
      return {
        ...state,
        milestone: action.data,
      };
    case ActionTypes.CLEAR_MILESTONE_DETAIL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
