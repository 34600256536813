// @flow

import axios from 'axios';
import { getBaseUrl } from '../config/config';

export default function getOthersDelaysApi(projectId: string, week: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/other_delays`, {
    params: { start_date: week },
  });
}
