// @flow

import React, { Fragment } from 'react';
import type { Node, Element } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { isEmpty, pathOr } from 'ramda';

import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import activityIcon from '../../styles/icons/activity.png';
import constraintIcon from '../../styles/icons/constraint.png';
import Loader from '../../components/loader';
import VersionTextMessage from '../../utils/versionText';

import type { TopCriticalPathDelayedActivitiesState } from './reducer';
import type { CriticalPathDelayedActivity } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import delayTexts from '../../utils/delay-reasons';
import ToggleButton from '../../components/toggle-button';
import CriticalMilestoneChanges from '../critical-milestones-changes';

import './styles.css';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: TopCriticalPathDelayedActivitiesState,
  project: Project,
  versionDates: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  tabContainer: {
    marginBottom: 16,
  },
  table: {
    width: '100%',
  },
  tbodyContainer: {
    display: 'block',
    overflowY: 'scroll',
    maxHeight: '320px',
  },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  deletedGrey: {
    color: '#6f6f6f',
    pointerEvents: 'none',
  },
  parentHierarchy: {
    fontSize: '0.70rem',
    marginTop: '0.2rem',
  },
  reason: {
    textTransform: 'capitalize',
  },
  cause: {
    fontSize: '0.7rem',
  },
  causeName: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
  note: {
    fontSize: '0.8rem',
    marginTop: '15px',
  },
};

type State = {
  order: string,
  column: string,
  currentTab: 'project' | 'milestones'
};

class TopCriticalPathDelayedActivitiesWidget extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      order: 'desc',
      column: 'project_delay',
      currentTab: 'project',
    };
  }

  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES,
      data: { projectId: this.props.project.id },
    });
  }

  // getParentHierarchy(activity: CriticalPathDelayedActivity): string {
  //   const parents: Array<string> = activity.parent.split(' | ').reverse();
  //   parents.pop();
  //   return parents.join(' ➔ ');
  // }

  // getCauseDetails(activity: CriticalPathDelayedActivity): React.Element<any> | null {
  //   const causes: Object = {
  //     activity_constraint: 'activity constraint',
  //     task: 'task',
  //     task_constraint: 'task constraint',
  //     activity_dependency: 'activity dependency',
  //     task_dependency: 'task dependency',
  //   };

  //   if (activity.cause === null) {
  //     return (
  //       <span>
  //         <span style={styles.cause}>Activity</span>
  //       </span>
  //     );
  //   }

  //   return (
  //     <span>
  //       <span style={styles.cause}>Delayed due to the {causes[activity.cause]}</span> <em style={styles.causeName}>{activity.cause_name}</em>
  //     </span>
  //   );
  // }

  onSortChange(column: string) {
    this.setState(
      (prevState: State): State => ({
        column,
        order: prevState.order === 'desc' ? 'asc' : 'desc',
      }),
    );
    // this.setState({
    //   column,
    //   order: this.state.order === 'desc' ? 'asc' : 'desc',
    // });
  }

  onTabChange = (tab: string) => {
    this.setState({
      currentTab: tab,
    });
  }

  getReasons(reasons: string[]): string {
    let texts: string[] = reasons.map(
      (reason: string): string => {
        if (reason === null) {
          return '';
        }
        if (delayTexts[reason]) {
          return delayTexts[reason];
        }
        if (reason.match(/others: pending/)) {
          return 'Pending';
        }
        if (reason.match(/others:/)) {
          return 'Others';
        }
        return reason;
      },
    );
    // remove empty strings
    texts = texts.filter((text: string): boolean => !!text);

    if ([...new Set(texts)].includes('Others')) {
      const reason: any = [...new Set(texts)];
      reason.splice(reason.indexOf('Others'), 1);
      return reason.join(', ');
    }
    return [...new Set(texts)].join(', ');
  }

  getActivityName(activity: Object): string {
    if (activity.reason.indexOf('first_lookahead') > -1) {
      return `*${activity.name || 'First lookahead generation'}`;
    }
    if (activity.reason.indexOf('second_version') > -1) {
      return `** ${activity.name}`;
    }
    if (activity.deleted) {
      return `\u271D(Deleted) ${activity.name}`;
    }
    return activity.name;
  }

  showActivityDetailModal = (activity: CriticalPathDelayedActivity): null => {
    // if (window.__r2d2) {
    //   return null;
    // }
    this.props.dispatch({
      type: 'showModal',
      modalType: 'activityDetail',
      modalProps: {
        activityId: activity.id,
        activityName: activity.name,
      },
    });
    return null;
  };

  showConstraintDetailModal = (artifact: CriticalPathDelayedActivity): null => {
    // if (window.__r2d2) {
    //   return null;
    // }
    this.props.dispatch({
      type: 'showModal',
      modalType: 'constraintDetail',
      modalProps: {
        constraintId: artifact.id,
        constraintName: artifact.name,
      },
    });
    return null;
  };

  showOthersDelaysModal = (): null => {
    if (window.__r2d2) {
      return null;
    }
    this.props.dispatch({
      type: 'showModal',
      modalType: 'othersDelays',
      modalProps: {
        projectId: this.props.project.id,
        week: this.props.week,
      },
    });
    return null;
  }

  renderTable(): Element<any> {
    const sortedData: CriticalPathDelayedActivity[] = this.props.data.chartData.sort(
      (item1: CriticalPathDelayedActivity, item2: CriticalPathDelayedActivity): number => {
        if (this.state.order === 'asc') {
          if (item1[this.state.column] < item2[this.state.column]) {
            return -1;
          }
          if (item1[this.state.column] > item2[this.state.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.column] < item2[this.state.column]) {
          return 1;
        }
        if (item1[this.state.column] > item2[this.state.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (this.props.data.chartData.length === 0 && this.props.data.loading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <Fragment>
        <table className="table-container">
          <thead className="thead-container">
            <tr>
              <th style={{ width: 650 }} className="header" onClick={this.onSortChange.bind(this, 'name')}>
                Activity/ Constraint
                {this.state.column === 'name' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: 230 }} className="header" onClick={this.onSortChange.bind(this, 'delay')}>
                Activity/ Constraint Delay
                (days)
                {this.state.column === 'delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: 150 }} className="header" onClick={this.onSortChange.bind(this, 'project_delay')}>
                Project Delay (days)
                {this.state.column === 'project_delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th className="header" onClick={this.onSortChange.bind(this, 'reason')}>
                Reason
                {this.state.column === 'reason' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
            </tr>
          </thead>
          <tbody style={styles.tbodyContainer}>
            {sortedData.map(
              (artifact: CriticalPathDelayedActivity, i: number): Node => (
                <tr
                  key={i}
                  style={artifact.deleted || artifact.reason.indexOf('first_lookahead') > -1 || artifact.reason.indexOf('second_version') > -1 || artifact.reason.indexOf('version_change') > -1 ? styles.deletedGrey : null}
                >
                  <td style={{ width: 650 }}>
                    <a onClick={artifact.artifact === 'activity' ? this.showActivityDetailModal.bind(this, artifact) : this.showConstraintDetailModal.bind(this, artifact)} className="underline-hover" style={{ display: 'flex' }}>
                        <span style={{ marginRight: artifact.artifact === 'activity' ? 7 : 4, marginLeft: artifact.artifact === 'activity' ? 3 : 0 }}>
                          <img
                            width={artifact.artifact === 'activity' ? '10' : '16'}
                            src={artifact.artifact === 'activity' ? activityIcon : constraintIcon}
                            alt="i"
                          />
                        </span>
                      {this.getActivityName(artifact)}
                    </a>
                  </td>
                  <td style={{ width: 230 }}>{artifact.delay}</td>
                  <td style={{ width: 150 }}>{artifact.project_delay === null ? 'NA' : artifact.project_delay}</td>
                  {
                    artifact.reason.join('').match(/others:/)
                      ? <td style={styles.reason}>
                        {
                          <Fragment>
                            <a onClick={this.showOthersDelaysModal} className="underline-hover">Others</a>
                            <span>{`, ${this.getReasons(artifact.reason)}`}</span>
                          </Fragment>
                        }
                        </td>
                      : <td style={styles.reason}>{this.getReasons(artifact.reason)}</td>

                  }
                </tr>
              ),
            )}
          </tbody>
        </table>
        <div style={styles.note}>* Delay on project end date due to rescheduling of various activities on first lookahead generation</div>
        <div style={styles.note}>
          &#10013; Project delay due to the deleted activity or deleted tasks, task constraints or dependencies under the activity
        </div>
        <div style={styles.note}>** Delay on project end date due to new masterplan version upload</div>
      </Fragment>
    );
  }

  getContent = (): Element<any> => {
    if (this.state.currentTab === 'project') {
      return this.renderTable();
    }
    return <CriticalMilestoneChanges project={this.props.project} />;
  }

  render(): Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Project Delays and Advances
          {' '}
          <img
            data-tip="This widget shows two tables. The first table shows the list of top critical path activities and constraints that have caused a project delay along with the total days of delay it has gone through and the reasons of these delays. The second table shows the list of critical milestones in the plan with a history of all the schedule changes they have undergone. On click of any record related to a critical milestone, the root cause that impacted the change in the milestone schedule are shown."
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        <div style={styles.tabContainer}>
          <ToggleButton
            items={[
              { value: 'project', label: 'Project' },
              { value: 'milestones', label: 'Critical milestones' },
            ]}
            value={this.state.currentTab}
            onChange={this.onTabChange}
          />
        </div>
        {this.props.data.loading === null ? <Loader /> : this.getContent()}
        {
          this.props.data.loading === false && !isEmpty(this.props.versionDates)
          && <VersionTextMessage versionDates={this.props.versionDates} />
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: TopCriticalPathDelayedActivitiesState } => ({
    data: state.topCriticalPathDelayedActivities,
    versionDates: pathOr({}, ['project', 'versionDates'], state),
  }),
)(TopCriticalPathDelayedActivitiesWidget);

component.style = {
  width: '98%',
};

export default component;
