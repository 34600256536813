// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { map } from 'ramda';
import colors from '../../styles/colors';
import ResponsivePieChart from '../../components/charts/pie';


type Props = {}

type ViewModel = {
  label: string,
  value: number,
  color?: string,
  text?: string,
}

const LabelStrings: Object = {
  as_planned: 'As Planned',
  extended: 'Extended',
  shortened: 'Shortened',
  delayed: 'Delayed',
  earlier: 'Earlier',
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const formatData: Function = function formatData(data: any): any[] {
  return map((key: string): any => (
    {
      id: key,
      value: data[key].value,
      percentage: data[key].percentage,
    }
  ), Object.keys(data));
};

const dummyData: any = {
  as_planned: {
    value: 10,
    percentage: 55.6,
  },
  delayed: {
    value: 8,
    percentage: 44.4,
  },
  shortened: {
    value: 0,
    percentage: 0,
  },
};

const ColorConfig: Object = {
  'As Planned': colors.themeSecondaryColorNew,
  Delayed: colors.themePrimaryColorNew,
  Shortened: colors.newYellow,
};

class ActivityProfileDate extends React.PureComponent<Props> {
  getFormattedData(data: Array<Object>): ViewModel[] {
    return data.map((item: Object): ViewModel => ({ label: LabelStrings[item.id], value: item.percentage, text: `${item.value} activities` }));
  }

  getChartData(data: Array<Object>): ViewModel[] {
    return data.map(
      (item: Object): Object => ({
        label: item.label,
        value: item.value,
        color: ColorConfig[item.label],
        text: item.text,
      }),
    );
  }

  getWidget(): React.Node {
    if (formatData(dummyData).length !== 0) {
      return (
        <div>
          <div>
            <ResponsivePieChart data={this.getChartData(this.getFormattedData(formatData(dummyData)))} />
          </div>
        </div>
      );
    }
    return (
      <span style={styles.noData}>
      No data available
      </span>
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    return (
      <section style={styles.widget}>
        <h1>
          {'Plan Reliability Categorization (Start Date)'}
          {' '}
        </h1>
        {
         this.getWidget()
        }
      </section>
    );
  }
}

const Component: any = connect()(ActivityProfileDate);

Component.style = {
  width: '31%',
};

export default Component;
