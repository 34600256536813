// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import { getCostProgressApi } from './apis';

export type CostProgress = {
  actual_cost: number;
  earned_value: number;
  time: string;
};

export type CostsByPeriod = {
  estimatedValue: number;
  periodEndDate: string;
  periodKey: string;
  periodStartDate: string;
  plannedValue: number;
  spi: number;
}

export type CostProgressResponse = {
  scheduleVariance: number;
  scheduleVarianceMeasureWeekID: string;
  costsByPeriod: CostsByPeriod[];
  plannedCostsByPeriod: CostsByPeriod[]
}

export type CostProgressAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_COST_TIME_SUCCESS'>,
  data: CostProgressResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_COST_TIME_FAIL'>,
|};

function* getCostProgress(action: {
  data: { projectId: string },
}): Generator<IOEffect, void, any> {
  // eslint-disable-line
  try {
    const response: { data: CostProgressResponse } = yield call(getCostProgressApi, action.data.projectId);

    yield put({
      type: ActionTypes.GET_COST_TIME_SUCCESS,
      data: response.data,
    });
  } catch (e) {
    yield put({ type: ActionTypes.GET_COST_TIME_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_COST_TIME, getCostProgress);
}
