// @flow
/* eslint-disable */
import React from 'react';

import './tab-button.css';

type Props = {
  label: string;
  onChangeTab: (e: React.ChangeEventHandler<HTMLInputElement>) => void;
  value: string;
  selectedValue: string;
  name: String;
}


type TabGroupProps = Props & {
  items: { label: string, value: string }[];
}

const TabButton: Function = (props: Props): React.ReactNode => {
  const isChecked: boolean = props.value === props.selectedValue;

  return (
  <label className="btn-container">
    <input checked={isChecked} name="tabs" value={props.value} type="radio" className="tab-radio" onChange={props.onChangeTab} />
    <div className="tab-label">{props.label}</div>
  </label>
  );
};

TabButton.Group = (props: TabGroupProps): React.ReactNode => {
  const {items,...otherProps } =props;

  return (
    <div className="tab-group">
      {items.map((item: { label: string, value: string }): TabButton => (<TabButton
        key={item.value}
        {...item}
        {...otherProps}
      />))}
    </div>
  );
};

export default TabButton;
