// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { isEmpty, pathOr } from 'ramda';

import GroupedBar from '../../components/charts/grouped-bar';
import ActionTypes from './action-types';
import colors from '../../styles/colors';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import VersionTextMessage from '../../utils/versionText';

import type { WeeklyWorkableBacklogState, WeeklyWorkableBacklogItem } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: WeeklyWorkableBacklogState,
  project: Project,
  shortWeeks: any,
  versionChangeWeeks: any,
  versionDates: string;
}

type ViewModel = Array<{
  labels: string[],
  values: number[],
  text: string,
  color?: string,
}>;

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    // height: 501,
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class WorkableBacklog extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG,
      data: { projectId: this.props.project.id },
    });
  }

  getFormattedData(data: WeeklyWorkableBacklogItem[]): ViewModel {
    const shouldDo: number[] = [];
    const canDo: number[] = [];
    const willDo: number[] = [];
    const done: number[] = [];
    const dates: string[] = [];
    data.forEach((item: WeeklyWorkableBacklogItem) => {
      shouldDo.push(item.shouldDo);
      canDo.push(item.canDo);
      willDo.push(item.willDo);
      done.push(item.done);
      dates.push(moment(item.endDate).format('DD MMM YYYY'));
    });
    const traces: ViewModel = [
      {
        labels: dates,
        values: shouldDo,
        text: 'Should Do',
        color: colors.tundora,
      },
      {
        labels: dates,
        values: canDo,
        text: 'Can Do',
        color: colors.cerulean,
      },
      {
        labels: dates,
        values: willDo,
        text: 'Will Do',
        color: colors.orange,
      },
      {
        labels: dates,
        values: done,
        text: 'Did',
        color: colors.aquaForest,
      },
    ];

    return traces;
  }

  getOptions(chartData: WeeklyWorkableBacklogItem[]): any {
    const annotations: any = [];
    chartData.forEach((data: ProjectWeeklyProgressType, index: number) => {
      if (this.props.shortWeeks[data.endDate]) {
        annotations.push({
          x: index,
          y: 0,
          text: 'Lookahead day change',
          showarrow: true,
          font: {
            size: 10,
            color: 'red',
          },
          borderColor: 'red',
          arrowhead: 0.5,
          ax: 0,
          ay: this.props.versionChangeWeeks[data.endDate] ? 15 : 20,
          arrowcolor: 'red',
          arrowsize: 0.3,
        });
      }
      if (this.props.versionChangeWeeks[data.endDate]) {
        annotations.push({
          x: this.props.shortWeeks[data.endDate] ? index + (chartData.length / 1000) : index,
          y: 0,
          text: 'Version change',
          showarrow: true,
          font: {
            size: 10,
            color: 'blue',
          },
          borderColor: 'blue',
          arrowhead: 0.5,
          ax: 0,
          ay: this.props.shortWeeks[data.endDate] ? 30 : 20,
          arrowcolor: 'blue',
          arrowsize: 0.3,
        });
      }
    });
    return {
      annotations,
    };
  }

  renderWidget(): React$Element<*> {
    if (this.props.data.weeklyWorkableBacklog.length === 0 && this.props.data.weeklyWorkableBacklogLoading === false) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <GroupedBar
        data={this.getFormattedData(this.props.data.weeklyWorkableBacklog)}
        options={this.getOptions(this.props.data.weeklyWorkableBacklog)}
      />
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
Weekly Workable Backlog
          <img data-tip="This chart provides the total number of tasks that the team SHOULD/ CAN/ WILL/ and DID in a week" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.weeklyWorkableBacklogLoading === null
            ? <Loader /> : this.renderWidget()
        }
        {
          this.props.data.weeklyWorkableBacklogLoading === false && !isEmpty(this.props.versionDates)
          && <VersionTextMessage versionDates={this.props.versionDates} />
        }
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: WeeklyWorkableBacklogState } => (
    {
      data: state.weeklyWorkableBacklog,
      shortWeeks: pathOr({}, ['project', 'shortWeeks'], state),
      versionChangeWeeks: pathOr({}, ['project', 'versionChangeWeeks'], state),
      versionDates: pathOr({}, ['project', 'versionDates'], state),
    }
  ),
)(WorkableBacklog);

Component.style = {
  width: '98%',
};

export default Component;
