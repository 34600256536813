// @flow

import axios from 'axios';
import { getBaseUrl } from '../../config/config';

export function getEndDateProfile(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/end_date_profile${startDate ? `?start_date=${startDate}` : ''}`);
}

export function getEndDateProfileTask(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/task_end_date_profile?start_date=${startDate}`);
}
