// @flow

import axios from 'axios';
import { getBaseUrl } from '../../config/config';

function getFilterQuery(filter: string): string {
  if (filter === 'project_delay' || filter === 'artifact_delay') {
    return `delay_sort=${filter}&`;
  }
  return '';
}

export default function getReasonForDelays(projectId: string, filter: string, startDate: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/variance_count?${getFilterQuery(filter)}${startDate ? `start_date=${startDate}` : ''}`);
}
