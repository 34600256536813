// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './week-item.css';

const styles: Object = {
  activeWeekRectangle: {
    border: 'solid 2px #ff6500',
  },
  activeWeekLine: {
    border: 'solid 1px #ff6500',
  },
  activeWeekFontColor: {
    color: '#ff6500',
  },
};

type Props = {
  weekStart: string;
  dates: Object,
  projectId: string,
  toggleMenu: Function,
  weekNumber: number,
  companyId: string
};

const WeekItem: Function = function WeekItem(props: Props): React.Node {
  let companyUrl: string = '';
  if (props.companyId) {
    companyUrl = `/c/${props.companyId}`;
  }
  return (
    <Link to={`${companyUrl}/projects/${props.projectId}/weekly/${props.dates.start}`} className="tab-text-week-item" onClick={props.toggleMenu}>
      <div className="rectangle-week-item" style={(props.weekStart === props.dates.start) ? styles.activeWeekRectangle : null}>
        <div className="dates-week-item" style={(props.weekStart === props.dates.start) ? styles.activeWeekFontColor : null}>
          <div>{moment(props.dates.start).format('DD MMM YYYY')}</div>
          <div>-</div>
          <div>{moment(props.dates.end).format('DD MMM YYYY')}</div>
        </div>
        <div className="line-week-item" style={(props.weekStart === props.dates.start) ? styles.activeWeekLine : null} />
        <div className="week-number-week-item" style={(props.weekStart === props.dates.start) ? styles.activeWeekFontColor : null}>
          Week
          {' '}
          {props.weekNumber}
        </div>
      </div>
    </Link>
  );
};

export default WeekItem;
