// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getConstraintDetailApi from './apis';

export type DelayType = {
  delay: number,
  id: number,
  variance: string,
};

export type ConstraintDetailResponseType = {
  base_due_date: string,
  due_date: string,
  task_name: string,
  activity_name: string,
  constraint_delays: DelayType[],
};

export type ConstraintDetailAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CONSTRAINT_DETAIL_SUCCESS'>,
  data: ConstraintDetailResponseType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CONSTRAINT_DETAIL_FAIL'>,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'CLEAR_CONSTRAINT_DETAIL'>,
|};

function* getConstraintDetail(action: { data: { projectId: string, constraintId: number } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: ConstraintDetailResponseType } = yield call(getConstraintDetailApi, action.data.projectId, action.data.constraintId);
    yield put({ type: ActionTypes.GET_CONSTRAINT_DETAIL_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_CONSTRAINT_DETAIL_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_CONSTRAINT_DETAIL, getConstraintDetail);
}
