// @flow

// eslint-disable-next-line flowtype/require-return-type
export const getBaseDomainUrl: any = () => {
  const host: any = window.location.host;
  if (host.includes('app.theplando')) {
    return 'https://app.theplando.com';
  }
  if (host.includes('lean.theplando')) {
    return 'https://lean.theplando.com';
  }
  if (host.includes('app.leanplando')) {
    return 'https://app.leanplando.com';
  }
  return '';
};

let baseUrl: string = '/api/';

export function setBaseUrl(url: string) {
  baseUrl = `/api/${url}`;
}

export function getBaseUrl(): string {
  return baseUrl;
}

let flowBaseUrl: string = '/api/flow/';

export function setFlowBaseUrl(url: string) {
  flowBaseUrl = `/api/flow/${url}`;
}

export function getFlowBaseUrl(): string {
  const root: string = getBaseDomainUrl();

  return `${root}${flowBaseUrl}`;
}
