// @flow
// import { compose, toUpper, lensIndex, over, join } from 'ramda';

import ActionTypes from './action-types';
import type { ReasonForDelayAction, ReasonForDelayResponseItem } from './sagas';
import delayTexts from '../../utils/delay-reasons';

// const delayTexts: Object = {
//   access_space: 'Access / Space',
//   approval_permits: 'Approval / Permits',
//   client_decision: 'Client decision',
//   contracts: 'Contracts',
//   drawings: 'Drawings',
//   equipment: 'Equipment',
//   inspection: 'Inspection',
//   labor: 'Labor',
//   logistics_storage: 'Logistics / Storage',
//   material: 'Material',
//   method_statement: 'Method statement',
//   minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
//   rfi: 'RFI',
//   safety_requirement: 'Safety requirement',
//   re_scheduling: 'Re-Scheduling ',
//   unforeseen_site_conditions: 'Unforeseen site conditions',
//   weather: 'Weather',
//   schedule_error: 'Schedule error',
//   defects_rework: 'Defects rework',
//   first_lookahead: 'Delay on First Lookahead',
//   others: 'Others',
//   do_list_confirmation: 'Do List Confirmation',
//   change_in_dependency: 'Change In Dependency',
//   creation_of_dependency: 'Creation of Dependency',
// };

export type ReasonForDelayItem = {
  label: string,
  value: number,
  classification: string,
  projectDelay: number,
  artifactDelay: number,
};

export type ReasonForDelaysState = {
  chartData: ReasonForDelayItem[],
  loading: boolean | null,
};

// const toTitle: Function = compose(join(''), over(lensIndex(0), toUpper));

const getFormattedData: Function = function getFormattedData(
  data: ReasonForDelayResponseItem[],
): ReasonForDelayItem[] {
  const list: ReasonForDelayItem[] = data.map(
    (item: ReasonForDelayResponseItem): ReasonForDelayItem => ({
      label: delayTexts[item.type] || item.type,
      value: item.count,
      classification: item.classification,
      projectDelay: item.project_delay,
      artifactDelay: item.artifact_delay,
    }),
  );
  return list;
};

const initialState: ReasonForDelaysState = { loading: null, chartData: [] };

export default function (
  state: ReasonForDelaysState = initialState,
  action: ReasonForDelayAction,
): ReasonForDelaysState {
  switch (action.type) {
    case ActionTypes.GET_REASON_FOR_DELAYS_SUCCESS:
      return {
        ...state,
        chartData: getFormattedData(action.data.variance),
        loading: false,
      };
    default:
      return state;
  }
}
