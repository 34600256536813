// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import { compose } from 'recompose';
import type { MapStateToProps, Connector } from 'react-redux';

import withProjectContext from '../auth/with-project-context';
import { Text } from '../styles/colors';

import type { dashboardState, widget as widgetType } from './reducer';
import type { Reducers } from '../reducers';
import type { Project } from '../auth/with-project-context';

// import Header from './header';

import DelayReasonsWidget from '../widgets/delay-reasons';
import DelayResponsibilityWidget from '../widgets/delay-responsibility';
import ReasonForDelaysWidget from '../widgets/reason-for-delays';
import ProjectWeeklyProgressWidget from '../widgets/project-weekly-progress';
import ProjectWeeklyEarlyLateProgressWidget from '../widgets/project-weekly-early-late-progress';
import ProjectWeeklyWeightProgressWidget from '../widgets/project-weekly-weight-progress';
import ProjectWeeklyCostProgressWidget from '../widgets/project-weekly-cost-progress';
import TopMostDelayedActivities from '../widgets/top-most-delayed-activities';
import TopExtendedActivities from '../widgets/top-extended-activities';
import TopDelayedConstraints from '../widgets/top-delayed-constraints';
import TopEarlyFinishedActivities from '../widgets/top-early-finished-activities';
import TopShortenedActivities from '../widgets/top-shortened-activities';
import ActivityProfileDuration from '../widgets/activity-profile-duration';
import ActivityProfileDate from '../widgets/activity-profile-date';
import ActivityProfileEndDate from '../widgets/activity-profile-enddate';
import WeeklyActivityProfileDuration from '../widgets/weekly-activity-profile-duration';
import WeeklyActivityProfileDate from '../widgets/weekly-activity-profile-date';
import WeeklyActivityProfileEndDate from '../widgets/weekly-activity-profile-end-date';
import DelayMitigationEffortWidget from '../widgets/delay-mitigation-effort';
import TopCriticalPathDelayedActivities from '../widgets/top-critical-path-delayed-activities';
import ProjectStatusWidget from '../widgets/project-status';
import ProjectProjectionWidget from '../widgets/project-projection';
import WeeklyPPCWidget from '../widgets/weekly-ppc';
import DelaysAndMitigations from '../widgets/delays-and-mitigations';
// import WeeklyDelayMitigation from '../widgets/weekly-delay-mitigation';
// import WeeklyProductivityImprovement from '../widgets/weekly-productivity-improvement';
import WeeklyMitigationAndImprovementEffort from '../widgets/weekly-mitigation-improvement-effort';
import WeeklyWorkableBacklog from '../widgets/weekly-workablebacklog';
import WeeklyCommitmentReliability from '../widgets/weekly-commitment-reliability';
import WorkDelayWidget from '../widgets/work-delay-widget-weekly';
import ConstraintDelayWidget from '../widgets/constraint-delay-widget-weekly';
import CriticalMilestoneStatus from '../widgets/critical-milestone-status';
import CostPerformance from '../widgets/cost-performance';
import CostProgress from '../widgets/cost-progress';
import FlowStatus from '../flow/widgets/flow-status';
import ForceStarted from '../widgets/force-started';

import RootModal from '../modals/root-modal';

import ProjectActions from '../project/action-types';

import { ProjectFeatures } from '../project/reducer';

type Props = {
  dispatch: Function,
  store: dashboardState,
  project: Project,
  projectDetails: Object,
  features: ProjectFeatures
};

const widgetTypes: Object = {
  delayReasons: DelayReasonsWidget,
  delayResponsibility: DelayResponsibilityWidget,
  reasonForDelays: ReasonForDelaysWidget,
  projectWeeklyProgress: ProjectWeeklyProgressWidget,
  projectWeeklyEarlyLateProgress: ProjectWeeklyEarlyLateProgressWidget,
  projectWeeklyWeightProgress: ProjectWeeklyWeightProgressWidget,
  projectWeeklyCostProgress: ProjectWeeklyCostProgressWidget,
  topMostDelayedActivities: TopMostDelayedActivities,
  topExtendedActivities: TopExtendedActivities,
  topDelayedConstraints: TopDelayedConstraints,
  topEarlyFinishedActivities: TopEarlyFinishedActivities,
  topShortenedActivities: TopShortenedActivities,
  activityProfileDuration: ActivityProfileDuration,
  activityProfileDate: ActivityProfileDate,
  activityProfileEndDate: ActivityProfileEndDate,
  weeklyActivityProfileDate: WeeklyActivityProfileDate,
  weeklyActivityProfileDuration: WeeklyActivityProfileDuration,
  weeklyActivityProfileEndDate: WeeklyActivityProfileEndDate,
  delayMitigationEffort: DelayMitigationEffortWidget,
  topCriticalPathDelayedActivities: TopCriticalPathDelayedActivities,
  projectStatus: ProjectStatusWidget,
  weeklyPPC: WeeklyPPCWidget,
  projectProjection: ProjectProjectionWidget,
  // weeklyDelayMitigation: WeeklyDelayMitigation,
  // weeklyProductivityImprovement: WeeklyProductivityImprovement,
  weeklyMitigationImprovementEffort: WeeklyMitigationAndImprovementEffort,
  workDelay: WorkDelayWidget,
  constraintDelay: ConstraintDelayWidget,
  delaysAndMitigations: DelaysAndMitigations,
  weeklyWorkableBacklog: WeeklyWorkableBacklog,
  weeklyCommitmentReliability: WeeklyCommitmentReliability,
  criticalMilestoneStatus: CriticalMilestoneStatus,
  CostPerformance,
  CostProgress,
  FlowStatus,
  ForceStarted,
};

const styles: Object = {
  emptyState: {
    textAlign: 'center',
    paddingTop: '10rem',
    lineHeight: 1.8,
    heading: {
      fontSize: '1.3rem',
    },
    subheading: {
      color: Text.secondary,
      ':hover': {
        color: 'black',
        cursor: 'pointer',
      },
    },
  },
  // widgetsContainer: {
  //   padding: '1rem',
  //   paddingTop: '2rem',
  // },
  // widget: {
  //   display: 'inline-block',
  //   width: '48%',
  //   marginBottom: '2%',
  //   marginLeft: '1%',
  //   marginRight: '1%',
  //   verticalAlign: 'top',
  //   '@media (max-width: 800px)': {
  //     width: '100%',
  //   },
  // },
};

class DashboardContainer extends React.PureComponent<Props> {
  static defaultProps: Props;

  componentDidMount() {
    this.props.dispatch({
      type: ProjectActions.GET_COMPANIES,
      data: { projectId: this.props.project.id },
    });
  }

  renderPlaceholder(): React.Node {
    return (
      <div style={styles.emptyState}>
        <h1 style={styles.emptyState.heading}>You have not added any widgets yet</h1>
        <h2 style={styles.emptyState.subheading}>Customise your dashboard by adding widgets. Click here to add widgets</h2>
      </div>
    );
  }

  renderWidgets(): React.Node {
    if (R.isEmpty(this.props.store.widgets)) {
      return this.renderPlaceholder();
    }

    if (!this.props.projectDetails) {
      return null;
    }

    const shouldShowCost: boolean = this.props.projectDetails.preferences && this.props.projectDetails.preferences.cost
      && this.props.projectDetails.preferences.cost.cost_enabled && this.props.projectDetails.company_role
      === 'Main Contractor' && this.props.projectDetails.logged_user.permissions >= 1.5;

    const filteredWidgets: widgetType[] = this.props.store.widgets.filter((widget: widgetType): React.Element<any> => {
      // We are filtering out cost widgets when feature is not enabled
      if (['CostPerformance', 'CostProgress'].includes(widget.name)) {
        if (!this.props.features.costPlanning) {
          return false;
        }
      }

      if (widget.name === 'FlowStatus' && this.props.projectDetails.type !== 'flowschedule') {
        return false;
      }

      if (widget.name === 'ForceStarted' && this.props.projectDetails.type !== 'flowschedule') {
        return false;
      }

      if (widget.name === 'projectWeeklyWeightProgress' && !this.props.projectDetails.isProgressWeightageEnabled) {
        return false;
      }

      return true;
    });

    return (
      <ul className="widget-container">
        {filteredWidgets.map((widget: widgetType): React.Element<any> => {
          const Widget: $Values<typeof widgetTypes> = widgetTypes[widget.name];
          if (!shouldShowCost && widget.name === 'projectWeeklyCostProgress') {
            return <span key="null" />;
          }
          if (widget.name === 'FlowStatus') {
            return <li key={widget.name} className="widget" style={{ width: '98%' }}><Widget projectId={this.props.project.id} /></li>;
          }
          return <li key={widget.name} className="widget" style={Widget.style}><Widget project={this.props.project} /></li>;
        })}
      </ul>
    );
  }

  render(): React.Node {
    return (
      <section>
        {/* <Header /> */}
        {this.renderWidgets()}
        <RootModal />
      </section>
    );
  }
}

// export default compose(
//   connect((state: Reducers): Object => ({ store: state.dashboard, projectDetails: state.project.projectDetails })),
//   withProjectContext,
// )(DashboardContainer);

type TMapStateToFilter = MapStateToProps<Reducers, any, any>

const mapStateToProps: TMapStateToFilter = (state: any): Object => ({ store: state.dashboard, projectDetails: state.project.projectDetails, features: state.project.features });

const connector: Connector<any, Props> = compose(connect(mapStateToProps), withProjectContext);

export default connector(DashboardContainer);
