// @flow

export type CriticalMilestonesChanges = {
  GET_CRITICAL_MILESTONES_STATUS: 'getCriticalMilestonesStatus',
  GET_CRITICAL_MILESTONES_STATUS_SUCCESS: 'getCriticalMilestonesStatusSuccess',
  GET_CRITICAL_MILESTONES_STATUS_FAIL: 'getCriticalMilestonesStatusFail',
}

const actionTypes: CriticalMilestonesChanges = {
  GET_CRITICAL_MILESTONES_STATUS: 'getCriticalMilestonesStatus',
  GET_CRITICAL_MILESTONES_STATUS_SUCCESS: 'getCriticalMilestonesStatusSuccess',
  GET_CRITICAL_MILESTONES_STATUS_FAIL: 'getCriticalMilestonesStatusFail',
};

export default actionTypes;
