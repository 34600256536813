// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { isEmpty, pathOr } from 'ramda';
import ActionTypes from './action-types';
import LineChart from '../../components/charts/line';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import CandleBar from '../../components/candle-bar';
import Loader from '../../components/loader';
import VersionTextMessage from '../../utils/versionText';

import type { WeeklyCommitmentReliabilityState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import type { WeeklyCommitmentReliability as WeeklyCommitmentReliabilityType } from './sagas';
import withProjectContext from '../../auth/with-project-context';
import colors from '../../styles/colors';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: WeeklyCommitmentReliabilityState,
  project: Project,
  week?: string,
  shortWeeks: any,
  versionChangeWeeks: any,
  versionDates: string;
}

type State = {
  selectedFilter: string,
}

type ViewModel = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  meta: {
    fontSize: '0.85rem',
    position: 'relative',
    top: '-1.9rem',
  },
  candleBar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '30px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class WeeklyCommitmentReliability extends React.Component<Props, State> {
  // constructor(props: Props) {
  //   super(props);
  // this.onFilterChange = this.onFilterChange.bind(this);
  // }

  // state = {
  //   selectedFilter: '_all',
  // }

  static defaultProps={
    week: '',
  }

  componentDidMount() {
    this.fetchData(this.props.week);
  }

  componentWillReceiveProps(nProps: Props) {
    if (nProps.week !== this.props.week) {
      this.fetchData(nProps.week);
    }
  }

  // onFilterChange: Function;

  // onFilterChange(val: Object) {
  //   this.setState({
  //     selectedFilter: val.value,
  //   }, this.fetchData);
  // }

  getData(): ViewModel[] {
    const labels: string[] = [];
    const constraintManagement: number[] = [];
    const planReliability: number[] = [];
    const ppc: number[] = [];
    const siteReadiness: number[] = [];
    this.props.data.chartData.forEach((item: WeeklyCommitmentReliabilityType) => {
      labels.push(`${moment(item.end_date).format('DD MMM YYYY')} <br> Confirmed on: ${moment(item.confirmed_date).format('DD MMM YYYY')}`);
      constraintManagement.push(item.constraint_management);
      planReliability.push(item.plan_reliability);
      ppc.push(item.ppc);
      siteReadiness.push(item.site_readiness);
    });

    return [
      {
        labels,
        values: constraintManagement,
        name: 'Constraint Management level',
        color: colors.tundora,
      },
      {
        labels,
        values: siteReadiness,
        name: 'Site Readiness level',
        color: colors.cerulean,
      },
      {
        labels,
        values: ppc,
        name: 'Commitment level (PPC)',
        color: colors.orange,
      },
      {
        labels,
        values: planReliability,
        name: 'Construct Plan Reliability (CPR)',
        color: colors.aquaForest,
      },
    ];
  }

  getOptions(): any {
    const annotations: any = [];
    this.props.data.chartData.forEach((data: WeeklyCommitmentReliabilityType, index: number) => {
      if (this.props.shortWeeks[data.end_date]) {
        annotations.push({
          x: index,
          y: 0,
          text: 'Lookahead day change',
          showarrow: true,
          font: {
            size: 10,
            color: 'red',
          },
          borderColor: 'red',
          arrowhead: 0.5,
          ax: 0,
          ay: 20,
          arrowcolor: 'red',
          arrowsize: 0.3,
        });
      }
      if (this.props.versionChangeWeeks[data.end_date]) {
        annotations.push({
          x: index,
          y: this.props.shortWeeks[data.end_date] ? -20 : 0,
          text: 'Version change',
          showarrow: true,
          font: {
            size: 10,
            color: 'blue',
          },
          borderColor: 'blue',
          arrowhead: 0.5,
          ax: 0,
          ay: 20,
          arrowcolor: 'blue',
          arrowsize: 0.3,
        });
      }
    });
    return {
      annotations,
    };
  }

  fetchData(week?: string) {
    this.props.dispatch({
      type: ActionTypes.GET_WEEKLY_COMMITMENT_RELIABILITY,
      data: { projectId: this.props.project.id, week },
    });
  }

  /* eslint max-len: ["error", { "ignoreStrings": true, "code": 100}] */
  renderWidget(): React$Element<*> {
    const yAxis: Object = {
      yaxis: {
        ticksuffix: '%',
        // rangemode: 'tozero',
        autorange: true,
      },
    };
    if (this.props.data.chartData.length === 0 && this.props.data.loading === false) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <div>
        <LineChart
          data={this.getData()}
          yAxes={yAxis}
          legendY={-0.8}
          options={this.getOptions()}
        />
        {this.props.data.statsData
          ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={styles.candleBar}>
                <CandleBar
                  color={colors.tundora}
                  data={this.props.data.statsData.constraint_management}
                />
                <CandleBar
                  color={colors.cerulean}
                  data={this.props.data.statsData.site_readiness}
                />
                <CandleBar
                  color={colors.orange}
                  data={this.props.data.statsData.ppc}
                />
                <CandleBar
                  color={colors.aquaForest}
                  data={this.props.data.statsData.plan_reliability}
                />
              </span>
            </div>)
          : null }
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Weekly Commitments and Reliability
          <img data-tip="This graph presents the weekly reliablity & commitment levels of the project team.<br />Constraint management level (Can do / Should Do) describes how well constraints are identified and managed.<br />Site readiness level (Will Do / Can Do) depicts how ready the site team to take up the planned jobs.<br />Commitment level (PPC - Did / Will Do) represents how well the site team can keep their commitment, and<br />Construct Plan Reliability (CPR - Did / Should Do) describes the overall reliability of the plan.<br />The higher these indicators are, the better performance is expected" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget()
        }
        {
          this.props.data.loading === false && !isEmpty(this.props.versionDates)
          && <VersionTextMessage versionDates={this.props.versionDates} />
        }
      </section>
    );
  }
}

const component: any = compose(
  connect((state: Reducers): { data: WeeklyCommitmentReliabilityState } => (
    {
      data: state.weeklyCommitmentReliability,
      shortWeeks: pathOr({}, ['project', 'shortWeeks'], state),
      versionChangeWeeks: pathOr({}, ['project', 'versionChangeWeeks'], state),
      versionDates: pathOr({}, ['project', 'versionDates'], state),
    }
  )),
  withProjectContext,
)(WeeklyCommitmentReliability);

component.style = {
  width: '98%',
};

export default component;
