// @flow
import * as React from 'react';

import '../style.css';
import ProjectProgress from './project-progress';
import OverallProjectProgressStatus from './overall-project-progress-status';
import ProjectOverallProgress from './project-overall-progress';
import progress from '../icons/progress.png';


type Props = {};

// eslint-disable-next-line react/prefer-stateless-function
class Progress extends React.PureComponent<Props> {
  render(): React.Node {
    return (
      <div className="c-level__widget">
        <h1 className="c-level__header-widget-common">
          <span>
            <img
              width="20"
              src={progress}
              alt="i"
              className="c-level__icon"
            />
          </span>
          Progress
        </h1>
          <div className="c-level__content-row">
            <OverallProjectProgressStatus />
            <ProjectOverallProgress />
          </div>
        <div>
          <ProjectProgress />
        </div>
      </div>
    );
  }
}

// Progress.style = {
//   width: '98%',
// };

export default Progress;
