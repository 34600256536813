// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, pathOr, filter } from 'ramda';
import ReactTooltip from 'react-tooltip';

import StackedBarChart from '../../components/charts/stacked-bar';
import colors from '../../styles/colors';
import ActionTypes from './action-types';
import SelectBox from '../../components/select-box';
// import Button from '../../components/button';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import VersionTextMessage from '../../utils/versionText';

import type { CprState, CompaniesItem, AllItem } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import type { DataItem, BorderLine, Annotations } from '../../components/charts/stacked-bar';

import delayTexts from '../../utils/delay-reasons';

import './styles.css';

type Action = { type: $Values<typeof ActionTypes> | 'showModal', data?: Object };
type Dispatch = (action: Action) => void;

type ViewModel = Array<DataItem>;

type Props = {
  dispatch: Dispatch,
  data: CprState,
  project: Project,
  week: string,
  versionDates: string;
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   color: '#484848',
  //   marginBottom: '1rem',
  //   height: '36px',
  //   boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.5)',
  // },
  widget: {
    maxHeight: '495px',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  topContainer: {
    display: 'flex',
  },
  deletedGrey: {
    color: '#6f6f6f',
    pointerEvents: 'none',
  },
  selectedButton: {
    backgroundColor: '#E7E6E6',
    boxShadow: 'inset 0 0.2rem 0.6rem rgba(0, 0, 0, .125)',
  },
  tableContainer: {
    width: '100%',
  },
  criticalItem: {
    color: '#ff0000',
  },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    float: 'right',
    marginLeft: '4px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    float: 'right',
    marginLeft: '4px',
    marginTop: '8px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px 0',
  },
  note: {
    fontSize: '0.8rem',
    marginTop: '8px',
  },
};

// const delayTexts: Object = {
//   access_space: 'Access / Space',
//   approval_permits: 'Approval / Permits',
//   client_decision: 'Client decision',
//   contracts: 'Contracts',
//   drawings: 'Drawings',
//   equipment: 'Equipment',
//   inspection: 'Inspection',
//   labor: 'Labor',
//   logistics_storage: 'Logistics / Storage',
//   material: 'Material',
//   method_statement: 'Method statement',
//   minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
//   rfi: 'RFI',
//   safety_requirement: 'Safety requirement',
//   re_scheduling: 'Re-Scheduling ',
//   unforeseen_site_conditions: 'Unforeseen site conditions',
//   weather: 'Weather',
//   schedule_error: 'Schedule error',
//   defects_rework: 'Defects rework',
//   first_lookahead: 'Delay on First Lookahead',
//   others: 'Others',
//   do_list_confirmation: 'Do List Confirmation',
//   change_in_dependency: 'Change In Dependency',
//   creation_of_dependency: 'Creation of Dependency',
// };

type State = {
  companyId: number | string,
  activeTab: string,
  cantDoSort: {
    order: string,
    column: string,
  },
  wontDoSort: {
    order: string,
    column: string,
  },
  dintDoSort: {
    order: string,
    column: string,
  },
};

class CprWeekly extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      companyId: 'all',
      activeTab: 'cantDo',
      cantDoSort: {
        order: 'desc',
        column: 'task_name',
      },
      wontDoSort: {
        order: 'desc',
        column: 'task_name',
      },
      dintDoSort: {
        order: 'desc',
        column: 'task_name',
      },
    };
  }

  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  onCompanyChange = this.onCompanyChange.bind(this);

  onTabChange = this.onTabChange.bind(this);

  onCompanyChange(e: Object) {
    this.setState({
      companyId: e.value,
    });
  }

  onTabChange(tab: string) {
    this.setState({
      activeTab: tab,
    });
  }

  onSortChange(tab: string, column: string) {
    // this.setState({
    //   [tab]: {
    //     column,
    //     order: this.state[tab].order === 'desc' ? 'asc' : 'desc',
    //   },
    // });
    this.setState(
      (prevState: State): any => ({
        [tab]: {
          column,
          order: prevState[tab].order === 'desc' ? 'asc' : 'desc',
        },
      }),
    );
  }

  getFormattedData(data: CompaniesItem | AllItem): ViewModel {
    const topBars: number[] = [0, data.cantDo, data.wontDo, data.didntDo];
    const bottomBars: number[] = [data.shouldDo, data.canDo, data.willDo, data.done];
    const labelNames: string[] = [
      `(${data.shouldDo})<br />Should do`,
      `(${data.canDo})<br />Can do`,
      `(${data.willDo})<br />Will do`,
      `(${data.done})<br />Did`];
    const topColors: string = colors.white;
    const bottomColors: string[] = [colors.tundora, colors.cerulean, colors.orange, colors.aquaForest];
    const topBorderColor: string[] = [colors.white, colors.lightShadeCerulean, colors.lightShadeOrange, colors.lightShadeAquaForest];
    const topBorderLine: BorderLine = {
      width: 4,
      color: topBorderColor,
    };
    const bottomBorderLine: BorderLine = {
      width: 4,
      color: bottomColors,
    };
    const traces: ViewModel = [
      {
        labels: labelNames,
        values: bottomBars,
        text: '',
        color: bottomColors,
        borderLine: bottomBorderLine,
      },
      {
        labels: labelNames,
        values: topBars,
        text: '',
        color: topColors,
        borderLine: topBorderLine,
      },
    ];

    return traces;
  }

  getCompanies(companies: Object[]): React$Element<*> {
    return <SelectBox options={companies} value={this.state.companyId} onChange={this.onCompanyChange} />;
  }

  getDelayList(delays: string[]): string {
    const delayReasons: string[] = delays.map((delay: string): string => delayTexts[delay] || delay);
    return delayReasons.join(', ') === '' ? 'Due to predecessor' : delayReasons.join(', ');
  }

  getDelayDescription(delayDesc: string[]): string {
    const delayDescription: string[] = delayDesc ? delayDesc.map((delay: string): string => delay) : [];
    return delayDescription.join(', ') === '' ? 'NA' : delayDescription.join(', ');
  }

  getCantdoList(companyId: number | string): Array<React$Element<*>> {
    const data: any = this.props.data.data.tableData.cantDo;
    const sortedData: Array<React$Element<*>> = data.sort(
      (item1: Object, item2: Object): number => {
        if (this.state.cantDoSort.order === 'asc') {
          if (item1[this.state.cantDoSort.column] < item2[this.state.cantDoSort.column]) {
            return -1;
          }
          if (item1[this.state.cantDoSort.column] > item2[this.state.cantDoSort.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.cantDoSort.column] < item2[this.state.cantDoSort.column]) {
          return 1;
        }
        if (item1[this.state.cantDoSort.column] > item2[this.state.cantDoSort.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (companyId === 'all') {
      return sortedData.map(
        (item: Object, index: number): React$Element<*> => (
          <tr className="tbody-tr-container" key={index} style={item.deleted ? styles.deletedGrey : null}>
            <td style={{ width: '30%' }}>
              <a onClick={this.showTaskDetailModal.bind(this, item)} className="underline-hover">
                {item.task_name}
              </a>
            </td>
            <td style={{ width: '20%' }}>{item.base_start_date ? moment(item.base_start_date).format('DD MMM YYYY') : null}</td>
            <td style={{ width: '20%' }}>{item.expected_start_date ? moment(item.expected_start_date).format('DD MMM YYYY') : null}</td>
            <td style={{ width: '30%' }}>{item.constraints ? item.constraints.join(', ') : ''}</td>
          </tr>
        ),
      );
    }
    const companyFiltered: [] = filter((item: Object): boolean => item.company_id === companyId, sortedData);
    return companyFiltered.map(
      (item: Object, index: number): React$Element<*> => (
        <tr className="tbody-tr-container" key={index} style={item.deleted ? styles.deletedGrey : null}>
          <td style={{ width: '30%' }}>
            <a onClick={this.showTaskDetailModal.bind(this, item)} className="underline-hover">
              {item.task_name}
            </a>
          </td>
          <td style={{ width: '20%' }}>{item.base_start_date ? moment(item.base_start_date).format('DD MMM YYYY') : null}</td>
          <td style={{ width: '20%' }}>{item.expected_start_date ? moment(item.expected_start_date).format('DD MMM YYYY') : null}</td>
          <td style={{ width: '30%' }}>{item.constraints ? item.constraints.join(', ') : ''}</td>
        </tr>
      ),
    );
  }

  getWontdoList(companyId: number | string): Array<React$Element<*>> {
    const data: any = this.props.data.data.tableData.wontDo;
    const sortedData: Array<React$Element<*>> = data.sort(
      (item1: Object, item2: Object): number => {
        if (this.state.wontDoSort.order === 'asc') {
          if (item1[this.state.wontDoSort.column] < item2[this.state.wontDoSort.column]) {
            return -1;
          }
          if (item1[this.state.wontDoSort.column] > item2[this.state.wontDoSort.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.wontDoSort.column] < item2[this.state.wontDoSort.column]) {
          return 1;
        }
        if (item1[this.state.wontDoSort.column] > item2[this.state.wontDoSort.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (companyId === 'all') {
      return sortedData.map(
        (item: Object, index: number): React$Element<*> => (
          <tr className="tbody-tr-container" key={index} style={item.deleted || item.delay_reasons.indexOf('first_lookahead') > -1 ? styles.deletedGrey : null}>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%' }}>
              <a onClick={this.showTaskDetailModal.bind(this, item)} className="underline-hover">
                {item.task_name}
              </a>
            </td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '20%' }}>
              {item.base_start_date ? moment(item.base_start_date).format('DD MMM YYYY') : null}
            </td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '20%' }}>
              {item.expected_start ? moment(item.expected_start).format('DD MMM YYYY') : null}
            </td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%', wordBreak: 'break-all' }}>{this.getDelayList(item.delay_reasons)}</td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%', wordBreak: 'break-all' }}>{this.getDelayDescription(item.delay_description)}</td>
          </tr>
        ),
      );
    }
    const companyFiltered: [] = filter((item: Object): boolean => item.company_id === companyId, sortedData);
    return companyFiltered.map(
      (item: Object, index: number): React$Element<*> => (
        <tr className="tbody-tr-container" key={index} style={item.deleted || item.delay_reasons.indexOf('first_lookahead') > -1 ? styles.deletedGrey : null}>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%' }}>
            <a onClick={this.showTaskDetailModal.bind(this, item)} className="underline-hover">
              {item.task_name}
            </a>
          </td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '20%' }}>
            {item.base_start_date ? moment(item.base_start_date).format('DD MMM YYYY') : null}
          </td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '20%' }}>
            {item.expected_start ? moment(item.expected_start).format('DD MMM YYYY') : null}
          </td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%', wordBreak: 'break-all' }}>{this.getDelayList(item.delay_reasons)}</td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%', wordBreak: 'break-all' }}>{this.getDelayDescription(item.delay_description)}</td>
        </tr>
      ),
    );
  }

  getShortfallProgressValue(value: string, units: string | null): string {
    if (units) {
      return `${value} ${units}`;
    }
    return value === '100.0' ? '100' : `${parseFloat(value).toFixed(2)} %`;
  }

  getDidntdoList(companyId: number | string): Array<React$Element<*>> {
    const data: any = this.props.data.data.tableData.didntDo;
    const sortedData: Array<React$Element<*>> = data.sort(
      (item1: Object, item2: Object): number => {
        if (this.state.dintDoSort.order === 'asc') {
          if (
            (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
              ? item1[this.state.dintDoSort.column] * 1000
              : item1[this.state.dintDoSort.column])
            < (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
              ? item2[this.state.dintDoSort.column] * 1000
              : item2[this.state.dintDoSort.column])
          ) {
            return -1;
          }
          if (
            (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
              ? item1[this.state.dintDoSort.column] * 1000
              : item1[this.state.dintDoSort.column])
            > (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
              ? item2[this.state.dintDoSort.column] * 1000
              : item2[this.state.dintDoSort.column])
          ) {
            return 1;
          }
          return 0;
        }
        if (
          (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
            ? item1[this.state.dintDoSort.column] * 1000
            : item1[this.state.dintDoSort.column])
          < (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
            ? item2[this.state.dintDoSort.column] * 1000
            : item2[this.state.dintDoSort.column])
        ) {
          return 1;
        }
        if (
          (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
            ? item1[this.state.dintDoSort.column] * 1000
            : item1[this.state.dintDoSort.column])
          > (this.state.dintDoSort.column === 'expected_percentage' || this.state.dintDoSort.column === 'actual_percentage'
            ? item2[this.state.dintDoSort.column] * 1000
            : item2[this.state.dintDoSort.column])
        ) {
          return -1;
        }
        return 0;
      },
    );
    if (companyId === 'all') {
      return sortedData.map(
        (item: Object, index: number): React$Element<*> => (
          <tr className="tbody-tr-container" key={index} style={item.deleted || item.delay_reasons.indexOf('first_lookahead') > -1 ? styles.deletedGrey : null}>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%' }}>
              <a onClick={this.showTaskDetailModal.bind(this, item)} className="underline-hover">
                {item.task_name}
              </a>
            </td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '21%' }}>
              {item.expected_end_date ? moment(item.expected_end_date).format('DD MMM YYYY') : null}
            </td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '15%' }}>
              {this.getShortfallProgressValue(item.expected_percentage, item.unit)}
            </td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '10%' }}>
              {this.getShortfallProgressValue(item.actual_percentage, item.unit)}
            </td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '24%', wordBreak: 'break-all' }}>{this.getDelayList(item.delay_reasons)}</td>
            <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '24%', wordBreak: 'break-all' }}>{this.getDelayDescription(item.delay_description)}</td>
          </tr>
        ),
      );
    }
    const companyFiltered: [] = filter((item: Object): boolean => item.company_id === companyId, sortedData);
    return companyFiltered.map(
      (item: Object, index: number): React$Element<*> => (
        <tr className="tbody-tr-container" key={index} style={item.deleted || item.delay_reasons.indexOf('first_lookahead') > -1 ? styles.deletedGrey : null}>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '30%' }}>
            <span role="button" tabIndex={0} onClick={this.showTaskDetailModal.bind(this, item)} onKeyDown={() => { }} className="underline-hover">
              {item.task_name}
            </span>
          </td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '21%' }}>
            {item.expected_end_date ? moment(item.expected_end_date).format('DD MMM YYYY') : null}
          </td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '15%' }}>
            {this.getShortfallProgressValue(item.expected_percentage, item.unit)}
          </td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '10%' }}>
            {this.getShortfallProgressValue(item.actual_percentage, item.unit)}
          </td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '24%', wordBreak: 'break-all' }}>{this.getDelayList(item.delay_reasons)}</td>
          <td style={{ ...(item.project_delay > 0 ? styles.criticalItem : null), width: '24%', wordBreak: 'break-all' }}>{this.getDelayDescription(item.delay_description)}</td>
        </tr>
      ),
    );
  }

  getTable(companyId: number | string, activeTab: string): React$Element<*> {
    if (activeTab === 'cantDo') {
      return (
        <table className="table-container">
          <thead className="thead-container">
            <tr>
              <th style={{ width: '30%' }} className="header" onClick={this.onSortChange.bind(this, 'cantDoSort', 'task_name')}>
                Tasks
                {' '}
                {this.state.cantDoSort.column === 'task_name' ? <span style={this.state.cantDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '21%' }} className="header" onClick={this.onSortChange.bind(this, 'cantDoSort', 'base_start_date')}>
                {' '}
                Base start date
                {' '}
                {this.state.cantDoSort.column === 'base_start_date' ? <span style={this.state.cantDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '20%' }} className="header" onClick={this.onSortChange.bind(this, 'cantDoSort', 'expected_start_date')}>
                {' '}
                Expected
                <br />
                start date
                {' '}
                <span>
                  {this.state.cantDoSort.column === 'expected_start_date' ? <span style={this.state.cantDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
                </span>
              </th>
              <th style={{ width: '29%' }} className="header" onClick={this.onSortChange.bind(this, 'cantDoSort', 'constraints')}>
                {' '}
                Constraints detail
                {' '}
                {this.state.cantDoSort.column === 'constraints' ? <span style={this.state.cantDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
            </tr>
          </thead>
          <tbody className="tbody-container">{this.getCantdoList(companyId)}</tbody>
        </table>
      );
    } if (activeTab === 'wontDo') {
      return (
        <table className="table-container">
          <thead className="thead-container">
            <tr>
              <th style={{ width: '30%' }} className="header" onClick={this.onSortChange.bind(this, 'wontDoSort', 'task_name')}>
                Tasks
                {' '}
                {this.state.wontDoSort.column === 'task_name' ? <span style={this.state.wontDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '20%' }} className="header" onClick={this.onSortChange.bind(this, 'wontDoSort', 'base_start_date')}>
                Base start date
                {' '}
                {this.state.wontDoSort.column === 'base_start_date' ? <span style={this.state.wontDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '20%' }} className="header" onClick={this.onSortChange.bind(this, 'wontDoSort', 'expected_start')}>
                Expected
                <br />
                start date
                {' '}
                {this.state.wontDoSort.column === 'expected_start' ? <span style={this.state.wontDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '30%' }} className="header" onClick={this.onSortChange.bind(this, 'wontDoSort', 'delay_reasons')}>
                Delay reason
                {' '}
                {this.state.wontDoSort.column === 'delay_reasons' ? <span style={this.state.wontDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '30%' }} className="header" onClick={this.onSortChange.bind(this, 'wontDoSort', 'delay_description')}>
                Delay
                <br />
                description
                {' '}
                {this.state.wontDoSort.column === 'delay_description' ? <span style={this.state.wontDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
            </tr>
          </thead>
          <tbody className="tbody-container">{this.getWontdoList(companyId)}</tbody>
        </table>
      );
    }
    return (
      <table className="table-container">
        <thead className="thead-container">
          <tr>
            <th style={{ width: '30%' }} className="header" onClick={this.onSortChange.bind(this, 'dintDoSort', 'task_name')}>
              Tasks
              {' '}
              {this.state.dintDoSort.column === 'task_name' ? <span style={this.state.dintDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th style={{ width: '21%' }} className="header" onClick={this.onSortChange.bind(this, 'dintDoSort', 'expected_end_date')}>
              Expected
              <br />
              end date
              {' '}
              {this.state.dintDoSort.column === 'expected_end_date' ? <span style={this.state.dintDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th style={{ width: '15%' }} className="header" onClick={this.onSortChange.bind(this, 'dintDoSort', 'expected_percentage')}>
              Expected
              {' '}
              {this.state.dintDoSort.column === 'expected_percentage' ? <span style={this.state.dintDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th style={{ width: '10%' }} className="header" onClick={this.onSortChange.bind(this, 'dintDoSort', 'actual_percentage')}>
              Actual
              {' '}
              {this.state.dintDoSort.column === 'actual_percentage' ? <span style={this.state.dintDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th style={{ width: '24%' }} className="header" onClick={this.onSortChange.bind(this, 'dintDoSort', 'delay_reasons')}>
              Delay reason
              {' '}
              {this.state.dintDoSort.column === 'delay_reasons' ? <span style={this.state.dintDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th style={{ width: '24%' }} className="header" onClick={this.onSortChange.bind(this, 'dintDoSort', 'delay_description')}>
              Delay
              <br />
              description
              {' '}
              {this.state.dintDoSort.column === 'delay_description' ? <span style={this.state.dintDoSort.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
          </tr>
        </thead>
        <tbody className="tbody-container">{this.getDidntdoList(companyId)}</tbody>
      </table>
    );
  }

  getTableContainer(companyId: number | string, data: CompaniesItem | AllItem): React$Element<*> {
    return (
      <div>
        <div>
          <div className="tabs-table">
            <span className="shortfall-container">
              Shortfall (
              {data.shouldDo - data.done}
)
              {' '}
              <img style={{ marginLeft: '5px' }} data-tip="This is the difference between the SHOULD DO and DID tasks in a week" width="14" src={infoIcon} alt="i" />
            </span>
            <ReactTooltip place="top" type="dark" effect="solid" border multiline />
            <span
              className="tabs-button"
              style={this.state.activeTab === 'cantDo' ? styles.selectedButton : {}}
              role="button"
              tabIndex="-2"
              onClick={(): any => this.onTabChange('cantDo')}
              onKeyDown={() => { }}
            >
              Cannot do (
              {data.cantDo}
)
            </span>
            <span
              className="tabs-button"
              style={this.state.activeTab === 'wontDo' ? styles.selectedButton : {}}
              role="button"
              tabIndex="-1"
              onClick={(): any => this.onTabChange('wontDo')}
              onKeyDown={() => { }}
            >
              Will not do (
              {data.wontDo}
)
            </span>
            <span
              className="tabs-button"
              style={this.state.activeTab === 'didntDo' ? styles.selectedButton : {}}
              role="button"
              tabIndex="-2"
              onClick={(): any => this.onTabChange('didntDo')}
              onKeyDown={() => { }}
            >
              Did not do (
              {data.didntDo}
)
            </span>
          </div>
        </div>
        {this.getTable(companyId, this.state.activeTab)}
      </div>
    );
  }

  getGraph(companyId: number | string): React$Element<*> {
    if (!this.props.data.data.companies || this.props.data.data.companies[0].companyId === 0) {
      return <span style={styles.noData}>No data available</span>;
    }
    const companies: Object[] = this.props.data.data.companies.map((c: CompaniesItem): Object => ({ label: c.companyName, value: c.companyId }));
    companies.unshift({
      label: 'All companies',
      value: 'all',
    });
    const index: number = companyId !== 'all' ? companies.findIndex((obj: Object): boolean => obj.value === companyId) - 1 : -2;
    const data: CompaniesItem | AllItem = index !== -2 ? this.props.data.data.companies[index] : this.props.data.data.all;
    const annotations: Annotations = [
      {
        x: 1,
        y: data.canDo + data.cantDo + data.shouldDo * 0.1,
        text: `Cannot do<br />(${data.cantDo})`,
        showarrow: false,
        font: {
          size: 12,
        },
      },
      {
        x: 2,
        y: data.willDo + data.wontDo + data.shouldDo * 0.1,
        text: `Will not do<br />(${data.wontDo})`,
        showarrow: false,
        font: {
          size: 12,
        },
      },
      {
        x: 3,
        y: data.done + data.didntDo + data.shouldDo * 0.1,
        text: `Did not do<br />(${data.didntDo})`,
        showarrow: false,
        font: {
          size: 12,
        },
      },
    ];
    const options: Object = {
      height: 340,
      tickangle: '0',
      margin: {
        l: 10,
        r: 10,
        t: 30,
        b: 50,
        pad: 5,
      },
      xaxis: {
        fixedrange: true,
        tickfont: {
          size: 12,
        },
      },
      yaxis: {
        showgrid: false,
        showticklabels: false,
        fixedrange: true,
      },
      showlegend: false,
      annotations,
    };
    if (data.shouldDo === 0 && data.canDo === 0 && data.willDo === 0 && data.done === 0) {
      return (
        <div>
          <div className="select-box-container">{this.getCompanies(companies)}</div>
          <span style={styles.noData}>No data available for selected company</span>
        </div>
      );
    }
    return (
      <div>
        <div className="top-bar">
          <div className="select-box-container">{this.getCompanies(companies)}</div>
          <div>
            {/* <Button onClick={this.downloadFile.bind(this)}>Export</Button> */}
          </div>
        </div>
        <div style={styles.topContainer}>
          <div className="graph-container">
            <StackedBarChart data={this.getFormattedData(data)} options={options} />
          </div>
          <div className="graph-vertical-line" />
          <div className="graph-table-container">{this.getTableContainer(this.state.companyId, data)}</div>
        </div>
      </div>
    );
  }

  showTaskDetailModal = (task: Object) => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'taskDetail',
      modalProps: {
        activityId: task.activity_id,
        taskId: task.task_id,
        taskName: task.task_name,
      },
    });
  };

  downloadFile() {
    if (this.props.project.id !== undefined && this.props.project.id !== null) {
      if (window.location.host === 'insights.theplando.com') {
        window.open(`https://theplando.com/projects/${this.props.project.id}/insights/workable_backlog_excel?start_date=${this.props.week}`, '_blank');
      } else if (window.location.host === 'insights.leanplando.com') {
        window.open(`https://leanplando.com/projects/${this.props.project.id}/insights/workable_backlog_excel?start_date=${this.props.week}`, '_blank');
      } else {
        window.open(`http://devplando.com/projects/${this.props.project.id}/insights/workable_backlog_excel?start_date=${this.props.week}`, '_blank');
      }
    }
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_CPR_WEEKLY,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderWidget(): React$Element<*> {
    if (this.props.data.data.companies === undefined && this.props.data.CprLoading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    return this.getGraph(this.state.companyId);
  }

  render(): React$Element<*> {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Backlog
          {' '}
          <img
            data-tip="This chart provides the total number of tasks that the team SHOULD DO/ CAN DO/ WILL DO/ and DID in a week.<br />It displays the list of tasks that the team CANNOT DO/ WILL NOT DO/ DID NOT DO in that week"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.CprLoading === null ? <Loader /> : this.renderWidget()}
        <span style={styles.note}>*Data available from March 2018</span>
        {
           this.props.data.CprLoading === false && !isEmpty(this.props.versionDates)
          && <VersionTextMessage versionDates={this.props.versionDates} />
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: CprState } => ({
    data: state.cprWeekly,
    versionDates: pathOr({}, ['project', 'versionDates'], state),
  }),
)(CprWeekly);

component.style = {
  width: '98%',
};

export default component;
