// @flow

import axios from 'axios';
import { getBaseUrl } from '../config/config';

export function getCompanies(projectId: string): Promise<Object> {
  if (window.__r2d2) {
    return axios.get(`${getBaseUrl()}projects/${projectId}/companylist`);
  }
  return axios.get(`${getBaseUrl()}projects/${projectId}/companies/list`);
}

export function getProjectDetails(projectId: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}`);
}

export function getAllWeeks(projectId: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/do_weeks`);
}

export function getVersionDetails(projectId: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/versions/history`);
}

export function getProjectFeatures(projectId: string): Promise<Object> {
  return axios.get(`/api/projects/${projectId}/featureset`);
}

export function getLookaheadMetaDataApi({ companyId, projectId, planId }: { projectId: number, companyId: number, planId: number }): Promise<Object> {
  return axios.get(`/api/companies/${companyId}/projects/${projectId}/plans/${planId}/lookaheads/latest/meta`);
}
