// @flow
const delayReasons: Object = {
  access_space: 'Access / Space',
  approval_permits: 'Approval / Permits',
  client_decision: 'Client decision',
  contracts: 'Contracts',
  drawings: 'Drawings',
  equipment: 'Equipment',
  inspection: 'Inspection',
  labor: 'Labor',
  logistics_storage: 'Logistics / Storage',
  material: 'Material',
  method_statement: 'Method statement',
  minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
  rfi: 'RFI',
  safety_requirement: 'Safety requirement',
  re_scheduling: 'Re-Scheduling ',
  unforeseen_site_conditions: 'Unforeseen site conditions',
  weather: 'Weather',
  schedule_error: 'Schedule error',
  defects_rework: 'Defects rework',
  first_lookahead: 'Delay on first lookahead',
  others: 'Others',
  do_list_confirmation: 'Do List confirmation',
  change_in_dependency: 'Change in dependency',
  creation_of_dependency: 'Creation of dependency',
  delete_activity: 'Deletion of activity',
  delete_activity_dependency: 'Deletion of activity dependency',
  delete_task_dependency: 'Deletion of task dependency',
  delete_constraint: 'Deletion of constraint',
  create_activity: 'Creation of activity',
  covid_19: 'Covid 19',
  version_change: 'Version change',
  task_progress_reset: 'Reset of task progress',
  'others: pending': 'Pending',
};

export default delayReasons;
