// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import '../style.css';

type Props = {}

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    width: '20%',
  },

  infoBlock: {
    textAlign: 'center',
    padding: '6px',
  },

  textLarge: {
    fontSize: '30px',
    marginBottom: '20px',
  },

  textMedium: {
    fontSize: '20px',
    marginBottom: '20px',
  },

  textSmall: {
    fontSize: '15px',
  },
};


class ManHourWidget extends React.PureComponent<Props> {
  renderInfoBlock = (): React.Element<any> => (
   <div style={styles.infoBlock}>
     <div style={styles.textMedium}>Man-hours</div>
     <div style={styles.textLarge}>20,564</div>
     <div style={styles.textSmall}>without Loss Time Injury</div>
   </div>
  );

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        {this.renderInfoBlock()}
      </section>
    );
  }
}

const component: any = connect()(ManHourWidget);

// component.style = {
//   width: '31%',
// };

export default component;
