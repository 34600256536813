// @flow

import * as React from 'react';

type Props = {
  title: string
}

class Gauge extends React.PureComponent<Props> {
  getElementRef: Function = (el: HTMLDivElement) => {
    this.el = el;
  }

  el: any

  render(): React.Node {
    return (
      <section ref={this.getElementRef}>
        <h1 style={{ textAlign: 'center' }}>{this.props.title}</h1>
        <svg style={{ width: '450px', height: '250px' }}>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#e9624a" />
              <stop offset="70%" stopColor="#f19c25" />
              <stop offset="100%" stopColor="#33b787" />
            </linearGradient>
          </defs>
          <path
            stroke="url(#gradient)"
            d="M 100 200 A 25 25 0 1 1 350 200"
            fill="none"
            // stroke="skyblue"
            strokeWidth="40"
          />
          <path
            d="M 300 160 L 360 130"
            stroke="#e1302f"
            strokeWidth="6"
          />
          <text style={{ marginTop: '60px', fontSize: '30px' }} x="50" y="200">0</text>
          <text style={{ marginTop: '60px', fontSize: '30px' }} x="380" y="200">100</text>
        </svg>
        <p style={{ textAlign: 'center' }}>
          Target Value: 30 m
          <sup>3</sup>
          /hour
        </p>
      </section>
    );
  }
}

export default Gauge;
