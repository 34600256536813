// @flow

export type ConstraintDetailPropTypes = {
  GET_CONSTRAINT_DETAIL: 'getConstraintDetail',
  GET_CONSTRAINT_DETAIL_SUCCESS: 'getConstraintDetailSuccess',
  GET_CONSTRAINT_DETAIL_FAIL: 'getConstraintDetailFail',
  CLEAR_CONSTRAINT_DETAIL: 'clearConstraintDetail',
}

const actionTypes: ConstraintDetailPropTypes = {
  GET_CONSTRAINT_DETAIL: 'getConstraintDetail',
  GET_CONSTRAINT_DETAIL_SUCCESS: 'getConstraintDetailSuccess',
  GET_CONSTRAINT_DETAIL_FAIL: 'getConstraintDetailFail',
  CLEAR_CONSTRAINT_DETAIL: 'clearConstraintDetail',
};

export default actionTypes;
