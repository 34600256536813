// @flow

import React from 'react';
import type { Node, Element } from 'react';
import { connect } from 'react-redux';
import { isEmpty, pathOr } from 'ramda';
// import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import VersionTextMessage from '../../utils/versionText';

import type { CriticalMilestoneStatusState } from './reducer';
import type { CriticalMilestoneStatus } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

// import SelectBox from '../../components/select-box';

// import delayTexts from '../../utils/delay-reasons';

// import './styles.css';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: CriticalMilestoneStatusState,
  project: Project,
  versionDates: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  tabContainer: {
    marginBottom: 16,
  },
  selectBoxContainer: {
    maxWidth: 450,
  },
  table: {
    width: '100%',
  },
  tbodyContainer: {
    width: '100%',
    display: 'block',
    overflowY: 'scroll',
    maxHeight: '320px',
  },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  deletedGrey: {
    color: '#6f6f6f',
    pointerEvents: 'none',
  },
  parentHierarchy: {
    fontSize: '0.70rem',
    marginTop: '0.2rem',
  },
  reason: {
    textTransform: 'capitalize',
  },
  cause: {
    fontSize: '0.7rem',
  },
  causeName: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
  note: {
    fontSize: '0.8rem',
    marginTop: '15px',
  },
};

type State = {
  order: String,
  column: string,
};

class CriticalMilestoneStatusWidget extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      order: 'desc',
      column: 'wbs',
    };
  }

  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_CRITICAL_MILESTONES_STATUS,
      data: { projectId: this.props.project.id },
    });
  }

  onSortChange(column: string) {
    this.setState(
      (prevState: State): State => ({
        column,
        order: prevState.order === 'desc' ? 'asc' : 'desc',
      }),
    );
  }

  showActivityDetailModal = (activity: CriticalMilestoneStatus): null => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'activityDetail',
      modalProps: {
        activityId: activity.id,
        activityName: activity.name,
      },
    });
    return null;
  };


  getTable = (): Element<any> => {
    const sortedData: CriticalMilestoneStatus[] = this.props.data.chartData.sort(
      (item1: CriticalMilestoneStatus, item2: CriticalMilestoneStatus): number => {
        if (this.state.order === 'asc') {
          if (item1[this.state.column] < item2[this.state.column]) {
            return -1;
          }
          if (item1[this.state.column] > item2[this.state.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.column] < item2[this.state.column]) {
          return 1;
        }
        if (item1[this.state.column] > item2[this.state.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (this.props.data.chartData.length === 0 && this.props.data.loading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <table className="table-container">
        <thead className="thead-container" style={{ width: 'calc(100% - 1.1em)' }}>
          <tr>
            <th style={{ width: 500 }} className="header" onClick={this.onSortChange.bind(this, 'wbs')}>
              Name
              {this.state.column === 'wbs' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th className="ms-list-col header" onClick={this.onSortChange.bind(this, 'base_start_date')}>
              Base End Date
              {this.state.column === 'planned_start_date' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th className="ms-list-col header" onClick={this.onSortChange.bind(this, 'expected_start_date')}>
              Expected End Date
              {this.state.column === 'expected_start_date' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th className="ms-list-col header" onClick={this.onSortChange.bind(this, 'variance')}>
              Variance
              {this.state.column === 'variance' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
          </tr>
        </thead>
        <tbody style={styles.tbodyContainer}>
          {sortedData.map((item: any): Node => (
            <tr
              className="thead-container ms-row"
              key={item.id}
              style={{ width: '100%' }}
            >
              <td style={{ width: 500 }}>{`${item.wbs} ${' '} ${item.name}`}</td>
              <td className="ms-list-col" style={{ verticalAlign: 'middle' }}>{moment(item.base_start_date).format('DD MMM YYYY')}</td>
              <td className="ms-list-col" style={{ verticalAlign: 'middle' }}>{moment(item.expected_start_date).format('DD MMM YYYY')}</td>
              <td className="ms-list-col" style={{ verticalAlign: 'middle' }}>{`${Math.abs(item.variance)} ${Math.abs(item.variance) === 1 ? 'day' : 'days'} ${item.variance >= 0 ? 'delay' : 'advance'}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render(): Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Critical Milestone Status
          {' '}
          <img
            data-tip="Displays the list of critical milestones with the variance between their respective base end dates and expected end dates"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.getTable()}
        {
          this.props.data.loading === false && !isEmpty(this.props.versionDates)
          && <VersionTextMessage versionDates={this.props.versionDates} />
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: CriticalMilestoneStatusState } => ({
    data: state.criticalMilestoneStatus,
    versionDates: pathOr({}, ['project', 'versionDates'], state),
  }),
)(CriticalMilestoneStatusWidget);

component.style = {
  width: '98%',
};

export default component;
