// @flow

type CostProgress = {
    GET_COST_TIME: 'getCostProgress',
    GET_COST_TIME_SUCCESS: 'getCostProgressSuccess',
    GET_COST_TIME_FAIL: 'getCostProgressFail',
  }

const actionTypes: CostProgress = {
  GET_COST_TIME: 'getCostProgress',
  GET_COST_TIME_SUCCESS: 'getCostProgressSuccess',
  GET_COST_TIME_FAIL: 'getCostProgressFail',
};

export default actionTypes;
