// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import {
  getCompanies as getCompaniesApi,
  getProjectDetails as getProjectDetailsApi,
  getAllWeeks as getAllWeeksApi,
  getVersionDetails as getVersionDetailsapi,
  getProjectFeatures as getProjectFeaturesApi,
  getLookaheadMetaDataApi,
} from './apis';

export type COMPANY_LIST_RESPONSE_TYPE = {string: string};
export type PROJECT_RESPONSE_TYPE = {
  project: Object
};
export type WEEK_TYPE = {
  start: string,
  end: string,
};
export type VERSION_TYPE = {
  id: string,
  projectID: number,
  newVersion: number,
  changedOn: string,
  changedBy: number
};

export type PROJECT_ALL_WEEKS_RESPONSE_TYPE = Array<WEEK_TYPE>;

export type PROJECT_VERSION_DETAILS_RESPONSE_TYPE = {
  history: Array<VERSION_TYPE>;
}

export type PROJECT_ACTIONS_TYPE = {|
  +type: typeof ActionTypes.GET_COMPANIES_SUCCESS,
  data: COMPANY_LIST_RESPONSE_TYPE,
|} | {|
  +type: typeof ActionTypes.GET_COMPANIES_FAIL,
|} | {
  +type: typeof ActionTypes.GET_COMPANIES,
  data: 'calendar' | 'task',
} |

{| +type: typeof ActionTypes.GET_PROJECT_DETAILS_SUCCESS,
  data: PROJECT_RESPONSE_TYPE,
|} | {|
  +type: typeof ActionTypes.GET_PROJECT_DETAILS_FAIL,
|} | {
  +type: typeof ActionTypes.GET_PROJECT_DETAILS,
  data: 'calendar' | 'task',
} |

{| +type: typeof ActionTypes.GET_ALL_WEEKS_SUCCESS,
  data: PROJECT_ALL_WEEKS_RESPONSE_TYPE,
|} | {|
  +type: typeof ActionTypes.GET_ALL_WEEKS_FAIL,
|} | {
  +type: typeof ActionTypes.GET_ALL_WEEKS,
  data: 'calendar' | 'task',
} |

{| +type: typeof ActionTypes.GET_VERSION_DETAILS_SUCCESS,
  data: PROJECT_VERSION_DETAILS_RESPONSE_TYPE,
|} | {|
  +type: typeof ActionTypes.GET_VERSION_DETAILS_FAIL,
|} | {
  +type: typeof ActionTypes.GET_VERSION_DETAILS,
  data: 'calendar' | 'task',
}


export type LOOKAHEAD_META_RESPONSE_TYPE = {
  lookahead: {
    doWeekEndDate: string,
    id: Number,
    nextWeekEndDate: string | null,
    nextWeekStartDate: string | null,
    size: number;
    startDate: string;
  }
}

function* getCompanies(action: { data: { projectId: string} }): Generator<IOEffect, void, any> {
  try {
    const response: { data: COMPANY_LIST_RESPONSE_TYPE } = yield call(getCompaniesApi, action.data.projectId);
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_COMPANIES_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_COMPANIES_FAIL };
    yield put(actionData);
  }
}

function* getProjectDetails(action: { data: { projectId: string} }): Generator<IOEffect, void, any> {
  try {
    const response: { data: PROJECT_RESPONSE_TYPE } = yield call(getProjectDetailsApi, action.data.projectId);
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_PROJECT_DETAILS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_PROJECT_DETAILS_FAIL };
    yield put(actionData);
  }
}

function* getAllWeeks(action: { data: { projectId: string} }): Generator<IOEffect, void, any> {
  try {
    const response: { data: PROJECT_ALL_WEEKS_RESPONSE_TYPE } = yield call(getAllWeeksApi, action.data.projectId);
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_ALL_WEEKS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_ALL_WEEKS_FAIL };
    yield put(actionData);
  }
}

function* getVersionDetails(action: { data: { projectId: string} }): Generator<IOEffect, void, any> {
  try {
    const response: { data: PROJECT_VERSION_DETAILS_RESPONSE_TYPE } = yield call(getVersionDetailsapi, action.data.projectId);
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_VERSION_DETAILS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: PROJECT_ACTIONS_TYPE = { type: ActionTypes.GET_VERSION_DETAILS_FAIL };
    yield put(actionData);
  }
}

function* getProjectFeatures(action: { data: { projectId: string} }): Generator<IOEffect, void, any> {
  try {
    const response: { data: PROJECT_VERSION_DETAILS_RESPONSE_TYPE } = yield call(getProjectFeaturesApi, action.data.projectId);

    const actionData: PROJECT_ACTIONS_TYPE = {
      type: ActionTypes.GET_PROJECT_FEATURES_SUCCESS,
      data: response.data,
    };

    yield put(actionData);
  } catch (e) {
    console.log(e.response);
  }
}

function* getLookaheadMeta(action: { payload: { projectId: number, companyId: number, planId: number } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: LOOKAHEAD_META_RESPONSE_TYPE } = yield call(getLookaheadMetaDataApi, action.payload);

    const actionData: PROJECT_ACTIONS_TYPE = {
      type: ActionTypes.GET_LOOKAHEAD_META_SUCCESS,
      data: response.data.lookahead,
    };

    yield put(actionData);
  } catch (e) {
    console.log(e.response);
    if (e.response.status === 404) {
      const actionData: PROJECT_ACTIONS_TYPE = {
        type: ActionTypes.GET_LOOKAHEAD_META_SUCCESS,
        data: {},
      };
      yield put(actionData);
    }
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield [
    takeLatest(ActionTypes.GET_COMPANIES, getCompanies),
    takeLatest(ActionTypes.GET_PROJECT_DETAILS, getProjectDetails),
    takeLatest(ActionTypes.GET_ALL_WEEKS, getAllWeeks),
    takeLatest(ActionTypes.GET_VERSION_DETAILS, getVersionDetails),
    takeLatest(ActionTypes.GET_PROJECT_FEATURES, getProjectFeatures),
    takeLatest(ActionTypes.GET_LOOKAHEAD_META, getLookaheadMeta),
  ];
}
