// @flow

const Definitions: Object = {
  white: '#ffffff',
  wildSand: '#f6f6f6',
  paleCerulean: '#70a7d8',
  mandysPink: '#f58f4c',
  yellow: '#FADC00',
  cappuccino: '#CBBFA9',
  aquaForest: '#70AC72',
  lightShadeAquaForest: '#a9cdaa',
  malibu: '#90CAF9',
  seashell: '#E7E6E6',
  geyser: '#CBD6DD',
  pink: '#FCE4EC',
  lightOrange: '#FFE0B2',
  lightYellow: '#fdf197',
  fuscousGray: '#5D584F',
  apricot: '#ED9E72',
  themePrimaryColor: '#FF6500',
  themeSecondaryColor: '#3777B0',
  cinderella: '#F8DECD',
  tundora: '#484848',
  cerulean: '#0098d2',
  lightShadeCerulean: '#9bbbd7',
  orange: '#fc6621',
  lightShadeOrange: '#ffb27f',
  chateauGreen: '#278A5B',
  newYellow: '#f19c25',
  themePrimaryColorNew: '#e9614a',
  themeSecondaryColorNew: '#4ba8ef',
  cLevelGreen: '#34b787',
  cLevelGray: '#667a9b',
  cLevelRed: '#e1302f',
};

export default Definitions;

export const Text: Object = {
  primary: '#00000',
  secondary: Definitions.fuscousGray,
};
