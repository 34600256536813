// @flow

import * as React from 'react';

type Props = {
  versionDates: string;
};

const modalStyles: Object = {
  container: {
    fontSize: '0.8rem',
    marginTop: '15px',
    lineHeight: '0.9rem',
  },
};

// eslint-disable-next-line react/prefer-stateless-function
class VersionTextMessage extends React.Component<Props> {
  render(): React.Node {
    return (
      <div style={modalStyles.container}>
        <span>{`${'Widget data might be impacted due to change in plan version on'} ${this.props.versionDates}`}</span>
      </div>
    );
  }
}

export default VersionTextMessage;
