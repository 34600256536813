// @flow

import axios from 'axios';

import { getBaseUrl } from '../../config/config';

export function getCostProgressApi(projectId: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/costprogress`);
}

export default {};
