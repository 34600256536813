// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import type { MapStateToProps, Connector } from 'react-redux';

import PieChart from '../../components/charts/pie';
import colors from '../../styles/colors';
import ToggleButton from '../../components/toggle-button';
import '../style.css';

type Props = {};

type State = {
  selectedFilter: string
};

type ViewModel = {
  label: string,
  value: number,
  color?: string,
  text?: string
};

const styles: Object = {
  widget: {
    width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  filterContainer: {
    marginTop: '1rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const ColorConfig: Object = {
  Avoidable_by_getting_valid_commitments: colors.themePrimaryColorNew,
  Avoidable_by_making_plan_reliable_and_robust: colors.themeSecondaryColorNew,
  Unforeseen_external_conditions: colors.newYellow,
};

const TextConstants: Object = {
  Avoidable_by_getting_valid_commitments:
    'Preventable by getting valid commitments',
  Avoidable_by_making_plan_reliable_and_robust:
    'Preventable by making plan reliable and robust',
  Unforeseen_external_conditions: 'Unforeseen / external conditions',
};

const getOrderedList: Function = function getOrderedList(data: any): any[] {
  const list: any[] = Object.keys(data).map((key: string): any => {
    const projectDelay: number = Object.keys(data[key].project_delay)
      .reduce((acc: number, delay: number): number => acc + data[key].project_delay[delay], 0);

    return {
      label: key,
      value: data[key].value,
      text: `A total of ${data[key].occurrence} occurrence(s) has delayed the project by ${projectDelay} day(s)`,
    };
  });

  return list.sort((item1: any, item2: any): number => (
    item1.value < item2.value ? 1 : -1
  ));
};

const dummyData: any = {
  Avoidable_by_getting_valid_commitments: {
    occurrence: 1,
    project_delay: {
      labor: 24,
    },
    value: 0.085,
  },
  Avoidable_by_making_plan_reliable_and_robust: {
    occurrence: 10,
    project_delay: {
      approval_permits: 7,
      contracts: 1,
      logistics_storage: 7,
      material: 7,
      minor_temp_prerequisite_work: 14,
      re_scheduling: 50,
      schedule_error: 314,
    },
    value: 0.835,
  },
  Unforeseen_external_conditions: {
    occurrence: 20,
    project_delay: {},
    value: 0.085,
  },
};

const dummyDataOccurrence: any = {
  Avoidable_by_getting_valid_commitments: {
    occurrence: 358,
    project_delay: {
      labor: 24,
    },
    value: 0.2,
  },
  Avoidable_by_making_plan_reliable_and_robust: {
    occurrence: 1306,
    project_delay: {
      first_lookahead: 689,
    },
    value: 0.72,
  },
  Unforeseen_external_conditions: {
    occurrence: 154,
    project_delay: {},
    value: 0.085,
  },
};

class DelayReasonsWidget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'occurences',
  };

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState(
      {
        selectedFilter: val,
      },
      this.fetchData,
    );
  }

  getChartData(data: Array<Object>): ViewModel[] {
    return data.map(
      (item: Object): Object => ({
        label: TextConstants[item.label],
        value: item.value,
        color: ColorConfig[item.label],
        text: item.text,
      }),
    );
  }


  renderWidget(): React.Node {
    return getOrderedList(this.state.selectedFilter === 'occurences' ? dummyDataOccurrence : dummyData).length !== 0
      ? (
        <div>
          <PieChart data={this.getChartData(getOrderedList(this.state.selectedFilter === 'occurences' ? dummyDataOccurrence : dummyData))} />
        </div>
      ) : (
        <span style={styles.noData}>No data available</span>
      );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section
        style={
          getOrderedList(this.state.selectedFilter === 'occurences' ? dummyDataOccurrence : dummyData).length !== 0
            ? { ...styles.widget, height: 536 }
            : styles.widget
        }
      >
        <div className="c-level__flex">
          <h1>
            Delay Preventions
          </h1>
          <div style={styles.filterContainer}>
            <ToggleButton
              items={[
                { value: 'occurences', label: 'Occurences' },
                { value: 'days', label: 'Total Project Delay (days)' },
              ]}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
          </div>
        </div>
        {this.renderWidget()}
      </section>
    );
  }
}

type TMapStateToFilter = MapStateToProps<Reducers, any, { data: any }>;

const mapStateToProps: TMapStateToFilter = (state: any): Object => ({
  data: state.delayReasons,
});

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(DelayReasonsWidget);
