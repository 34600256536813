// @flow
import * as React from 'react';

import DelayReasons from './delay-reasons';
import DelayClassification from './reason-for-delays';
import TopDelayedActivities from './top-delayed-activities';
import PlanReliabilityStartDate from './activity-profile-date';
import PlanReliabilityDuration from './activity-profile-duration';
import PlanReliabilityEndDate from './activity-profile-enddate';
import DelayResponsibility from './delay-responsibility';
import plan from '../icons/plan.png';

import '../style.css';


type Props = {
  // dispatch: Function,
  // match: Object,
  // location: Object,
  // project: Object,
};

// eslint-disable-next-line react/prefer-stateless-function
class Plan extends React.PureComponent<Props> {
  render(): React.Node {
    return (
      <div className="c-level__widget">
        <h1 className="c-level__header-widget-common">
          <span>
            <img
              width="20"
              src={plan}
              alt="i"
              className="c-level__icon"
            />
          </span>
          Plan Analysis
        </h1>
        <div>
          <DelayClassification />
          <TopDelayedActivities />
        </div>
        <div className="c-level__content-row">
          <DelayResponsibility />
          <DelayReasons />
        </div>
        <div className="c-level__content-row">
          <PlanReliabilityStartDate />
          <PlanReliabilityDuration />
          <PlanReliabilityEndDate />
        </div>
      </div>
    );
  }
}

// Plan.style = {
//   width: '98%',
// };

export default Plan;
