// @flow
import ActionTypes from './action-types';
import type { CriticalMilestoneChangesAction, CriticalMilestoneChanges } from './sagas';

export type CriticalMilestoneChangesState = {
  chartData: CriticalMilestoneChanges[],
  loading: boolean | null,
};

const initialState: CriticalMilestoneChangesState = { loading: null, chartData: [] };

export default function (state: CriticalMilestoneChangesState = initialState, action: CriticalMilestoneChangesAction): CriticalMilestoneChangesState {
  switch (action.type) {
    case ActionTypes.GET_CRITICAL_MILESTONES_CHANGES_SUCCESS:
      return {
        ...state,
        chartData: [...action.data.critical_milestones],
        loading: false,
      };
    default:
      return state;
  }
}
