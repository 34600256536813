// @flow

type ProjectWeeklyWeightProgress = {
  GET_PROJECT_WEEKLY_WEIGHT_PROGRESS: 'getProjectWeeklyWeightProgress',
  GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_SUCCESS: 'getProjectWeeklyWeightProgressSuccess',
  GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_FAIL: 'getProjectWeeklyWeightProgressFail',
}

const actionTypes: ProjectWeeklyWeightProgress = {
  GET_PROJECT_WEEKLY_WEIGHT_PROGRESS: 'getProjectWeeklyWeightProgress',
  GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_SUCCESS: 'getProjectWeeklyWeightProgressSuccess',
  GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_FAIL: 'getProjectWeeklyWeightProgressFail',
};

export default actionTypes;
