// @flow

import React, { Fragment } from 'react';
import { isEmpty, pathOr } from 'ramda';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ResponsivePieChart from '../../components/charts/pie';
import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import VersionTextMessage from '../../utils/versionText';
import WorkableBacklog from '../workable-backlog-weekly';

import type { CommitmentReliabilityWeeklyState } from './reducer';
import type { CommitmentReliabilityResponse } from './sagas';
import type { DataItem } from '../../components/charts/pie';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import './styles.css';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: CommitmentReliabilityWeeklyState,
  project: Project,
  week: string,
  widgetStyle: Object,
  versionDates: string,
  weekStart: string,
  weekEnd: string,
};

const colorConfig: Object = {
  cpr: ['#484848', '#dedede'],
  constraintManagement: ['#0098d2', '#dedede'],
  siteReadiness: ['#fc6621', '#dedede'],
  commitment: ['#70AC72', '#dedede'],
};

const styles: Object = {
  singleWidget: {
    width: '40%',
    marginRight: '30px',
  },

  doubleWidget: {
    width: '57%',
  },
  selectBoxContainer: {
    maxWidth: '350px',
  },
  selectBoxCompany: {
    margin: '0 1% 0 4%',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px',
  },
  note: {
    display: 'block',
    fontSize: '0.8rem',
    marginTop: '30px',
  },
  tableContainer: {
    marginTop: '20px',
  },

  tbodyContainer: {
    width: 'calc(100% - 0.9em)',
    display: 'table',
    flex: '0 0 auto',
  },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '2px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '2px',
    marginTop: '8px',
  },
};


const getLabel: Object = {
  cpr: 'Did / Should do',
  constraintManagement: 'Can do / Should do',
  siteReadiness: 'Will do / Can do',
  commitment: 'Did / Will do',
};

const getLabelNames: Object = {
  cpr: 'Shortfall',
  constraintManagement: 'Cannot do',
  siteReadiness: 'Will not do',
  commitment: 'Did not do',
};

type State = {
  order: String,
  column: string,
};

class CommitmentReliabilityWeekly extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      order: 'desc',
      column: 'shouldDo',
    };
  }

  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  onSortChange(column: string) {
    this.setState(
      (prevState: State): State => ({
        column,
        order: prevState.order === 'desc' ? 'asc' : 'desc',
      }),
    );
  }

  getFormattedData(
    type: string,
    data: CommitmentReliabilityResponse,
  ): DataItem[] {
    const traces: DataItem[] = [
      {
        label: getLabel[type],
        value: data[type],
        text: `${data[type]}%`,
      },
      {
        label: getLabelNames[type],
        value: 100 - data[type],
        text: `${(100 - data[type]).toFixed(1)}%`,
      },
    ];

    return traces;
  }


  getCommitmentReliabilityGraph = this.getCommitmentReliabilityGraph.bind(this);

  getCommitmentReliabilityGraph(): React.Node {
    const data: CommitmentReliabilityResponse = this.props.data.data.allCompanies;
    return (
      <div>
        <div className="container-commitment-reliability">
          <div className="cpr-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('cpr', data)}
              dataOptions={this.getDataOptions('cpr')}
              options={this.getOptions('cpr', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#484848' }}
              />
              <div className="legend-commitment-reliability">
                Construction plan reliability (CPR)
              </div>
            </div>
          </div>
          <div className="other-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('constraintManagement', data)}
              dataOptions={this.getDataOptions('constraintManagement')}
              options={this.getOptions('constraintManagement', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#0098d2' }}
              />
              <div className="legend-commitment-reliability">
                Constraint management level
              </div>
            </div>
          </div>
          <div className="other-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('siteReadiness', data)}
              dataOptions={this.getDataOptions('siteReadiness')}
              options={this.getOptions('siteReadiness', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#fc6621' }}
              />
              <div className="legend-commitment-reliability">
                Site readiness level
              </div>
            </div>
          </div>
          <div className="other-container-commitment-reliability">
            <ResponsivePieChart
              data={this.getFormattedData('commitment', data)}
              dataOptions={this.getDataOptions('commitment')}
              options={this.getOptions('commitment', data)}
            />
            <div>
              <div
                className="legend-box-commitment-reliability"
                style={{ background: '#70AC72' }}
              />
              <div className="legend-commitment-reliability">
                Commitment level
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getDataOptions(str: string): Object {
    return {
      hole: 0.8,
      sort: false,
      textinfo: 'none',
      marker: {
        colors: colorConfig[str],
        // line: {
        //   color: 'white',
        //   width: 4,
        // },
      },
    };
  }

  getOptions(type: string, data: CommitmentReliabilityResponse): Object {
    return {
      hideDownloadImage: 'hideDownloadImage',
      height: 250,
      width: type === 'cpr' ? 200 : 150,
      margin: {
        t: 10,
        l: 0,
        r: 20,
        b: 10,
      },
      showlegend: false,
      annotations: [
        {
          x: 0.5,
          y: 0.5,
          text: `${data[type]}%`,
          showarrow: false,
          font: {
            size: type === 'cpr' ? 22 : 14,
          },
        },
      ],
    };
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_COMMITMENT_RELIABILITY,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.weekStart,
        endDate: this.props.weekEnd,
      },
    });
  }

  renderTable(data: CompanyData[]): Element<any> {
    return (
      <Fragment>
        <table className="table-container">
          <thead className="thead-container">
            <tr>
              <th style={{ width: '13%' }}>Company</th>
              <th style={{ width: '8%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'shouldDo')}>
                Should Do
                {this.state.column === 'shouldDo' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '5%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'canDo')}>
                Can Do
                {this.state.column === 'canDo' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '5%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'willDo')}>
                Will Do
                {this.state.column === 'willDo' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '5%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'did')}>
                Did
                {this.state.column === 'did' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '21%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'cpr')}>
                Construction plan reliability (CPR)
                {this.state.column === 'cpr' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '18%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'constraintManagement')}>
                Constraint management level
                {this.state.column === 'constraintManagement' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '12%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'siteReadiness')}>
                Site readiness level
                {this.state.column === 'siteReadiness' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th style={{ width: '13%', cursor: 'pointer' }} onClick={this.onSortChange.bind(this, 'commitment')}>
                Commitment level
                {this.state.column === 'commitment' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
            </tr>
          </thead>
          <tbody style={styles.tbodyContainer}>
            {data.map(
              (item: CompanyData, i: number): Node => (
                <tr
                  key={i}
                  className="thead-container ms-row"
                  style={{ width: '100%' }}
                >
                  <td style={{ width: '13%' }}>{item.companyName}</td>
                  <td style={{ width: '8%' }}>{item.shouldDo}</td>
                  <td style={{ width: '5%' }}>{item.canDo}</td>
                  <td style={{ width: '5%' }}>{item.willDo}</td>
                  <td style={{ width: '5%' }}>{item.did}</td>
                  <td style={{ width: '21%' }}>{`${item.cpr}${item.cpr === 0 ? '' : '%'}`}</td>
                  <td style={{ width: '18%' }}>{`${item.constraintManagement}${item.constraintManagement === 0 ? '' : '%'}`}</td>
                  <td style={{ width: '12%' }}>{`${item.siteReadiness}${item.siteReadiness === 0 ? '' : '%'}`}</td>
                  <td style={{ width: '13%' }}>{`${item.commitment}${item.commitment === 0 ? '' : '%'}`}</td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </Fragment>
    );
  }

  renderWidget(): React$Element<*> {
    const sortedData: CompanyData[] = this.props.data.data.companies.sort(
      (item1: CompanyData, item2: CompanyData): number => {
        if (this.state.order === 'asc') {
          if (item1[this.state.column] < item2[this.state.column]) {
            return -1;
          }
          if (item1[this.state.column] > item2[this.state.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.column] < item2[this.state.column]) {
          return 1;
        }
        if (item1[this.state.column] > item2[this.state.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (isEmpty(this.props.data.data) && this.props.data.CommitmentReliabilityLoading === false) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <div>
      <div className="week-summary-container">
        <div style={styles.singleWidget}>
          <WorkableBacklog project={this.props.project} data={this.props.data.data.allCompanies} />
        </div>
        <div style={styles.doubleWidget}>
          {this.getCommitmentReliabilityGraph()}
        </div>
      </div>
      <div style={styles.tableContainer}>{this.renderTable(sortedData)}</div>
      </div>
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    return (
      <section style={this.props.widgetStyle}>
        <h1 className="header-widget-common">
          Commitment and Reliability
          <img
            data-tip="Bar graph provides the total number of tasks that the team SHOULD/ CAN/ WILL/ and DID in a week.<br/> Donut chart presents the weekly reliablity & commitment levels of the project team in that week.<br />Constraint management level (Can do / Should Do) describes how well constraints are identified and managed.<br />Site readiness level (Will Do / Can Do) depicts how ready the site team to take up the planned jobs.<br />Commitment level (PPC - Did / Will Do) represents how well the site team can keep their commitment, and<br />Construct Plan Reliability (CPR - Did / Should Do) describes the overall reliability of the plan.<br />The higher these indicators are, the better performance is expected"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.CommitmentReliabilityLoading === null ? <Loader /> : this.renderWidget()
        }
        <span style={styles.note}>*Data available from March 2018</span>
        {
           this.props.data.CommitmentReliabilityLoading === false && !isEmpty(this.props.versionDates)
          && <VersionTextMessage versionDates={this.props.versionDates} />
        }
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: CommitmentReliabilityWeeklyState } => ({
    data: state.commitmentReliabilityWeekly,
    versionDates: pathOr({}, ['project', 'versionDates'], state),
  }),
)(CommitmentReliabilityWeekly);

Component.style = {
  width: '98%',
};

export default Component;
