// @flow

export type FlowActionPropTypes = {
  GET_DISCIPLINE_MASTER_LIST: 'GET_DISCIPLINE_MASTER_LIST',
  GET_DISCIPLINE_MASTER_LIST_SUCCESS: 'GET_DISCIPLINE_MASTER_LIST_SUCCESS',
}

const actionTypes: FlowActionPropTypes = {
  GET_DISCIPLINE_MASTER_LIST: 'GET_DISCIPLINE_MASTER_LIST',
  GET_DISCIPLINE_MASTER_LIST_SUCCESS: 'GET_DISCIPLINE_MASTER_LIST_SUCCESS',
};

export default actionTypes;
