// @flow
import * as React from 'react';

import OverallProgress from './overall-progress';
import TotalDelay from './total-delay';
import ProductivityWidget from './productivity';
import ManHourWidget from './man-hours';

import '../style.css';


type Props = {};

// eslint-disable-next-line react/prefer-stateless-function
class Productivity extends React.PureComponent<Props> {
  render(): React.Node {
    return (
      <div className="c-level__widget">
        <div className="c-level__flex">
          <OverallProgress />
          <TotalDelay />
          <ProductivityWidget />
          <ManHourWidget />
        </div>
      </div>
    );
  }
}

// Productivity.style = {
//   width: '98%',
// };


export default Productivity;
