// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { isEmpty, map } from 'ramda';
import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import activityIcon from '../../styles/icons/activity.png';
import constraintIcon from '../../styles/icons/constraint.png';

import type { TopDelayReasonsState } from './reducer';
import type { TopDelayReasonsSuccessType, TopDelayResponseType } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
// import ExportButton from '../../components/export';

import delayTexts from '../../utils/delay-reasons';

import './styles.css';

type Action = { type: $Values<typeof ActionTypes> | 'showModal', data?: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: TopDelayReasonsState,
  project: Project,
  week: string,
  widgetStyle: Object,
};

const styles: Object = {
  // header: {
  //   fontSize: '20px',
  //   color: '#484848',
  //   marginBottom: '1rem',
  //   height: '36px',
  //   boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.5)',
  // },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    float: 'right',
    marginLeft: '4px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    float: 'right',
    marginLeft: '4px',
    marginTop: '8px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '350px',
  },
  deletedGrey: {
    color: '#6f6f6f',
    pointerEvents: 'none',
  },
};

// const delayTexts: Object = {
//   access_space: 'Access / Space',
//   approval_permits: 'Approval / Permits',
//   client_decision: 'Client decision',
//   contracts: 'Contracts',
//   drawings: 'Drawings',
//   equipment: 'Equipment',
//   inspection: 'Inspection',
//   labor: 'Labor',
//   logistics_storage: 'Logistics / Storage',
//   material: 'Material',
//   method_statement: 'Method statement',
//   minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
//   rfi: 'RFI',
//   safety_requirement: 'Safety requirement',
//   re_scheduling: 'Re-Scheduling ',
//   unforeseen_site_conditions: 'Unforeseen site conditions',
//   weather: 'Weather',
//   schedule_error: 'Schedule error',
//   defects_rework: 'Defects rework',
//   first_lookahead: 'Delay on First Lookahead',
//   second_version: 'Delay on new version upload',
//   others: 'Others',
//   do_list_confirmation: 'Do List Confirmation',
//   change_in_dependency: 'Change In Dependency',
//   creation_of_dependency: 'Creation of Dependency',
// };

type State = {
  order: string,
  column: string,
};

class TopDelayReasonsWeekly extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      order: 'desc',
      column: 'project_delay',
    };
  }

  componentDidMount() {
    if (this.props.week !== '') {
      this.dispatch();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  onSortChange(column: string) {
    this.setState(
      (prevState: State): State => ({
        column,
        order: prevState.order === 'desc' ? 'asc' : 'desc',
      }),
    );
    // this.setState({
    //   column,
    //   order: this.state.order === 'desc' ? 'asc' : 'desc',
    // });
  }

  getDelayDescription(delayDesc: string[]): string {
    const delayDescription: string[] = delayDesc.map((desc: string): string => desc);
    return delayDescription.join(', ') === '' ? 'NA' : delayDescription.join(delayDescription.length <= 2 && delayDescription[0] === '' ? '' : ', ');
  }

  getDelayReason(delayReason: string[]): string {
    const delayReasons: string[] = delayReason.map((reason: string): string => (delayTexts[reason] ? delayTexts[reason] : reason));
    return delayReasons.join(', ') === '' ? 'NA' : delayReasons.join(delayReason.length <= 2 && delayReason[0] === '' ? '' : ', ');
  }

  getActivityName(activity: Object): string {
    if (activity.reason.indexOf('first_lookahead') > -1) {
      return `*${activity.name || 'First lookahead generation'}`;
    }
    if (activity.reason.indexOf('second_version') > -1) {
      return `** ${activity.name}`;
    }
    if (activity.deleted) {
      return `\u271D(Deleted) ${activity.name}`;
    }
    return activity.name;
  }

  getRows(data: Array<Object>): React.Node {
    return map(
      (activity: Object): React.Node => (
        <tr
          key={activity.id}
          style={activity.deleted || activity.reason.indexOf('first_lookahead') > -1 || activity.reason.indexOf('second_version') > -1 ? styles.deletedGrey : null}
        >
          <td>
            <a onKeyPress={(): null => null} onClick={activity.artifact === 'activity' ? this.showActivityDetailModal.bind(this, activity) : this.showConstraintDetailModal.bind(this, activity)} className="underline-hover" style={{ display: 'flex' }}>
              <span style={{ marginRight: activity.artifact === 'activity' ? 7 : 4, marginLeft: activity.artifact === 'activity' ? 3 : 0 }}>
                <img
                  width={activity.artifact === 'activity' ? '10' : '16'}
                  src={activity.artifact === 'activity' ? activityIcon : constraintIcon}
                  alt="i"
                />
              </span>
              {this.getActivityName(activity)}
            </a>
          </td>
          <td style={{ textAlign: 'center', width: '9em' }}>{activity.delay}</td>
          <td style={{ textAlign: 'center', width: '6em' }}>{activity.project_delay}</td>
          <td>{this.getDelayReason(activity.reason)}</td>
          <td>{this.getDelayDescription(activity.description)}</td>
        </tr>
      ),
      data,
    );
  }

  showActivityDetailModal = (activity: TopDelayResponseType) => {
    this.props.dispatch({
      type: 'showModal',
      modalType: 'activityDetail',
      modalProps: {
        activityId: activity.id,
        activityName: activity.name,
      },
    });
  };

  showConstraintDetailModal = (artifact: TopDelayResponseType): null => {
    // if (window.__r2d2) {
    //   return null;
    // }
    this.props.dispatch({
      type: 'showModal',
      modalType: 'constraintDetail',
      modalProps: {
        constraintId: artifact.id,
        constraintName: artifact.name,
      },
    });
    return null;
  };

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_TOP_DELAY_REASONS,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.week,
      },
    });
  }

  renderWidget(): React$Element<*> {
    const data: Array<TopDelayReasonsSuccessType> = this.props.data.data;
    const sortedData: Array<TopDelayReasonsSuccessType> = data.sort(
      (item1: Object, item2: Object): any => {
        if (this.state.order === 'asc') {
          if (item1[this.state.column] < item2[this.state.column]) {
            return -1;
          }
          if (item1[this.state.column] > item2[this.state.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.column] < item2[this.state.column]) {
          return 1;
        }
        if (item1[this.state.column] > item2[this.state.column]) {
          return -1;
        }
        return 0;
      },
    );
    // const data: Array<TopDelayReasonsSuccessType> = [
    //   {
    //     delay_reason: 'inspection',
    //     days: 33,
    //   },
    //   {
    //     delay_reason: 'labor',
    //     days: 31,
    //   },
    //   {
    //     delay_reason: 'logistics_storage',
    //     days: 28,
    //   },
    //   {
    //     delay_reason: 'material',
    //     days: 22,
    //   },
    //   {
    //     delay_reason: 'method_statement',
    //     days: 12,
    //   },
    //   {
    //     delay_reason: 'minor_temp_prerequisite_work',
    //     days: 8,
    //   },
    //   {
    //     delay_reason: 'rfi',
    //     days: 1,
    //   },
    // ];
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 180}] */
    if (isEmpty(data) && this.props.data.TopDelayReasonsLoading === false) {
      return <span style={styles.noData}>The project was not delayed in this week</span>;
    }
    return (
      <div>
        {/* <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="top_critical_delays" /> */}
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '24%' }} className="header" onClick={this.onSortChange.bind(this, 'name')}>
               Activity/ Constraint Name
                {this.state.column === 'name' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
                <span className="days-suffix" />
              </th>
              <th style={{ textAlign: 'center', width: '6em' }} className="header" onClick={this.onSortChange.bind(this, 'delay')}>
              Activity/ Constraint Delay
                <span>{this.state.column === 'delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}</span>
                <br />
                <span className="days-suffix">(days)</span>
              </th>
              <th style={{ textAlign: 'center', width: '6em' }} className="header" onClick={this.onSortChange.bind(this, 'project_delay')}>
                Project Delayed
                <span>{this.state.column === 'project_delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}</span>
                <br />
                <span className="days-suffix">(days)</span>
              </th>
              <th style={{ width: '20%' }} className="header" onClick={this.onSortChange.bind(this, 'reason')}>
                Reasons
                {this.state.column === 'reason' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
              <th className="header" onClick={this.onSortChange.bind(this, 'description')}>
                Description
                {this.state.column === 'description' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </th>
            </tr>
          </thead>
          <tbody>{this.getRows(sortedData)}</tbody>
        </table>
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={this.props.widgetStyle}>
        <h1 className="header-widget-common">
          Top Critical Delays and Advances
          <img data-tip="This displays the top critical project delays and advances in the week" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.TopDelayReasonsLoading === null ? <Loader /> : this.renderWidget()}
      </section>
    );
  }
}

const Component: any = connect(
  (state: Reducers): { data: TopDelayReasonsState } => ({
    data: state.topDelayReasonsWeekly,
  }),
)(TopDelayReasonsWeekly);

Component.style = {
  width: '98%',
};

export default Component;
