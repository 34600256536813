// @flow

const WorkData: any[] = [
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'Feb - 16',
  },
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'Mar - 16',
  },
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'Apr - 16',
  },
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'May - 16',
  },
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'Jun - 16',
  },
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'Jul - 16',
  },
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'Aug - 16',
  },
  {
    constraint_management: 0,
    plan_reliability: 0,
    ppc: 0,
    site_readiness: 0,
    extra: 0,
    month: 'Sep - 16',
  },
  {
    constraint_management: 3000,
    plan_reliability: 2000,
    ppc: 1500,
    site_readiness: 1000,
    extra: 500,
    month: 'Oct - 16',
  },
  {
    constraint_management: 4000,
    plan_reliability: 3000,
    ppc: 2500,
    site_readiness: 2000,
    extra: 1000,
    month: 'Nov - 16',
  },
  {
    constraint_management: 6000,
    plan_reliability: 3500,
    ppc: 3500,
    site_readiness: 2500,
    extra: 1500,
    month: 'Dec - 16',
  },
  {
    constraint_management: 10000,
    plan_reliability: 5500,
    ppc: 4500,
    site_readiness: 6500,
    extra: 2000,
    month: 'Jan - 17',
  },
  {
    constraint_management: 16000,
    plan_reliability: 7500,
    ppc: 5500,
    site_readiness: 14500,
    extra: 3000,
    month: 'Feb - 17',
  },
  {
    constraint_management: 26000,
    plan_reliability: 10500,
    ppc: 5500,
    site_readiness: 20500,
    extra: 8000,
    month: 'Mar - 17',
  },
  {
    constraint_management: 48000,
    plan_reliability: 12500,
    ppc: 9000,
    site_readiness: 27500,
    extra: 10000,
    month: 'Apr - 17',
  },
  {
    constraint_management: 72000,
    plan_reliability: 18500,
    ppc: 13000,
    site_readiness: 32500,
    extra: 20000,
    month: 'May - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 25500,
    ppc: 19000,
    site_readiness: 38500,
    extra: 23000,
    month: 'June - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 42500,
    ppc: 24000,
    site_readiness: 43500,
    extra: 27000,
    month: 'Jul - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 54500,
    ppc: 36000,
    site_readiness: 47500,
    extra: 33000,
    month: 'Aug - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 80500,
    ppc: 56000,
    site_readiness: 53500,
    extra: 43000,
    month: 'Sep - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 88500,
    ppc: 77000,
    site_readiness: 65500,
    extra: 56000,
    month: 'Oct - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 96500,
    ppc: 86000,
    site_readiness: 72500,
    extra: 61000,
    month: 'Nov - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 98500,
    ppc: 95000,
    site_readiness: 74500,
    extra: 65000,
    month: 'Dec - 17',
  },
  {
    constraint_management: 100000,
    plan_reliability: 99500,
    ppc: 97000,
    site_readiness: 75500,
    extra: 66000,
    month: 'Jan - 18',
  },
  {
    constraint_management: 100000,
    plan_reliability: 100000,
    ppc: 99000,
    site_readiness: 79500,
    month: 'Feb - 18',
  },
  {
    constraint_management: 100000,
    plan_reliability: 100000,
    ppc: 100000,
    site_readiness: 86500,
    month: 'Mar - 18',
  },
  {
    constraint_management: 100000,
    plan_reliability: 100000,
    ppc: 100000,
    site_readiness: 92500,
    month: 'Apr - 18',
  },
  {
    constraint_management: 100000,
    plan_reliability: 100000,
    ppc: 100000,
    site_readiness: 98500,
    month: 'May - 18',
  },
  {
    constraint_management: 100000,
    plan_reliability: 100000,
    ppc: 100000,
    site_readiness: 100000,
    month: 'Jun - 18',
  },
  {
    constraint_management: 100000,
    plan_reliability: 100000,
    ppc: 100000,
    site_readiness: 100000,
    month: 'Jul - 18',
  },
];

export default WorkData;
