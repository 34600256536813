// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import BarChart from '../../components/charts/bar';
import colors from '../../styles/colors';
import { incidentsBySeverityData, incidentsByTradeData, incidentsByTypeData } from './data';
import ToggleButton from '../../components/toggle-button';

type Props = {}

type ChartData = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

/* eslint-disable */
const getHeight: Object = {
  '1': '140',
  '2': '175',
  '3': '207',
  '4': '240',
  '5': '270',
  '6': '302',
  '7': '334',
  '8': '366',
  '9': '398',
  '10': '430',
};
/* eslint-enable */
type State = {
  selectedFilter: string,
}

class IcidentsBySeverityWidget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'severity',
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState({
      selectedFilter: val,
    });
  }

  getData(): ChartData[] {
    if (this.state.selectedFilter === 'severity') {
      return (
        incidentsBySeverityData.map((item: any): any => ({
          label: item.label,
          value: item.value,
          color: colors.cLevelGreen,
          text: item.value,
        })));
    }
    if (this.state.selectedFilter === 'type') {
      return (
        incidentsByTypeData.map((item: any): any => ({
          label: item.label,
          value: item.value,
          color: colors.cLevelGreen,
          text: item.value,
        })));
    }
    return (
      incidentsByTradeData.map((item: any): any => ({
        label: item.label,
        value: item.value,
        color: colors.cLevelGreen,
        text: item.value,
      })));
  }

  renderWidget(options: Object): React.Node {
    return incidentsBySeverityData.length !== 0
      ? (
        <div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div>
              <BarChart data={this.getData()} options={options} dataOptions={{ orientation: 'h', textposition: 'outside', hoverinfo: 'none' }} />
            </div>
          </div>
        </div>)
      : <span style={styles.noData}>No data available</span>;
  }

  render(): React.Node {
    const options: Object = {
      height: 2,
      yaxis: {
        showticklabels: true,
      },
      xaxis: {
        showticklabels: false,
        showgrid: false,
      },
      margin: {
        t: 35,
        r: 10,
        l: 150,
        b: 20,
      },
    };
    const toggleItems: Array<*> = [
      { value: 'severity', label: 'Severity' },
      { value: 'type', label: 'Type' },
      { value: 'trade', label: 'Trade' },
    ];
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <div style={styles.flex}>
          <h1>
            Incidents
          </h1>
          <div>
            <ToggleButton
              items={toggleItems}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
          </div>
        </div>
        {
          this.renderWidget(options)
        }
      </section>
    );
  }
}

const component: any = connect()(IcidentsBySeverityWidget);

export default component;
