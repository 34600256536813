// @flow

import React from 'react';

import type { Project } from '../../auth/with-project-context';
import './styles.css';
// import ProjectStatus from '../project-status-weekly';
// import WorkableBacklog from '../workable-backlog-weekly';
import RateOfProgress from '../rate-progress-weekly';
import CommitmentReliability from '../commitment-reliability-weekly';
import TopDelayReasonsWeekly from '../top-critical-delay-reasons-weekly';

type Props = {
  project: Project,
  week: string,
  weekStart: string,
  weekEnd: string,
}

const styles: Object = {
  // header: {
  //   fontSize: '20px',
  //   color: '#484848',
  //   marginBottom: '1rem',
  //   height: '36px',
  //   boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.5)',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  commonWidget: {
    minHeight: '446px',
    maxHeight: '450px',
    margin: '0.5rem',
  },

  commitmentWidget: {
    // minHeight: '446px',
    margin: '0.5rem',
  },

  singleWidget: {
    width: '30%',
  },
  doubleWidget: {
    width: '64%',
  },
};
function WeekHighlightsWeekly(props: Props): any {
  return (
    <section style={styles.widget}>
      <h1 className="header-widget-common">Week Highlights</h1>
      <div>
        <div className="week-summary-container">
          <RateOfProgress project={props.project} week={props.week} widgetStyle={{ ...styles.widget, ...styles.singleWidget, ...styles.commonWidget }} />
          <TopDelayReasonsWeekly project={props.project} week={props.week} widgetStyle={{ ...styles.widget, ...styles.doubleWidget, ...styles.commonWidget }} />
        </div>
        <div>
          {/* <WorkableBacklog project={props.project} week={props.week} widgetStyle={{ ...styles.widget, ...styles.singleWidget, ...styles.commonWidget }} /> */}
          <CommitmentReliability
            project={props.project}
            week={props.week}
            weekStart={props.weekStart}
            weekEnd={props.weekEnd}
            widgetStyle={{ ...styles.widget, ...styles.commitmentWidget }}
          />
        </div>
      </div>
    </section>
  );
}

WeekHighlightsWeekly.style = {
  width: '98%',
};

export default WeekHighlightsWeekly;
