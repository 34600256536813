// @flow

import React, { Fragment } from 'react';
import type { Node, Element } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';

import type { DeletedWorkflowsState } from './reducer';
import type { LinkedWorkFlowsType } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';


import './styles.css';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: DeletedWorkflowsState,
  project: Project,
  weekStart: string,
  weekEnd: string,
  week: string,
};

type State = {};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  table: {
    width: '100%',
  },
  parentHierarchy: {
    fontSize: '0.65rem',
    marginTop: '0.2rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

function getParentHierarchy(
  artifact: LinkedWorkFlowsType | null,
): string {
  if (!artifact || !artifact.parentChain) return '';

  const parents: Array<string> = artifact.parentChain.split(' | ').reverse();
  // parents.pop();
  return parents.join(' ➔ ');
}

class DeletedWorkflowsWeeklyWidget extends React.PureComponent<Props, State> {
  componentDidMount() {
    this.dispatch();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_DELETD_WORKFLOW,
      data: {
        projectId: this.props.project.id,
        startDate: this.props.weekStart,
        endDate: this.props.weekEnd,
      },
    });
  }

  renderTable(): Element<any> {
    if (!this.props.data.deletedData || (this.props.data.deletedData
        && this.props.data.deletedData.length === 0 && this.props.deletedData.loading === false)) {
      return <span style={styles.noData}>No data available</span>;
    }

    return (
      <Fragment>
          <div className="delete-grid-container">
            <div className="header">Deleted Workflows</div>
            <div className="header">Activities/ Task linked</div>
            <div className="header">Deleted On</div>
            <div className="header">Deleted By</div>
            { this.props.data.deletedData.map(
              (workflow: LinkedWorkFlowsType, index: number): Node => (
                <Fragment key={index}>
                  <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>{`${workflow.serialNo} ${workflow.name}`}</div>
                  <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>
                    <div style={styles.artifactName}>
                      {`${workflow.dependentArtifacts[0].artifactType === 'activity'
                        ? workflow.dependentArtifacts[0].wbs
                        : `#${workflow.dependentArtifacts[0].sequenceNo}`} ${workflow.dependentArtifacts[0].name}`}
                    </div>
                    <div style={styles.parentHierarchy}>{getParentHierarchy(workflow.dependentArtifacts[0])}</div>
                  </div>
                  <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>{moment(workflow.deletedAt).format('DD MMM YYYY')}</div>
                  <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>{workflow.deletedBy}</div>
                </Fragment>
              ),
            )}
          </div>
      </Fragment>
    );
  }

  render(): Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Deleted Workflows
          <img
            data-tip="This table shows the list of deleted workflows that are linked with activities/tasks."
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.renderTable()}
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: DeletedWorkflowsState } => ({
    data: state.deletedWorkflows,
  }),
)(DeletedWorkflowsWeeklyWidget);

component.style = {
  width: '98%',
};

export default component;
