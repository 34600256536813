// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { map } from 'ramda';

import GroupedBar from '../../components/charts/grouped-bar';
import { overallManpower } from './data';
import SelectBox from '../../components/select-box';
import colors from '../../styles/colors';

type Props = {}

type State = {
  work: string,
}

type ViewModel = Array<{
  labels: string[],
  values: number[],
  text: string,
  color?: string,
}>;

const styles: Object = {
  widget: {
    width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    // height: 501,
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  selectBoxContainer: {
    width: '350px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const formatData: Function = function formatData(data: any): any[] {
  return map((item: any): any => (
    {
      month: item.month,
      actual: item.actual,
      planned: item.planned,
    }
  ), data);
};

class OtherWorksWidget extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      work: 'Installation works',
    };
  }

  onCompanyChange = this.onCompanyChange.bind(this);

  onCompanyChange(e: Object) {
    this.setState({
      work: e.value,
    });
  }

  getFormattedData(data: any[]): ViewModel {
    const actual: number[] = [];
    const planned: number[] = [];
    const month: string[] = [];
    data.forEach((item: any) => {
      actual.push(item.actual);
      planned.push(item.planned);
      month.push(item.month);
    });
    const traces: ViewModel = [
      {
        labels: month,
        values: actual,
        text: 'Overall manpower actual',
        color: colors.themePrimaryColorNew,
      },
      {
        labels: month,
        values: planned,
        text: 'Overall manpower planned',
        color: colors.themeSecondaryColorNew,
      },
    ];

    return traces;
  }

  getWorks(companies: Object[]): React.Node {
    return (

      <SelectBox
        options={companies}
        value={this.state.work}
        onChange={this.onCompanyChange}
      />
    );
  }

  renderWidget(): React$Element<*> {
    if (formatData(overallManpower).length === 0) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <GroupedBar
        data={this.getFormattedData(formatData(overallManpower))}
        dataOptions={{
          // textposition: 'outside',
          hoverinfo: 'none',
        }}
      />
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    const data: any[] = [{ label: 'Installation works' }, { label: 'Plumbing works' }, { label: 'Dismantling works' }];
    const works: Object[] = data.map(
      (c: any): Object => ({
        label: c.label,
        value: c.label,
      }),
    );
    return (
      <section style={styles.widget}>
        <div className="c-level__flex">
          <h1>
            Other Works
          </h1>
          <div style={styles.selectBoxContainer}>
            {this.getWorks(works)}
          </div>
        </div>
        {
          this.renderWidget()
        }
      </section>
    );
  }
}

const Component: any = connect()(OtherWorksWidget);

Component.style = {
  width: '98%',
};

export default Component;
