/* eslint-disable flowtype/require-return-type */
// @flow
import React from 'react';

import { getCriticalWorkflowListApi } from '../../apis';
import { getBaseDomainUrl } from '../../constants';


import '../../index.css';
import './index.css';


type Props = {
  projectId: number;
  companyId: number;
  disciplineId: string;
}

type State = {
  data: any;
  isLoading: boolean;
}

type WorkflowName = {
  id: string;
  isoNumber: string;
  name: string;
  serialNo: String;
}

class CriticalWorkflows extends React.Component<Props, State> {
  state = {
    data: null,
    isLoading: false,
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.disciplineId !== prevProps.disciplineId) {
      this.fetchData();
    }
  }

  fetchData = async (): any => {
    try {
      this.setState({
        isLoading: true,
      });

      const response: any = await (getCriticalWorkflowListApi(
        { projectId: this.props.projectId, companyId: this.props.companyId, disciplineId: this.props.disciplineId },
      ));
      if (response.status === 200) {
        this.setState({
          isLoading: false,
          data: response.data,
        });
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
    }
  }

  renderLoading(): React.ReactNode {
    return <div>Loading...</div>;
  }

  getParentHierarchy(
    activity: any | null,
  ): React.ReactNode {
    if (!activity || !activity.parentChain) return '';
    const parents: Array<string> = activity.parentChain.split(' | ').reverse();
    // parents.pop();
    return parents.join(' > ');
  }

  openWorkflow(id: string) {
    /* eslint-disable */
    if (this.props.projectId !== undefined && this.props.projectId !== null) {
      window.open(`${getBaseDomainUrl()}/c/${this.props.companyId}/projects/${this.props.projectId}/flow/instance/${id}`, "_blank");
    }
  }

  getAnchoredData(data: any, id: string): any {
    return <a className="anchorTag" onClick={this.openWorkflow.bind(this, id)}>{data}</a>;
  }

  getWorkflowDetails(names: WorkflowName[]): string {
    const filteredNames: WorkflowName[] = (names || []).filter((name: WorkflowName) => name.serialNo && name.isoNumber);
    if (filteredNames.length === 0) {
      return 'NA';
    }

    const workflowNames: string[] = filteredNames.reduce((a: string, item: WorkflowName, index: number) => {
      a.push(this.getAnchoredData(`${item.serialNo} ${item.isoNumber}`, item.id));
      if (filteredNames.length > 1) {
        if (index < (filteredNames.length) - 1) {
          a.push(', ');
        }
      }
      return a;
    }, []);
    return workflowNames;
  }


  renderContent(): React.ReactNode {

    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <div className="tbl-content">
        <div className="wigt-header">Critical Workflows</div>
        {
          !this.state.data || (this.state.data && this.state.data.length <= 0)
            ? <div className="tbl-nodata">No Data available</div>
            : (
              <div>
                <div className="cw-item">
                  <div className="tbl-header">Activities</div>
                  <div className="tbl-header">Linked workflows</div>
                </div>
                {this.state.data.map((item: any) => (
                    <div className="cw-item">
                      <div className="tbl-items">
                        <div className="tbl-activityName">
                          {item.wbs}
                          {' '}
                          {item.name}
                        </div>
                        <div className="tbl-parentChain">{this.getParentHierarchy(item)}</div>
                      </div>
                      <div className="tbl-items">{this.getWorkflowDetails(item.linkedWorkflows)}</div>
                    </div>
                ))}
            </div>
            )
        }
        
      </div>
    );
  }

  render(): React.ReactNode {
    return <div className="wigt-container">{this.renderContent()}</div>;
  }
}

export default CriticalWorkflows;
