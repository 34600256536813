// @flow

/* eslint-disable */
import * as React from 'react';
import moment from 'moment';
import { groupBy } from 'ramda';

import Colors from '../styles/colors';
import type { MilestoneDetailResponseType } from './sagas';
import delayTexts from '../utils/delay-reasons';

type Props = {
  milestoneName: string,
  milestone: MilestoneDetailResponseType | null,
};

type State = {
};

const styles: Object = {
  heading: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  parent: {
    fontSize: '0.8rem',
    marginTop: '0.6rem',
  },
  tabsContainer: {
    marginTop: '1rem',
    overflowY: 'auto',
  },
  tabs: {
    fontSize: '0.9rem',
    marginBottom: '1rem',
  },
  tab: {
    float: 'left',
    padding: '0.5rem 0.8rem',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
  selectedTab: {
    borderBottomColor: Colors.themePrimaryColor,
  },
  variants: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    paddingLeft: '1rem',
  },
  variant: {
    lineHeight: 1.4,
    marginBottom: '1.5rem',
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  variantName: {
    color: '#6f6f6f',
  },
  delay: {
    color: '#6f6f6f',
    paddingRight: '10px',
  },
  dates: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    lineHeight: 1.5,
  },
  dateRow: {
    display: 'flex',
  },
  date: {
    flex: 1,
  },
  projectDelay: {
    color: '#ff0000',
  },
  projectAdvance: {
    color: '#278A5B',
  },
  delayHeading: {
    fontSize: '1.0rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    textDecoration: 'underline',
  },
  description: {
    color: '#6f6f6f',
  },
  delayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    paddingRight: '10px',
  },
  displayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    padding: '20px 0 20px 0',
  },
  delayedBy: {
    color: '#6f6f6f',
  },
};

function getParentHierarchy(
  milestone: MilestoneDetailResponseType | null,
): string {
  if (!milestone || !milestone.parent_chain) return '';

  const parents: Array<string> = milestone.parent_chain.split(' | ').reverse();
  // parents.pop();
  return parents.join(' ➔ ');
}


export default class MilestoneDetail extends React.Component<Props, State> {
  
  getResponsibleParty(delayItem: Object): React.Node {
    const party: string = delayItem.delay_responsible_party;
    if (party) {
      return (
        <div style={styles.delay}>
          Responsible Party:{' '}
          {party === 'others: pending' ? 'Pending': party}
        </div>
      );
    }
    return null;
  }

  getMilestoneProjectDelay(item: Object): React.Node {
    const delayReasons: Object = {
      delete_activity: 'deletion of activity',
      delete_constraint: 'deletion of constraint',
      create_activity: 'creation of activity',
      create_milestone:'creation of milestone',
      delete_milestone:'deletion of milestone',
      task_progress_reset: 'Reset of task progress',
    };

    const projectDelay = item.project_delay;
      const dateDelay =
      item.res_delay + item.start_date_delay;
      const durationDelay =
      item.duration_delay + item.remaining_duration_delay;

      const dateProperty =
      item.res_delay !== 0
      ? 'remaining early start'
      : 'start date';
      const durationProperty =
      item.remaining_duration_delay !== 0
      ? 'remaining duration'
      : 'duration';

      const dateChange = dateDelay < 0 ? 'advanced' : 'delayed';
      const durationChange = durationDelay < 0 ? 'reduced' : 'extended';

      const dateDetails = dateDelay ? `${item.artifact_type} ${item.name ? `- '${item.name}'` : ''} ${dateProperty} ${dateChange} by ${Math.abs(dateDelay)} ${Math.abs(dateDelay) > 1 ? 'days' : 'day'}` : '';
      const durationDetails = durationDelay ? `${ !dateDetails ? `${item.artifact_type} ${item.name ? `- '${item.name}'` : ''}` : ''} ${durationProperty} ${durationChange} by ${Math.abs(durationDelay)} ${Math.abs(durationDelay) > 1 ? 'days' : 'day'}` : '';

      let artifactDelayInfo = dateDetails && durationDetails ? `${dateDetails} and ${durationDetails}` : dateDetails || durationDetails;

      if (!artifactDelayInfo) {
        artifactDelayInfo = `${item.artifact_type} ${item.name ? `- '${item.name}'` : ''} ${item.delay < 0 ? 'advanced' : 'delayed'} by ${Math.abs(item.delay)} ${Math.abs(item.delay) > 1 ? 'days' : 'day'}`;
      }

      if (item.variance === "create_activity" || item.variance === "delete_activity" || item.variance === "create_milestone" || item.variance === "delete_milestone" || item.variance === "delete_constraint") {
        artifactDelayInfo = `${item.delay < 0 ? 'Advanced' : 'Delayed'} by ${Math.abs(item.delay)} ${Math.abs(item.delay) > 1 ? 'days' : 'day'} due to ${delayReasons[item.variance] || item.variance}`;
      }

      const projectDetails =
      projectDelay && projectDelay !== 0 ? (
        <span>
            {`Project ${projectDelay < 0 ? 'advanced' : 'delayed'} by `}
            <span 
              style={projectDelay < 0 ? styles.projectAdvance : styles.projectDelay}
            >{`${Math.abs(projectDelay)} ${
              Math.abs(projectDelay) > 1 ? 'days' : 'day'
            }`}</span>
          </span>
        ) : ('');

      return (
        <div style={styles.delayMessage}>
          {artifactDelayInfo ? <span style={{marginRight: 3}}>{`${artifactDelayInfo.charAt(0).toUpperCase()}${artifactDelayInfo.slice(1)}.`}</span> : ''}
          {projectDetails}
        </div>
      );
  }

  // getGroupedList(list: Object[]): Object {
  //   const milestone: Object[] = list;
  //   const byProjectDelays: (Object[]) => Object = groupBy(
  //     (milestoneDelay: Object): string =>
  //     milestoneDelay.project_delay === null
  //         ? 'otherDelayReasons'
  //         : 'projectDelayReasons',
  //   );
  //   return byProjectDelays(milestone);
  // }

  renderGivenDelays(delays: Object[]): React.Node {
    return delays
      ? delays.map(
          (item: Object, index: number): React.Node => (
            <li style={styles.variant} >
              {
                this.getMilestoneProjectDelay(item)
                }
                {item.activity_name && item.activity_wbs ? (
                  <div style={styles.variantName}>
                    Activity name:{' '}
                    {`${item.activity_wbs} ${item.activity_name}`}
                  </div>
                ) : null}
                {delayTexts[item.variance] || item.variance ? (
                <div style={styles.variantName}>
                  Reason:{' '}
                  {delayTexts[item.variance] || item.variance}
                </div>
              ) : null}
              <div style={styles.description}>
                Description:{' '}
                {item.description === 'others: pending' ? 'Pending from the user' : item.description}
              </div>
              {this.getResponsibleParty(item)}
              {item.project_delay ?
                 <div style={styles.variantName}>
                  Old project end date: {' '}
                  {moment(item.old_project_end_date).format('DD MMM YYYY')}
                </div>
                : ''
                }
                {item.project_delay ?
                <div style={styles.variantName}>
                  New project end date: {' '}
                  {moment(item.new_project_end_date).format('DD MMM YYYY')}
                </div> : ''
                }
              {item.delayed_by ? (
                <div style={styles.delayedBy}>
                  Recorded by:{' '}
                  {item.delayed_by}
                </div>
              ) : null}
              {item.delayed_on ? (
                <div style={styles.delayedBy}>
                  Recorded on:{' '}
                  {moment(item.delayed_on).format('DD MMM YYYY, hh:mm a')}
                </div>
              ) : null}
            </li>
       ),
       )
     : null;
  }

  renderTabs(): React.Node {
    const tabs: Array<React.Node> = [];

    if (this.props.milestone === null) return null;

    if (this.props.milestone.delays) {
      tabs.push(
        <li
          style={
              { ...styles.tab, ...styles.selectedTab }
          }
          key="milestone"
        >
          Delays / Advances  affected on Milestone
        </li>,
      );
    }

    return <ul style={styles.tabs}>{tabs}</ul>;
  }

  renderDelays(): React.Node {

    if (this.props.milestone === null || this.props.milestone.delays === null) return null;

    // const groupedDelays: Object = this.getGroupedList(
    //   this.props.milestone['delays'],
    // );

    return (
      <ul style={styles.variants}>
        {this.renderGivenDelays(this.props.milestone.delays)}
      </ul>
    );
  }

  renderDates(): React.Node | null {
    const milestone: MilestoneDetailResponseType | null = this.props.milestone;
  
    if (milestone === null) return null;
    return (
      <div style={styles.dates}>
        <div style={styles.dateRow}>
          <div style={styles.date}>
            Base End date:
            {' '}{milestone.base_end_date ? moment(milestone.base_end_date).format('DD MMM YYYY') : ' - '}
          </div>
          <div style={styles.date}>
            Expected End date:
            {' '}{milestone.end_date ? moment(milestone.end_date).format('DD MMM YYYY') : ' - '}
          </div>
        </div>
       
      </div>
    );
  }



  render(): React.Node {
    return (
      <section style={styles.container}>
        <h2 style={styles.heading}>{this.props.milestoneName}</h2>
        <div style={styles.parent}>
          {getParentHierarchy(this.props.milestone)}
        </div>
        <div>{this.renderDates()}</div>
        <div style={styles.tabsContainer}>{this.renderTabs()}</div>
        <div style={{ flex: 1, overflowY: 'auto' }}>{this.renderDelays()}</div>
      </section>
    );
  }
}
