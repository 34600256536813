// @flow

import ActionTypes from './action-types';
import type { DelayResponseType, DelayHistoryType, DelayDetailAction } from './sagas';

export type DelayDetailState = {
  chartData: DelayResponseType[],
  delayData: DelayHistoryType[],
  isLoading: boolean,
  status: number,
};

const initialState: DelayDetailState = {
  isLoading: true,
  chartData: [],
  delayData: [],
  status: 0,
};


type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

export default function (state: DelayDetailState = initialState, action: DelayDetailAction): Exact<DelayDetailState> {
  switch (action.type) {
    case ActionTypes.GET_DELAY_COMMENTS_SUCCESS:
      return {
        ...state,
        chartData: action.data.comments,
        status: 0,
      };
    case ActionTypes.GET_DELAY_HISTORY_SUCCESS:
      return {
        ...state,
        delayData: action.data.edit_history,
        status: 0,
      };
    case ActionTypes.UPDATE_DELAY_HISTORY_STATUS_FAIL:
      return {
        ...state,
        status: action.status,
      };
    case ActionTypes.CLEAR_DELAY_DETAIL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
