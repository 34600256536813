// @flow
import * as React from 'react';

import '../style.css';
import CivilWorksWidget from './civil-works';
import MechanicalWorksWidget from './mechanical-works';
import ElectricalWorksWidget from './electrical-works';
import OtherWorksWidget from './other-works';
import Subcontractor01 from './subcontractor-01';
import Subcontractor02 from './subcontractor-02';
import Subcontractor03 from './subcontractor-03';
import Subcontractor04 from './subcontractor-04';
import ToggleButton from '../../components/toggle-button';

type Props = {
  // dispatch: Function,
  // match: Object,
  // location: Object,
  // project: Object,
};

type State = {
  selectedFilter: string,
}

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },

  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

// eslint-disable-next-line react/prefer-stateless-function
class ManpowerWidget extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'discipline',
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState({
      selectedFilter: val,
    });
  }

  render(): React.Node {
    const toggleItems: Array<*> = [
      { value: 'discipline', label: 'Discipline' },
      { value: 'subcontractors', label: 'Subcontractors' },
    ];
    return (
      <section style={styles.widget}>
        <div className="c-level__flex">
          <h1>
            Manpower
          </h1>
          <div>
            <ToggleButton
              items={toggleItems}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
          </div>
        </div>
        { this.state.selectedFilter === 'discipline'
          ? <div>
              <div className="c-level__section">
                <CivilWorksWidget />
                <MechanicalWorksWidget />
              </div>
              <div className="c-level__section">
                <ElectricalWorksWidget />
                <OtherWorksWidget />
              </div>
            </div>
          : <div>
              <div className="c-level__section">
                <Subcontractor01 />
                <Subcontractor02 />
              </div>
              <div className="c-level__section">
                <Subcontractor03 />
                <Subcontractor04 />
              </div>
            </div>
        }
      </section>
    );
  }
}

export default ManpowerWidget;
