// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getProjectWeeklyWeightProgressApi from './apis';

export type ProjectWeeklyWeightProgressResponse = {
  week_start_date: string,
  week_end_date: string,
  planned_weighted_progress_based_on_early_start: number,
  planned_weighted_progress_based_on_late_start: number,
  actual_weighted_progress: number,
}

export type ProjectWeeklyWeightProgressAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_SUCCESS'>,
  data: ProjectWeeklyWeightProgressResponse[],
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_FAIL'>,
|};

function* getProjectWeeklyWeightProgress(action: { data: { projectId: string} }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: ProjectWeeklyWeightProgressResponse[] } = yield call(getProjectWeeklyWeightProgressApi, action.data.projectId);
    yield put({ type: ActionTypes.GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_PROJECT_WEEKLY_WEIGHT_PROGRESS, getProjectWeeklyWeightProgress);
}
