// @flow

/* eslint-disable */
import * as React from 'react';
import moment from 'moment';

import { categories } from './constants';
import Colors from '../styles/colors';

type Props = {
  artifact: any,
  workflowDetails?: any;
  openWorkflow: (id: string) => void
};

type State = {
};

const styles: Object = {
  heading: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  parent: {
    fontSize: '0.8rem',
    marginTop: '0.6rem',
  },
  tab: {
    float: 'left',
    padding: '0.5rem 0.8rem',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
  selectedTab: {
    borderBottomColor: Colors.themePrimaryColor,
  },
  variants: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    paddingLeft: '1rem',
  },
  variant: {
    lineHeight: 1.4,
    marginBottom: '1.5rem',
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  variantName: {
    color: '#6f6f6f',
  },
  dates: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    lineHeight: 1.5,
  },
  dateRow: {
    display: 'flex',
  },
  date: {
    flex: 1,
  },
  description: {
    color: '#6f6f6f',
  },
  flowName: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    paddingRight: '10px',
  },
};


export default class FlowDetail extends React.Component<Props, State> {
  renderFlowDetails(workflowDetails: Object[]): React.Node {
    return workflowDetails
      ? workflowDetails.map(
          (item: Object, index: number): React.Node => (
            <li style={styles.variant} key={index}>
              <div
                onClick={this.props.openWorkflow.bind(this, item[0].workflow.id)}
                className="underline-hover"
              >
                <div style={styles.flowName}>{`${item[0].workflow.isoNumber} ${item[0].workflow.name}`}</div>
              </div>
              <div style={styles.description}>
                Category name:{' '}
                {categories.find((r: any) => r.id === item[0].categoryId) ? categories.find((r: any) => r.id === item[0].categoryId).name : '-'}
              </div>
              <div style={styles.description}>
                Start date:{' '}
                {moment(item[0].workflow.startDate).format('DD MMM YYYY')}
              </div>
              <div style={styles.description}>
                End date:{' '}
                {item[0].completedOn ? moment(item[0].completedOn).format('DD MMM YYYY') : '-'}
              </div>
            </li>
          ),
        )
      : null;
  }

  renderTabs(): React.Node {
    const tabs: Array<React.Node> = [];

    if (this.props.workflowDetails && this.props.workflowDetails.length !== 0) {
      tabs.push(
        <li
          style={
              { ...styles.tab, ...styles.selectedTab }
          }
          key="workflow"
        >
          Workflows affected by Force Start
        </li>,
      );
    }

    return <ul style={styles.tabs}>{tabs}</ul>;
  }

  renderDelays(): React.Node {
    return (
      <ul style={styles.variants}>
        {this.renderFlowDetails(this.props.workflowDetails)}
      </ul>
    );
  }

  renderDates(): React.Node | null {
    const artifact: ForceStartedType | null = this.props.artifact;
  
    if (artifact === null) return null;
    return (
      <div style={styles.dates}>
        <div style={styles.dateRow}>
          <div style={styles.date}>
           Force started on:
            {' '}{moment(artifact.forceStartedAt).format('DD MMM YYYY')}
          </div>
          <div style={styles.date}>
            Force started by:
            {' '}{artifact.forceStartedBy}
          </div>
        </div>
       
      </div>
    );
  }

  renderParent(): React.Node | null {
    const parents: Array<string> = this.props.artifact.artifactParentChain.split(' | ').reverse();
    return (
     <div style={styles.parent}>
        {'Root activity name:'}{' '}
          {parents.join(' ➔ ')}
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.container}>
        <h2 style={styles.heading}>{this.props.artifact.artifactName}</h2>
        <div>{this.renderParent()}</div>
        <div>{this.renderDates()}</div>
        <div style={styles.tabsContainer}>{this.renderTabs()}</div>
        <div style={{ flex: 1, overflowY: 'auto' }}>{this.renderDelays()}</div>
      </section>
    );
  }
}
