// @flow
import ActionTypes from './action-types';
import type { ForceStartedAction, ForceStartedType } from './sagas';

export type ForceStartedState = {
  forceStartData: ForceStartedType[],
  loading: boolean | null,
};

const initialState: ForceStartedState = { loading: null, forceStartData: [] };

export default function (state: ForceStartedState = initialState, action: ForceStartedAction): ForceStartedState {
  switch (action.type) {
    case ActionTypes.GET_FORCE_STARTED_SUCCESS:
      return {
        ...state,
        forceStartData: action.data,
        loading: false,
      };
    default:
      return state;
  }
}
