// @flow
import FlowStatus from './widgets/flow-status';
import ProjectDelay from './widgets/project-delay';
import PotentialDelays from './widgets/potential-delays';
import CriticalWorklfows from './widgets/critical-workflows';
import TopDelayReasons from './widgets/top-delay-reasons';

const widgets: { [id: string]: [] } = {
  '7d896135-b4af-4033-aff8-fb83fbf8d3e9': [{
    name: 'FlowStatus',
    widget: FlowStatus,
  },
  {
    name: 'ProjectDelay',
    widget: ProjectDelay,
  },
  {
    name: 'CriticalWorklfows',
    widget: CriticalWorklfows,
  },
  {
    name: 'TopDelayReasons',
    widget: TopDelayReasons,
  },
  ],
  '9417398e-9f93-43ab-b1d0-333b0b0578d2': [{
    name: 'FlowStatus',
    widget: FlowStatus,
  },
  {
    name: 'PotentialDelays',
    widget: PotentialDelays,
  },
  {
    name: 'ProjectDelay',
    widget: ProjectDelay,
  },
  {
    name: 'CriticalWorklfows',
    widget: CriticalWorklfows,
  },
  {
    name: 'TopDelayReasons',
    widget: TopDelayReasons,
  },
  ],
  '373dcaa6-1be5-4eba-a16e-5a6081033474': [{
    name: 'FlowStatus',
    widget: FlowStatus,
  },
  {
    name: 'PotentialDelays',
    widget: PotentialDelays,
  },
  {
    name: 'ProjectDelay',
    widget: ProjectDelay,
  },
  {
    name: 'CriticalWorklfows',
    widget: CriticalWorklfows,
  },
  {
    name: 'TopDelayReasons',
    widget: TopDelayReasons,
  },
  ],
  '63fd37da-7b53-4cf1-a7c4-a979f5101d05': [{
    name: 'FlowStatus',
    widget: FlowStatus,
  },
  {
    name: 'PotentialDelays',
    widget: PotentialDelays,
  },
  {
    name: 'ProjectDelay',
    widget: ProjectDelay,
  },
  {
    name: 'CriticalWorklfows',
    widget: CriticalWorklfows,
  },
  {
    name: 'TopDelayReasons',
    widget: TopDelayReasons,
  },
  ],
};


export default widgets;
