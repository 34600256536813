// @flow

export type DeletedWorkflows = {
  GET_DELETD_WORKFLOW: 'getDeletedWorkflow',
  GET_DELETD_WORKFLOW_SUCCESS: 'getDeletedWorkflowSuccess',
  GET_DELETD_WORKFLOW_FAIL: 'getDeletedWorkflowFail',
}

const actionTypes: DeletedWorkflows = {
  GET_DELETD_WORKFLOW: 'getDeletedWorkflow',
  GET_DELETD_WORKFLOW_SUCCESS: 'getDeletedWorkflowSuccess',
  GET_DELETD_WORKFLOW_FAIL: 'getDeletedWorkflowFail',
};

export default actionTypes;
