// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import { compose } from 'recompose';
import type { MapStateToProps, Connector } from 'react-redux';

import withProjectContext from '../auth/with-project-context';
import { Text } from '../styles/colors';

import type { weeklyState, widget as widgetType } from './reducer';
import type { Reducers } from '../reducers';
import type { Project } from '../auth/with-project-context';

import Header from './header';

import WeeklyCommitmentReliability from '../widgets/weekly-commitment-reliability';
import ProjectSummaryWeekly from '../widgets/project-summary-weekly';
import WeekHighlightsWeekly from '../widgets/week-highlights-weekly';
import CprWeekly from '../widgets/cpr-weekly';
import ReasonForDelaysWidget from '../widgets/reason-for-delays';
import PlanReliabilityActivityWeekly from '../widgets/plan-reliability-activity-weekly';
import PlanReliabilityTaskWeekly from '../widgets/plan-reliability-tasks-weekly';
import TopMostDelayedActivities from '../widgets/top-most-delayed-activities';
import WorkDelayWidget from '../widgets/work-delay-widget-weekly';
import ConstraintDelayWidget from '../widgets/constraint-delay-widget-weekly';
import DeletedWorkflowsWeeklyWidget from '../widgets/deleted-workflows-weekly';
import TopExtendedActivities from '../widgets/top-extended-activities';
import TopEarlyFinishedActivities from '../widgets/top-early-finished-activities';
import TopShortenedActivities from '../widgets/top-shortened-activities';
import TopDelayedConstraints from '../widgets/top-delayed-constraints';
import TopMostDelayedTasks from '../widgets/top-most-delayed-tasks';
import TopExtendedTasks from '../widgets/top-extended-tasks';
import TopEarlyFinishedTasks from '../widgets/top-early-finished-tasks';
import TopShortenedTasks from '../widgets/top-shortened-tasks';

import RootModal from '../modals/root-modal';

import ProjectActions from '../project/action-types';

type Props = {
  dispatch: Function,
  store: weeklyState,
  project: Project,
  projectAllWeeks: [],
  projectDetails: Object,
  match: {
    params: {
      weekStart: string,
    },
  },
};

const widgetTypes: Object = {
  weeklyCommitmentReliability: WeeklyCommitmentReliability,
  projectSummaryWeekly: ProjectSummaryWeekly,
  weekHighlightsWeekly: WeekHighlightsWeekly,
  cprWeekly: CprWeekly,
  reasonForDelays: ReasonForDelaysWidget,
  planReliabilityActivityWeekly: PlanReliabilityActivityWeekly,
  planReliabilityTaskWeekly: PlanReliabilityTaskWeekly,
  topMostDelayedActivities: TopMostDelayedActivities,
  workDelay: WorkDelayWidget,
  constraintDelay: ConstraintDelayWidget,
  deletedWorkflows: DeletedWorkflowsWeeklyWidget,
  topExtendedActivities: TopExtendedActivities,
  topEarlyFinishedActivities: TopEarlyFinishedActivities,
  topShortenedActivities: TopShortenedActivities,
  topDelayedConstraints: TopDelayedConstraints,
  topMostDelayedTasks: TopMostDelayedTasks,
  topExtendedTasks: TopExtendedTasks,
  topEarlyFinishedTasks: TopEarlyFinishedTasks,
  topShortenedTasks: TopShortenedTasks,
};

const styles: Object = {
  emptyState: {
    textAlign: 'center',
    paddingTop: '10rem',
    lineHeight: 1.8,
    heading: {
      fontSize: '1.3rem',
    },
    subheading: {
      color: Text.secondary,
      ':hover': {
        color: 'black',
        cursor: 'pointer',
      },
    },
  },
  // widgetsContainer: {
  //   padding: '2rem',
  //   paddingTop: '2rem',
  // },
  // widget: {
  //   display: 'inline-block',
  //   width: '48%',
  //   marginTop: '2%',
  //   marginBottom: '2%',
  //   marginLeft: '1%',
  //   marginRight: '1%',
  //   verticalAlign: 'top',
  //   '@media (max-width: 800px)': {
  //     width: '100%',
  //   },
  // },
};

class WeeklyContainer extends React.PureComponent<Props> {
  static defaultProps: Props;

  componentDidMount() {
    this.props.dispatch({
      type: ProjectActions.GET_COMPANIES,
      data: { projectId: this.props.project.id },
    });
  }

  getWeek(): string {
    if (R.isEmpty(this.props.projectAllWeeks)) {
      return '';
    } if (this.props.match.params.weekStart === 'current') {
      return this.props.projectAllWeeks.slice(-1).pop().start;
    } if (this.props.match.params.weekStart === 'last') {
      return this.props.projectAllWeeks.slice(-2, -1).pop().start;
    }
    return this.props.match.params.weekStart;
  }

  getWeekId(weekStart: string): number | null {
    const requiredweek: any = this.props.projectAllWeeks.find((week: any): boolean => week.start === weekStart);
    if (requiredweek) {
      return requiredweek.id;
    }
    return null;
  }

  renderPlaceholder(): React.Node {
    return (
      <div style={styles.emptyState}>
        <h1 style={styles.emptyState.heading}>You have not added any widgets yet</h1>
        <h2 style={styles.emptyState.subheading}>Customise your dashboard by adding widgets. Click here to add widgets</h2>
      </div>
    );
  }

  renderWidgets(): React.Node {
    if (R.isEmpty(this.props.store.widgets)) {
      return this.renderPlaceholder();
    }

    return (
      <ul className="widget-container">
        {this.props.store.widgets.map((widget: widgetType): React.Element<any> => {
          const Widget: $Values<typeof widgetTypes> = widgetTypes[widget.name];
          // const widgetStyles: Array<Object> = [styles.widget];

          // widgetStyles.push(Widget.style);
          const week: string = this.getWeek();
          const weekId: number | null = this.getWeekId(week);

          const weekDates: ?Object = this.props.projectAllWeeks.find((weekday: any): boolean => weekday.start === week);
          if (widget.name === 'deletedWorkflows' && this.props.projectDetails && this.props.projectDetails.type !== 'flowschedule') {
            return null;
          }
          if (widget.name === 'deletedWorkflows' && weekDates && this.props.projectDetails.type === 'flowschedule') {
            return <li key={widget.name} className="widget" style={Widget.style}>
                      <Widget project={this.props.project} week={week} weekStart={weekDates.start} weekEnd={weekDates.end} />
                   </li>;
          }
          if (widget.name === 'weekHighlightsWeekly' && weekDates) {
            return <li key={widget.name} className="widget" style={Widget.style}>
                      <Widget project={this.props.project} weekStart={weekDates.start} weekEnd={weekDates.end} week={week} weekId={weekId} />
                   </li>;
          }
          return <li key={widget.name} className="widget" style={Widget.style}><Widget project={this.props.project} week={week} weekId={weekId} /></li>;
        })}
      </ul>
    );
  }

  render(): React.Node {
    return (
      <section>
        <Header
          weekDate={this.getWeek()}
          weekStart={this.props.match.params.weekStart}
          project={this.props.project}
          companyId={this.props.match.params.companyId}
          allWeeks={this.props.projectAllWeeks}
        />
        {this.renderWidgets()}
        <RootModal />
      </section>
    );
  }
}

// export default compose(
//   connect((state: Reducers): Object => ({ store: state.weekly, projectDetails: state.project.projectDetails, projectAllWeeks: state.project.projectAllWeeks })),
//   withProjectContext,
// )(WeeklyContainer);


type TMapStateToFilter = MapStateToProps<Reducers, any, any>

const mapStateToProps: TMapStateToFilter = (state: any): Object => (
  { store: state.weekly, projectDetails: state.project.projectDetails, projectAllWeeks: state.project.projectAllWeeks });

const connector: Connector<any, Props> = compose(connect(mapStateToProps), withProjectContext);

export default connector(WeeklyContainer);
