// @flow

import * as React from 'react';
// import { connect } from 'react-redux';

import colors from '../../../styles/colors';
import Chart from './chart';
import Gauge from './gauge';
import ConcreteCastingData from '../data';

type Props = {};

type ViewModel = {
  labels: string[],
  values: number[],
  texts: string[],
  name: string,
  color: string,
  textColor: ?string,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class WeeklyDelayMitigation extends React.PureComponent<Props> {
  getChartData(data: any[]): Object {
    const monthlyPlanned: number[] = [];
    const monthlyActual: number[] = [];
    const cumilativePlanned: number[] = [];
    const cumilativeActual: number[] = [];

    const label: string[] = [];

    const monthlyPlannedHoverTexts: string[] = [];
    const monthlyActualHoverTexts: string[] = [];
    const cumilativePlannedHoverTexts: string[] = [];
    const cumilativeActualHoverTexts: string[] = [];

    data.forEach((item: any) => {
      monthlyPlanned.push(item.monthly_planned);
      monthlyActual.push(item.monthly_actual);
      cumilativePlanned.push(item.cumilative_planned);
      cumilativeActual.push(item.cumilative_actual);

      label.push(item.month);

      monthlyPlannedHoverTexts.push(item.monthly_planned);
      monthlyActualHoverTexts.push(item.monthly_actual);
      cumilativePlannedHoverTexts.push(item.cumilative_planned);
      cumilativeActualHoverTexts.push(item.cumilative_actual);
    });
    const traces: ViewModel[] = [
      {
        labels: label,
        values: monthlyPlanned,
        texts: monthlyPlannedHoverTexts,
        name: 'Monthly planned',
        color: colors.themePrimaryColor,
        textColor: colors.cLevelGray,
      },
      {
        labels: label,
        values: monthlyActual,
        texts: monthlyActualHoverTexts,
        name: 'Monthly actual',
        color: colors.themeSecondaryColor,
        textColor: colors.cLevelGray,
      },
    ];

    const totalLine: ViewModel[] = [
      {
        labels: label,
        values: cumilativePlanned,
        texts: cumilativePlannedHoverTexts,
        name: 'Cumulative planned',
        color: colors.themePrimaryColor,
        textColor: null,
      },
      {
        labels: label,
        values: cumilativeActual,
        texts: cumilativeActualHoverTexts,
        name: 'Cumulative actual',
        color: colors.themeSecondaryColor,
        textColor: null,
      },
    ];

    return {
      traces,
      totalLine,
    };
  }

  renderWidget(chartData: Object): React.Node {
    return ConcreteCastingData.length !== 0 ? (
      <React.Fragment>
        <div style={{ width: '62%' }}><Chart barData={chartData.traces} lineData={chartData.totalLine} /></div>
      </React.Fragment>
    ) : (
      <span style={styles.noData}>No data available</span>
    );
  }

  render(): React.Node {
    const chartData: Object = this.getChartData(ConcreteCastingData);
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
          <h1>
            Cable Pullling Productivity
          </h1>
          <div className="c-level__flex">
            <Gauge />
            {this.renderWidget(chartData)}
          </div>
      </section>
    );
  }
}

const Component: any = (WeeklyDelayMitigation);


export default Component;
