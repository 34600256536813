// @flow
import ActionTypes from './action-types';
import type { CriticalMilestoneStatusAction, CriticalMilestoneStatus } from './sagas';

export type CriticalMilestoneStatusState = {
  chartData: CriticalMilestoneStatus[],
  loading: boolean | null,
};

const initialState: CriticalMilestoneStatusState = { loading: null, chartData: [] };

export default function (state: CriticalMilestoneStatusState = initialState, action: CriticalMilestoneStatusAction): CriticalMilestoneStatusState {
  switch (action.type) {
    case ActionTypes.GET_CRITICAL_MILESTONES_STATUS_SUCCESS:
      return {
        ...state,
        chartData: [...action.data.critical_milestones],
        loading: false,
      };
    default:
      return state;
  }
}
