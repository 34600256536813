// @flow

import * as React from 'react';

import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Home from './home';
import R2D2Home from './home/r2d2';
import CLevel from './c-level';

import store from './store';

const App: Function = (): React.Element<any> => (
  <Provider store={store}>
    <div className="App">
      <Router>
        <Switch>
          <Route path="/projects/:projectId" component={Home} />
          <Route path="/c/:companyId/projects/:projectId" component={R2D2Home} />
          <Route path="/clevel/projects/:projectId" component={CLevel} />
        </Switch>
      </Router>
    </div>
  </Provider>
);

export default App;
