// @flow

/* eslint-disable */
import * as React from 'react';
import moment from 'moment';
import { groupBy } from 'ramda';

import Colors from '../styles/colors';
import type { ActivityDetailResponseType } from './sagas';
import delayTexts from '../utils/delay-reasons';

type Props = {
  activityName: string,
  activity: ActivityDetailResponseType | null,
};

type State = {
  selectedTab: string,
};

const styles: Object = {
  heading: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  parent: {
    fontSize: '0.8rem',
    marginTop: '0.6rem',
  },
  tabsContainer: {
    marginTop: '1rem',
    overflowY: 'auto',
  },
  tabs: {
    fontSize: '0.9rem',
    marginBottom: '1rem',
  },
  tab: {
    float: 'left',
    padding: '0.5rem 0.8rem',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
  selectedTab: {
    borderBottomColor: Colors.themePrimaryColor,
  },
  variants: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    paddingLeft: '1rem',
  },
  variant: {
    lineHeight: 1.4,
    marginBottom: '1.5rem',
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  variantName: {
    color: '#6f6f6f',
  },
  delay: {
    color: '#6f6f6f',
    paddingRight: '10px',
  },
  dates: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    lineHeight: 1.5,
  },
  dateRow: {
    display: 'flex',
  },
  date: {
    flex: 1,
  },
  projectDelay: {
    color: '#ff0000',
  },
  projectAdvance: {
    color: '#278A5B',
  },
  delayHeading: {
    fontSize: '1.0rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    textDecoration: 'underline',
  },
  description: {
    color: '#6f6f6f',
  },
  delayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    paddingRight: '24px',
  },
  displayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    padding: '20px 0 20px 0',
  },
  delayedBy: {
    color: '#6f6f6f',
  },
};

// const delayTexts: Object = {
//   access_space: 'Access / Space',
//   approval_permits: 'Approval / Permits',
//   client_decision: 'Client decision',
//   contracts: 'Contracts',
//   drawings: 'Drawings',
//   equipment: 'Equipment',
//   inspection: 'Inspection',
//   labor: 'Labor',
//   logistics_storage: 'Logistics / Storage',
//   material: 'Material',
//   method_statement: 'Method statement',
//   minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
//   rfi: 'RFI',
//   safety_requirement: 'Safety requirement',
//   re_scheduling: 'Re-Scheduling ',
//   unforeseen_site_conditions: 'Unforeseen site conditions',
//   weather: 'Weather',
//   schedule_error: 'Schedule error',
//   defects_rework: 'Defects rework',
//   first_lookahead: 'Delay on First Lookahead',
//   others: 'Others',
//   do_list_confirmation: 'Do List Confirmation',
//   change_in_dependency: 'Change In Dependency',
//   creation_of_dependency: 'Creation of Dependency',
//   delete_activity: 'Deletion of activity',
//   delete_activity_dependency: 'Deletion of activity dependency',
//   delete_task_dependency: 'Deletion of task dependency',
//   delete_constraint: 'Deletion of constraint',
//   create_activity: 'Creation of activity',
// };

function getParentHierarchy(
  activity: ActivityDetailResponseType | null,
): string {
  if (!activity || !activity.parent_chain) return '';

  const parents: Array<string> = activity.parent_chain.split(' | ').reverse();
  // parents.pop();
  return parents.join(' ➔ ');
}

export default class ActivityDetail extends React.Component<Props, State> {
  state = {
    selectedTab: '',
  };

  componentWillReceiveProps(props: Props) {
    if (this.props.activity !== props.activity) {
      this.setState({ selectedTab: this.getActiveTab(props) });
    }
  }

  getActiveTab(props: Props): string {
    if (props.activity === null) return '';
    if (props.activity.activity_delays.length !== 0) return 'activity';
    if (props.activity.activity_constraint_delays.length !== 0)
      return 'activityConstraint';
    if (props.activity.task_constraint_delays.length !== 0)
      return 'taskConstraint';
    if (props.activity.task_delays.length !== 0) return 'task';

    return '';
  }

  setTab(tab: string) {
    this.setState({ selectedTab: tab });
  }

  getResponsibleParty(delayItem: Object): React.Node {
    const party: string = delayItem.delay_responsible_party;
    if (party) {
      return (
        <div style={styles.delay}>
          Responsible Party: {' '}
          {party === 'others: pending' ? 'Pending': party}
        </div>
      );
    }
    return null;
  }

  getHeading(item: Object): React.Node {
    try {
      const projectDelay = item.project_delay;
      // one value will be '0' here, so safe to add them
      const dateDelay =
      item.res_delay + item.start_date_delay;
      const durationDelay =
      item.duration_delay + item.remaining_duration_delay;

      const dateProperty =
      item.res_delay !== 0
      ? 'remaining early start'
      : 'start date';
      const durationProperty =
      item.remaining_duration_delay !== 0
      ? 'remaining duration'
      : 'duration';

      const dateChange = dateDelay < 0 ? 'advanced' : 'delayed';
      const durationChange = durationDelay < 0 ? 'reduced' : 'extended';

      const dateDetails = dateDelay ? `${dateProperty} ${dateChange} by ${Math.abs(dateDelay)} ${Math.abs(dateDelay) > 1 ? 'days' : 'day'}` : '';
      const durationDetails = durationDelay ? `${durationProperty} ${durationChange} by ${Math.abs(durationDelay)} ${Math.abs(durationDelay) > 1 ? 'days' : 'day'}` : '';

      let activityDelayInfo = dateDetails && durationDetails ? `${dateDetails} and ${durationDetails}` : dateDetails || durationDetails;

      if (!activityDelayInfo) {
        activityDelayInfo = `Activity ${item.delay < 0 ? 'advanced' : 'delayed'} by ${Math.abs(item.delay)} ${Math.abs(item.delay) > 1 ? 'days' : 'day'}`;
      }

      if (item.variance === "create_activity" || item.variance === "delete_activity") {
        activityDelayInfo = "";
      }

      const projectDetails =
      projectDelay !== 0 ? (
        <span>
            {`Project ${projectDelay < 0 ? 'advanced' : 'delayed'} by `}
            <span 
            >{`${Math.abs(projectDelay)} ${
              Math.abs(projectDelay) > 1 ? 'days' : 'day'
            }`}</span>
          </span>
        ) : ('');

      return (
        <div style={styles.delayMessage}>
          {activityDelayInfo ? <span style={{marginRight: 3}}>{`${activityDelayInfo.charAt(0).toUpperCase()}${activityDelayInfo.slice(1)}.`}</span> : ''}
          {projectDetails}
        </div>
      );
    } catch(e) {
      console.log(e);
      // return null;
    }
    return null;
  };

  getActivityProjectDelay(item: Object): React.Node {
    let change = item.delay < 0 ? 'advanced' : 'delayed';
    let message = '';
    if (item.project_delay) {
      change = item.delay < 0 ? 'advance' : 'delay';
      // if (item.variance === 'creation_of_dependency' || item.variance === 'change_in_dependency') {
      //   message = `Activity dependency caused project to ${change} by `;
      // } else {
      //   message = `Activity ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} of ${Math.abs(item.delay)} day(s) caused project to ${change} by `;
      // }

      message = `Activity ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} of ${Math.abs(item.delay)} day(s) caused project to ${change} by `;

      if (item.variance === 'creation_of_dependency') {
        message = `Creation of activity dependency caused project to ${change} by `;
      }
      if (item.variance === 'change_in_dependency') {
        message = `Change in activity dependency caused project to ${change} by `;
      }
      if (item.variance === 'delete_activity') {
        message = `Deletion of activity caused project to ${change} by `;
      }
      if (item.variance === 'delete_activity_dependency') {
        message = `Deletion of activity dependency caused project to ${change} by `;
      }
      if (item.variance === 'delete_constraint') {
        message = `Deletion of constraint caused project to ${change} by `;
      }
      if (item.variance === 'create_activity') {
        message = `Creation of activity caused project to ${change} by `;
      }

      return (
        <div style={styles.delayMessage}>
          {message}
          <span style={item.project_delay < 0 ? styles.projectAdvance : styles.projectDelay}
          >{Math.abs(item.project_delay)} day(s)</span>
        </div>
      );
    }
    // if (item.variance === 'creation_of_dependency' || item.variance === 'change_in_dependency') {
    //   message = `Activity ${change} by ${Math.abs(item.delay)} day(s) due to dependency`;
    // } else {
    //   message = `Activity ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} by ${Math.abs(item.delay)} day(s)`
    // }

    message = `Activity ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} by ${Math.abs(item.delay)} day(s)`

    if (item.variance === 'creation_of_dependency') {
      message = `Activity ${change} by ${Math.abs(item.delay)} day(s) due to creation of dependency`;
    }
    if (item.variance === 'change_in_dependency') {
      message = `Activity ${change} by ${Math.abs(item.delay)} day(s) due to change in dependency`;
    }
    if (item.variance === 'delete_activity') {
      message = `Activity ${change} by ${Math.abs(item.delay)} day(s) due to deletion of activity`;
    }
    if (item.variance === 'delete_activity_dependency') {
      message = `Activity ${change} by ${Math.abs(item.delay)} day(s) due to deletion of dependency`;
    }
    if (item.variance === 'delete_constraint') {
      message = `Activity ${change} by ${Math.abs(item.delay)} day(s) due to deletion of constraint`;
    }
    if (item.variance === 'create_activity') {
      message = `Activity ${change} by ${Math.abs(item.delay)} day(s) due to creation of activity`;
    }

    return (
      <div style={styles.delayMessage}>
        {message}
      </div>
    );
  }

  getTaskProjectDelay(item: Object): React.Node {
    let change = item.delay < 0 ? 'advanced' : 'delayed';
    let message = '';
    if (item.project_delay) {
      change = item.delay < 0 ? 'advance' : 'delay';
      // if (item.variance === 'creation_of_dependency' || item.variance === 'change_in_dependency') {
      //   message = `Task dependency caused project to ${change} by `
      // } else {
      //   message = `${item.name} ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} of ${Math.abs(item.delay)} caused project to ${change} by `
      // }
      message = `${item.name} ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} of ${Math.abs(item.delay)} caused project to ${change} by `

      if (item.variance === 'creation_of_dependency') {
        message = `Creation of task dependency caused project to ${change} by `
      }
      if (item.variance === 'change_in_dependency') {
        message = `Change in task dependency caused project to ${change} by `
      }
      if (item.variance === 'delete_task_dependency') {
        message = `Deletion of task dependency caused project to ${change} by `
      }
      if (item.variance === 'delete_constraint') {
        message = `Deletion of constraint caused project to ${change} by `
      }

      return (
        <div style={styles.delayMessage}>
          {message}
          <span style={item.project_delay < 0 ? styles.projectAdvance : styles.projectDelay}
          >{Math.abs(item.project_delay)}</span> day(s)
        </div>
      );
    }

    // if (item.variance === 'creation_of_dependency' || item.variance === 'change_in_dependency') {
    //   message = `${item.name} ${change} by ${Math.abs(item.delay)} day(s) due to dependency`;
    // } else {
    //   message = `${item.name} ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} by ${Math.abs(item.delay)} day(s)`
    // }

    message = `${item.name} ${item.affected_day === 'start_day' ? 'start date' : 'end date'} ${change} by ${Math.abs(item.delay)} day(s)`

    if (item.variance === 'creation_of_dependency') {
      message = `${item.name} ${change} by ${Math.abs(item.delay)} day(s) due to creation of dependency`;
    }
    if (item.variance === 'change_in_dependency') {
      message = `${item.name} ${change} by ${Math.abs(item.delay)} day(s) due to change in dependency`;
    }
    if (item.variance === 'delete_task_dependency') {
      message = `${item.name} ${change} by ${Math.abs(item.delay)} day(s) due to deletion of dependency`;
    }
    if (item.variance === 'delete_constraint') {
      message = `${item.name} ${change} by ${Math.abs(item.delay)} day(s) due to deletion of constraint`;
    }

    return (
      <div style={styles.delayMessage}>
        {message}
      </div>
    );
  }

  getGroupedList(list: Object[]): Object {
    const task: Object[] = list;
    const byProjectDelays: (Object[]) => Object = groupBy(
      (taskDelay: Object): string =>
        taskDelay.project_delay === null
          ? 'otherDelayReasons'
          : 'projectDelayReasons',
    );
    return byProjectDelays(task);
  }

  renderGivenDelays(delays: Object[]): React.Node {
    return delays
      ? delays.map(
          (item: Object, index: number): React.Node => (
            <li style={styles.variant} key={index}>
              {this.state.selectedTab !== 'activity'
                ? this.getHeading(item)
                : this.getHeading(item)}
                {this.state.selectedTab === 'task' &&
                  <div style={styles.variantName}>
                  Task name: {' '}
                  {item.name}
                </div>
                }
              {delayTexts[item.variance] || item.variance ? (
                <div style={styles.variantName}>
                  Reason: {' '}
                  {delayTexts[item.variance] || item.variance}
                </div>
              ) : null}
              {item.description ? <div style={styles.description}>
                Description:{' '}
                {item.description === 'others: pending' ? 'Pending from the user' : item.description}
              </div> : null}
              {this.getResponsibleParty(item)}
              {item.project_delay ?
                 <div style={styles.variantName}>
                  Old project end date: {' '}
                  {moment(item.old_project_end_date).format('DD MMM YYYY')}
                </div> : ''
                }
                {item.project_delay ?
                <div style={styles.variantName}>
                  New project end date: {' '}
                  {moment(item.new_project_end_date).format('DD MMM YYYY')}
                </div> : ''
                }
              {item.delayed_by ? (
                <div style={styles.delayedBy}>
                  Recorded by: {' '}
                  {item.delayed_by}
                </div>
              ) : null}
              {item.delayed_on ? (
                <div style={styles.delayedBy}>
                  Recorded on: {' '}
                  {moment(item.delayed_on).format('DD MMM YYYY, hh:mm a')}
                </div>
              ) : null}
            </li>
          ),
        )
      : null;
  }

  renderTabs(): React.Node {
    const tabs: Array<React.Node> = [];

    if (this.props.activity === null) return null;

    if (this.props.activity.activity_delays.length !== 0) {
      tabs.push(
        <li
          onClick={this.setTab.bind(this, 'activity')}
          style={
            this.state.selectedTab === 'activity'
              ? { ...styles.tab, ...styles.selectedTab }
              : styles.tab
          }
          key="activity"
        >
          Activity delays and advances
        </li>,
      );
    }

    if (this.props.activity.activity_constraint_delays.length !== 0) {
      tabs.push(
        <li
          onClick={this.setTab.bind(this, 'activityConstraint')}
          style={
            this.state.selectedTab === 'activityConstraint'
              ? { ...styles.tab, ...styles.selectedTab }
              : styles.tab
          }
          key="activity-constraints"
        >
          Activity Constraint Delays
        </li>,
      );
    }

    if (this.props.activity.task_constraint_delays.length !== 0) {
      tabs.push(
        <li
          onClick={this.setTab.bind(this, 'taskConstraint')}
          style={
            this.state.selectedTab === 'taskConstraint'
              ? { ...styles.tab, ...styles.selectedTab }
              : styles.tab
          }
          key="task-constraints"
        >
          Task Constraint Delays
        </li>,
      );
    }

    if (this.props.activity.task_delays.length !== 0) {
      tabs.push(
        <li
          onClick={this.setTab.bind(this, 'task')}
          style={
            this.state.selectedTab === 'task'
              ? { ...styles.tab, ...styles.selectedTab }
              : styles.tab
          }
          key="task"
        >
          Task Delays
        </li>,
      );
    }

    return <ul style={styles.tabs}>{tabs}</ul>;
  }

  renderDelays(): React.Node {
    const types: Object = {
      activityConstraint: 'activity_constraint_delays',
      taskConstraint: 'task_constraint_delays',
      task: 'task_delays',
      activity: 'activity_delays',
    };

    // const itemName: Object = {
    //   activityConstraint: 'Activity constraint',
    //   taskConstraint: 'Task constraint',
    //   task: 'Task',
    //   activity: 'Activity',
    // };

    if (this.props.activity === null) return null;
    if (this.state.selectedTab === '') return null;

    const groupedDelays: Object = this.getGroupedList(
      this.props.activity[types[this.state.selectedTab]],
    );
    // const itemType: Object = itemName[this.state.selectedTab];

    return (
      <ul style={styles.variants}>
        {this.renderGivenDelays(groupedDelays.projectDelayReasons)}
        {this.renderGivenDelays(groupedDelays.otherDelayReasons)}
      </ul>
    );
  }

  renderDates(): React.Node | null {
    const activity: ActivityDetailResponseType | null = this.props.activity;
    let startDate: React.Node;
    let endDate: React.Node;

    if (activity === null) return null;

    if (activity.actual_start_date) {
      startDate = (
        <div style={styles.date}>
          Actual start date: {' '}
          {moment(activity.actual_start_date).format('DD MMM YYYY')}
        </div>
      );
    } else {
      startDate = (
        <div style={styles.date}>
          Expected start date: {' '}
          {moment(activity.start_date).format('DD MMM YYYY')}
        </div>
      );
    }

    if (activity.actual_end_date) {
      endDate = (
        <div style={styles.date}>
          Actual end date: {' '}
          {moment(activity.actual_end_date).format('DD MMM YYYY')}
        </div>
      );
    } else {
      endDate = (
        <div style={styles.date}>
          Expected end date: {' '}
          {moment(activity.end_date).format('DD MMM YYYY')}
        </div>
      );
    }

    return (
      <div style={styles.dates}>
        <div style={styles.dateRow}>
          <div style={styles.date}>
            Base start date: {' '}
            {moment(activity.base_start_date).format('DD MMM YYYY')}
          </div>
          <div style={styles.date}>
            Base end date: {' '}
            {moment(activity.base_end_date).format('DD MMM YYYY')}
          </div>
        </div>
        <div style={styles.dateRow}>
          {startDate}
          {endDate}
        </div>
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.container}>
        <h2 style={styles.heading}>{this.props.activityName}</h2>
        <div style={styles.parent}>
          {getParentHierarchy(this.props.activity)}
        </div>
        <div>{this.renderDates()}</div>
        <div style={styles.tabsContainer}>{this.renderTabs()}</div>
        <div style={{ flex: 1, overflowY: 'auto' }}>{this.renderDelays()}</div>
      </section>
    );
  }
}
