// @flow
import ActionTypes from './action-types';
import type { DeletedWorkflowsAction, DeletedWorkflows } from './sagas';


export type DeletedWorkflowsState = {
  deletedData: DeletedWorkflows[],
  loading: boolean | null,
};

const initialState: DeletedWorkflowsState = { loading: null, chartData: [] };

export default function (state: DeletedWorkflowsState = initialState, action: DeletedWorkflowsAction): DeletedWorkflowsState {
  switch (action.type) {
    case ActionTypes.GET_DELETD_WORKFLOW_SUCCESS:
      return {
        ...state,
        deletedData: action.data.workflows,
        loading: false,
      };
    default:
      return state;
  }
}
