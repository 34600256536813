// @flow

import ActionTypes from './action-types';

import { CostProgressResponse, CostProgressAction } from './sagas';

export type CostProgressState = {
  chartData: CostProgressResponse,
  loading: boolean | null,
};

const initialState: CostProgressState = {
  loading: null,
  chartData: {},
  projectStart: null,
};

export default function (
  state: CostProgressState = initialState,
  action: CostProgressAction,
): CostProgressState {
  switch (action.type) {
    case ActionTypes.GET_COST_TIME_SUCCESS:
      return {
        ...state,
        chartData: action.data,
        loading: false,
      };
    case ActionTypes.GET_COST_TIME_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}
