// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getMilestoneDetail from './apis';

export type DelayType = {
  delay: number,
  id: number,
  variance: string,
};

export type MilestoneDetailResponseType = {
  delays: DelayType[],
  base_end_date: string,
  end_date: string,
  parent_chain: string,
};

export type MilestoneDetailAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_MILESTONE_DETAIL_SUCCESS'>,
  data: MilestoneDetailResponseType,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_MILESTONE_DETAIL_FAIL'>,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'CLEAR_MILESTONE_DETAIL'>,
|};

function* getMilestoneDetailData(action: { data: { projectId: string, milestoneId: number } }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: MilestoneDetailResponseType } = yield call(getMilestoneDetail, action.data.projectId, action.data.milestoneId, action.data.delayId);
    yield put({ type: ActionTypes.GET_MILESTONE_DETAIL_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_MILESTONE_DETAIL_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_MILESTONE_DETAIL, getMilestoneDetailData);
}
