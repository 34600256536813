// @flow

import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
  node: React.ReactNode,
  children: React.ComponentType,
}

class Portal extends React.Component<Props> {
  constructor(props: any) {
    super(props);

    this.el = document.createElement('div');
    this.portalNode = this.props.node || document.body;
  }

  componentDidMount() {
    if (this.portalNode !== null) {
      this.portalNode.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.portalNode !== null) {
      this.portalNode.removeChild(this.el);
    }
  }

  render(): React.ReactNode {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Portal;
