// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import ResponsivePieChart from '../../components/charts/pie';
import { DaysWithoutIncidentData } from './data';
import '../style.css';

type Props = {}

const styles: Object = {
  widget: {
    // width: '100%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
    height: '180px',
    width: '280px',
    position: 'relative',
  },
  chartContainer: {
    position: 'absolute',
    height: '50%',
    width: '50%',
    top: '-85px',
    left: '65px',
  },

  hoverText: {
    position: 'absolute',
    top: 173,
    left: 19,
    bottom: 0,
    color: '#fff',
    fontSize: '20px',
  },
};


class DaysWithoutIncidentWidget extends React.PureComponent<Props> {
  getChartData(): ViewModel[] {
    return DaysWithoutIncidentData.map(
      (item: Object): Object => ({
        // label: item.label,
        value: item.value,
        color: item.color,
        // text: item.text,
      }),
    );
  }

  renderPieChart = (): React.Element<any> => (
    <div style={styles.chartContainer}>
      <ResponsivePieChart
        data={this.getChartData()}
        dataOptions={{
          hoverinfo: 'none',
          hole: '0.5',
          showlegend: false,
          textinfo: 'none',
        }}
      />
      <div style={styles.hoverText}>63</div>
    </div>
  );

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1>
          Days Without Incident
        </h1>
        {this.renderPieChart()}
      </section>
    );
  }
}

const component: any = connect()(DaysWithoutIncidentWidget);

// component.style = {
//   width: '25%',
// };

export default component;
