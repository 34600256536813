// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import { isEmpty, pathOr } from 'ramda';
import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';
import LineChart from '../../components/charts/line';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import VersionTextMessage from '../../utils/versionText';

// import ToggleButton from './../../components/toggle-button';
import type { ProjectWeeklyEarlyLateProgressState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';
import type { ProjectWeeklyEarlyLateProgressResponse as ProjectWeeklyEarlyLateProgressType } from './sagas';

import withProjectContext from '../../auth/with-project-context';
import colors from '../../styles/colors';

type Action = { type: $Values<typeof ActionTypes>, data: Object }
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ProjectWeeklyEarlyLateProgressState,
  project: Project,
  shortWeeks: any,
  versionChangeWeeks: any,
  versionDates: string,
}

type ViewModel = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  filterContainer: {
    marginTop: '1rem',
  },
  meta: {
    fontSize: '0.85rem',
    position: 'relative',
    top: '-1.9rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};


class ProjectWeeklyEarlyLateProgress extends React.Component<Props, State> {
  componentDidMount() {
    this.fetchData();
  }


  getData(): ViewModel[] {
    const labels: string[] = [];
    const earlyStarts: number[] = [];
    const lateStarts: number[] = [];
    const actualProgress: number[] = [];
    this.props.data.chartData.forEach((item: ProjectWeeklyEarlyLateProgressType) => {
      const earlyStart: number = parseFloat(item.planned_progress_early_start
        && item.planned_progress_early_start.toFixed(1));
      const lateStart: number = parseFloat(item.planned_progress_late_start
        && item.planned_progress_late_start.toFixed(1));
      const actual: number = parseFloat(item.actual_progress
          && item.actual_progress.toFixed(1));
      const label: string = moment(item.week_end_date).format('DD MMM YYYY');
      labels.push(label);
      earlyStarts.push(earlyStart);
      lateStarts.push(lateStart);
      if (this.props.lookahead
        && moment(item.week_end_date).isBefore(this.props.lookahead.startDate)) {
        actualProgress.push(actual);
      }
    });

    return [
      {
        labels,
        values: earlyStarts,
        name: 'Early Start Progress',
        color: colors.themePrimaryColor,
      },
      {
        labels,
        values: lateStarts,
        name: 'Late Start Progress',
        color: colors.themeSecondaryColor,
      },
      {
        labels,
        values: actualProgress,
        name: 'Actual Progress',
        color: colors.aquaForest,
      },
    ];
  }

  getOptions(): any {
    const annotations: any = [];
    this.props.data.chartData.forEach((data: ProjectWeeklyEarlyLateProgressType, index: number) => {
      if (this.props.shortWeeks[data.week_end_date]) {
        annotations.push({
          x: index,
          y: 0,
          text: 'Lookahead day change',
          showarrow: true,
          font: {
            size: 10,
            color: 'red',
          },
          borderColor: 'red',
          arrowhead: 0.5,
          ax: 0,
          ay: 20,
          arrowcolor: 'red',
          arrowsize: 0.3,
        });
      }
      if (this.props.versionChangeWeeks[data.week_end_date]) {
        annotations.push({
          x: index,
          y: this.props.shortWeeks[data.week_end_date] ? -15 : 0,
          text: 'Version change',
          showarrow: true,
          font: {
            size: 10,
            color: 'blue',
          },
          borderColor: 'blue',
          arrowhead: 0.5,
          ax: 0,
          ay: 20,
          arrowcolor: 'blue',
          arrowsize: 0.3,
        });
      }
    });
    return {
      annotations,
    };
  }

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS,
      data: { projectId: this.props.project.id },
    });
  }

  /* eslint max-len: ["error", { "ignoreStrings": true, "code": 100}] */

  renderWidget(): React$Element<*> {
    if ((this.props.data.chartData === null && this.props.data.loading === false)
      || (this.props.data.chartData.length === 0
        && this.props.data.loading === false)) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <div>
        <LineChart data={this.getData()} options={this.getOptions()} />
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Weekly Project Progress (Early & Late)
          <img data-tip="Displays the comparison of the Actual, Early start & late start progress on a weekly basis.<br />This is useful for understanding the gap from the progress to be achieved, if any" width="14" src={infoIcon} alt="i" />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget()
        }
         {
          this.props.offsetPercentage
          && <div style={{ fontSize: '0.8rem', marginTop: 8 }}>
             * Actual and Planned progress values are recalculated based on already
              completed percentage of the project provided in the settings
             </div>
          }
          {
            this.props.data.loading === false && !isEmpty(this.props.versionDates)
            && <VersionTextMessage versionDates={this.props.versionDates} />
          }
      </section>
    );
  }
}

const component: any = compose(
  connect((state: Reducers): { data: ProjectWeeklyEarlyLateProgressState, shortWeeks: any } => (
    {
      data: state.projectWeeklyEarlyLateProgress,
      offsetPercentage: pathOr(null, ['project', 'projectDetails', 'preferences', 'general', 'offset_percentage'], state),
      shortWeeks: pathOr({}, ['project', 'shortWeeks'], state),
      versionChangeWeeks: pathOr({}, ['project', 'versionChangeWeeks'], state),
      versionDates: pathOr({}, ['project', 'versionDates'], state),
      lookahead: state.project.lookahead,
    }
  )),
  withProjectContext,
)(ProjectWeeklyEarlyLateProgress);

component.style = {
  width: '98%',
};

export default component;
