// @flow
import * as React from 'react';
import {
  Route, Link, Redirect, Switch,
} from 'react-router-dom';
// import { Route, Redirect, Switch } from 'react-router-dom';
import type { MapStateToProps, Connector } from 'react-redux';

import { connect } from 'react-redux';

import Header from './header';
import Dashboard from '../dashboard';
import Weekly from '../weekly';
import Loader from '../components/loader';
import './style.css';

import ProjectActions from '../project/action-types';
import type { Reducers } from '../reducers';

const styles: Object = {
  activeTab: {
    backgroundColor: '#2e3e46',
  },
  activeFont: {
    color: '#ffffff',
  },
  content: {
    paddingTop: '3rem',
  },
};

type Props = {
  dispatch: Function,
  match: Object,
  location: Object,
  project: Object,
  projectAllWeeks: Object,
};

class Home extends React.PureComponent<Props> {
  componentWillMount() {
    this.props.dispatch({
      type: ProjectActions.GET_PROJECT_DETAILS,
      data: { projectId: this.props.match.params.projectId },
    });
    this.props.dispatch({
      type: ProjectActions.GET_ALL_WEEKS,
      data: { projectId: this.props.match.params.projectId },
    });
    this.props.dispatch({
      type: ProjectActions.GET_VERSION_DETAILS,
      data: { projectId: this.props.match.params.projectId },
    });
  }

  getContent(): React.Node {
    if (!this.props.project) {
      return <div style={styles.content}><Loader /></div>;
    }
    if (!(this.props.project.company_role === 'Main Contractor' || this.props.project.company_role === 'Client'
    || (this.props.project.company_role === 'Consultant' && this.props.project.logged_user.permissions === 1.5))) {
      return null;
    }
    const projectId: string = this.props.match.params.projectId;
    const activeTab: string = this.props.location.pathname.includes('/weekly') ? 'weekly' : 'overview';
    return (
      <div style={styles.content}>
        <div className="tab-container">
          <div className="tabs">
            <Link to={`/projects/${projectId}/overview`} className="link">
              <span className="button overview" style={activeTab === 'overview' ? styles.activeTab : null}>
                <span className="tab-text" style={activeTab === 'overview' ? styles.activeFont : null}>
                  Overview
                </span>
              </span>
            </Link>
            <Link to={`/projects/${projectId}/weekly/${this.props.projectAllWeeks.length === 1 ? 'current' : 'last'}`} className="link">
              <span className="button weekly" style={activeTab === 'weekly' ? styles.activeTab : null}>
                <span className="tab-text" style={activeTab === 'weekly' ? styles.activeFont : null}>
                  Weekly
                </span>
              </span>
            </Link>
          </div>
          <span className="project-name">{this.props.project && this.props.project.name}</span>
        </div>

        <Switch>
          <Redirect exact from="/projects/:projectId" to={`/projects/${projectId}/overview`} />
          <Route path="/projects/:projectId/overview" component={(Dashboard: any)} />
          <Route path="/projects/:projectId/weekly/:weekStart/" component={(Weekly: any)} />
        </Switch>
      </div>
    );
  }

  render(): React.Node {
    return (
      <div>
        <Header />
        {this.getContent()}
      </div>
    );
  }
}

// export default connect(
//   (store: Reducers): Object => ({
//     project: store.project.projectDetails,
//     projectAllWeeks: store.project.projectAllWeeks,
//   }),
// )(Home);

type TMapStateToFilter = MapStateToProps<Reducers, any, any>;

const mapStateToProps: TMapStateToFilter = (store: Reducers): Object => ({
  project: store.project.projectDetails,
  projectAllWeeks: store.project.projectAllWeeks,
});

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(Home);
