// @flow

export type ProjectActionTypes = {
  GET_COMPANIES: 'getCompanies',
  GET_COMPANIES_SUCCESS: 'getCompaniesSuccess',
  GET_COMPANIES_FAIL: 'getCompaniesFail',
  GET_PROJECT_DETAILS: 'getProjectDetails',
  GET_PROJECT_DETAILS_SUCCESS: 'getProjectDetailsSuccess',
  GET_PROJECT_DETAILS_FAIL: 'getProjectDetailsFail',
  GET_ALL_WEEKS: 'getAllWeeks',
  GET_ALL_WEEKS_SUCCESS: 'getAllWeeksSuccess',
  GET_ALL_WEEKS_FAIL: 'getAllWeeksFail',
  GET_VERSION_DETAILS: 'getVersionDetails',
  GET_VERSION_DETAILS_SUCCESS: 'getVersionDetailsSuccess',
  GET_VERSION_DETAILS_FAIL: 'getVersionDetailsFail',
  GET_PROJECT_FEATURES: 'getProjectFeatures',
  GET_PROJECT_FEATURES_SUCCESS: 'getProjectFeaturesSuccess',
  GET_LOOKAHEAD_META: 'getLookaheadMeta',
  GET_LOOKAHEAD_META_SUCCESS: 'getLookaheadMetaSuccess',
}

const actionTypes: ProjectActionTypes = {
  GET_COMPANIES: 'getCompanies',
  GET_COMPANIES_SUCCESS: 'getCompaniesSuccess',
  GET_COMPANIES_FAIL: 'getCompaniesFail',
  GET_PROJECT_DETAILS: 'getProjectDetails',
  GET_PROJECT_DETAILS_SUCCESS: 'getProjectDetailsSuccess',
  GET_PROJECT_DETAILS_FAIL: 'getProjectDetailsFail',
  GET_ALL_WEEKS: 'getAllWeeks',
  GET_ALL_WEEKS_SUCCESS: 'getAllWeeksSuccess',
  GET_ALL_WEEKS_FAIL: 'getAllWeeksFail',
  GET_VERSION_DETAILS: 'getVersionDetails',
  GET_VERSION_DETAILS_SUCCESS: 'getVersionDetailsSuccess',
  GET_VERSION_DETAILS_FAIL: 'getVersionDetailsFail',
  GET_PROJECT_FEATURES: 'getProjectFeatures',
  GET_PROJECT_FEATURES_SUCCESS: 'getProjectFeaturesSuccess',
  GET_LOOKAHEAD_META: 'getLookaheadMeta',
  GET_LOOKAHEAD_META_SUCCESS: 'getLookaheadMetaSuccess',
};

export default actionTypes;
