// @flow
import React from 'react';
import Portal from './portal';

const modalStyles: Object = {
  content: {
    right: 'auto',
    width: '53rem',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '30rem',
    position: 'absolute',
    inset: '40px auto 40px 50%',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(255, 255, 255)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    botton: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    width: '100%',
    height: '100%',
  },
};

type Props = {
  onClose: () => void,
  children: React.ComponentType,
}

const CustomModal: any = (props: Props): React.ReactNode => (
    <Portal>
      <div style={modalStyles.overlay} onClick={props.onClose}>
        <div style={modalStyles.content} onClick={(e: any): any => e.stopPropagation()}>
          { props.children }
        </div>
      </div>
    </Portal>
);

export default CustomModal;
