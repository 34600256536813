// @flow

import ActionTypes from './action-types';
import type { ProjectWeeklyEarlyLateProgressAction, ProjectWeeklyEarlyLateProgressResponse } from './sagas';

export type ProjectWeeklyEarlyLateProgressState = {
  chartData: ProjectWeeklyEarlyLateProgressResponse[],
  loading: boolean | null,
};

const initialState: ProjectWeeklyEarlyLateProgressState = { loading: null, chartData: [] };

export default function (state: ProjectWeeklyEarlyLateProgressState = initialState, action: ProjectWeeklyEarlyLateProgressAction): ProjectWeeklyEarlyLateProgressState {
  switch (action.type) {
    case ActionTypes.GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_SUCCESS:
      return {
        ...state,
        chartData: action.data,
        loading: false,
      };
    case ActionTypes.GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_FAIL:
      return {
        ...state,
        chartData: [],
        loading: false,
      };
    default:
      return state;
  }
}
