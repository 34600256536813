// @flow

import manPower from '../icons/manPower.png';
import manPowerActive from '../icons/manPower-S.png';
import plan from '../icons/plan.png';
import planActive from '../icons/plan-S.png';
import productivityActive from '../icons/productivity-S.png';
import productivity from '../icons/productivity.png';
import progress from '../icons/progress.png';
import progressActive from '../icons/progress-S.png';
import safetyActive from '../icons/safety-S.png';
import safety from '../icons/safety.png';
import workActive from '../icons/workFront-S.png';
import work from '../icons/workFront.png';

export default [
  {
    title: {
      label: 'Progress',
      value: 'progress',
      icon: progress,
      iconActive: progressActive,
    },
    // items: [
    //   'Overall Project Progress Status',
    //   'Project Progress',
    //   'Discipline',
    //   'Sub Contractors',
    // ],
  },
  {
    title: {
      label: 'Productivity',
      value: 'productivity',
      icon: productivity,
      iconActive: productivityActive,
    },
    // items: [
    //   'Concrete Casting Productivity',
    //   'Steel Erection Productivity',
    //   'Cable Pullling Productivity',
    //   'Weekly Commitments and Reliability',
    //   'Weekly Workable Backlog',
    // ],
  },
  {
    title: {
      label: 'Plan',
      value: 'plan',
      icon: plan,
      iconActive: planActive,
    },
    // items: [
    //   'Delay Classification',
    //   'Top Delayed Activities',
    //   'Delay Responsibility',
    //   'Delay Preventions',
    //   'Plan Reliability Categorization (Start Date)',
    //   'Plan Reliability Categorization (Duration)',
    //   'Plan Reliability Categorization (End Date)',
    // ],
  },
  {
    title: {
      label: 'Safety',
      value: 'safety',
      icon: safety,
      iconActive: safetyActive,
    },
    // items: [
    //   'Days Without Incident',
    //   'Incidents',
    //   'Loss time injury',
    // ],
  },
  {
    title: {
      label: 'Man Power',
      value: 'manpower',
      icon: manPower,
      iconActive: manPowerActive,
    },
    // items: [
    //   'Manpower Utilization',
    //   'Manpower Utilization by Trade',
    //   'Civil Works',
    //   'Mechanical Works',
    //   'Electrical Works',
    //   'Other Works',
    // ],
  },
  {
    title: {
      label: 'Work',
      value: 'work',
      icon: work,
      iconActive: workActive,
    },
    // items: [
    //   'Concrete Pouring',
    //   'Steel Structure',
    //   'Piping Fabrication',
    //   'Piping Erection',
    //   'Paintings',
    //   'Others',
    // ],
  },
];
