/* eslint-disable flowtype/require-return-type */
// @flow
import React from 'react';

import { getTopDelayReasonsListApi } from '../../apis';
import { delayReasons } from '../../constants';


import '../../index.css';
import './index.css';


type Props = {
  projectId: number;
  disciplineId: string;
}

type State = {
  data: any;
  isLoading: boolean;
}

class TopDelayReasons extends React.Component<Props, State> {
  state = {
    data: null,
    isLoading: false,
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.disciplineId !== prevProps.disciplineId) {
      this.fetchData();
    }
  }

  fetchData = async (): any => {
    try {
      this.setState({
        isLoading: true,
      });

      const response: any = await (getTopDelayReasonsListApi(
        { projectId: this.props.projectId, companyId: this.props.companyId, disciplineId: this.props.disciplineId },
      ));
      if (response.status === 200) {
        this.setState({
          isLoading: false,
          data: response.data,
        });
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
    }
  }

  renderLoading(): React.ReactNode {
    return <div>Loading...</div>;
  }


  getFormattedReason(reason: string[]): string {
    return delayReasons.find((r: any) => r.value === reason) ? delayReasons.find((r: any) => r.value === reason).label : reason;
  }

  renderContent(): React.ReactNode {
    if (this.state.isLoading) {
      return this.renderLoading();
    }

    return (
      <div className="tbl-content">
        <div className="wigt-header">Top Delay Reasons</div>
        {
             !this.state.data || (this.state.data && this.state.data.length <= 0)
               ? <div className="tbl-nodata">No Data available</div>
               : (
              <div>
                <div className="dr-item">
                  <div className="tbl-header">Reasons</div>
                  <div className="tbl-header">Delay</div>
                  <div className="tbl-header">Occurances</div>
                </div>
                {this.state.data.map((item: any) => (
                    <div className="dr-item">
                      <div className="tbl-items">
                        <div className="tbl-activityName">
                          {this.getFormattedReason(item.reason)}
                        </div>
                      </div>
                      <div className="tbl-redLabel">
                        {item.totalDelay}
                        {' '}
                        {item.totalDelay && (item.totalDelay === 1 ? 'Day' : 'Days')}
                      </div>
                      <div className="tbl-items">{item.occurrences}</div>
                    </div>
                ))}
              </div>
               )
        }
      </div>
    );
  }

  render(): React.ReactNode {
    return <div className="wigt-container">{this.renderContent()}</div>;
  }
}

export default TopDelayReasons;
