// @flow

const ConcreteCastingData: any = [
  {
    monthly_planned: 5.725,
    monthly_actual: 0.475,
    cumilative_planned: 2.735 * 10000,
    cumilative_actual: 0.457 * 10000,
    month: '26-Jul-17',
  },
  {
    monthly_planned: 8.824,
    monthly_actual: 4.775,
    cumilative_planned: 3.735 * 10000,
    cumilative_actual: 2.457 * 10000,
    month: '26-Aug-17',
  },
  {
    monthly_planned: 20.192,
    monthly_actual: 8.875,
    cumilative_planned: 6.935 * 10000,
    cumilative_actual: 3.857 * 10000,
    month: '28-Sep-17',
  },
  {
    monthly_planned: 21.111,
    monthly_actual: 8.378,
    cumilative_planned: 9.635 * 10000,
    cumilative_actual: 4.457 * 10000,
    month: '26-Oct-17',
  },
  {
    monthly_planned: 29.565,
    monthly_actual: 10.692,
    cumilative_planned: 13.145 * 10000,
    cumilative_actual: 6.746 * 10000,
    month: '24-Nov-17',
  },
  {
    monthly_planned: 32.132,
    monthly_actual: 10.401,
    cumilative_planned: 17.987 * 10000,
    cumilative_actual: 8.876 * 10000,
    month: '29-Dec-17',
  },
  {
    monthly_planned: 27.457,
    cumilative_planned: 23.987 * 10000,
    month: '26-Jan-18',
  },
  {
    monthly_planned: 23.554,
    cumilative_planned: 27.764 * 10000,
    month: '23-Feb-18',
  },
  {
    monthly_planned: 19.054,
    cumilative_planned: 29.142 * 10000,
    month: '30-Mar-18',
  },
  {
    monthly_planned: 6.984,
    cumilative_planned: 32.602 * 10000,
    month: '27-Apr-18',
  },
  {
    monthly_planned: 6.365,
    cumilative_planned: 34.987 * 10000,
    month: '28-May-18',
  },
  {
    monthly_planned: 5.216,
    cumilative_planned: 35.609 * 10000,
    month: '26-Jun-18',
  },
  {
    monthly_planned: 2.766,
    cumilative_planned: 36.109 * 10000,
    month: '27-Jul-18',
  },
  {
    monthly_planned: 2.899,
    cumilative_planned: 36.509 * 10000,
    month: '29-Aug-18',
  },
  {
    monthly_planned: 3.109,
    cumilative_planned: 36.908 * 10000,
    month: '28-Sep-18',
  },
  {
    monthly_planned: 2.506,
    cumilative_planned: 37.108 * 10000,
    month: '24-Oct-18',
  },
  {
    monthly_planned: 2.106,
    cumilative_planned: 37.308 * 10000,
    month: '26-Nov-18',
  },
  {
    monthly_planned: 0.206,
    cumilative_planned: 37.908 * 10000,
    month: '30-Dec-18',
  },
];

export default ConcreteCastingData;
