// @flow

type ProjectWeeklyWeightProgress = {
  GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS: 'getProjectWeeklyEarlyLateProgress',
  GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_SUCCESS: 'getProjectWeeklyEarlyLateProgressSuccess',
  GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_FAIL: 'getProjectWeeklyEarlyLateProgressFail',
}

const actionTypes: ProjectWeeklyWeightProgress = {
  GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS: 'getProjectWeeklyEarlyLateProgress',
  GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_SUCCESS: 'getProjectWeeklyEarlyLateProgressSuccess',
  GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_FAIL: 'getProjectWeeklyEarlyLateProgressFail',
};

export default actionTypes;
