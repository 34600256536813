// @flow

import * as React from 'react';
import WeekItem from './week-item';

const styles: Object = {
  container: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    backgroundColor: '#ffffff',
    marginTop: '52px',
    position: 'absolute',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
  },
};

type Props = {
  projectId: string,
  toggleMenu: Function,
  allWeeks: [],
  weekStart: string,
  companyId: string,
};

function getWeekItemList(props: Props): React.Node {
  const weekList: any = props.allWeeks.slice(0, -2).reverse();
  return weekList.map(
    (week: Object, index: number): React.Element<any> => (
      <WeekItem
        key={index}
        dates={week}
        projectId={props.projectId}
        toggleMenu={props.toggleMenu}
        weekNumber={weekList.length - index}
        weekStart={props.weekStart}
        companyId={props.companyId}
      />
    ),
  );
}

const WeekList: Function = function WeekList(props: Props): React.Node {
  // fixed react/no-unused-prop-types
  // https://github.com/yannickcr/eslint-plugin-react/blob/HEAD/docs/rules/no-unused-prop-types.md#false-positives-sfc
  const {
    projectId, toggleMenu, allWeeks, weekStart, companyId,
  }: Props = props;
  return (
    <div style={styles.container}>
      {getWeekItemList({
        projectId, toggleMenu, allWeeks, weekStart, companyId,
      })}
    </div>
  );
};

export default WeekList;
