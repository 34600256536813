// @flow

import ActionTypes from './action-types';
import type { ProjectWeeklyWeightProgressAction, ProjectWeeklyWeightProgressResponse } from './sagas';

export type ProjectWeeklyWeightProgressState = {
  chartData: ProjectWeeklyWeightProgressResponse[],
  loading: boolean | null,
};

const initialState: ProjectWeeklyWeightProgressState = { loading: null, chartData: [] };

export default function (state: ProjectWeeklyWeightProgressState = initialState, action: ProjectWeeklyWeightProgressAction): ProjectWeeklyWeightProgressState {
  switch (action.type) {
    case ActionTypes.GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_SUCCESS:
      return {
        ...state,
        chartData: action.data,
        loading: false,
      };
    case ActionTypes.GET_PROJECT_WEEKLY_WEIGHT_PROGRESS_FAIL:
      return {
        ...state,
        chartData: [],
        loading: false,
      };
    default:
      return state;
  }
}
