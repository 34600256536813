// @flow
import ActionTypes from './action-types';
import type { TopCriticalPathDelayedActivitiesAction, CriticalPathDelayedActivity } from './sagas';

export type TopCriticalPathDelayedActivitiesState = {
  chartData: CriticalPathDelayedActivity[],
  loading: boolean | null,
};

const initialState: TopCriticalPathDelayedActivitiesState = { loading: null, chartData: [] };

export default function (state: TopCriticalPathDelayedActivitiesState = initialState, action: TopCriticalPathDelayedActivitiesAction): TopCriticalPathDelayedActivitiesState {
  switch (action.type) {
    case ActionTypes.GET_TOP_CRITICAL_PATH_DELAYED_ACTIVITIES_SUCCESS:
      return {
        ...state,
        chartData: [...action.data.critical_activities, ...action.data.critical_constraints],
        loading: false,
      };
    default:
      return state;
  }
}
