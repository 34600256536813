// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import type { Reducers } from '../reducers';
import type { Project } from '../auth/with-project-context';
import type { ConstraintDetailState } from './reducer';
import CustomModal from '../components/custom-modal';

import ActionTypes from './action-types';
import ConstraintDetail from './constraint-detail';

import withProjectContext from '../auth/with-project-context';

type Props = {
  constraintId: number,
  constraintName: string,
  project: Project,
  dispatch: Object => mixed,
  data: ConstraintDetailState,
};

class ConstraintDetailContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_CONSTRAINT_DETAIL,
      data: {
        constraintId: this.props.constraintId,
        projectId: this.props.project.id,
      },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: ActionTypes.CLEAR_CONSTRAINT_DETAIL });
  }

  closeModal = () => {
    this.props.dispatch({
      type: 'hideModal',
    });
  }

  render(): React.Node {
    return (
      <CustomModal onClose={this.closeModal}>
        <ConstraintDetail constraint={this.props.data.constraint} constraintName={this.props.constraintName} />
      </CustomModal>
    );
  }
}

const Component: any = compose(
  connect((state: Reducers): { data: Object } => ({ data: state.constraintDetail })),
  withProjectContext,
)(ConstraintDetailContainer);

export default Component;
