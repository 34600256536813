// @flow

/* eslint-disable */
import * as React from 'react';
import moment from 'moment';
import { groupBy, map } from 'ramda';

import Colors from '../styles/colors';
import ActionTypes from './action-types';
import type { Project } from '../auth/with-project-context';

import type { DelayResponseType, DelayType, DelayHistoryType} from './sagas';
import './delay-detail.css';


type Props = {
  comments: any,
  delayHistory: DelayHistoryType[],
  companies: any,
  workdata: any,
  onDelaySave: (...args: any[]) => void,
  onDelayStatusUpdate: (...args: any[]) => void,
  onSave: (...args: any[]) => void,
  status: number,
};

type State = {
  selectedTab: string,
  newComment: string,
  newVariance: any,
  otherReason: string,
  responsibleParty: any,
  responsiblePartyOtherValue: any,
  delayDescription: string,
  showError: boolean,
  message: string,
};

const styles: Object = {
  heading: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'visible',
  },
  parent: {
    fontSize: '0.8rem',
    marginTop: '0.6rem',
  },
  parentChain: {
    fontSize: '0.8rem',
    marginTop: '0.6rem',
    lineHeight: 1.2,
  },
  tabsContainer: {
    marginTop: '1rem',
    marginBottom: '10px',
  },
  tabs: {
    fontSize: '0.9rem',
    marginBottom: '1rem',
  },
  tab: {
    float: 'left',
    padding: '0.5rem 0.8rem',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    cursor: 'pointer',
    width: '120px',
    textAlign: 'center',
  },
  selectedTab: {
    borderBottomColor: Colors.themePrimaryColor,
  },
  variants: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    overflowY: 'auto',
    maxHeight: '27vh',
  },
  variantsOther: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    overflowY: 'auto',
    maxHeight: '31vh',
  },
  commentVariants: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    overflowY: 'auto',
    maxHeight: '50vh',
  },
  variant: {
    lineHeight: 1.4,
    marginBottom: '1.5rem',
    verticalAlign: 'top',
    width: '47rem',
    wordBreak: 'break-all',
  },
  commentVariant: {
    lineHeight: 1.4,
    marginBottom: '0.5rem',
    verticalAlign: 'top',
    width: '30rem',
    wordBreak: 'break-all',
    border: '1px solid #dddddd',
    borderRadius: '2px',
    padding: '4px',
  },
  variantName: {
    color: '#6f6f6f',
  },
  description: {
    color: '#6f6f6f',
    fontSize: '0.75rem',
  },
  delayDescription: {
    color: '#6f6f6f',
    fontSize: '0.75rem',
    width: '42rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentDescription: {
    color: '#6f6f6f',
    fontSize: '0.75rem',
    width: '30rem',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '3px',
  },
  delayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    paddingRight: '10px',
  },
  textArea: {
    width: '30rem',
    resize: 'none',
    padding: '2px',
    // borderRadius: '2px',
  },
  textAreaContainer: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // margin: '0 20px',
    fontSize: '5px',
    height: '100%',
  },
};

const delayTexts: Object = {
  "": "-- select --",
  'access_space': 'Access / Space',
  'approval_permits': 'Approval / Permits',
  'client_decision': 'Client decision',
  'contracts': 'Contracts',
  'covid_19': 'Covid 19',
  'drawings': 'Drawings',
  'equipment': 'Equipment',
  'inspection': 'Inspection',
  'labor': 'Labor',
  'logistics_storage': 'Logistics / Storage',
  'material': 'Material',
  'method_statement': 'Method statement',
  'minor_temp_prerequisite_work': 'Minor / Temp pre-requisite work',
  'rfi': 'RFI',
  'safety_requirement': 'Safety requirement',
  're_scheduling': 'Re-Scheduling ',
  'unforeseen_site_conditions': 'Unforeseen site conditions',
  'weather': 'Weather',
  // 'schedule_error': 'Schedule error',
  'defects_rework': 'Defects rework',
  // 'first_lookahead': 'Delay on First Lookahead',
  'others': 'Others',
  'others: pending': 'Pending'
  // 'do_list_confirmation': 'Do List Confirmation',
  // 'change_in_dependency': 'Change In Dependency',
  // 'creation_of_dependency': 'Creation of Dependency',
};

const delayType: Object= {
  task_constraint: 'Task constraint',
  activity_constraint: 'Activity constraint',
  task: 'Task',
  activity: 'Activity',
}

const responsiblePartyTexts = {
  authorities: "Authorities",
  unexpected: "Unexpected Conditions",
  "others: pending" : "Pending"
};


export default class DelayDetail extends React.Component<Props, State> {
  state = {
    selectedTab: 'delayDetails',
    newComment: '',
    newVariance: this.getReasons(this.props.workdata.variance),
    otherReason: this.getOtherReasons(this.props.workdata.variance),
    responsibleParty: this.getResponsibleParty(this.props.workdata.responsibleCompanyId),
    responsiblePartyOtherValue: this.getOtherDetails(this.props.workdata.responsibleParty),
    delayDescription: this.props.workdata.delayDescription ? this.props.workdata.delayDescription : '',
    showError: false,
    message: '',
  };



  setTab(tab: string) {
    this.setState({ selectedTab: tab });
  }

  getParentHierarchy(parentChain: any | null): string {
    if (!parentChain) return '';

    const parents: Array<string> = parentChain.split(' | ').reverse();
    // this.props.workdata.delayedArtifact === 'task' || this.props.workdata.delayedArtifact === 'task_constraint' ? null : parents.pop();
    return parents.join(' ➔ ');
  }

  getResponsibleParty(responsibleParty: number): React.Node {
    if(responsibleParty) {
      return responsibleParty;
    }
    if (!responsibleParty) {
      const newResponsibleParty: string = this.props.workdata.responsibleParty;
      if(newResponsibleParty === 'others: pending') {
        return newResponsibleParty
      }
      if (newResponsibleParty) {
        var array = [];
        array = newResponsibleParty.split(':');
        return array[0] === "unexpectedConditions" ? 'unexpected' : array[0].toLowerCase();
      }
      return '';
    }
    return '';
  }

  getOtherDetails(responsibleParty: string): any {
    if (responsibleParty) {
      var array = [];
      array = responsibleParty.split(':');
      if(array.length > 1 && array[1] === " pending") {
        return ''
      }
      if (array.length > 1) {
        
        return array[1];
      }
    }
    return '';
  }

  getReasons(reason: string): string {
    if (reason === null) {
      return '';
    }
    if (delayTexts[reason]) {
      return reason;
    }
    if (reason.match(/others:/)) {
      return 'others';
    }
    if (reason.match(/others: pending/)) {
      return 'pending';
    }
    return reason;
  }

  getOtherReasons(reason: string): string {
    if (!delayTexts[reason]) {
      const myString: any = reason;
      if (myString) {
        var array = [];
        array = myString.split(':');
        if (array.length > 1) {
          return array[1];
        }
        return '';
      }
      return '';
    }
    return '';
  }


  renderTabs(): React.Node {
    const tabs: Array<React.Node> = [];

    tabs.push(
      <li
        onClick={this.setTab.bind(this, 'delayDetails')}
        style={
          this.state.selectedTab === 'delayDetails'
            ? { ...styles.tab, ...styles.selectedTab }
            : styles.tab
        }
        key="delayDetails"
      >
        Delay details
      </li>,
    );

    tabs.push(
      <li
        onClick={this.setTab.bind(this, 'comments')}
        style={
          this.state.selectedTab === 'comments'
            ? { ...styles.tab, ...styles.selectedTab }
            : styles.tab
        }
        key="task"
      >
        Comments
      </li>,
    );

    return <ul style={styles.tabs}>{tabs}</ul>;
  }

  onCommentChange(e: any){
    this.setState({
      newComment: e.currentTarget.value,
    })
  }

  keyDownHandler(e: any) {
    var comment = this.state.newComment;
    if (comment !== '' && comment.length >= 0 && e.keyCode === 13) {
      this.props.onSave({
        comment: this.state.newComment,
       })
       this.setState({
        newComment: '',
      })
    }
  }

  keyUpHandler(e:any) {
    var comment = this.state.newComment;
    if (comment !== '' && comment.length >= 0 && e.keyCode === 13) {
       this.setState({
        newComment: '',
      })
    }
  }

  onSave() {
    var comment = this.state.newComment;
    if (comment !== '' && comment.length >= 0) {
      this.props.onSave({
          comment: this.state.newComment,
      })
      this.setState({
        newComment: '',
      })
    }
  }

  onDelaySave() {
    let variance= this.state.newVariance;
    let responsibleParty = this.state.responsibleParty;

    const companies = this.props.companies;
    const clients = companies.Client;
    if (variance === "others") {
      if (this.state.otherReason === null || !this.state.otherReason.trim()) {
          this.setState({
            showError: true,
            message: "Please provide the reason for delay"
          });
          return;
      }
      variance = "others:" + this.state.otherReason;
    }

    if (variance === 'others: pending') {
        this.setState({
          showError: true,
          message: "Please provide the reason for delay"
        });
        return;
    }

    if (responsibleParty === 'others: pending') {
      this.setState({
        showError: true,
        message: "Please provide the details for the responsible party"
      });
      return;
  }

    if (responsibleParty === "others" || responsibleParty === "supplier") {
      if (this.state.responsiblePartyOtherValue === null || !this.state.responsiblePartyOtherValue.trim()) {
        this.setState({
          showError: true,
          message: "Please provide the details for the responsible party"
        });
        return;
      }
      responsibleParty = responsibleParty + ":" + this.state.responsiblePartyOtherValue;
    }
    if (this.state.newVariance === null || !this.state.newVariance  ) {
      this.setState({
        showError: true,
        message: "Please provide the reason for delay"
      });
      return;
    }
    if (this.state.delayDescription === null || !this.state.delayDescription.trim()) {
      this.setState({
        showError: true,
        message: "Please provide the delay description"
      });
      return;
    }
    if (this.state.responsibleParty === null || !this.state.responsibleParty ) {
      this.setState({
        showError: true,
        message: "Please provide the responsible party"
      });
      return;
    }
    if (this.state.newVariance === this.getReasons(this.props.workdata.variance) && this.state.responsibleParty === this.getResponsibleParty(this.props.workdata.responsibleCompanyId)
      && this.state.delayDescription.trim() === this.props.workdata.delayDescription) {
      if ((this.state.otherReason.trim() === this.getOtherReasons(this.props.workdata.variance))&& ( this.state.responsiblePartyOtherValue.trim() === this.getOtherDetails(this.props.workdata.responsibleParty)) ) {
        this.setState({
          showError: true,
          message: "No changes to Save"
        });
        return;
      }
    }
    this.props.onDelaySave({
      cmd: "update_delay",
      variance: variance,
      description: this.state.delayDescription.trim(),
      responsible_party: this.state.responsibleParty === 'authorities' || this.state.responsibleParty === 'supplier'
      || this.state.responsibleParty === 'unexpected' || this.state.responsibleParty === 'others' || ((!clients || Object.keys(clients).length === 0) && this.state.responsibleParty === 'client') ? responsibleParty : parseInt(responsibleParty),
      newAccountableEntity: window.__r2d2 && (this.state.responsibleParty === 'authorities' || this.state.responsibleParty === 'supplier'
      || this.state.responsibleParty === 'unexpected' || this.state.responsibleParty === 'others' || ((!clients || Object.keys(clients).length === 0) && this.state.responsibleParty === 'client')) ? responsibleParty : null,
    })
  }

  onVarianceChange(e: any) {
    const newVariance: any = e.currentTarget.value;
    if( newVariance === 'others: pending')  {
      return
    }
    if (newVariance && newVariance !== "first_lookahead") {
      this.setState({
        newVariance: newVariance,
      })
    }
    if (!newVariance || newVariance === "first_lookahead") {
      this.setState({
        message:"Please provide a valid reason for delay",
        showError: true,
      });
    }
    if (newVariance !== "others" && newVariance) {
      this.setState({
        showError: false,
      });
    }

    if (newVariance) {
      this.setState({
        showError: false,
      });
    }
  }

  onOthersFieldChange(e: any)  {
    const otherReason = e.currentTarget.value;
    this.setState({
      otherReason: otherReason,
    })
    if (otherReason) {
      this.setState({
        showError: false,
      });
    }

  }

  onCompanyChange(e: any) {
    const responsibleParty = e.currentTarget.value;
    if (responsibleParty) {
      this.setState({
        responsibleParty: responsibleParty,
      })
    }

    if (responsibleParty === 'others: pending') {
      this.setState({
        message:"Please provide a valid responsible party",
        showError: true,
      });
      return
    }

    if (!responsibleParty) {
      this.setState({
        message:"Please provide a valid responsible party",
        showError: true,
      });
    }
    if (responsibleParty && (responsibleParty !== "others" || responsibleParty !== "supplier")) {
      this.setState({
        showError: false,
      });
    }
  }

  onResponsiblePartyOthersFieldChange(e: any) {
      const responsiblePartyOtherValue = e.currentTarget.value;
      this.setState({
        responsiblePartyOtherValue: responsiblePartyOtherValue,
      })
      if (responsiblePartyOtherValue) {
        this.setState({
          showError: false,
        });
      }
  }

  onDescriptionChange(e: any) {
      const delayDescription = e.currentTarget.value;
      this.setState({
        delayDescription: delayDescription,
      })
      if (delayDescription) {
        this.setState({
          showError: false,
        });
      }
  }

  getCompanyOptions() {
    let companyOptions: any = [];
    const companies = this.props.companies;
    const mainCon = companies["Main Contractor"];
    companyOptions.push(
      <optgroup key="Main Contractor" label="Main Contractor" style={{color: "red"}}>
        { Object.keys(mainCon).map((item) => {
          return <option key={item} value={item}>{mainCon[item]}</option>;
        })}
      </optgroup>)

    const subcons = companies["Sub Contractor"];
    if (subcons && Object.keys(subcons).length > 0) {
        companyOptions.push(<optgroup key="Sub Contractor" label="Sub Contractor">
            { Object.keys(subcons).map((item) => {
                return <option key={item} value={item}>{subcons[item]}</option>;
            })}
        </optgroup>)
    }

    const clients = companies.Client;
    if (clients && Object.keys(clients).length > 0) {
        companyOptions.push(<optgroup key="Client" label="Client">
            { Object.keys(clients).map((item) => {
                return <option key={item} value={item}>{clients[item]}</option>;
            })}
        </optgroup>)
    }

    const consultants = companies.Consultant;
    if (consultants && Object.keys(consultants).length > 0) {
        companyOptions.push(<optgroup key="Consultant" label="Consultant">
            { Object.keys(consultants).map((item) => {
                return <option key={item} value={item}>{consultants[item]}</option>;
            })}
        </optgroup>)
    }

    if (!clients || Object.keys(clients).length === 0 || this.props.workdata.responsibleParty === 'client') {
        companyOptions.push(<option key="client" value="client">Client</option>);
    }

    type Exact<T> = T & $Shape<T>; // https://github.com/facebook/flow/issues/2405

    companyOptions = companyOptions.concat([
        <option key="authorities" value="authorities">Authorities</option>,
        <option key="supplier" value="supplier">Supplier</option>,
        <option key="unexpectedConditions" value="unexpected">Unexpected Conditions</option>,
        <option key="others: pending" value="others: pending">Pending</option>,
        <option key="others" value="others">Others</option>
    ]);

    companyOptions.unshift(<option key="null" value="">- select -</option>);
    return companyOptions;
  }

  onDelayStatusChange(data:any, e:any) {
    this.props.onDelayStatusUpdate({status: data.status, schedule_change_history_id: data.id});
}

  renderDelayDetails(): React.Node {
    const options: any =  Object.keys(delayTexts).map((key) => {
      return (<option value={key} key={key}>{delayTexts[key]}</option>);
    });
    return (
      <div>
        <h3 style={{marginTop: 2, marginBottom: 11, fontWeight: 'Bold', fontSize: 14 }}>Edit delay:</h3>
        <div className="delay-reason">
            <div className="flex-sp-bet">
                <label>Reason for change</label>
                <select style={{maxWidth: 210}} className="variance" onChange={this.onVarianceChange.bind(this)} ref="varianceSelect" value={this.state.newVariance}>
                  {options}
                </select>
            </div>
            {this.state.newVariance === "others" ? (
            <div className="flex-sp-bet" style={{marginTop: 8}}>
                <label>Other reason*</label>
                <input  style={{width: 210}} onChange={this.onOthersFieldChange.bind(this)} value={this.state.otherReason} />
            </div>) : ""}
            <div className="flex-sp-bet">
                <textarea className="description-field" ref="description"  placeholder="Description" onChange={this.onDescriptionChange.bind(this)} value={this.state.delayDescription === 'others: pending' ? "Pending from the user" : this.state.delayDescription}></textarea>
            </div>
            <div className="flex-sp-bet">
                <label>Responsible Party</label>
                <select style={{maxWidth: 250}} className="variance" onChange={this.onCompanyChange.bind(this)} value={this.state.responsibleParty}>
                {this.getCompanyOptions()}
                </select>
            </div>
            {this.state.responsibleParty === "others" || this.state.responsibleParty === "supplier" ? (
              <div className="flex-sp-bet" style={{marginTop: 8}}>
                  <label>Details*</label>
                  <input style={{width: 210}} onChange={this.onResponsiblePartyOthersFieldChange.bind(this)} value={this.state.responsiblePartyOtherValue}/>
              </div>) : "" }
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
              {this.state.showError ? <span style={{color: "red", marginTop: 10}}>{this.state.message}</span> : <span />}
              <button className="saveButton" onClick={this.onDelaySave.bind(this)}>Save</button>
            </div>
        </div>
        
      </div>
    );
  }
  
  renderDelayHistory(): React.Node {
    const companies = this.props.companies;
    const clients = companies.Client;
    return(
      <div>
        <h3 style={{marginTop: 20, marginBottom: 10, fontWeight: 'Bold', fontSize: 14 }}>{this.props.delayHistory && this.props.delayHistory.length >= 1 ? 'Change history:' : null}</h3>
        {
          this.props.status === 400 &&
          <div style={{color: 'red', marginBottom: 4, marginTop: 4}}>You are not authorised to perform this action</div>
        }
          <div style={this.state.newVariance === "others" && (this.state.responsibleParty === "others" || this.state.responsibleParty === "supplier") ? styles.variants : styles.variantsOther  } >
            { this.props.delayHistory ? this.props.delayHistory.slice().reverse().map((item: any, index): any => {
                return(
                  <ul style={styles.variant} key={index}>
                    <li>{delayTexts[this.getReasons(item.variance)]}{this.getReasons(item.variance) === "others" ? <span>: {this.getOtherReasons(item.variance)}</span> : null }</li>
                    <span style={ styles.description}>
                      <li >Description: {item.description === 'others: pending' ? 'Pending from the user' : item.description}</li>
                      <li >Responsible party: {item.responsible_company_name ? item.responsible_company_name : (responsiblePartyTexts[item.responsible_entity] || (item.responsible_entity ? item.responsible_entity.split(":").join(" - ") : null))}</li>
                      <span style={ styles.delayDescription}>
                        <li>{index === 0 ? 'Recorded by:' : 'Modified by:'} {item.modified_by} ({item.modified_by_email}) </li>
                        <li>{moment(item.modified_on).format('DD MMM YYYY, hh:mm a')} </li>
                      </span>
                      {
                        item.status && 
                        <span style={ styles.delayDescription}>
                          <li>{item.status === 'approved' ? 'Approved by:' : 'Rejected by:'} {item.approved_by} ({item.approved_by_email}) </li>
                          <li>{moment(item.approved_on).format('DD MMM YYYY, hh:mm a')} </li>
                        </span>
                      }
                    </span>
                      {/* {index !== 0 && item.status === ''  && clients && Object.keys(clients).length > 0 && <div  style={{marginBottom: 8, marginTop: 8 }}>Only a client user can Approve or Reject the Change in Delay</div>}
                    {index !== 0 && item.status === ''  && clients && Object.keys(clients).length > 0 && <div className="buttonGroup">
                    <button style={{marginRight: 16, }} className="approveButton" onClick={this.onDelayStatusChange.bind(this, {status:true, id:item.schedule_change_history_id})}>Approve</button>
                    <button  className="rejectButton" onClick={this.onDelayStatusChange.bind(this, {status:false, id:item.schedule_change_history_id})}>Reject</button>
                    </div>
                    
                    } */}
                  </ul>
                )
              }) : null
            }
          </div>
      </div>
    )

  }

  renderComments(): React.Node {
    const data: any = this.props.comments && (window.__r2d2 ? this.props.comments : this.props.comments.comments);
    return (
      <div>
        <div style={styles.textAreaContainer}>
          <textarea  style={styles.textArea} placeholder="Enter comments here" onChange={this.onCommentChange.bind(this)} value={this.state.newComment} onKeyDown={this.keyDownHandler.bind(this)} onKeyUp={this.keyUpHandler.bind(this)} />
          <span style={{ padding: 2}}><button className="saveButton" onClick={this.onSave.bind(this)}>Comment</button></span>
        </div>
        <h3 style={{marginTop: 20, marginBottom: 10, fontWeight: 'Bold', fontSize: 14 }}>{data && data.length >= 1 ? 'Comments:' : null}</h3>
        <div style={styles.commentVariants}>
          { data ? data.slice().reverse().map((item: any): any => {
              return(
                <ul style={styles.commentVariant} key={item.comment_id}>
                  <span style={ styles.commentDescription}>
                    <li >{item.userName}</li>
                    <li> {moment(window.__r2d2 ? item.commentedOn : item.commented_at).format('DD MMM YYYY, hh:mm a')} </li>
                  </span>
                  <li>{window.__r2d2 ? item.text : item.comment}</li>
                </ul>
              )
            }) : null
          }
        </div>
      </div>
    );
  }


  render(): React.Node {
    return (
      <section style={styles.container}>
        <h2 style={styles.heading}>{delayTexts[this.getReasons(this.props.workdata.variance)]}{this.getReasons(this.props.workdata.variance) === "others" ? <span>: {this.getOtherReasons(this.props.workdata.variance)}</span> : null }</h2>
        {this.props.workdata.delayedArtifact === 'task_constraint' || this.props.workdata.delayedArtifact === 'activity_constraint'
         ? <div style={styles.parent}>Constraint description: {this.props.workdata.delayedArtifact === 'activity_constraint' ? this.props.workdata.activityConstraintDesc : this.props.workdata.taskConstraintDesc}</div> : null
        }
        <div style={styles.parent}>
          <div style={{ display: 'inline-block' }}>Delay type: {delayType[this.props.workdata.delayedArtifact]} delay</div>
          <div style={{ marginLeft: 70, display: 'inline-block' }}>Recorded On: {moment(this.props.workdata.delayedOn).format('DD MMM YYYY, hh:mm a')}</div>
          <div style={{ marginLeft: 70, display: 'inline-block' }}>Recorded By: {this.props.workdata.delayedBy}</div>
        </div>
        {this.props.workdata.delayedArtifact === 'task' || this.props.workdata.delayedArtifact === 'task_constraint'
          ? <div style={styles.parent}>Task name: {this.props.workdata.taskName}</div>
          : <div style={styles.parent}>Activity name: {this.props.workdata.activityName}</div>
        }
        <div style={styles.parentChain}>{this.getParentHierarchy(this.props.workdata.parentChain)}</div>
        <div style={styles.tabsContainer}>{this.renderTabs()}</div>
        <div>{this.state.selectedTab === 'delayDetails' ? this.renderDelayDetails() : null }</div>
        <div>{this.state.selectedTab === 'delayDetails' ? this.renderDelayHistory() : null }</div>
        <div>{this.state.selectedTab === 'comments' ? this.renderComments() : null}</div>
      </section>
    );
  }
}
