// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import CustomModal from '../components/custom-modal';

import type { Reducers } from '../reducers';
import type { Project } from '../auth/with-project-context';
import type { DelayDetailState } from './reducer';

import ActionTypes from './action-types';
import DelayDetail from './delay-detail';

// import withProjectContext from '../auth/with-project-context';

type Props = {
  delayId: number,
  project: Project,
  dispatch: Object => mixed,
  data: DelayDetailState,
  companies: any,
  workdata: Object,
  onClose: () => void,
};

class DelayDetailContainer extends React.Component<Props> {
  componentDidMount() {
    if (!window.__r2d2) {
      this.props.dispatch({
        type: ActionTypes.GET_DELAY_COMMENTS,
        data: {
          projectId: this.props.project.id,
          delayId: this.props.workdata.delayId,
        },
      });
    } else {
      this.props.dispatch({
        type: ActionTypes.GET_DELAY_COMMENTS,
        data: {
          projectId: this.props.project.id,
          planId: this.props.project.plans[0].id,
          delayId: this.props.workdata.delayId,
        },
      });
    }
    this.props.dispatch({
      type: ActionTypes.GET_DELAY_HISTORY,
      data: {
        projectId: this.props.project.id,
        delayId: this.props.workdata.delayId,
      },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: ActionTypes.CLEAR_DELAY_DETAIL });
  }

  closeModal = () => {
    this.props.onClose();
  }


  commentsUpdateHandler = (data: any) => {
    if (window.__r2d2) {
      this.props.dispatch({
        type: ActionTypes.POST_DELAY_COMMENTS,
        data: {
          projectId: this.props.project.id,
          planId: this.props.project.plans[0].id,
          delayId: this.props.workdata.delayId,
          data,
        },
      });
    } else {
      this.props.dispatch({
        type: ActionTypes.POST_DELAY_COMMENTS,
        data: {
          projectId: this.props.project.id,
          delayId: this.props.workdata.delayId,
          data,
        },
      });
    }
  }

  delayUpdateHandler = (data: any) => {
    if (window.__r2d2) {
      this.props.dispatch({
        type: ActionTypes.UPDATE_DELAY_HISTORY,
        data: {
          projectId: this.props.project.id,
          delayId: this.props.workdata.delayId,
          planId: this.props.project.plans[0].id,
          data,
        },
      });
    } else {
      this.props.dispatch({
        type: ActionTypes.UPDATE_DELAY_HISTORY,
        data: {
          projectId: this.props.project.id,
          delayId: this.props.workdata.delayId,
          data,
        },
      });
    }
  }

  delayStatusUpdateHandler = (data: any) => {
    this.props.dispatch({
      type: ActionTypes.UPDATE_DELAY_HISTORY_STATUS,
      data: {
        projectId: this.props.project.id,
        delayId: this.props.workdata.delayId,
        planId: this.props.project.plans[0].id,
        status: data.status,
        sheduleChangeId: data.schedule_change_history_id,
      },
    });
  }

  render(): React.Node {
    return (
      <CustomModal onClose={this.closeModal}>
        <DelayDetail
          comments={this.props.data.chartData}
          delayHistory={this.props.data.delayData}
          onSave={this.commentsUpdateHandler}
          onDelaySave={this.delayUpdateHandler}
          onDelayStatusUpdate={this.delayStatusUpdateHandler}
          companies={this.props.companies}
          workdata={this.props.workdata}
          status={this.props.data.status}
        />
      </CustomModal>
    );
  }
}

const Component: any = compose(
  // eslint-disable-next-line object-curly-newline
  connect((state: Reducers): { data: Object } => ({ data: state.delayDetail, companies: state.project.companies, project: state.project.projectDetails, status: state.status })),
)(DelayDetailContainer);

export default Component;
