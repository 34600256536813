/* eslint-disable flowtype/require-return-type */
// @flow
export const delayReasons: any = [
  {
    label: 'Wrong Estimate',
    value: 'wrong_estimate',
  },
  {
    label: 'Client Decision',
    value: 'client_decision',
  },
  {
    label: 'Unexpected Conditions',
    value: 'unexpected_conditions',
  },
  {
    label: 'Unplanned Requirement',
    value: 'unplanned_requirement',
  },
  {
    label: 'Urgent Requirement',
    value: 'urgent_requirement',
  },
  {
    label: 'Subcontractor Delay',
    value: 'subcontractor_delay',
  },
  {
    label: 'Workflow Rescheduled',
    value: 'workflow_rescheduled',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const getBaseDomainUrl: any = () => {
  const host: any = window.location.hostname;
  if (host.includes('app.theplando')) {
    return 'https://app.theplando.com';
  }
  if (host.includes('lean.theplando')) {
    return 'https://lean.theplando.com';
  }
  if (host.includes('app.leanplando')) {
    return 'https://app.leanplando.com';
  }
  return host;
};
