// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import type { Reducers } from '../reducers';
import type { Project } from '../auth/with-project-context';
import type { MilestoneDetailState } from './reducer';
import CustomModal from '../components/custom-modal';

import ActionTypes from './action-types';
import MilestoneDetail from './milestone-detail';

import withProjectContext from '../auth/with-project-context';

type Props = {
  milestoneId: number,
  delayId: number,
  milestoneName: string,
  project: Project,
  dispatch: Object => mixed,
  data: MilestoneDetailState,
};

class MilestoneDetailContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_MILESTONE_DETAIL,
      data: {
        milestoneId: this.props.milestoneId,
        delayId: this.props.delayId,
        projectId: this.props.project.id,
      },
    });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: ActionTypes.CLEAR_MILESTONE_DETAIL });
  }

  closeModal = () => {
    this.props.dispatch({
      type: 'hideModal',
    });
  }

  render(): React.Node {
    return (
      <CustomModal onClose={this.closeModal}>
        <MilestoneDetail milestone={this.props.data.milestone} milestoneName={this.props.milestoneName} />
      </CustomModal>
    );
  }
}

const Component: any = compose(
  connect((state: Reducers): { data: Object } => ({ data: state.milestoneDetail })),
  withProjectContext,
)(MilestoneDetailContainer);

export default Component;
