// @flow

export type CriticalMilestonesChanges = {
  GET_CRITICAL_MILESTONES_CHANGES: 'getCriticalMilestonesChanges',
  GET_CRITICAL_MILESTONES_CHANGES_SUCCESS: 'getCriticalMilestonesChangesSuccess',
  GET_CRITICAL_MILESTONES_CHANGES_FAIL: 'getCriticalMilestonesChangesFail',
}

const actionTypes: CriticalMilestonesChanges = {
  GET_CRITICAL_MILESTONES_CHANGES: 'getCriticalMilestonesChanges',
  GET_CRITICAL_MILESTONES_CHANGES_SUCCESS: 'getCriticalMilestonesChangesSuccess',
  GET_CRITICAL_MILESTONES_CHANGES_FAIL: 'getCriticalMilestonesChangesFail',
};

export default actionTypes;
