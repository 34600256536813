// @flow

export function truncatePercent(value: number): string {
  let percentage: any = `${value}`;

  percentage = percentage.split('.');
  if (percentage.length > 1) {
    // eslint-disable-next-line prefer-template
    percentage = percentage[0] + '.' + percentage[1].substr(0, 2);
  } else {
    percentage = percentage[0];
  }

  return percentage;
}
