// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getCriticalMilestoneStatusApi from './apis';

export type CriticalMilestoneStatus = {
  name: string,
  reason: string[],
  delay: string,
  id: number,
};

type CriticalMilestoneStatusResponse = {
  critical_milestones: Array<CriticalMilestoneStatus>,
};

export type CriticalMilestoneStatusAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CRITICAL_MILESTONES_STATUS_SUCCESS'>,
  data: CriticalMilestoneStatusResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_CRITICAL_MILESTONES_STATUS_FAIL'>,
|};


function* getCriticalMilestoneStatus(action: { data: { projectId: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: CriticalMilestoneStatusResponse } = yield call(getCriticalMilestoneStatusApi, action.data.projectId);
    const actionData: CriticalMilestoneStatusAction = { type: ActionTypes.GET_CRITICAL_MILESTONES_STATUS_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: CriticalMilestoneStatusAction = { type: ActionTypes.GET_CRITICAL_MILESTONES_STATUS_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_CRITICAL_MILESTONES_STATUS, getCriticalMilestoneStatus);
}
