// @flow
import * as React from 'react';
import {
  Route, Link, Redirect, Switch,
} from 'react-router-dom';
import type { MapStateToProps, Connector } from 'react-redux';
import { connect } from 'react-redux';

import Header from './header';
import Dashboard from '../dashboard';
import Weekly from '../weekly';
import Loader from '../components/loader';
import OverviewFlow from '../flow/overview-flow-widgets';

import { setBaseUrl, setFlowBaseUrl } from '../config/config';

import ProjectActions from '../project/action-types';
import flowActions from '../flow/action-types';

import type { Reducers } from '../reducers';

import './style.css';

const styles: Object = {
  activeTab: {
    backgroundColor: '#2e3e46',
  },
  activeFont: {
    color: '#ffffff',
  },
  content: {
    paddingTop: '3rem',
  },
};

type Props = {
  dispatch: Function,
  match: Object,
  location: Object,
  project: Object,
  projectAllWeeks: Object,
  lookahead: Object,
};

type State = {
  overviewFlowWidgets: boolean
}

class Home extends React.Component<Props, State> {
  state= {
    overviewFlowWidgets: false,
  }

  componentDidMount() {
    // TODO: move it to store or config
    // eslint-disable-next-line no-underscore-dangle
    window.__r2d2 = true;

    setBaseUrl(`companies/${this.props.match.params.companyId}/`);
    setFlowBaseUrl(`companies/${this.props.match.params.companyId}/`);

    this.props.dispatch({
      type: ProjectActions.GET_PROJECT_FEATURES,
      data: { projectId: this.props.match.params.projectId },
    });
    this.props.dispatch({
      type: ProjectActions.GET_PROJECT_DETAILS,
      data: { projectId: this.props.match.params.projectId },
    });
    this.props.dispatch({
      type: ProjectActions.GET_ALL_WEEKS,
      data: { projectId: this.props.match.params.projectId },
    });
    this.props.dispatch({
      type: ProjectActions.GET_VERSION_DETAILS,
      data: { projectId: this.props.match.params.projectId },
    });
    this.props.dispatch({
      type: flowActions.GET_DISCIPLINE_MASTER_LIST,
      payload: {
        projectId: this.props.match.params.projectId,
        companyId: this.props.match.params.companyId,
      },
    });
  }

  componentDidUpdate() {
    if (!this.props.lookahead && this.props.project && this.props.project.plans && this.props.project.plans.length > 0) {
      this.props.dispatch({
        type: ProjectActions.GET_LOOKAHEAD_META,
        payload: {
          projectId: this.props.match.params.projectId,
          companyId: this.props.match.params.companyId,
          planId: this.props.project.plans[0].id,
        },
      });
    }
  }

  handleOverviewChange=() => {
    this.setState((prevState: State): State => ({ ...prevState, overviewFlowWidgets: !prevState.overviewFlowWidgets }));
  }

  getContent(): React.Node {
    if (!this.props.project || !this.props.lookahead) {
      return <div style={styles.content}><Loader /></div>;
    }
    // if (!(this.props.project.company_role === 'Main Contractor' || this.props.project.company_role === 'Client'
    // || (this.props.project.company_role === 'Consultant' && this.props.project.logged_user.permissions === 1.5))) {
    //   return null;
    // }
    const projectId: string = this.props.match.params.projectId;
    const activeTab: string = this.props.location.pathname.includes('/weekly') ? 'weekly' : 'overview';
    return (
      <div style={styles.content}>
        <div className="tab-container">
          <div className="tabs">
            <Link to={`/c/${this.props.match.params.companyId}/projects/${projectId}/overview`} className="link">
              <span className="button overview" style={activeTab === 'overview' ? styles.activeTab : null}>
                <span className="tab-text" style={activeTab === 'overview' ? styles.activeFont : null}>
                  Overview
                </span>
              </span>
            </Link>
            <Link to={`/c/${this.props.match.params.companyId}/projects/${projectId}/weekly/${this.props.projectAllWeeks.length === 1 ? 'current' : 'last'}`} className="link">
              <span className="button weekly" style={activeTab === 'weekly' ? styles.activeTab : null}>
                <span className="tab-text" style={activeTab === 'weekly' ? styles.activeFont : null}>
                  Weekly
                </span>
              </span>
            </Link>
          </div>
       {/* {this.props.project.type === 'flowschedule'
         ? <>
            <Switch>
              <Route
                path="/c/:companyId/projects/:projectId/overview"
                render={(): React.ReactNode => <FlowSwitchButton checked={this.state.overviewFlowWidgets} onChange={this.handleOverviewChange} />}
              />
            </Switch>
            <span className="project-name">
              {this.props.project && this.props.project.name}
            </span>
           </> : null
        } */}
        </div>

        <Switch>
          <Redirect exact from="/c/:companyId/projects/:projectId" to={`/c/:companyId/projects/${projectId}/overview`} />
          <Route path="/c/:companyId/projects/:projectId/weekly/:weekStart/" component={Weekly} />
          <Route path="/c/:companyId/projects/:projectId/overview" component={this.state.overviewFlowWidgets ? (OverviewFlow: any) : (Dashboard: any)} />
        </Switch>
      </div>
    );
  }

  render(): React.Node {
    return (
      <div>
        <Header />
        {this.getContent()}
      </div>
    );
  }
}

// export default connect(
//   (store: Reducers): Object => ({
//     project: store.project.projectDetails,
//     projectAllWeeks: store.project.projectAllWeeks,
//   }),
// )(Home);

type TMapStateToFilter = MapStateToProps<Reducers, any, any>;

const mapStateToProps: TMapStateToFilter = (store: Reducers): Object => ({
  project: store.project.projectDetails,
  projectAllWeeks: store.project.projectAllWeeks,
  lookahead: store.project.lookahead,
});

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(Home);
