// @flow

/* eslint-disable */
import * as React from 'react';
import moment from 'moment';
import { groupBy } from 'ramda';

import Colors from '../styles/colors';
import type { ConstraintDetailResponseType } from './sagas';
import delayTexts from '../utils/delay-reasons';

type Props = {
  constraintName: string,
  constraint: ConstraintDetailResponseType | null,
};

type State = {
};

const styles: Object = {
  heading: {
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  parent: {
    fontSize: '0.8rem',
    marginTop: '0.6rem',
  },
  tabsContainer: {
    marginTop: '1rem',
    overflowY: 'auto',
  },
  tabs: {
    fontSize: '0.9rem',
    marginBottom: '1rem',
  },
  tab: {
    float: 'left',
    padding: '0.5rem 0.8rem',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
  selectedTab: {
    borderBottomColor: Colors.themePrimaryColor,
  },
  variants: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    listStyleType: 'square',
    paddingLeft: '1rem',
  },
  variant: {
    lineHeight: 1.4,
    marginBottom: '1.5rem',
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
  },
  variantName: {
    color: '#6f6f6f',
  },
  delay: {
    color: '#6f6f6f',
    paddingRight: '10px',
  },
  dates: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    lineHeight: 1.5,
  },
  dateRow: {
    display: 'flex',
  },
  date: {
    flex: 1,
  },
  projectDelay: {
    color: '#ff0000',
  },
  projectAdvance: {
    color: '#278A5B',
  },
  delayHeading: {
    fontSize: '1.0rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    textDecoration: 'underline',
  },
  description: {
    color: '#6f6f6f',
  },
  delayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    paddingRight: '10px',
  },
  displayMessage: {
    fontSize: '0.85rem',
    fontWeight: 'bold',
    padding: '20px 0 20px 0',
  },
  delayedBy: {
    color: '#6f6f6f',
  },
};


export default class ConstraintDetail extends React.Component<Props, State> {
  
  getResponsibleParty(delayItem: Object): React.Node {
    const party: string = delayItem.delay_responsible_party;
    if (party) {
      return (
        <div style={styles.delay}>
          Responsible Party:{' '}
          {party === 'others: pending' ? 'Pending': party}
        </div>
      );
    }
    return null;
  }

  getConstraintProjectDelay(item: Object): React.Node {
    const change = item.delay < 0 ? 'advanced' : 'delayed';
    let message = '';
    if (item.project_delay) {
      message = `Constraint ${change} by ${Math.abs(item.delay)} ${Math.abs(item.delay) > 1 ? 'days' : 'day'}`;
      if (item.variance === 'delete_constraint') {
        message = '';
      }

      const projectDetails =
     (
        <span>
          {`Project ${item.project_delay < 0 ? 'advanced' : 'delayed'} by `}
          <span 
            style={item.project_delay < 0 ? styles.projectAdvance : styles.projectDelay}
          >{`${Math.abs(item.project_delay)} ${
            Math.abs(item.project_delay) > 1 ? 'days' : 'day'
          }`}</span>
        </span>
        );
      return (
        <div style={styles.delayMessage}>
          {message ? <span style={{marginRight: 3}}>{`${message.charAt(0).toUpperCase()}${message.slice(1)}.`}</span> : ''}
          {projectDetails}
        </div>
      );
    }
    message = `Constraint ${change} by ${Math.abs(item.delay)} ${Math.abs(item.delay) > 1 ? 'days' : 'day'}`;
  
    return (
      <div style={styles.delayMessage}>
        {message}
      </div>
    );
  }

  getGroupedList(list: Object[]): Object {
    const constraint: Object[] = list;
    const byProjectDelays: (Object[]) => Object = groupBy(
      (constraintDelay: Object): string =>
      constraintDelay.project_delay === null
          ? 'otherDelayReasons'
          : 'projectDelayReasons',
    );
    return byProjectDelays(constraint);
  }

  renderGivenDelays(delays: Object[]): React.Node {
    return delays
      ? delays.map(
          (item: Object, index: number): React.Node => (
            <li style={styles.variant} key={index}>
              {
                this.getConstraintProjectDelay(item)
                }
              <div style={styles.description}>
                Step name:{' '}
                {item.name}
              </div>
              {delayTexts[item.variance] || item.variance ? (
                <div style={styles.variantName}>
                  Reason:{' '}
                  {delayTexts[item.variance] || item.variance}
                </div>
              ) : null}
              <div style={styles.description}>
                Description:{' '}
                {item.description === 'others: pending' ? 'Pending from the user' : item.description}
              </div>
              {this.getResponsibleParty(item)}
              {item.project_delay ?
                 <div style={styles.variantName}>
                  Old project end date: {' '}
                  {moment(item.old_project_end_date).format('DD MMM YYYY')}
                </div>
                : ''
                }
                {item.project_delay ?
                <div style={styles.variantName}>
                  New project end date: {' '}
                  {moment(item.new_project_end_date).format('DD MMM YYYY')}
                </div> : ''
                }
              {item.delayed_by ? (
                <div style={styles.delayedBy}>
                  Recorded by:{' '}
                  {item.delayed_by}
                </div>
              ) : null}
            </li>
          ),
        )
      : null;
  }

  renderTabs(): React.Node {
    const tabs: Array<React.Node> = [];

    if (this.props.constraint === null) return null;

    if (this.props.constraint.constraint_delays.length !== 0) {
      tabs.push(
        <li
          style={
              { ...styles.tab, ...styles.selectedTab }
          }
          key="constrait"
        >
          Constraint Delays
        </li>,
      );
    }

    return <ul style={styles.tabs}>{tabs}</ul>;
  }

  renderDelays(): React.Node {

    if (this.props.constraint === null) return null;

    const groupedDelays: Object = this.getGroupedList(
      this.props.constraint['constraint_delays'],
    );

    return (
      <ul style={styles.variants}>
        {this.renderGivenDelays(groupedDelays.projectDelayReasons)}
        {this.renderGivenDelays(groupedDelays.otherDelayReasons)}
      </ul>
    );
  }

  renderDates(): React.Node | null {
    const constraint: ConstraintDetailResponseType | null = this.props.constraint;
  
    if (constraint === null) return null;
    return (
      <div style={styles.dates}>
        <div style={styles.dateRow}>
          <div style={styles.date}>
            Base due date:
            {' '}{constraint.base_due_date ? moment(constraint.base_due_date).format('DD MMM YYYY') : ' - '}
          </div>
          <div style={styles.date}>
            Due date:
            {' '}{moment(constraint.due_date).format('DD MMM YYYY')}
          </div>
        </div>
       
      </div>
    );
  }

  renderParent(): React.Node | null {
    const constraint: ConstraintDetailResponseType | null = this.props.constraint;
  
    if (constraint === null) return null;
    return (
     <div style={styles.parent}>
        {constraint.activity_name ? 'Root activity name:' : 'Root task name:'}{' '}
          {constraint.activity_name || constraint.task_name}
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.container}>
        <h2 style={styles.heading}>{this.props.constraintName}</h2>
        <div>{this.renderParent()}</div>
        <div>{this.renderDates()}</div>
        <div style={styles.tabsContainer}>{this.renderTabs()}</div>
        <div style={{ flex: 1, overflowY: 'auto' }}>{this.renderDelays()}</div>
      </section>
    );
  }
}
