// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import '../style.css';
import colors from '../../styles/colors';


type Props = {}

const styles: Object = {
  widget: {
    width: '50%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  topText: {
    display: 'flex',
    color: colors.cLevelGray,
    fontSize: '20px',
    marginBottom: '10px',
  },
  firstText: {
    marginRight: '12px',
    marginLeft: '25px',
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '25px',
    position: 'relative',
    marginLeft: '-30px',
  },
  container: {
    position: 'relative',
  },
  topLineContainer: {
    position: 'absolute',
    top: '21px',
    left: '15px',
    width: '280px',
    display: 'flex',
  },
  topLine: {
    width: '182px',
    height: '2px',
    background: colors.cLevelGray,
    display: 'flex',
    justifyContent: 'center',
  },
  firstLineContainer: {
    position: 'absolute',
    bottom: '-8px',
    right: '-70px',
    color: colors.cLevelRed,
    width: '160px',
  },
  secondLineContainer: {
    position: 'absolute',
    bottom: '-57px',
    right: '-105px',
    zIndex: '100',
    color: colors.cLevelGray,
    width: '253px',
  },
  thirdLineContainer: {
    position: 'absolute',
    bottom: '-100px',
    right: '-99px',
    color: colors.cLevelRed,
    width: '160px',
  },
  fourthLineContainer: {
    position: 'absolute',
    bottom: '-127px',
    right: '-99px',
    color: colors.cLevelRed,
    width: '160px',
  },
  lastLineContainer: {
    position: 'absolute',
    bottom: '-168px',
    right: '-95px',
    color: colors.cLevelRed,
    width: '160px',
  },
  secondLine: {
    width: '120px',
    height: '2px',
    background: colors.cLevelRed,
  },
  thirdLine: {
    width: '70px',
    height: '2px',
    background: colors.cLevelRed,
  },
  fourthLine: {
    width: '150px',
    borderTopWidth: 3,
    borderTopColor: colors.cLevelGray,
    borderTopStyle: 'dashed',
  },
  floatingNumbers: {
    position: 'absolute',
    top: '70px',
    left: '15px',
    color: '#fff',
    fontSize: '40',
  },
  floatingNumbers01: {
    position: 'absolute',
    top: '30px',
    left: '15px',
    color: '#fff',
    fontSize: '40',
  },
  floatingText: {
    position: 'absolute',
    top: '69px',
    left: '-90px',
    color: colors.cLevelGray,
    fontSize: '40',
  },
  floatingText02: {
    position: 'absolute',
    top: '22px',
    left: '-90px',
    color: colors.cLevelGray,
    fontSize: '40',
  },
  leftTopBox: {
    width: '55px',
    height: '130px',
    background: colors.cLevelGreen,
    marginRight: '20px',
  },
  leftBottomBox: {
    width: '55px',
    height: '78px',
    background: colors.cLevelRed,
    marginRight: '20px',
  },
  rightTopBox: {
    width: '55px',
    height: '160px',
    background: colors.cLevelGreen,
  },
  rightBottomBox: {
    width: '55px',
    height: '160px',
    background: colors.cLevelRed,
  },
  align: {
    display: 'flex',
    justifyContent: 'center',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class ProjectOverallProgressWidget extends React.PureComponent<Props> {
  getWidget(): React.Node {
    return (
      <div style={styles.topContainer}>
        <div style={styles.topContainer}>
          <div style={styles.topText}>
            <div style={styles.firstText}>Planned</div>
            <span>Actual</span>
          </div>
          <div style={styles.topLineContainer}>
            <div style={styles.flex}>
              <div style={styles.topLine} />
              <span>Mar 2017</span>
            </div>
          </div>
        </div>
        <div style={styles.align}>
          <div>
            <div style={styles.container}>
              <div style={styles.leftTopBox} />
              <span style={styles.floatingNumbers}>60%</span>
              <div style={styles.floatingText}>Completed</div>
            </div>
            <div style={styles.container}>
              <div style={styles.leftBottomBox} />
              <span style={styles.floatingNumbers01}>40%</span>
              <div style={styles.floatingText02}>
                Not yet
                <br />
                Completed
              </div>
            </div>
          </div>
          <div>
            <div style={styles.container}>
              <div style={styles.rightTopBox} />
              <span style={styles.floatingNumbers}>50%</span>
              <div style={styles.firstLineContainer}>
                <div style={styles.flex}>
                  <div style={styles.secondLine} />
                  <div> Today</div>
                </div>
              </div>
              <div style={styles.secondLineContainer}>
                <div style={styles.flex}>
                  <div style={styles.fourthLine} />
                  <div style={styles.flex}>Oct 2021</div>
                </div>
              </div>
              <div style={styles.thirdLineContainer}>
                <div style={styles.flex}>
                  <div style={styles.thirdLine} />
                  <div> Penalty 1</div>
                </div>
              </div>
              <div style={styles.fourthLineContainer}>
                <div style={styles.flex}>
                  <div style={styles.thirdLine} />
                  <div> Penalty 2</div>
                </div>
              </div>
              <div style={styles.lastLineContainer}>
                <div style={styles.flex}>
                  <div style={styles.thirdLine} />
                  <div style={styles.flex}> Apr 2022</div>
                </div>
              </div>
            </div>
            <div style={styles.container}>
              <div style={styles.rightBottomBox} />
              <span style={styles.floatingNumbers}>50%</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <div style={styles.chart}>
          <h1>
            Overall Project Progress Status
            {' '}
          </h1>
            {
              this.getWidget()
            }
        </div>
      </section>
    );
  }
}

const Component: any = connect()(ProjectOverallProgressWidget);

// Component.style = {
//   width: '30%',
// };

export default Component;
