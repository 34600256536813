// @flow

import * as React from 'react';
import navigationList from './navigationList';
import hamMenu from '../icons/hamMenu.png';

const styles: Object = {
  container: {
    width: '220px',
    minWidth: '220px',
    height: '100vh',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    marginRight: '20px',
    padding: '20px 0',
    color: '#435064',
    position: 'sticky',
    top: 0,
    overflow: 'auto',

  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  heading: {
    fontSize: '18px',
  },
  icon: {
    marginRight: '10px',
  },
  navigation: {
    paddingBottom: '20px',
  },
  menu: {
    padding: '10px 0px',
    ':last-child': {
      border: 0,
    },
  },
  menuItem: {
    // display: 'flex',
    // alignItems: 'center',
    padding: '6px 0px',
    fontSize: '12px',
    cursor: 'pointer',
    lineHeight: 1.5,
  },
  active: {
    // boxShadow: '3px 0px 6px 3px rgba(156, 156, 156, 0.1)',
    color: '#e9614a',
  },
  titleItem: {
    fontSize: '16px',
    padding: '0 20px',
  },
  small: {
    width: '60px',
    minWidth: '60px',
    transition: 'width 300ms ease 0s',
  },
  subMenu: {
    margin: '0 20px',
    borderBottom: '1px solid rgba(156, 156, 156, 0.1)',
  },
};

type Props= {
  refs: Object,
  isFullMenu: Boolean,
  toggleMenu: Function,
}


type State= {
  selectedMenu: string | null;
}

class Navigation extends React.Component<Props, State> {
  state: State = {
    selectedMenu: null,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll= () => {
    Object.keys(this.props.refs).forEach((refName: any) => {
      const ref: any = this.props.refs[refName];
      const { offsetTop, offsetBottom }: any = this.getDimensions(ref);
      const scrollPosition: any = window.scrollY;
      if (scrollPosition > offsetTop - 100 && scrollPosition < offsetBottom && refName !== this.state.selectedMenu) {
        this.setState({ selectedMenu: refName });
      }
    });
  }

  getDimensions = (ele: any): any => {
    const { height }: any = ele.getBoundingClientRect();
    const offsetTop: Number = ele.offsetTop;
    const offsetBottom: Number = offsetTop + height;
    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  handleClick= (item: string, e: any) => {
    e.preventDefault();
    window.removeEventListener('scroll', this.handleScroll);

    const selectedRef: any = Object.keys(this.props.refs).find((r: any): any => r === item);

    const ref: any = this.props.refs[selectedRef];

    if (ref) {
      // e.target.scrollIntoView();
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.setState({ selectedMenu: item }, () => {
        // FIXME: need to find out more
        setTimeout((): any => window.addEventListener('scroll', this.handleScroll), 2000);
      });
    }
  }

  toggleMenu = () => {
    // this.setState((prevState: State): any => ({ isFullMenu: !prevState.isFullMenu }));
    this.props.toggleMenu();
  }

  render(): React.Node {
    const { isFullMenu }: Props = this.props;

    return (
      <div style={{ ...styles.container, ...(!isFullMenu ? styles.small : {}) }}>
        <div style={styles.header} onClick={this.toggleMenu}>
          <div>
            <img
              width="26"
              src={hamMenu}
              alt="i"
              style={styles.icon}
            />
          </div>
          {isFullMenu && <h2>LPD Insights</h2>}
        </div>
        <div style={styles.navigation}>
          {
            navigationList.map((menu: any): React.Node => (
              <div
                style={{
                  ...styles.menu,
                  ...(!isFullMenu ? { padding: 0 } : {}),
                }}
              >
                <div
                  style={{
                    ...styles.menuItem,
                    ...styles.titleItem,
                    ...(this.state.selectedMenu === menu.title.value ? styles.active : {}),
                    ...(!isFullMenu ? { padding: '10px 20px', boxShadow: 'none' } : {}),
                  }}
                  onClick={this.handleClick.bind(this, menu.title.value)}
                  title={menu.title.label}
                >
                 {/* <span style={styles.icon}>{menu.title.label.split('')[0]}</span> */}
                    <img
                      width="16"
                      src={this.state.selectedMenu === menu.title.value ? menu.title.iconActive : menu.title.icon}
                      alt="i"
                      style={styles.icon}
                    />
                {isFullMenu && <span>{menu.title.label}</span>}
                </div>
                {/* {
                  isFullMenu
                   && <div style={styles.subMenu}>
                        {
                          menu.items.map((item: any): React.Node => <div style={{ ...styles.menuItem }} onClick={this.handleClick.bind(this, item)}>{item}</div>)
                        }
                      </div>
                } */}
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

const Component: any = (Navigation);

export default Component;
