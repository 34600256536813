// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { map } from 'ramda';
import type { WeeklyWorkableBacklogItem } from '../../widgets/weekly-workablebacklog/reducer';

import GroupedBar from '../../components/charts/grouped-bar';
import colors from '../../styles/colors';

type Props = {}

type ViewModel = Array<{
  labels: string[],
  values: number[],
  text: string,
  color?: string,
}>;

const styles: Object = {
  widget: {
    width: '50%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const formatData: Function = function formatData(data: any): any[] {
  return map((item: any): any => (
    {
      shouldDo: item.num_should_do,
      canDo: item.num_can_do,
      willDo: item.num_will_do,
      done: item.num_done,
      completePercentage: item.complete_percentage,
      endDate: item.end_date,
    }
  ), data);
};

const dummyData: any = [
  {
    complete_percentage: 0.7149532710280374,
    end_date: '2018-08-23',
    num_can_do: 204,
    num_done: 153,
    num_should_do: 214,
    num_will_do: 170,
    project_id: 1456,
    start_date: '2018-08-17',
  },
  {
    complete_percentage: 0.679324894514768,
    end_date: '2018-08-30',
    num_can_do: 230,
    num_done: 161,
    num_should_do: 237,
    num_will_do: 185,
    project_id: 1456,
    start_date: '2018-08-24',
  },
  {
    complete_percentage: 0.5636363636363636,
    end_date: '2018-09-06',
    num_can_do: 258,
    num_done: 155,
    num_should_do: 275,
    num_will_do: 186,
    project_id: 1456,
    start_date: '2018-08-31',
  },
  {
    complete_percentage: 0.7188612099644128,
    end_date: '2018-09-13',
    num_can_do: 270,
    num_done: 202,
    num_should_do: 281,
    num_will_do: 221,
    project_id: 1456,
    start_date: '2018-09-07',
  },
  {
    complete_percentage: 0.6317460317460317,
    end_date: '2018-09-20',
    num_can_do: 296,
    num_done: 199,
    num_should_do: 315,
    num_will_do: 225,
    project_id: 1456,
    start_date: '2018-09-14',
  },
  {
    complete_percentage: 0.8308270676691729,
    end_date: '2018-09-27',
    num_can_do: 262,
    num_done: 221,
    num_should_do: 266,
    num_will_do: 240,
    project_id: 1456,
    start_date: '2018-09-21',
  },
  {
    complete_percentage: 0.7467948717948718,
    end_date: '2018-10-04',
    num_can_do: 310,
    num_done: 233,
    num_should_do: 312,
    num_will_do: 258,
    project_id: 1456,
    start_date: '2018-09-28',
  },
  {
    complete_percentage: 0.9185185185185185,
    end_date: '2018-10-07',
    num_can_do: 135,
    num_done: 124,
    num_should_do: 135,
    num_will_do: 135,
    project_id: 1456,
    start_date: '2018-10-05',
  },
  {
    complete_percentage: 0.6511627906976745,
    end_date: '2018-10-14',
    num_can_do: 252,
    num_done: 168,
    num_should_do: 258,
    num_will_do: 196,
    project_id: 1456,
    start_date: '2018-10-08',
  },
  {
    complete_percentage: 0.7196969696969697,
    end_date: '2018-10-21',
    num_can_do: 259,
    num_done: 190,
    num_should_do: 264,
    num_will_do: 216,
    project_id: 1456,
    start_date: '2018-10-15',
  },
  {
    complete_percentage: 0.824,
    end_date: '2018-10-28',
    num_can_do: 248,
    num_done: 206,
    num_should_do: 250,
    num_will_do: 246,
    project_id: 1456,
    start_date: '2018-10-22',
  },
];


class WorkableBacklog extends React.PureComponent<Props> {
  // componentDidMount() {
  //   this.props.dispatch({
  //     type: ActionTypes.GET_WEEKLY_WORKABLE_BACKLOG,
  //     data: { projectId: this.props.project.id },
  //   });
  // }

  getFormattedData(data: WeeklyWorkableBacklogItem[]): ViewModel {
    const shouldDo: number[] = [];
    const canDo: number[] = [];
    const willDo: number[] = [];
    const done: number[] = [];
    const dates: string[] = [];
    data.forEach((item: WeeklyWorkableBacklogItem) => {
      shouldDo.push(item.shouldDo);
      canDo.push(item.canDo);
      willDo.push(item.willDo);
      done.push(item.done);
      dates.push(moment(item.endDate).format('DD MMM YYYY'));
    });
    const traces: ViewModel = [
      {
        labels: dates,
        values: shouldDo,
        text: 'Should Do',
        color: colors.cLevelGray,
      },
      {
        labels: dates,
        values: canDo,
        text: 'Can Do',
        color: colors.themeSecondaryColorNew,
      },
      {
        labels: dates,
        values: willDo,
        text: 'Will Do',
        color: colors.themePrimaryColorNew,
      },
      {
        labels: dates,
        values: done,
        text: 'Did',
        color: colors.cLevelGreen,
      },
    ];

    return traces;
  }

  renderWidget(): React$Element<*> {
    if (formatData(dummyData).length === 0) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <GroupedBar data={this.getFormattedData(formatData(dummyData))} />
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1>
          Weekly Workable Backlog
        </h1>
        {
          this.renderWidget()
        }
      </section>
    );
  }
}

const Component: any = connect(
  // (state: Reducers): { data: WeeklyWorkableBacklogState } => ({ data: state.weeklyWorkableBacklog }),
)(WorkableBacklog);

// Component.style = {
//   width: '50%',
// };

export default Component;
