// @flow
import ActionTypes from './action-types';
import { FlowDiscipline } from './types';

export type FlowState = {
  disciplines: FlowDiscipline[],
};

export default function flowReducer(state: FlowState = { disciplines: [] }, action: any): FlowState {
  switch (action.type) {
    case ActionTypes.GET_DISCIPLINE_MASTER_LIST_SUCCESS:
      return {
        ...state,
        disciplines: action.data,
      };
    default:
      return state;
  }
}
