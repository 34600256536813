// @flow

import * as React from 'react';
import { compose } from 'recompose';
import { Connector } from 'react-redux';
import { withRouter } from 'react-router';
import type { Project } from '../auth/with-project-context';
import withProjectContext from '../auth/with-project-context';

import getFlowDetail from './apis';

import FlowDetail from './flow-detail';

import { getBaseDomainUrl } from '../flow/constants';
import CustomModal from '../components/custom-modal';

type Props = {
  artifact: any;
  project: Project,
  closeModal: () => void;
  match: Object,
};

// const modalStyles: Object = {
//   content: {
//     right: 'auto',
//     width: '53rem',
//     left: '50%',
//     transform: 'translateX(-50%)',
//     height: '30rem',
//   },
//   overlay: {
//     backgroundColor: 'rgba(0, 0, 0, 0.75)',
//   },
// };

class FlowDetailContainer extends React.Component<Props> {
  constructor(props: Object) {
    super(props);
    this.state = {
      workflowDetails: [],
    };
  }

  componentDidMount() {
    if (this.props.artifact.workflowIds && this.props.project) {
      this.props.artifact.workflowIds.map(
        (id: string): React.Node => (
          this.fetchData(id)
        ),
      );
    }
  }

  fetchData = async (id: string): any => {
    const workflowDetails: any = [];
    try {
      const response: any = await (getFlowDetail(
        { projectId: this.props.project.id, workflowID: id },
      ));
      if (response.status === 200) {
        workflowDetails.push(response.data);
        this.setState((prevState: State): any => ({
          workflowDetails: [...prevState.workflowDetails, workflowDetails],
        }));
      }
    } catch (e) {
    //  console.log(e)
    }
  }

  openWorkflow = (id: string) => {
    /* eslint-disable */
    if(this.props.match && this.props.match.params.companyId) {
      window.open(`${getBaseDomainUrl()}/c/${this.props.match.params.companyId}/projects/${this.props.project.id}/flow/instance/${id}/history`, "_blank");
    }
  }

  render(): React.Node {
    return (
      <CustomModal onClose={this.props.closeModal}>
        <FlowDetail workflowDetails={this.state.workflowDetails} artifact={this.props.artifact} openWorkflow={this.openWorkflow}/>
      </CustomModal>
    );
  }
}

const connector: Connector<any, Props> = compose(withProjectContext);
const Component: any = connector(withRouter(FlowDetailContainer));

export default Component;
