// @flow
import * as React from 'react';

import '../style.css';
import ToggleButton from '../../components/toggle-button';
import CivilWorks from './civil-works';
import MechanicalWorks from './mechanical-works';
import ElectricalWorks from './electrical-works';
import OtherWorks from './other-works';
import Subcontractor01 from './subcontractor01';
import Subcontractor02 from './subcontractor02';
import Subcontractor03 from './subcontractor03';
import Subcontractor04 from './other-subcontractor';


type Props = {};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
};

type State = {
  selectedFilter: string,
}
// eslint-disable-next-line react/prefer-stateless-function
class Plan extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'discipline',
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState({
      selectedFilter: val,
    });
  }

  render(): React.Node {
    const toggleItems: Array<*> = [
      { value: 'discipline', label: 'Discipline' },
      { value: 'subcontractors', label: 'Subcontractors' },
    ];
    return (
      <div style={styles.widget}>
        <div className="c-level__flex">
          Project Progress
          <div>
            <ToggleButton
              items={toggleItems}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
          </div>
        </div>
        { this.state.selectedFilter === 'discipline'
          ? <div>
              <div className="c-level__content-row">
                <CivilWorks />
                <MechanicalWorks />
              </div>
              <div className="c-level__content-row">
                <ElectricalWorks />
                <OtherWorks />
              </div>
            </div>
          : <div>
              <div className="c-level__content-row">
                <Subcontractor01 />
                <Subcontractor02 />
              </div>
              <div className="c-level__content-row">
                <Subcontractor03 />
                <Subcontractor04 />
              </div>
            </div>
        }
      </div>
    );
  }
}

export default Plan;
