// @flow

import React from 'react';
import type { Node, Element } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import ReactTooltip from 'react-tooltip';

import ActionTypes from './action-types';
// import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
// import activityIcon from '../../styles/icons/activity.png';
// import constraintIcon from '../../styles/icons/constraint.png';
import Loader from '../../components/loader';

import type { CriticalMilestoneChangesState } from './reducer';
// import type { CriticalMilestoneScheduleChange } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

// import SelectBox from '../../components/select-box';

// import delayTexts from '../../utils/delay-reasons';

import './styles.css';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: CriticalMilestoneChangesState,
  project: Project,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  tabContainer: {
    marginBottom: 16,
  },
  selectBoxContainer: {
    maxWidth: 500,
    marginBottom: 16,
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
  },
  milestones: {
    width: 300,
    paddingRight: 12,
    maxHeight: 374,
    overflow: 'auto',
  },
  tableContainer: {
    flex: 1,
    border: '1px solid #eee',
  },
  table: {
    width: '100%',
  },
  tbodyContainer: {
    display: 'block',
    overflowY: 'scroll',
    maxHeight: '320px',
  },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  deletedGrey: {
    color: '#6f6f6f',
    pointerEvents: 'none',
  },
  parentHierarchy: {
    fontSize: '0.70rem',
    marginTop: '0.2rem',
  },
  reason: {
    textTransform: 'capitalize',
  },
  greySmallText: {
    fontSize: '0.75rem',
    color: '#6f6f6f',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

type State = {
  currentMilestoneIndex: number;
  order: String,
  column: string,
};

class CriticalMilestoneChangesWidget extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      currentMilestoneIndex: 0,
      order: 'desc',
      column: 'recorded_on',
    };
  }

  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_CRITICAL_MILESTONES_CHANGES,
      data: { projectId: this.props.project.id },
    });
  }

  onMilestoneChange = (index: number) => {
    this.setState({
      currentMilestoneIndex: index,
    });
  }

  onSortChange(column: string) {
    this.setState(
      (prevState: State): State => ({
        ...prevState,
        column,
        order: prevState.order === 'desc' ? 'asc' : 'desc',
      }),
    );
  }

  showMilestoneDetailModal = (delay: CriticalMilestoneChanges): null => {
    const milestoneData: any = this.props.data.chartData[this.state.currentMilestoneIndex];
    this.props.dispatch({
      type: 'showModal',
      modalType: 'milestoneDetail',
      modalProps: {
        milestoneId: milestoneData.id,
        delayId: delay.id,
        milestoneName: milestoneData.name,
      },
    });
    return null;
  };

  getTable = (): Element<any> => {
    if (this.props.data.chartData.length === 0 && this.props.data.loading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    const milestoneData: any = this.props.data.chartData[this.state.currentMilestoneIndex];

    const sortedData: CriticalMilestoneScheduleChange[] = milestoneData && milestoneData.schedule_changes.sort(
      (item1: CriticalMilestoneScheduleChange, item2: CriticalMilestoneScheduleChange): number => {
        if (this.state.column === 'wbs' || this.state.column === 'artifact_name') {
          if (this.state.order === 'asc') {
            if (item1.schedule_changes[this.state.column] < item2.schedule_changes[this.state.column]) {
              return -1;
            }
            if (item1.schedule_changes[this.state.column] > item2.schedule_changes[this.state.column]) {
              return 1;
            }
            return 0;
          }
          if (item1.schedule_changes[this.state.column] < item2.schedule_changes[this.state.column]) {
            return 1;
          }
          if (item1.schedule_changes[this.state.column] > item2.schedule_changes[this.state.column]) {
            return -1;
          }
          return 0;
        }
        if (this.state.order === 'asc') {
          if (item1[this.state.column] < item2[this.state.column]) {
            return -1;
          }
          if (item1[this.state.column] > item2[this.state.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.column] < item2[this.state.column]) {
          return 1;
        }
        if (item1[this.state.column] > item2[this.state.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (!sortedData) {
      return <div />;
    }

    return (
      <table className="table-container">
        <thead className="thead-container" style={{ width: 'calc(100% - 1.1em)' }}>
          <tr>
            <th className="ms-list-col header" onClick={this.onSortChange.bind(this, 'old_start_date')}>
              Old End Date
              {/* {this.state.column === 'old_start_date' ? <span style={this.state.order === 'asc' ?
              styles.arrowUp : styles.arrowDown} /> : null} */}
            </th>
            <th className="ms-list-col header" onClick={this.onSortChange.bind(this, 'new_start_date')}>
              New End Date
              {/* {this.state.column === 'new_start_date' ? <span style={this.state.order === 'asc' ?
              styles.arrowUp : styles.arrowDown} /> : null} */}
            </th>
            <th style={{ width: '10%' }} className="ms-list-col header" onClick={this.onSortChange.bind(this, 'variance')}>
              Variance
              {this.state.column === 'variance' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              <br />
              (in days)
            </th>
            <th style={{ width: '10%' }} className="ms-list-col header" onClick={this.onSortChange.bind(this, 'state')}>
             State
              {/* {this.state.column === 'state' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null} */}
            </th>
            <th style={{ width: '11%' }} className="ms-list-col header" onClick={this.onSortChange.bind(this, 'wbs')}>
             WBS
              {this.state.column === 'wbs' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th style={{ }} className="header" onClick={this.onSortChange.bind(this, 'artifact_name')}>
              Affected By
              {this.state.column === 'artifact_name' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th className="ms-list-col header" onClick={this.onSortChange.bind(this, 'recorded_on')}>
              Recorded On
              {this.state.column === 'recorded_on' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
            </th>
            <th className="ms-list-col header" onClick={this.onSortChange.bind(this, 'recorded_by')}>
              Recorded By
              {/* {this.state.column === 'recorded_by' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null} */}
            </th>
          </tr>
        </thead>
        <tbody style={styles.tbodyContainer}>
          {sortedData.map((scheduleChange: any): Node => (
            <tr
              key={scheduleChange.id}
              className={`thead-container ms-row ${!scheduleChange.schedule_changes.artifact_name ? 'disabled' : ''}`}
              onClick={this.showMilestoneDetailModal.bind(this, scheduleChange)}
              style={{ width: '100%' }}
            >
              <td className="ms-list-col" style={{ verticalAlign: 'middle' }}>
                {/* <a
                  onClick={scheduleChange.schedule_changes[0].artifact_name ? this.showMilestoneDetailModal.bind(this, scheduleChange) : ''}
                  className={scheduleChange.schedule_changes[0].artifact_name ? 'underline-hover' : ''}
                  style={{ cursor: scheduleChange.schedule_changes[0].artifact_name ? 'pointer' : 'default', display: 'flex' }}
                > */}
                  {scheduleChange.old_start_date ? moment(scheduleChange.old_start_date).format('DD MMM YYYY') : 'NA'}
                {/* </a> */}
              </td>
              <td className="ms-list-col" style={{ verticalAlign: 'middle' }}>{scheduleChange.new_start_date ? moment(scheduleChange.new_start_date).format('DD MMM YYYY') : 'NA'}</td>
              <td className="ms-list-col" style={{ width: '10%', verticalAlign: 'middle' }}>{scheduleChange.variance || ' - '}</td>
              <td className="ms-list-col" style={{ width: '10%', textTransform: 'capitalize', verticalAlign: 'middle' }}>{scheduleChange.state}</td>
              <td className="ms-list-col" style={{ width: '11%', verticalAlign: 'middle' }}>{scheduleChange.schedule_changes.wbs || ' - '}</td>
              <td style={{ }}>
                {scheduleChange.schedule_changes.artifact_type
                  ? <div style={styles.greySmallText}>
                      <span style={{ textTransform: 'capitalize' }}>{scheduleChange.schedule_changes.artifact_type}</span>
                    </div> : ''}
                {/* {scheduleChange.schedule_changes[0].artifact_type ? <br /> : ''} */}
                  {/* <a
                    onClick={scheduleChange.schedule_changes[0].artifact_name ? this.showMilestoneDetailModal.bind(this, scheduleChange) : ''}
                    className={scheduleChange.schedule_changes[0].artifact_name ? 'underline-hover' : ''}
                    style={{ cursor: scheduleChange.schedule_changes[0].artifact_name ? 'pointer' : 'default' }}
                  > */}
                      <span style={{ lineHeight: 1 }}>
                        {scheduleChange.schedule_changes.artifact_name ? scheduleChange.schedule_changes.artifact_name : 'NA'}
                      </span>
                      {' '}
                      {/* {scheduleChange.schedule_changes.length > 1 && scheduleChange.schedule_changes.artifact_name
                        ? <span style={styles.greySmallText}>{`& ${scheduleChange.schedule_changes.length - 1} more`}</span> : ''
                      } */}
                  {/* </a> */}
              </td>
              <td className="ms-list-col" style={{ verticalAlign: 'middle', fontSize: '0.74rem' }}>
                {scheduleChange.recorded_on
                  ? moment(scheduleChange.recorded_on).format('DD MMM YYYY, hh:mm a')
                  : 'NA'}
              </td>
              <td className="ms-list-col" style={{ verticalAlign: 'middle' }}>{scheduleChange.recorded_by}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  getMilestones = (milestones: Object[]): Node => (
    milestones.map((m: Object, index: number): Node => (
      <div key={m.value} className={`ms-list ${this.state.currentMilestoneIndex === index ? 'ms-list__selected' : ''}`} onClick={this.onMilestoneChange.bind(null, index)}>
        {m.label}
      </div>
    ))
  )

  render(): Node {
    if (this.props.data.loading === null) {
      return <Loader />;
    }

    const milestones: Object[] = this.props.data.chartData.map((milestone: Object, index: number): Object => ({
      label: `${milestone.wbs} ${milestone.name}`,
      value: index,
    }));
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <div style={styles.tabContainer}>
        {/* <div style={styles.selectBoxContainer}>
          Milestone:
          <div style={{ marginLeft: 4, flex: '1' }}>
            <SelectBox
              options={milestones}
              value={this.state.currentMilestoneIndex}
              onChange={this.onMilestoneChange}
            />
          </div>
        </div>
        {this.getTable()} */}
        <div style={styles.container}>
          <div style={styles.milestones}>
            {/* <SelectBox
              options={milestones}
              value={this.state.currentMilestoneIndex}
              onChange={this.onMilestoneChange}
            /> */}
            {this.getMilestones(milestones)}
          </div>
          <div style={styles.tableContainer}>{this.getTable()}</div>
        </div>
      </div>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: CriticalMilestoneChangesState } => ({
    data: state.criticalMilestoneChanges,
  }),
)(CriticalMilestoneChangesWidget);

// component.style = {
//   width: '98%',
// };

export default component;
