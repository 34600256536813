// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';

import LineChart from '../../components/charts/line';
// import CandleBar from '../../components/candle-bar';
import colors from '../../styles/colors';


type Props = {}

type State = {
  selectedFilter: string,
}

type ViewModel = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
};

const styles: Object = {
  widget: {
    width: '50%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  meta: {
    fontSize: '0.85rem',
    position: 'relative',
    top: '-1.9rem',
  },
  candleBar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '30px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const dummyData: any = {
  statsData: {
    constraint_management: {
      max: 100,
      median: 99.4,
      min: 93.8,
    },
    plan_reliability: {
      max: 96.2,
      median: 78.8,
      min: 56.4,
    },
    ppc: {
      max: 96.2,
      median: 88,
      min: 61.5,
    },
    site_readiness: {
      max: 100,
      median: 96.3,
      min: 72.1,
    },
  },
  chartData: [
    {
      confirmed_date: 'Tue, 28 Aug 2018 03:34:00 GMT',
      constraint_management: 95.3,
      end_date: '2018-08-23',
      plan_reliability: 71.5,
      ppc: 90,
      project_id: 1456,
      site_readiness: 83.3,
      start_date: '2018-08-17',
    },
    {
      confirmed_date: 'Fri, 31 Aug 2018 06:25:51 GMT',
      constraint_management: 97,
      end_date: '2018-08-30',
      plan_reliability: 67.9,
      ppc: 87,
      project_id: 1456,
      site_readiness: 80.4,
      start_date: '2018-08-24',
    },
    {
      confirmed_date: 'Fri, 07 Sep 2018 09:11:10 GMT',
      constraint_management: 93.8,
      end_date: '2018-09-06',
      plan_reliability: 56.4,
      ppc: 83.3,
      project_id: 1456,
      site_readiness: 72.1,
      start_date: '2018-08-31',
    },
    {
      confirmed_date: 'Mon, 17 Sep 2018 15:29:15 GMT',
      constraint_management: 76.1,
      end_date: '2018-09-13',
      plan_reliability: 41.9,
      ppc: 51.4,
      project_id: 1456,
      site_readiness: 41.9,
      start_date: '2018-09-07',
    },
    {
      confirmed_date: 'Fri, 21 Sep 2018 07:51:52 GMT',
      constraint_management: 94,
      end_date: '2018-09-20',
      plan_reliability: 63.2,
      ppc: 88.4,
      project_id: 1456,
      site_readiness: 76,
      start_date: '2018-09-14',
    },
    {
      confirmed_date: 'Sat, 29 Sep 2018 05:29:06 GMT',
      constraint_management: 98.5,
      end_date: '2018-09-27',
      plan_reliability: 43.1,
      ppc: 92.1,
      project_id: 1456,
      site_readiness: 51.6,
      start_date: '2018-09-21',
    },
    {
      confirmed_date: 'Sun, 07 Oct 2018 02:18:35 GMT',
      constraint_management: 39.4,
      end_date: '2018-10-04',
      plan_reliability: 5.7,
      ppc: 20.3,
      project_id: 1456,
      site_readiness: 13.2,
      start_date: '2018-09-28',
    },
    {
      confirmed_date: 'Mon, 08 Oct 2018 02:59:03 GMT',
      constraint_management: 100,
      end_date: '2018-10-07',
      plan_reliability: 91.9,
      ppc: 91.9,
      project_id: 1456,
      site_readiness: 100,
      start_date: '2018-10-05',
    },
    {
      confirmed_date: 'Mon, 15 Oct 2018 00:43:28 GMT',
      constraint_management: 97.7,
      end_date: '2018-10-14',
      plan_reliability: 65.1,
      ppc: 85.7,
      project_id: 1456,
      site_readiness: 77.8,
      start_date: '2018-10-08',
    },
    {
      confirmed_date: 'Wed, 24 Oct 2018 03:05:03 GMT',
      constraint_management: 98.1,
      end_date: '2018-10-21',
      plan_reliability: 72,
      ppc: 88,
      project_id: 1456,
      site_readiness: 83.4,
      start_date: '2018-10-15',
    },
    {
      confirmed_date: 'Tue, 30 Oct 2018 08:09:19 GMT',
      constraint_management: 99.2,
      end_date: '2018-10-28',
      plan_reliability: 82.4,
      ppc: 83.7,
      project_id: 1456,
      site_readiness: 99.2,
      start_date: '2018-10-22',
    },
  ],
};

class WeeklyCommitmentReliability extends React.Component<Props, State> {
  static defaultProps= {
    week: '',
  }

  getData(): ViewModel[] {
    const labels: string[] = [];
    const constraintManagement: number[] = [];
    const planReliability: number[] = [];
    const ppc: number[] = [];
    const siteReadiness: number[] = [];
    dummyData.chartData.forEach((item: any) => {
      labels.push(`${moment(item.end_date).format('DD MMM YYYY')} <br> Confirmed on: ${moment(item.confirmed_date).format('DD MMM YYYY')}`);
      constraintManagement.push(item.constraint_management);
      planReliability.push(item.plan_reliability);
      ppc.push(item.ppc);
      siteReadiness.push(item.site_readiness);
    });

    return [
      {
        labels,
        values: constraintManagement,
        name: 'Constraint Management level',
        color: colors.cLevelGray,
      },
      {
        labels,
        values: siteReadiness,
        name: 'Site Readiness level',
        color: colors.themeSecondaryColorNew,
      },
      {
        labels,
        values: ppc,
        name: 'Commitment level (PPC)',
        color: colors.themePrimaryColorNew,
      },
      {
        labels,
        values: planReliability,
        name: 'Construct Plan Reliability (CPR)',
        color: colors.cLevelGreen,
      },
    ];
  }

  /* eslint max-len: ["error", { "ignoreStrings": true, "code": 100}] */
  renderWidget(): React$Element<*> {
    const yAxis: Object = {
      yaxis: {
        ticksuffix: '%',
        rangemode: 'tozero',
        autorange: true,
      },
    };
    if (dummyData.chartData.length === 0) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <div>
        <LineChart
          data={this.getData()}
          yAxes={yAxis}
          legendY={-0.8}
        />
        {/* {dummyData.statsData
          ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={styles.candleBar}>
                <CandleBar
                  color={colors.tundora}
                  data={dummyData.statsData.constraint_management}
                />
                <CandleBar
                  color={colors.cerulean}
                  data={dummyData.statsData.site_readiness}
                />
                <CandleBar
                  color={colors.orange}
                  data={dummyData.statsData.ppc}
                />
                <CandleBar
                  color={colors.aquaForest}
                  data={dummyData.statsData.plan_reliability}
                />
              </span>
            </div>)
          : null } */}
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.widget}>
        <h1>
          Weekly Commitments and Reliability
        </h1>
        {
         this.renderWidget()
        }
      </section>
    );
  }
}

const component: any = compose(connect())(WeeklyCommitmentReliability);

// component.style = {
//   width: '100%',
// };

export default component;
