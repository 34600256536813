// @flow
import * as React from 'react';

import IncidentsBySeverity from './incidents-by-severity';
import KeyPerformanceIndicator from './key-performer-indicator';
import DaysWithoutIncidentWidget from './days-without-incident';
import LossTimeInjuryWidget from './loss-time-injury';
import safety from '../icons/safety.png';

import '../style.css';


type Props = {};

// eslint-disable-next-line react/prefer-stateless-function
class Safety extends React.PureComponent<Props> {
  render(): React.Node {
    return (
      <div className="c-level__widget">
        <h1 className="c-level__header-widget-common">
          <span>
            <img
              width="20"
              src={safety}
              alt="i"
              className="c-level__icon"
            />
          </span>
          Safety and Environment
        </h1>
        <div style={{ display: 'flex' }}>
          <div>
            <LossTimeInjuryWidget />
            <DaysWithoutIncidentWidget />
          </div>
          <KeyPerformanceIndicator />
        </div>
        <div>
          <IncidentsBySeverity />
        </div>
      </div>
    );
  }
}

// Safety.style = {
//   width: '98%',
// };

export default Safety;
