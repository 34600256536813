// @flow

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty, pathOr } from 'ramda';
import ReactTooltip from 'react-tooltip';

import colors from '../../styles/colors';
import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';

import VersionTextMessage from '../../utils/versionText';
import { truncatePercent } from '../../utils/common';

import type { ProjectStatusState } from './reducer';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import './styles.css';

type Action = { type: $Values<typeof ActionTypes>, data: Object };
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ProjectStatusState,
  project: Project,
  versionDates: string,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  statusField: {
    borderColor: colors.themeSecondaryColor,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class ProjectStatusWidget extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch({
      type: ActionTypes.GET_PROJECT_STATUS,
      data: { projectId: this.props.project.id },
    });
  }

  renderWidget(): React$Element<*> {
    const data: Object = this.props.data.data;
    if (this.props.data.loading === false && isEmpty(data)) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <div className="project-status-widget">
        <div className="status-block dates">
          <div style={styles.statusField} className="status-field dates">
            <div className="top">
              <div className="content">
                <div className="date-field">{moment(data.expected_completion_date).date()}</div>
                <div className="month-field">{moment(data.expected_completion_date).format('MMM YY')}</div>
              </div>
            </div>
            <div className="bottom" style={styles.statusFieldBottom}>
              <div className="content">
                <div className="date-field">{moment(data.planned_completion_date).date()}</div>
                <div className="month-field">{moment(data.planned_completion_date).format('MMM YY')}</div>
              </div>
            </div>
          </div>
          <div className="h-separator">
            <div className="separation-text top">Expected</div>
            <div className="separation-text bottom">Planned</div>
          </div>
        </div>
        <div className="status-block status">
          <div style={styles.statusField} className="status-field status">
            <div>
              <div className="icon" />
              <div
                className="status-text"
                style={{ top: data.overall_status === 'Delayed' ? 26 : 38 }}
              >
                {data.overall_status}
              </div>
            </div>
            {data.overall_status === 'Delayed'
            && <div className="weekInfo">
                  {truncatePercent(data.delay_indicator)}
                <div>
                  {data.delay_indicator === 1 ? 'week' : 'weeks'}
                </div>
               </div>
            }
          </div>
          <div className="v-separator">
            <div className="left">
              <span style={{ marginRight: 8 }}>Completion</span>
              <div className="arrow left float-r">
                <div className="arrow-head" />
                <div className="arrow-line" />
              </div>
            </div>
            <div className="right">
              <div className="arrow right float-l">
                <div className="arrow-line" />
                <div className="arrow-head" />
              </div>
              <span style={{ marginLeft: 8 }}>Progress</span>
            </div>
          </div>
        </div>
        <div className="status-block">
          <div style={styles.statusField} className="status-field progress">
            <div className="top">
              <div className="content">
                {`${truncatePercent(data.actual_progress)} %
                ${this.props.offsetPercentage !== null ? '*' : ''}`}
              </div>
            </div>
            <div className="bottom" style={styles.statusFieldBottom}>
              <div className="content">
                {`${truncatePercent(data.planned_progress)} %
                ${this.props.offsetPercentage !== null ? '*' : ''}`}
              </div>
            </div>
          </div>
          <div className="h-separator right">
            <div className="separation-text top">Actual</div>
            <div className="separation-text bottom">Planned</div>
          </div>
        </div>
      </div>
    );
  }

  render(): React$Element<*> {
    const data: Object = this.props.data.data;
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 100}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          {isEmpty(data) ? 'Project Status' : `Project Status - ${data.project_name}`}
{' '}
          <img
            data-tip="Displays the current status of the project with the comparison of expected project completion date vs the planned project completion date.<br />It also displays the current project progress vs the planned project progress"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.renderWidget()}
        {!isEmpty(data) && this.props.offsetPercentage !== null ? (
          <div style={{ fontSize: '0.8rem', marginTop: '25px' }}>
            * Actual and Planned progress values are recalculated based on already
            completed percentage of the project provided in the settings
          </div>
        ) : null}
        {
          this.props.data.loading === false && !isEmpty(data) && !isEmpty(this.props.versionDates)
          && <VersionTextMessage versionDates={this.props.versionDates} />
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: ProjectStatusState } => ({
    data: state.projectStatus,
    offsetPercentage: pathOr(null, ['project', 'projectDetails', 'preferences', 'general', 'offset_percentage'], state),
    versionDates: pathOr({}, ['project', 'versionDates'], state),
  }),
)(ProjectStatusWidget);

component.style = {
  width: '98%',
};

export default component;
