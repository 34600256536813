// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import BarChart from '../../components/charts/bar';
import colors from '../../styles/colors';

import '../style.css';

type Props = {}

type ChartData = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
    marginTop: '1rem',
  },
  table: {
    width: '100%',
    textAlign: 'left',
  },
  taskNameTable: {
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  content: {
    marginTop: '1.5rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

/* eslint-disable */
const getHeight: Object = {
  '1': '140',
  '2': '175',
  '3': '207',
  '4': '240',
  '5': '270',
  '6': '302',
  '7': '334',
  '8': '366',
  '9': '398',
  '10': '430',
};
/* eslint-enable */

const dummyData: any = [
  {
    actual_start_date: '2018-09-19',
    base_start_date: '2017-08-22',
    id: 231377,
    name: 'Dismantle TC2',
    parent: 'Dismantle TC2 | TC2 & TC4 | Tower Crane Dismantling | STRUCTURAL WORK',
    start_date_difference: 389,
    wbs: '1.3.9.2.1',
  },
  {
    actual_start_date: '2018-12-21',
    base_start_date: '2018-07-09',
    id: 233812,
    name: 'Hose reel main pipe installation',
    parent: 'Hose reel main pipe installation | Internal Perimeter | Part 2 (South) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 165,
    wbs: '1.5.1.6.1.2.2.5',
  },
  {
    actual_start_date: '2018-12-17',
    base_start_date: '2018-07-05',
    id: 233810,
    name: 'Base coat painting',
    parent: 'Base coat painting | Internal Perimeter | Part 2 (South) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 145,
    wbs: '1.5.1.6.1.2.2.3',
  },
  {
    actual_start_date: '2018-12-06',
    base_start_date: '2018-06-24',
    id: 233808,
    name: 'Post construction',
    parent: 'Post construction | Internal Perimeter | Part 2 (South) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 135,
    wbs: '1.5.1.6.1.2.2.1',
  },
  {
    actual_start_date: '2018-12-09',
    base_start_date: '2018-06-27',
    id: 233809,
    name: 'Plastering & skimming',
    parent: 'Plastering & skimming | Internal Perimeter | Part 2 (South) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 125,
    wbs: '1.5.1.6.1.2.2.2',
  },
  {
    actual_start_date: '2018-12-06',
    base_start_date: '2018-06-24',
    id: 233828,
    name: 'Post construction',
    parent: 'Post construction | Internal Perimeter | Part 4 (North) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 125,
    wbs: '1.5.1.6.1.4.2.1',
  },
  {
    actual_start_date: '2018-12-17',
    base_start_date: '2018-07-05',
    id: 233830,
    name: 'Base coat painting',
    parent: 'Base coat painting | Internal Perimeter | Part 4 (North) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 125,
    wbs: '1.5.1.6.1.4.2.3',
  },
  {
    actual_start_date: '2018-12-20',
    base_start_date: '2018-07-08',
    id: 233831,
    name: '2nd coat painting',
    parent: '2nd coat painting | Internal Perimeter | Part 4 (North) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 105,
    wbs: '1.5.1.6.1.4.2.4',
  },
  {
    actual_start_date: '2018-12-06',
    base_start_date: '2018-06-24',
    id: 233818,
    name: 'Post construction',
    parent: 'Post construction | Internal Perimeter | Part 3 (West) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 105,
    wbs: '1.5.1.6.1.3.2.1',
  },
  {
    actual_start_date: '2018-12-20',
    base_start_date: '2018-07-08',
    id: 233821,
    name: '2nd coat painting',
    parent: '2nd coat painting | Internal Perimeter | Part 3 (West) | L1 to L5 | Ramp: External Perimeter & Internal Void | Exterior Plastering & Painting | EXTERIOR FAÇADE WORKS',
    start_date_difference: 97,
    wbs: '1.5.1.6.1.3.2.4',
  },
];

class TopMostDelayedActivitiesWidget extends React.PureComponent<Props> {
  getData(): ChartData[] {
    return dummyData.map((activity: any): any => ({
      label: activity.name + activity.wbs,
      value: parseInt(activity.start_date_difference, 10),
      color: colors.themeSecondaryColorNew,
      // text: `${activity.name} - ${activity.start_date_difference}`,
      text: `${activity.wbs} ${activity.name.trim()}<br>Actual start date: ${moment(activity.actual_start_date).format('DD MMM YY')}<br>`
            + `Base start date: ${moment(activity.base_start_date).format('DD MMM YY')}<br>Delayed by: ${activity.start_date_difference} day(s)`,
    }));
  }

  // showActivityDetailModal = (activity: DelayedActivity): null => {
  //   this.props.dispatch({
  //     type: 'showModal',
  //     modalType: 'activityDetail',
  //     modalProps: {
  //       activityId: activity.id,
  //       activityName: activity.name,
  //       delayType: 'start_day',
  //     },
  //   });

  //   return null;
  // }


  renderWidget(options: Object): React.Node {
    return dummyData.length !== 0
      ? (
        <div>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '60%' }}>
              {this.renderTable()}
            </div>
            <div style={{ width: '40%' }}>
              <BarChart data={this.getData()} options={options} dataOptions={{ orientation: 'h' }} />
            </div>
          </div>
        </div>)
      : <span style={styles.noData}>No data available</span>;
  }

  renderTable = (): React.Element<any> => (
    <table className="c-level__table">
      <thead>
        <tr>
          <th style={styles.taskNameTable}>Activity</th>
          <th className="hide-element">Base start date</th>
          <th className="hide-element">Actual start date</th>
          <th>Delay (days)</th>
        </tr>
      </thead>
      <tbody>
        {dummyData.map((activity: any, id: number): React.Node => (
          <tr key={id}>
            <td style={styles.taskNameTable}>{activity.name}</td>
            <td className="hide-element">{moment(activity.base_start_date).format('DD MMM YY')}</td>
            <td className="hide-element">{moment(activity.actual_start_date).format('DD MMM YY')}</td>
            <td>{activity.start_date_difference}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  render(): React.Node {
    const options: Object = {
      height: getHeight[dummyData.length],
      yaxis: {
        showticklabels: false,
      },
      xaxis: {
        title: 'Days delayed (start date)',
      },
      margin: {
        t: 35,
        r: 10,
        l: 3,
      },
    };
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1>
          Top Delayed Activities
        </h1>
        <div style={styles.content}>
          {
            this.renderWidget(options)
          }
        </div>
      </section>
    );
  }
}

const component: any = connect()(TopMostDelayedActivitiesWidget);

component.style = {
  width: '98%',
};

export default component;
