// @flow

export const overallManpower: any = [
  {
    month: 'Jan - 17',
    planned: 0,
    actual: 0,
  },
  {
    month: 'Feb - 17',
    planned: 0,
    actual: 0,
  },
  {
    month: 'Mar - 17',
    planned: 0,
    actual: 0,
  },
  {
    month: 'Apr - 17',
    planned: 0,
    actual: 0.124,
  },
  {
    month: 'May - 17',
    planned: 0,
    actual: 0.253,
  },
  {
    month: 'Jun - 17',
    planned: 0,
    actual: 0.468,
  },
  {
    month: 'Jul - 17',
    planned: 0,
    actual: 0.794,
  },
  {
    month: 'Aug - 17',
    planned: 0,
    actual: 0.924,
  },
  {
    month: 'Sep - 17',
    planned: 0,
    actual: 1.124,
  },
  {
    month: 'Oct - 17',
    planned: 0,
    actual: 1.624,
  },
  {
    month: 'Nov - 17',
    planned: 0,
    actual: 2.124,
  },
  {
    month: 'Dec - 17',
    planned: 0,
    actual: 2.964,
  },
  {
    month: 'Jan - 18',
    planned: 0,
    actual: 3.320,
  },
  {
    month: 'Feb - 18',
    planned: 0,
    actual: 4.124,
  },
  {
    month: 'Mar - 18',
    planned: 0,
    actual: 4.988,
  },
  {
    month: 'Apr - 18',
    planned: 0,
    actual: 5.667,
  },
  {
    month: 'May - 18',
    planned: 0,
    actual: 6.124,
  },
  {
    month: 'Jun - 18',
    planned: 0,
    actual: 6.786,
  },
  {
    month: 'Jul - 18',
    planned: 5.342,
    actual: 7.135,
  },
  {
    month: 'Aug - 18',
    planned: 8.349,
    actual: 7.243,
  },
  {
    month: 'Sep - 18',
    planned: 8.842,
    actual: 7.465,
  },
  {
    month: 'Oct - 18',
    planned: 8.999,
    actual: 7.589,
  },
  {
    month: 'Nov - 18',
    planned: 9.199,
    actual: 7.799,
  },
  {
    month: 'Dec - 18',
    planned: 9.229,
    actual: 8.158,
  },
  {
    month: 'Jan - 19',
    planned: 9.146,
    actual: 0,
  },
  {
    month: 'Feb - 19',
    planned: 9.005,
    actual: 0,
  },
  {
    month: 'Mar - 19',
    planned: 8.805,
    actual: 0,
  },
  {
    month: 'Apr - 19',
    planned: 7.356,
    actual: 0,
  },
  {
    month: 'May - 19',
    planned: 6.130,
    actual: 0,
  },
  {
    month: 'Jun - 19',
    planned: 5.637,
    actual: 0,
  },
  {
    month: 'Jul - 19',
    planned: 5.113,
    actual: 0,
  },
  {
    month: 'Aug - 19',
    planned: 4.226,
    actual: 0,
  },
  {
    month: 'Sep - 19',
    planned: 3.212,
    actual: 0,
  },
  {
    month: 'Oct - 19',
    planned: 2.2222,
    actual: 0,
  },
  {
    month: 'Oct - 19',
    planned: 2.2222,
    actual: 0,
  },
  {
    month: 'Nov - 19',
    planned: 1.469,
    actual: 0,
  },
  {
    month: 'Dec - 19',
    planned: 0.253,
    actual: 0,
  },
];

export const manpowerUtilizationByTradeData: any[] = [
  {
    label: 'Laborers  ',
    value: '96',
  },
  {
    label: 'Masons   ',
    value: '76',
  },
  {
    label: 'Carpenters  ',
    value: '54',
  },
  {
    label: 'Ironworkers  ',
    value: '47',
  },
  {
    label: 'Electricians   ',
    value: 42,
  },
  {
    label: 'Pipefitters  ',
    value: '38',
  },
  {
    label: 'Operators   ',
    value: '15',
  },
  {
    label: 'Welders   ',
    value: '14',
  },
  {
    label: 'Painters  ',
    value: '12',
  },
];
