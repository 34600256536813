// @flow
import * as React from 'react';

import CommitmentReliability from './commitment-reliability';
import WorkableBacklog from './workablebacklog';
import ConcreteCastingProductivity from './concrete-casting-productivity';
import SteelErectionProductivity from './steel-erection-productivity';
import CablePullingProductivity from './cable-pulling-productivity';
import productivity from '../icons/productivity.png';

import '../style.css';


type Props = {};

// eslint-disable-next-line react/prefer-stateless-function
class Productivity extends React.PureComponent<Props> {
  render(): React.Node {
    return (
      <div className="c-level__widget">
        <h1 className="c-level__header-widget-common">
          <span>
            <img
              width="20"
              src={productivity}
              alt="i"
              className="c-level__icon"
            />
          </span>
          Productivity Widgets
        </h1>
        <div>
          <ConcreteCastingProductivity />
          <SteelErectionProductivity />
          <CablePullingProductivity />
        </div>
        <div className="c-level__content-row">
          <CommitmentReliability />
          <WorkableBacklog />
        </div>
      </div>
    );
  }
}

export default Productivity;
