// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { groupBy } from 'ramda';

import BarChart from '../../components/charts/bar';
import ReasonsTable from './reasons-table';
import Legend from './legend';
import colors from '../../styles/colors';
import ToggleButton from '../../components/toggle-button';
import delayTexts from '../../utils/delay-reasons';
import '../style.css';


type Props = {
  dispatch: Dispatch,
  project: Project,
  week: string,
}

type State = {
  selectedFilter: string,
}

type ViewModel = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    overflow: 'auto',
    margin: '0.5rem',
  },
  chart: {
    width: '50%',
    float: 'left',
    marginRight: '2%',
  },
  table: {
    marginTop: 40,
    fontSize: '0.8rem',
    lineHeight: '1.3rem',
    overflowX: 'hidden',
    textAlign: 'left',
  },
  legendContainer: {
    paddingLeft: 40,
    paddingTop: '1rem',
  },
  label: {
    marginRight: 6,
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

const chartDummyData: ReasonForDelayResponseItem[] = [
  {
    type: 'weather',
    classification: 'Unforeseen / external conditions',
    project_delay: 2,
    count: 5,
    artifact_delay: 0,
  },
  {
    type: 'labor',
    classification: 'Avoidable by getting valid commitments',
    project_delay: 0,
    count: 4,
    artifact_delay: 0,
  },
  {
    type: 'equipment',
    classification: 'Avoidable by getting valid commitments',
    project_delay: 0,
    count: 3,
    artifact_delay: 0,
  },
  {
    type: 'material',
    classification: 'Avoidable by making plan reliable and robust',
    project_delay: 3,
    count: 2,
    artifact_delay: 0,
  },
  {
    type: 'access_space',
    classification: 'Avoidable by making plan reliable and robust',
    project_delay: 0,
    count: 2,
    artifact_delay: 0,
  },
  {
    type: 'approval_permits',
    classification: 'Avoidable by making plan reliable and robust',
    project_delay: 1,
    count: 2,
    artifact_delay: 0,
  },
  {
    type: 'drawings',
    classification: 'Avoidable by making plan reliable and robust',
    project_delay: 0,
    count: 2,
    artifact_delay: 0,
  },
  {
    type: 'safety_requirement',
    classification: 'Avoidable by making plan reliable and robust',
    project_delay: 0,
    count: 1,
    artifact_delay: 0,
  },
  {
    type: 'inspection',
    classification: 'Avoidable by making plan reliable and robust',
    project_delay: 0,
    count: 1,
    artifact_delay: 0,
  },
  {
    type: 'first_lookahead',
    classification: 'Avoidable by making plan reliable and robust',
    project_delay: 44,
    count: 1,
    artifact_delay: 0,
  },
];

// const delayTexts: Object = {
//   access_space: 'Access / Space',
//   approval_permits: 'Approval / Permits',
//   client_decision: 'Client decision',
//   contracts: 'Contracts',
//   drawings: 'Drawings',
//   equipment: 'Equipment',
//   inspection: 'Inspection',
//   labor: 'Labor',
//   logistics_storage: 'Logistics / Storage',
//   material: 'Material',
//   method_statement: 'Method statement',
//   minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
//   rfi: 'RFI',
//   safety_requirement: 'Safety requirement',
//   re_scheduling: 'Re-Scheduling ',
//   unforeseen_site_conditions: 'Unforeseen site conditions',
//   weather: 'Weather',
//   schedule_error: 'Schedule error',
//   defects_rework: 'Defects rework',
//   first_lookahead: 'Delay on First Lookahead',
//   others: 'Others',
//   do_list_confirmation: 'Do List Confirmation',
//   change_in_dependency: 'Change In Dependency',
// };

const getFormattedData: Function = function getFormattedData(
  data: any[],
): any[] {
  const list: any[] = data.map(
    (item: any): any => ({
      label: delayTexts[item.type] || item.type,
      value: item.count,
      classification: item.classification,
      projectDelay: item.project_delay,
      artifactDelay: item.artifact_delay,
    }),
  );
  return list;
};


const ColorConfig: Object = {
  'Avoidable by getting valid commitments': colors.themePrimaryColorNew,
  'Avoidable by making plan reliable and robust': colors.themeSecondaryColorNew,
  'Unforeseen / external conditions': colors.newYellow,
};

const TextConstants: Object = {
  'Avoidable by getting valid commitments': 'Preventable by getting valid commitments',
  'Avoidable by making plan reliable and robust': 'Preventable by making plan reliable and robust',
  'Unforeseen / external conditions': 'Unforeseen / external conditions',
};

class ReasonForDelays extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  state = {
    selectedFilter: 'occurences',
  }

  onFilterChange: Function;

  onFilterChange(val: string) {
    this.setState({
      selectedFilter: val,
    }, this.fetchData);
  }

  getChartData(data: any[]): ViewModel[] {
    return data.map((delayItem: any): Object => {
      const getSortFilter: Object = {
        occurences: delayItem.value,
        project_delay: delayItem.projectDelay,
        artifact_delay: delayItem.artifactDelay,
      };
      const value: number = getSortFilter[this.state.selectedFilter];
      return {
        label: delayItem.label,
        value,
        color: ColorConfig[delayItem.classification],
        text: `${TextConstants[delayItem.classification]}<br>A total of ${delayItem.value} occurrence(s) has delayed the project by ${delayItem.projectDelay} day(s)`,
        classificationText: TextConstants[delayItem.classification],
      };
    });
  }

  getLegend(data: ViewModel[]): Array<Object> {
    const byColors: Object = groupBy((item: ViewModel): string => item.color, data);
    return Object.keys(byColors).map((key: string): Object => ({
      color: key,
      label: byColors[key][0].classificationText,
    }));
  }

  getWidget(chartData: ViewModel[]): React.Node {
    return getFormattedData(chartDummyData).length !== 0
      ? (
        <div>
          {/* { this.props.week ?
            <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="delay_classification" />
           : null } */}
          <div style={styles.chart}>
            <BarChart data={chartData} />
            <div style={styles.legendContainer}>
              <Legend legend={this.getLegend(chartData)} />
            </div>
          </div>
          <div style={styles.table}>
            {this.props.week
              ? (
                <ReasonsTable
                  delayReasons={getFormattedData(chartDummyData)}
                  showOthersDelaysModal={this.showOthersDelaysModal}
                  showArtifact
                />)
              : (
                <ReasonsTable
                  delayReasons={getFormattedData(chartDummyData)}
                  showArtifact={false}
                  showOthersDelaysModal={this.showOthersDelaysModal}
                />)}
          </div>
        </div>)
      : <span style={styles.noData}> No data available</span>;
  }

  showOthersDelaysModal = (): null => {
    if (window.__r2d2) {
      return null;
    }
    this.props.dispatch({
      type: 'showModal',
      modalType: 'othersDelays',
      modalProps: {
        projectId: this.props.project.id,
        week: this.props.week,
      },
    });
    return null;
  }


  render(): React.Node {
    const chartData: ViewModel[] = this.getChartData(getFormattedData(chartDummyData));
    const toggleItems: Array<*> = [
      { value: 'occurences', label: 'Occurences' },
      // { value: 'artifact_delay', label: 'Total Delay (days)' },
      { value: 'project_delay', label: 'Total Project Delay (days)' },
    ];
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 200}] */
    return (
      <section style={styles.widget}>
        <div className="c-level__flex">
          <h1>
            Delay Classification
          </h1>
          <div>
            <ToggleButton
              items={toggleItems}
              value={this.state.selectedFilter}
              onChange={this.onFilterChange}
            />
          </div>
        </div>
        {this.getWidget(chartData)
        }
      </section>
    );
  }
}

const component: any = connect()(ReasonForDelays);

component.style = {
  width: '98%',
};

export default component;
