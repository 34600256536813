// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getForceStartedApi from './apis';

export type ForceStartedType = {
  activityId: number,
  taskId?: Number,
  artifactType: string,
  artifactName: string,
  artifactParentChain: string,
  workflowIds: string[],
  reason: String,
  forceStartedAt: Date | string,
  forceStartedBy: String,
};

type ForceStartedResponse = {
  forceStartData: Array<ForceStartedType>,
};

export type ForceStartedAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_FORCE_STARTED_SUCCESS'>,
  data: ForceStartedResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_FORCE_STARTED_FAIL'>,
|};

function* getForceStarted(action: { data: { projectId: string, startDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: ForceStartedResponse } = yield call(getForceStartedApi, action.data.projectId, action.data.startDate);
    const actionData: ForceStartedAction = { type: ActionTypes.GET_FORCE_STARTED_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: ForceStartedAction = { type: ActionTypes.GET_FORCE_STARTED_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_FORCE_STARTED, getForceStarted);
}
