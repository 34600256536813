// @flow
import React from 'react';
import { connect } from 'react-redux';

import TabButton from '../components/tab-button';

import type { Reducers } from '../reducers';

import { FlowDiscipline } from './types';

import widgets from './widgets';

import './index.css';

type State = {
  disciplineId: String;
}

type Props = {
  match: Object,
};

type StoreProps = {
  disciplines: FlowDiscipline[]
}

type CombineProps = Props & StoreProps;

class OverviewFlow extends React.Component<CombineProps, State> {
  static getDerivedStateFromProps(nextProps: CombineProps, prevState: State): any {
    if (!prevState.disciplineId && nextProps.disciplines.length > 0) {
      return {
        disciplineId: nextProps.disciplines[0].id,
      };
    }
    return null;
  }

  constructor(props: CombineProps) {
    super(props);

    this.state = {
      disciplineId: props.disciplines.length > 0 ? props.disciplines[0].id : '',
    };
  }

  handleDisciplineChange = (e: React.ChangeEventHandler<HTMLInputElement>) => {
    this.setState({
      disciplineId: e.target.value,
    });
  }

  renderContent = (): React.ReactNode => {
    if (!this.state.disciplineId) {
      return null;
    }

    return widgets[this.state.disciplineId].map(({ name, widget: Widget }: any): any => (
      <Widget
        key={name}
        projectId={parseInt(this.props.match.params.projectId, 10)}
        companyId={parseInt(this.props.match.params.companyId, 10)}
        disciplineId={this.state.disciplineId}
      />
    ));
  };

  render(): React.ReactNode {
    if (this.props.disciplines.length === 0) {
      return null;
    }

    const tabItems: any = this.props.disciplines.map((d: FlowDiscipline): any => ({ label: d.name, value: d.id }));

    return (
      <div className="flow-container">
        <div className="flow-tabs">
          <TabButton.Group
            items={tabItems}
            onChangeTab={this.handleDisciplineChange}
            selectedValue={this.state.disciplineId}
            name="flow-disciplines"
          />
        </div>
        <div className="flow-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

export default connect((store: Reducers): StoreProps => ({
  disciplines: store.flow.disciplines,
}))(OverviewFlow);
