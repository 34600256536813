// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import type { MapStateToProps, Connector } from 'react-redux';
import type { Reducers } from '../reducers';
import ActivityDetailsModal from '../activity-detail';
import TaskDetailsModal from '../task-detail';
import ConstraintDetailsModal from '../constraint-detail';
import MilestoneDetailsModal from '../milestone-detail';
import DelayDetailsModal from '../delay-detail';
import OthersDelaysModal from '../others-delays';
import FlowDetailModal from '../flow-detail';

import type { ModalsState } from './reducer';

const modals: Object = {
  activityDetail: ActivityDetailsModal,
  taskDetail: TaskDetailsModal,
  constraintDetail: ConstraintDetailsModal,
  milestoneDetail: MilestoneDetailsModal,
  delayDetail: DelayDetailsModal,
  othersDelays: OthersDelaysModal,
  flowDetail: FlowDetailModal,
};

type Props = {
  modals: ModalsState,
};

function RootModal(props: Props): React.Node {
  if (props.modals.modalType === '') return null;

  const Component: any = modals[props.modals.modalType];

  return (
    <Component {...props.modals.modalProps} />
  );
}

type TMapStateToFilter = MapStateToProps<Reducers, any, any>

const mapStateToProps: TMapStateToFilter = (state: Reducers): Object => ({ modals: state.modals });

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(RootModal);

// export default connect((state: Reducers): Object => ({ modals: state.modals }))(RootModal);
