// @flow

import axios from 'axios';
import { getBaseUrl } from '../../config/config';

export function getDateProfile(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/date_profile${startDate ? `?start_date=${startDate}` : ''}`);
}

export function getDateProfileTask(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/task_date_profile?start_date=${startDate}`);
}
