// @flow

import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';
import getProjectWeeklyEarlyLateProgressApi from './apis';

export type ProjectWeeklyEarlyLateProgressResponse = {
  week_start_date: string,
  week_end_date: string,
  planned_progress_early_start: number,
  planned_progress_late_start: number,
  actual_progress: number,
}

// type ProjectWeeklyEarlyLateProgressResponse = {
//   project_start_date: string,
//   weekly_early_late_progress: ProjectWeeklyEarlyLateProgress[]
// };

export type ProjectWeeklyEarlyLateProgressAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_SUCCESS'>,
  data: ProjectWeeklyEarlyLateProgressResponse[],
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_FAIL'>,
|};

function* getProjectWeeklyEarlyLateProgress(action: { data: { projectId: string} }): Generator<IOEffect, void, any> { // eslint-disable-line
  try {
    const response: { data: ProjectWeeklyEarlyLateProgressResponse[] } = yield call(getProjectWeeklyEarlyLateProgressApi, action.data.projectId);
    yield put({ type: ActionTypes.GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_SUCCESS, data: response.data });
  } catch (e) {
    yield put({ type: ActionTypes.GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS_FAIL });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_PROJECT_WEEKLY_EARLY_LATE_PROGRESS, getProjectWeeklyEarlyLateProgress);
}
