// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import '../style.css';

type Props = {}

const styles: Object = {
  widget: {
    // width: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },

  infoBlock: {
    // width: '200px',
    // height: '100px',
    // background: '#34b787',
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // color: '#fff',
  },

  text: {
    fontSize: '25px',
  },
};


class LossTimeInjuryWidget extends React.PureComponent<Props> {
  renderInfoBlock = (): React.Element<any> => (
   <div>
     {/* <div style={styles.text}>1,280,846</div>
     <div className="c-level__marginSmall">Man-hours without</div> */}
     <span>Loss time injury</span>
     {' '}
     <span style={styles.text}>  1,280,846</span>
   </div>
  );

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        {this.renderInfoBlock()}
      </section>
    );
  }
}

const component: any = connect()(LossTimeInjuryWidget);

component.style = {
  width: '31%',
};

export default component;
