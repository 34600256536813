// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import moment from 'moment';

import LineChart from '../../components/charts/line';
// import CandleBar from '../../components/candle-bar';
import colors from '../../styles/colors';
import WorkData from './data';


type Props = {}

type State = {
  selectedFilter: string,
}

type ViewModel = {
  labels: string[],
  values: number[],
  name: string,
  color?: string,
  texts?: string[],
};

const styles: Object = {
  widget: {
    width: '98%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem',
  },
  meta: {
    fontSize: '0.85rem',
    position: 'relative',
    top: '-1.9rem',
  },
  candleBar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '30px',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

class ConcretePouringWidget extends React.Component<Props, State> {
  static defaultProps= {
    week: '',
  }

  getData(): ViewModel[] {
    const labels: string[] = [];
    const constraintManagement: number[] = [];
    const planReliability: number[] = [];
    const ppc: number[] = [];
    const siteReadiness: number[] = [];
    const extra: number[] = [];
    WorkData.forEach((item: any) => {
      labels.push(item.month);
      constraintManagement.push(item.constraint_management);
      planReliability.push(item.plan_reliability);
      ppc.push(item.ppc);
      siteReadiness.push(item.site_readiness);
      extra.push(item.extra);
    });

    return [
      {
        labels,
        values: constraintManagement,
        name: 'PLANNED - Feasibility by piping dept',
        color: colors.cLevelGray,
      },
      {
        labels,
        values: planReliability,
        name: 'PLANNED - Feasibility to support production',
        color: colors.cLevelRed,
      },
      {
        labels,
        values: ppc,
        name: 'PLANNED - Production',
        color: colors.themeSecondaryColorNew,
      },
      {
        labels,
        values: siteReadiness,
        name: 'ACTUAL - Feasibility',
        color: colors.themePrimaryColorNew,
      },
      {
        labels,
        values: extra,
        name: 'ACTUAL - Production',
        color: colors.cLevelGreen,
      },
    ];
  }

  /* eslint max-len: ["error", { "ignoreStrings": true, "code": 100}] */
  renderWidget(): React$Element<*> {
    const yAxis: Object = {
      yaxis: {
        rangemode: 'tozero',
        autorange: true,
        title: 'DIA INCH',
        titlefont: {
          size: 12,
        },
      },
    };
    if (WorkData.length === 0) {
      return (
        <span style={styles.noData}>
        No data available
        </span>
      );
    }
    return (
      <div>
        <LineChart
          data={this.getData()}
          yAxes={yAxis}
          legendY={-0.8}
        />
      </div>
    );
  }

  render(): React.Node {
    return (
      <section style={styles.widget}>
        <h1>
          Concrete Pouring
        </h1>
        {
         this.renderWidget()
        }
      </section>
    );
  }
}

const component: any = compose(connect())(ConcretePouringWidget);

component.style = {
  width: '98%',
};

export default component;
