// @flow
import { takeLatest, put, call } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';
import ActionTypes from './action-types';

import { getDisciplineMasterListApi } from './apis';

function* getMasterDisciplineList(action: { type: any, payload: { projectId: string, companyId: string } }): Generator<IOEffect, void, any> {
  try {
    const projectId: number = parseInt(action.payload.projectId, 10);
    const companyId: number = parseInt(action.payload.companyId, 10);

    const response: { data: any } = yield call(getDisciplineMasterListApi, { projectId, companyId });

    yield put({ type: ActionTypes.GET_DISCIPLINE_MASTER_LIST_SUCCESS, data: response.data.disciplines });
  } catch (e) {
    console.log(e);
    // NOTE: when the api fails just send empty data to store
    yield put({ type: ActionTypes.GET_DISCIPLINE_MASTER_LIST_SUCCESS, data: [] });
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_DISCIPLINE_MASTER_LIST, getMasterDisciplineList);
}
