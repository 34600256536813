// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getDeletedWorkflowApi from './apis';

type DependentArtifactsType = {
  artifactType: string,
  id: number,
  name: string,
  parentChain: string,
  wbs: string,
  sequenceNo: string,
}

type LinkedWorkFlowsType = {
  deletedAt: string,
  deletedBy: string,
  id: string,
  isoNumber: string,
  name: string,
  serialNo: string,
  dependentArtifacts: DependentArtifactsType[],
}


type DeletedWorkflowsResponse = {
  artifacts: Array<LinkedWorkFlowsType>,
};

export type DeletedWorkflowsAction = {|
  +type: $PropertyType<typeof ActionTypes, 'GET_DELETD_WORKFLOW_SUCCESS'>,
  data: DeletedWorkflowsResponse,
|} | {|
  +type: $PropertyType<typeof ActionTypes, 'GET_DELETD_WORKFLOW_FAIL'>,
|};

function* getDeletedWorkflows(action: { data: { projectId: string, startDate: string, endDate: string } }): Generator<IOEffect, void, any> {
  try {
    const response: { data: DeletedWorkflowsResponse } = yield call(getDeletedWorkflowApi, action.data.projectId, action.data.startDate, action.data.endDate);
    const actionData: DeletedWorkflowsAction = { type: ActionTypes.GET_DELETD_WORKFLOW_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: DeletedWorkflowsAction = { type: ActionTypes.GET_DELETD_WORKFLOW_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_DELETD_WORKFLOW, getDeletedWorkflows);
}
