// @flow

export type ForceStarted = {
  GET_FORCE_STARTED: 'getForceStarted',
  GET_FORCE_STARTED_SUCCESS: 'getForceStartedSuccess',
  GET_FORCE_STARTED_FAIL: 'getForceStartedFail',
}

const actionTypes: ForceStarted = {
  GET_FORCE_STARTED: 'getForceStarted',
  GET_FORCE_STARTED_SUCCESS: 'getForceStartedSuccess',
  GET_FORCE_STARTED_FAIL: 'getForceStartedFail',
};

export default actionTypes;
