// @flow
import * as React from 'react';

import '../style.css';
import ManpowerUtilizationByTradeWidget from './manpower-utilization--by-trade';
import OverallProjectManpowerWidget from './overall-project-manpower';
import ManpowerWidget from './manpower';
import manPower from '../icons/manPower.png';


type Props = {
  // dispatch: Function,
  // match: Object,
  // location: Object,
  // project: Object,
};

// eslint-disable-next-line react/prefer-stateless-function
class Manpower extends React.PureComponent<Props> {
  render(): React.Node {
    return (
      <div className="c-level__widget">
        <h1 className="c-level__header-widget-common">
          <span>
            <img
              width="20"
              src={manPower}
              alt="i"
              className="c-level__icon"
            />
          </span>
          Manpower
        </h1>
        <div className="c-level__section">
          <OverallProjectManpowerWidget />
          <ManpowerUtilizationByTradeWidget />
        </div>
        <ManpowerWidget />
      </div>
    );
  }
}

// Manpower.style = {
//   width: '98%',
// };

export default Manpower;
