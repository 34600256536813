// @flow

import axios from 'axios';
import { getBaseUrl } from '../../config/config';

export function getDurationProfile(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/duration_profile${startDate ? `?start_date=${startDate}` : ''}`);
}

export function getDurationProfileTask(projectId: string, startDate: string): Promise<Object> {
  return axios.get(`${getBaseUrl()}projects/${projectId}/insights/task_duration_profile?start_date=${startDate}`);
}
