// @flow

export const keyPerformanceIndicatorData: any[] = [
  {
    indicator: 'Number of LTI',
    target: '0',
    cutoff: '1',
  },
  {
    indicator: 'Total Recordable Incident Frequency(TRIF)',
    target: '1',
    cutoff: '1.3',
  },
  {
    indicator: 'Number of Medical Treatments',
    target: '3',
    cutoff: '2',
  },
  {
    indicator: 'Number of First Aids',
    target: '10',
    cutoff: '16',
  },
  {
    indicator: 'Number of Near Misses',
    target: '50',
    cutoff: '78',
  },
];

export const DaysWithoutIncidentData: any[] = [
  {
    value: '500',
    color: '#e9614a',
  },
  {
    value: '1000',
    color: '#fc6621',
  },
];


export const incidentsBySeverityData: any[] = [
  {
    label: 'Fatality  ',
    value: '1',
  },
  {
    label: 'High  ',
    value: '3',
  },
  {
    label: 'Medium  ',
    value: '4',
  },
  {
    label: 'Low  ',
    value: '18',
  },
];


export const incidentsByTypeData: any[] = [
  {
    label: 'Permit voilation  ',
    value: '12',
  },
  {
    label: 'Environmental spill  ',
    value: '9',
  },
  {
    label: 'Property damage  ',
    value: '6',
  },
  {
    label: 'Laceration  ',
    value: '5',
  },
  {
    label: 'Fire  ',
    value: '4',
  },
  {
    label: 'Motor vehicle incident   ',
    value: '2',
  },
  {
    label: 'Fall from height   ',
    value: '1',
  },
];

export const incidentsByTradeData: any[] = [
  {
    label: 'Laborers  ',
    value: '21',
  },
  {
    label: 'Ironworkers  ',
    value: '13',
  },
  {
    label: 'Painters  ',
    value: '11',
  },
  {
    label: 'Pipefitters  ',
    value: '9',
  },
  {
    label: 'Carpenters  ',
    value: '4',
  },
  {
    label: 'Welders   ',
    value: '4',
  },
  {
    label: 'Masons   ',
    value: '3',
  },
  {
    label: 'Electricians   ',
    value: '3',
  },
  {
    label: 'Operators   ',
    value: '2',
  },
];
