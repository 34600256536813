// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { IOEffect } from 'redux-saga/effects';

import ActionTypes from './action-types';

import getCommitmentReliabilityApi from './apis';

export type CompanyData = {
  canDo: number;
  commitment: number;
  companyName?: string;
  constraintManagement: number;
  cpr: number;
  did: number;
  shouldDo: number;
  siteReadiness: number;
  willDo: number;
};

export type CommitmentReliabilityResponse = {
  allCompanies: CompanyData;
  companies: CompanyData[];
};

export type CommitmentReliabilitySuccessType = Array<CommitmentReliabilityResponse>;

export type CommitmentReliabilityAction = {|
  type: typeof ActionTypes.GET_COMMITMENT_RELIABILITY_SUCCESS,
  data: CommitmentReliabilitySuccessType,
|} | {|
  type: typeof ActionTypes.GET_COMMITMENT_RELIABILITY_FAIL,
|};

function* getCommitmentReliability(action: { data: { projectId: string, startDate: string, endDate: string} }): Generator<IOEffect, void, any> {
  try {
    const response: { data: CommitmentReliabilitySuccessType } = yield call(getCommitmentReliabilityApi, action.data.projectId, action.data.startDate, action.data.endDate);
    const actionData: CommitmentReliabilityAction = { type: ActionTypes.GET_COMMITMENT_RELIABILITY_SUCCESS, data: response.data };
    yield put(actionData);
  } catch (e) {
    const actionData: CommitmentReliabilityAction = { type: ActionTypes.GET_COMMITMENT_RELIABILITY_FAIL };
    yield put(actionData);
  }
}

export default function* root(): Generator<IOEffect, void, any> {
  yield takeLatest(ActionTypes.GET_COMMITMENT_RELIABILITY, getCommitmentReliability);
}
