// @flow
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Loader from '../../components/loader';
import LineChart from '../../components/charts/line';

import colors from '../../styles/colors';

import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import { CostsByPeriod as CostsByPeriodType } from './sagas';

import { CostProgressState } from './reducer';
import type { Reducers } from '../../reducers';

import ActionTypes from './action-types';

import withProjectContext from '../../auth/with-project-context';

type Action = { type: $Values<typeof ActionTypes>, data: Object }

type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: CostProgressState,
  project: Project,
};

type State = {
  selectedFilter: string,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    overflow: 'auto',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

// const ColorConfig: Object = {
//   'Avoidable by getting valid commitments': colors.themePrimaryColor,
//   'Avoidable by making plan reliable and robust': colors.themeSecondaryColor,
//   'Unforeseen / external conditions': colors.yellow,
// };

// const TextConstants: Object = {
//   'Avoidable by getting valid commitments': 'Preventable by getting valid commitments',
//   'Avoidable by making plan reliable and robust': 'Preventable by making plan reliable and robust',
//   'Unforeseen / external conditions': 'Unforeseen / external conditions',
// };

class CostProgress extends React.Component<Props, State> {
  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_COST_TIME,
      data: { projectId: this.props.project.id },
    });
  }

  getData= (): ViewModel[] => {
    const labels: string[] = [];

    const estimatedValue: number[] = [];

    const plannedValue: number[] = [];


    (this.props.data.chartData.costsByPeriod || []).forEach((d: CostsByPeriodType) => {
      labels.push(moment(d.periodEndDate).format('DD MMM YYYY'));
      estimatedValue.push(d.estimatedValue);
      plannedValue.push(d.plannedValue);
    });

    // add more planned data till project end date
    (this.props.data.chartData.plannedCostsByPeriod || []).forEach((d: CostsByPeriodType) => {
      labels.push(moment(d.periodEndDate).format('DD MMM YYYY'));
      plannedValue.push(d.plannedValue);
    });

    return [
      {
        labels,
        values: estimatedValue,
        name: 'Estimated Value',
        color: colors.themePrimaryColor,
        // dash: 'dot',
        type: 'scatter',
      },
      {
        labels,
        values: plannedValue,
        name: 'Planned Value',
        color: colors.themeSecondaryColor,
        // yaxis: 'y2',
        type: 'scatter',
      },
    ];
  }

  getOptions(): any {
    const lastValue: CostsByPeriodType = (this.props.data.chartData.costsByPeriod || [])[(this.props.data.chartData.costsByPeriod || []).length - 1];

    const label: string = moment(lastValue.periodEndDate).format('DD MMM YYYY');

    const options: any = {
      yaxis: {
        title: 'Cost',
        showline: true,
      },
      xaxis: {
        title: 'Time',
        showline: true,
      },
      shapes: [
        {
          type: 'line',
          x0: label,
          y0: 0,
          x1: label,
          y1: lastValue.plannedValue,
          line: {
            color: colors.cLevelGray,
            width: 1.5,
            dash: 'dot',
          },
        },
      ],
    };

    return options;
  }

  getWidget(): React.Node {
    if (!this.props.data.chartData || !this.props.data.chartData.costsByPeriod || this.props.data.chartData.costsByPeriod.length === 0) {
      return <span style={styles.noData}> No data available</span>;
    }

    const week: CostsByPeriodType = this.props.data.chartData.costsByPeriod.find((c: CostsByPeriodType): CostsByPeriodType => c.periodKey === this.props.data.chartData.scheduleVarianceMeasureWeekID);

    return (
      <div>
        <div>{`Schedule Variance is ${this.props.data.chartData.scheduleVariance.toFixed(2)} as of ${week ? moment(week.periodEndDate).format('DD MMM YYYY') : ''}`}</div>
        <LineChart data={this.getData()} options={this.getOptions()} />
      </div>
    );
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 200}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Cost Progress
          <img
            data-tip="Displays the comparison between the planned(baseline) cost and the estimate(earned) cost of the completed work"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>

        <ReactTooltip place="top" type="dark" effect="solid" border multiline />

        {this.props.data.loading === null ? <Loader /> : this.getWidget()}
      </section>
    );
  }
}

const component: any = compose(
  connect((state: Reducers): { data: CostProgressState, } => (
    {
      data: state.CostProgress,
    }
  )),
  withProjectContext,
)(CostProgress);

component.style = {
  width: '98%',
};

export default component;
