// @flow

import * as React from 'react';
import { connect } from 'react-redux';

import BarChart from '../../components/charts/bar';
import colors from '../../styles/colors';
import { manpowerUtilizationByTradeData } from './data';

type Props = {}

type ChartData = {
  label: string,
  value: number,
  color: string,
  text: string,
};

const styles: Object = {
  widget: {
    width: '50%',
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
    margin: '0.5rem 0 0.5rem 0.5rem',
  },


  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};

/* eslint-disable */
const getHeight: Object = {
  '1': '140',
  '2': '175',
  '3': '207',
  '4': '240',
  '5': '270',
  '6': '302',
  '7': '334',
  '8': '366',
  '9': '398',
  '10': '430',
};
/* eslint-enable */
type State = {
  selectedFilter: string,
}

class ManpowerUtilizationByTradeWidget extends React.PureComponent<Props, State> {
  getData(): ChartData[] {
    return (
      manpowerUtilizationByTradeData.map((item: any): any => ({
        label: item.label,
        value: item.value,
        color: colors.cLevelGreen,
        text: item.value,
      })));
  }

  renderWidget(options: Object): React.Node {
    return (
      <div>
        <div>
          <div>
            <BarChart data={this.getData()} options={options} dataOptions={{ orientation: 'h', textposition: 'outside', hoverinfo: 'none' }} />
          </div>
        </div>
      </div>
    );
  }

  render(): React.Node {
    const options: Object = {
      height: 5,
      yaxis: {
        showticklabels: true,
      },
      xaxis: {
        showticklabels: false,
        showgrid: false,
      },
      margin: {
        t: 35,
        r: 10,
        l: 150,
        b: 20,
      },
    };
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <div style={styles.flex}>
          <h1>
            Manpower Utilization by Trade
          </h1>
        </div>
        {
          this.renderWidget(options)
        }
      </section>
    );
  }
}

const component: any = connect()(ManpowerUtilizationByTradeWidget);

// component.style = {
//   width: '98%',
// };

export default component;
