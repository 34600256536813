// @flow
import * as React from 'react';
// import {
//   Route, Link, Redirect, Switch,
// } from 'react-router-dom';
// import { Route, Redirect, Switch } from 'react-router-dom';
import type { MapStateToProps, Connector } from 'react-redux';

import { connect } from 'react-redux';

import Header from './header';
import Plan from './plan';
import Productivity from './productivity';
import Progress from './progress';
import Work from './work';
import Safety from './safety';
import Manpower from './manpower';
import Navigation from './navigation';

// import Loader from '../components/loader';

import './style.css';

// import ProjectActions from '../project/action-types';
import type { Reducers } from '../reducers';

// const styles: Object = {
//   content: {
//     // paddingTop: '3rem',
//   },
// };

type Props = {
  // dispatch: Function,
  // match: Object,
  // location: Object,
  // project: Object,
};

type State = {
  isFullMenu: boolean;
}

class Home extends React.PureComponent<Props, State> {
  sectionRefs: Object= {};

  state: State = {
    isFullMenu: true,
  }

  componentWillMount() {
    // this.props.dispatch({
    //   type: ProjectActions.GET_PROJECT_DETAILS,
    //   data: { projectId: this.props.match.params.projectId },
    // });
  }

  getSectionRef= (ref: any, name: string) => {
    // console.log({ ref, name });
    this.sectionRefs[name] = ref;
    // console.log(this.refss);
  }

  getContent(): React.Node {
    // if (!this.props.project) {
    //   return <div style={styles.content}><Loader /></div>;
    // }

    return (
      <div
        className={`c-level__widget-container ${this.state.isFullMenu ? 'menuOpen' : ''}`}
        style={
        { width: this.state.isFullMenu ? 'calc(100% - 220px)' : 'calc(100% - 60px)' }}
      >
        <div>
          <Header />
        </div>
        <div id="progress" ref={(el: any): void => this.getSectionRef(el, 'progress')}>
          <Progress />
        </div>
        <div id="productivity" ref={(el: any): void => this.getSectionRef(el, 'productivity')}>
          <Productivity />
        </div>
        <div id="plan" ref={(el: any): void => this.getSectionRef(el, 'plan')}>
          <Plan />
        </div>
        <div id="safety" ref={(el: any): void => this.getSectionRef(el, 'safety')}>
          <Safety />
        </div>
        <div id="manpower" ref={(el: any): void => this.getSectionRef(el, 'manpower')}>
          <Manpower />
        </div>
        <div id="work" ref={(el: any): void => this.getSectionRef(el, 'work')}>
          <Work />
        </div>
      </div>
    );
  }

  toggleMenu = () => {
    this.setState((prevState: State): any => ({ isFullMenu: !prevState.isFullMenu }));
  }

  render(): React.Node {
    return (
      <div className="c-level__widge_body">
        <Navigation refs={this.sectionRefs} isFullMenu={this.state.isFullMenu} toggleMenu={this.toggleMenu} />
        {this.getContent()}
      </div>
    );
  }
}

// export default connect(
//   (store: Reducers): Object => ({
//     project: store.project.projectDetails,
//     projectAllWeeks: store.project.projectAllWeeks,
//   }),
// )(Home);

type TMapStateToFilter = MapStateToProps<Reducers, any, any>;

const mapStateToProps: TMapStateToFilter = (store: Reducers): Object => ({
  project: store.project.projectDetails,
  // projectAllWeeks: store.project.projectAllWeeks,
});

const connector: Connector<any, Props> = connect(mapStateToProps);

export default connector(Home);
