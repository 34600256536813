// @flow

import * as React from 'react';

import BarChart from '../../components/charts/bar';
import colors from '../../styles/colors';

type Props = {
  data: CompanyData,
}

type ViewModel = Array<{
  label: string,
  value: number,
  color: string,
  text: string,
}>;

type State = {
  companyId: number | string,
};

class WorkableBacklogWeekly extends React.PureComponent<Props, State> {
  getFormattedData(data: CompanyData): ViewModel {
    const shouldDo: number = data.shouldDo;
    const canDo: number = data.canDo;
    const willDo: number = data.willDo;
    const done: number = data.did;
    const traces: ViewModel = [
      {
        label: `(${shouldDo})<br />Should do`,
        value: shouldDo,
        text: `${shouldDo} Should Do`,
        color: colors.tundora,
      },
      {
        label: `(${canDo})<br />Can do`,
        value: canDo,
        text: `${canDo} Can do`,
        color: colors.cerulean,
      },
      {
        label: `(${willDo})<br />Will do`,
        value: willDo,
        text: `${willDo} Will Do`,
        color: colors.orange,
      },
      {
        label: `(${done})<br />Did`,
        value: done,
        text: `${done} Did`,
        color: colors.aquaForest,
      },
    ];

    return traces;
  }

  getWorkableBacklogGraph = this.getWorkableBacklogGraph.bind(this);

  getWorkableBacklogGraph(options: Object): React.Node {
    const data: CompanyData = this.props.data;
    return (
      <div>
        <BarChart
          data={this.getFormattedData(data)}
          options={options}
        />
      </div>
    );
  }

  renderWidget(): React$Element<*> {
    const data: CompanyData = this.props.data;
    const options: Object = {
      height: 340,
      tickangle: '0',
      margin: {
        t: 10,
        l: 10,
        r: 10,
        b: 60,
        pad: 12,
      },
      xaxis: {
        fixedrange: true,
        tickfont: {
          size: 12,
        },
      },
      yaxis: {
        zeroline: !(data.shouldDo === 0 && data.canDo === 0 && data.willDo === 0 && data.did === 0),
        showgrid: false,
        showticklabels: false,
        fixedrange: true,
      },
    };
    return (
      this.getWorkableBacklogGraph(options)
    );
  }

  render(): React.Node {
    return (
      this.renderWidget()
    );
  }
}

const Component: any = (WorkableBacklogWeekly);


export default Component;
