// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import ActionTypes from './action-types';

import type { Project } from '../../auth/with-project-context';
import Loader from '../../components/loader';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';

import type { ForceStartedState } from './reducer';
import type { ForceStartedType } from './sagas';
import type { Reducers } from '../../reducers';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ForceStartedState,
  project: Project,
};

const styles: Object = {
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  table: {
    width: '100%',
  },
  taskNameTable: {
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  parentHierarchy: {
    fontSize: '0.70rem',
    marginTop: '0.2rem',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
};


/* eslint-enable */

class ForceStartedWidget extends React.PureComponent<Props> {
  componentDidMount() {
    this.fetchData();
  }

  getParentHierarchy(artifact: ForceStartedType): string {
    const parents: Array<string> = artifact.artifactParentChain.split(' | ').reverse();
    parents.pop();
    return parents.join(' ➔ ');
  }


  showFlowDetailModal = (artifact: ForceStartedType) => {
    if (artifact.workflowIds) {
      this.props.dispatch({
        type: 'showModal',
        modalType: 'flowDetail',
        modalProps: {
          artifact,
          closeModal: this.closeModal,
        },
      });
    }
  };

  closeModal = () => {
    if (this.props.dispatch) {
      this.props.dispatch({
        type: 'hideModal',
      });
    }
  }

  getWorkflowList(workflowsList: string[]): string {
    const workflows: string[] = workflowsList.map(
      (workflow: string): string => {
        if (workflow.name === '') {
          return '';
        }
        return `${workflow.serialNo} ${workflow.name}`;
      },
    );
    return [...workflows].join(', ');
  }


  fetchData() {
    this.props.dispatch({
      type: ActionTypes.GET_FORCE_STARTED,
      data: {
        projectId: this.props.project.id,
      },
    });
  }

  renderTable(): React.Element<any> {
    return (
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.taskNameTable}>Activities/ Tasks</th>
            {/* <th className="hide-element">Linked workflows</th> */}
            <th className="hide-element">Reason</th>
            <th className="hide-element">Force started on</th>
            <th className="hide-element">Force started by</th>
          </tr>
        </thead>
        <tbody>
          {this.props.data.forceStartData.map(
            (artifact: ForceStartedType, i: number): React.Node => (
              <tr key={i}>
                <td style={styles.taskNameTable}>
                  <a
                    onClick={this.showFlowDetailModal.bind(this, artifact)}
                    className="underline-hover"
                  >
                    {artifact.artifactName}
                  </a>
                </td>
                {/* <td className="hide-element">{this.getWorkflowList(artifact.workflows)}</td> */}
                <td className="hide-element">{artifact.reason}</td>
                <td className="hide-element">{moment(artifact.forceStartedAt).format('DD MMM YYYY')}</td>
                <td className="hide-element">{artifact.forceStartedBy}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  }

  renderWidget(): React.Node {
    return this.props.data.forceStartData && this.props.data.forceStartData.length !== 0 && this.props.data.loading === false
      ? (
        <div>
          <div>
            {this.renderTable()}
          </div>
        </div>) : <span style={styles.noData}>No data available</span>;
  }

  render(): React.Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        <h1 className="header-widget-common">
          Force Started
          <img
            data-tip="This table shows the list of activities that are force started. This caused unlinking the corresponding workflows that are 'Yet to be started' and 'In-progres'"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {
          this.props.data.loading === null ? <Loader /> : this.renderWidget()
        }
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: ForceStartedState } => ({
    data: state.forceStarted,
  }),
)(ForceStartedWidget);

component.style = {
  width: '98%',
};

export default component;
