// @flow
import * as React from 'react';

import ConcretePouringWidget from './concrete-pouring';
import SteelStructureWidget from './steel-structure';
import PipingFabricationWidget from './piping-fabrication';
import PipingErectionWidget from './piping-erection';
import PaintingsWidget from './paintings';
import OtherWidget from './other';
import work from '../icons/workFront.png';

import '../style.css';


type Props = {
  // dispatch: Function,
  // match: Object,
  // location: Object,
  // project: Object,
};

// eslint-disable-next-line react/prefer-stateless-function
class Work extends React.PureComponent<Props> {
  render(): React.Node {
    return (
      <div className="c-level__widget">
        <h1 className="c-level__header-widget-common">
          <span>
            <img
              width="20"
              src={work}
              alt="i"
              className="c-level__icon"
            />
          </span>
          Work Widgets
        </h1>
        <div className="c-level__content-row">
          <ConcretePouringWidget />
          <SteelStructureWidget />
        </div>
        <div className="c-level__content-row">
          <PipingFabricationWidget />
          <PipingErectionWidget />
        </div>
        <div className="c-level__content-row">
          <PaintingsWidget />
          <OtherWidget />
        </div>
      </div>
    );
  }
}

// Work.style = {
//   width: '98%',
// };

export default Work;
