/* eslint-disable import/prefer-default-export */
// @flow
export const categories: any = [
  {
    id: 'f72b69d0-d174-4ad6-9733-a146bfd89a9f',
    name: 'Authority Approval',
  },
  {
    id: '89962f9d-ac2d-4e77-9dc6-be2de17d9efb',
    name: 'Client Approval',
  },
  {
    id: 'e4ad82aa-a8c5-4205-827a-a2b76023165e',
    name: 'Internal Approval',
  },
  {
    id: 'dd75aa39-d9d7-41fa-a6ce-90690f8b1d75',
    name: 'RFI',
  },
  {
    id: '84b92782-16ee-4487-bc2a-c26cb625c95',
    name: 'Third Party Agreement',
  },
  {
    id: 'a58b523a-ee79-4bbe-b34f-fbda7eff7f3a',
    name: 'Authority/Client Approval',
  },
  {
    id: 'ecfb0047-56f7-429e-bc2e-2ac5dbe6b9e8',
    name: 'Material Quality Inspection',
  },
  {
    id: 'ada5dd05-2701-4d20-a68a-d3c76bb82d70',
    name: 'Mock-up Inspection',
  },
  {
    id: '160522dd-c24d-4201-9079-a35ead78a98f',
    name: 'Performance/Defects Inspection (M&E, Finishings, Archi Works)',
  },
  {
    id: 'b47b318e-6eef-4762-9545-04a53d22b102',
    name: 'Site Work Inspections (for Structural & MEP Works)',
  },
  {
    id: '6c9ec064-3d08-469f-a3c9-d7ce04924751',
    name: 'Testing & Commissioning (for MEP works)',
  },
  {
    id: '7aa9c7a0-044d-4769-8a26-63b8789a084a',
    name: 'Authority/Client Approval',
  },
  {
    id: '6b272d24-9025-49b1-9988-6d027d8d841c',
    name: 'Equipment/Machinery Inspection',
  },
  {
    id: '08fafc2f-0e95-4b5a-ae14-4a04516fe746',
    name: 'Incident Management',
  },
  {
    id: 'bb241247-8595-42e6-a60f-b19a36e89104',
    name: 'PTW',
  },
  {
    id: 'c36f5106-3ad7-4802-9706-51c7da6e08a6',
    name: 'Site Safety Inspection',
  },
  {
    id: '5b7b20f2-dc8f-48bb-a4ec-de19d3e186a4',
    name: 'SSHE Safety Forms & Checklist',
  },
  {
    id: '1faf628b-444f-4b6f-9707-87a46ef86ed2',
    name: 'Architectural Works',
  },
  {
    id: 'd4b45ae0-96c3-400d-b816-9d37af09e102',
    name: 'Authority/Client Approval',
  },
  {
    id: '21422ed7-c1b0-4d9a-8272-9168d43dd113',
    name: 'Civil Works',
  },
  {
    id: '680767c2-38d1-4c38-b960-a255972dff56',
    name: 'Development Control(DC) & Building Plan(BP) for Building Works',
  },
  {
    id: '8b40ce9d-26f4-4b87-be2e-24a70fd26ecd',
    name: 'Geo-technical Building Works',
  },
  {
    id: '9473c3b8-15f8-473d-83ff-ff226e5123ca',
    name: 'Layout Plan Submission for Road works',
  },
  {
    id: '5ad90b22-a8ee-42cd-8e4f-1a5bc5a8cf38',
    name: 'M&E Works',
  },
  {
    id: '1fc359ef-396b-4697-a45d-3a19e45f2a2f',
    name: 'Structural works',
  },
  {
    id: '3425340f-c01f-4c4c-8ee6-c6ac19bcc427',
    name: 'Temporary Structures',
  },
  {
    id: 'dc2780dd-c3b7-4c52-b8e4-f3e6bf44e896',
    name: 'VDC coordination',
  },
];
