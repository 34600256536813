// @flow

import React, { Fragment } from 'react';
import type { Node, Element } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import ActionTypes from './action-types';
import infoIcon from '../../styles/icons/Dialog-Box-About-24.png';
import Loader from '../../components/loader';
import DelayDetail from '../../delay-detail';
// import ExportButton from '../../components/export';

import type { ConstraintDelayState } from './reducer';
import type { ConstraintDelay } from './sagas';
import type { Reducers } from '../../reducers';
import type { Project } from '../../auth/with-project-context';

import delayTexts from '../../utils/delay-reasons';

import './styles.css';

type Action = {
  type: $Values<typeof ActionTypes> | 'showModal',
  data?: Object,
};
type Dispatch = (action: Action) => void;

type Props = {
  dispatch: Dispatch,
  data: ConstraintDelayState,
  project: Project,
  weekId: number,
  week: string,
};

type State = {
  order: string,
  column: string,
  showDetail: boolean,
  detailViewArtifact: number | null,
};

const styles: Object = {
  // header: {
  //   fontSize: '1.2rem',
  //   textAlign: 'center',
  //   marginBottom: '2rem',
  // },
  widget: {
    border: '1px solid rgb(220, 220, 220)',
    padding: '1rem',
    background: '#fff',
    boxShadow: '0 0 6px 3px rgba(156, 156, 156, 0.1)',
  },
  table: {
    width: '100%',
  },
  arrowUp: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #2f2f2f',
    fontSize: '0',
    lineHeight: '0',
    position: 'absolute',
    marginLeft: '4px',
    marginTop: '8px',
  },
  deletedGrey: {
    color: '#6f6f6f',
    pointerEvents: 'none',
  },
  parentHierarchy: {
    fontSize: '0.65rem',
    marginTop: '0.2rem',
  },
  reason: {
    textTransform: 'capitalize',
  },
  activityName: {
    fontSize: '0.3rem',
  },
  cause: {
    fontSize: '0.7rem',
  },
  causeName: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
  note: {
    fontSize: '0.8rem',
    marginTop: '15px',
  },
};

// const delayTexts: Object = {
//   access_space: 'Access / Space',
//   approval_permits: 'Approval / Permits',
//   client_decision: 'Client decision',
//   contracts: 'Contracts',
//   drawings: 'Drawings',
//   equipment: 'Equipment',
//   inspection: 'Inspection',
//   labor: 'Labor',
//   logistics_storage: 'Logistics / Storage',
//   material: 'Material',
//   method_statement: 'Method statement',
//   minor_temp_prerequisite_work: 'Minor / Temp pre-requisite work',
//   rfi: 'RFI',
//   safety_requirement: 'Safety requirement',
//   re_scheduling: 'Re-Scheduling ',
//   unforeseen_site_conditions: 'Unforeseen site conditions',
//   weather: 'Weather',
//   schedule_error: 'Schedule error',
//   defects_rework: 'Defects rework',
//   first_lookahead: 'Delay on First Lookahead',
//   second_version: 'Delay on new version upload',
//   others: 'Others',
//   do_list_confirmation: 'Do List Confirmation',
//   change_in_dependency: 'Change In Dependency',
//   creation_of_dependency: 'Creation of Dependency',
// };

function getParentHierarchy(
  artifact: ConstraintDelay | null,
): string {
  if (!artifact || !artifact.parent_chain) return '';

  const parents: Array<string> = artifact.parent_chain.split(' | ').reverse();
  // parents.pop();
  return parents.join(' ➔ ');
}

class ConstraintDelayWidget extends React.PureComponent<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = {
      order: 'desc',
      column: 'activity_constraint_name',
      showDetail: false,
      detailViewArtifact: null,
    };
  }

  componentDidMount() {
    this.dispatch();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.week !== '' && prevProps.week !== this.props.week) {
      this.dispatch();
    }
  }

  onSortChange(column: string) {
    this.setState(
      (prevState: State): any => ({
        column,
        order: prevState.order === 'desc' ? 'asc' : 'desc',
      }),
    );
  }

  getReasons(reason: string): string {
    if (reason === null || reason === '') {
      return '';
    }
    if (delayTexts[reason]) {
      return delayTexts[reason];
    }
    if (reason.match(/others: pending/)) {
      return 'Pending';
    }
    if (reason.match(/others:/)) {
      return 'Others';
    }
    return reason;
  }

  getDetailViewData(): any {
    const artifact: any = this.props.data.chartData.find((d: ConstraintDelay): boolean => d.id === this.state.detailViewArtifact);
    if (artifact) {
      return {
        delayId: artifact.id,
        delayedArtifact: artifact.delayed_artifact,
        variance: artifact.variance,
        delayDescription: artifact.delay_description,
        responsibleCompany: artifact.responsible_company_name,
        responsibleCompanyId: artifact.responsible_party,
        responsibleParty: artifact.responsible_entity,
        parentChain: artifact.parent_chain,
        activityName: artifact.activity_name,
        taskName: artifact.task_name,
        activityConstraintDesc: artifact.activity_constraint_desc,
        taskConstraintDesc: artifact.task_constraint_desc,
        delayedOn: artifact.delayed_on,
        delayedBy: artifact.delayed_by,
      };
    }
    return {};
  }

  showDelayDetailModal = (delayId: number): null => {
    // if (window.__r2d2) {
    //   return null;
    // }
    this.setState({
      showDetail: true,
      detailViewArtifact: delayId,
    });
    return null;
  }

  closeDetailView() {
    this.setState({
      showDetail: false,
    });
    this.dispatch();
  }

  dispatch() {
    this.props.dispatch({
      type: ActionTypes.GET_CONSTRAINT_DELAY,
      data: {
        projectId: this.props.project.id,
        weekId: this.props.weekId,
      },
    });
  }

  renderTable(): Element<any> {
    const filterData: Array<*> = [];
    this.props.data.chartData.map((item: ConstraintDelay): any => {
      if (!this.props.week && item.delayed_artifact === 'activity_constraint') {
        filterData.push(item);
      } else if (this.props.week && this.props.weekId) {
        filterData.push(item);
      }
      return {};
    });
    const sortedData: ConstraintDelay[] = filterData.sort(
      (item1: ConstraintDelay, item2: ConstraintDelay): number => {
        const itemA: string = item1.responsible_company_name ? item1.responsible_company_name.toLowerCase() : '';
        const itemB: string = item1.responsible_entity ? item1.responsible_entity.toLowerCase() : '';
        const itemC: string = item2.responsible_company_name ? item2.responsible_company_name.toLowerCase() : '';
        const itemD: string = item2.responsible_entity ? item2.responsible_entity.toLowerCase() : '';
        const actA: string = item1.task_name ? item1.task_name.toLowerCase() : '';
        const actB: string = item1.activity_name ? item1.activity_name.toLowerCase() : '';
        const actC: string = item2.task_name ? item2.task_name.toLowerCase() : '';
        const actD: string = item2.activity_name ? item2.activity_name.toLowerCase() : '';

        if (this.state.column === 'activity_constraint_name') {
          if (this.state.order === 'asc') {
            if ((item1.activity_constraint_name ? item1.activity_constraint_name : item1.task_constraint_name)
            < (item2.activity_constraint_name ? item2.activity_constraint_name : item2.task_constraint_name)) {
              return -1;
            }
            if ((item1.activity_constraint_name ? item1.activity_constraint_name : item1.task_constraint_name)
            > (item2.activity_constraint_name ? item2.activity_constraint_name : item2.task_constraint_name)) {
              return 1;
            }
            return 0;
          }
          if ((item1.activity_constraint_name ? item1.activity_constraint_name : item1.task_constraint_name)
          < (item2.activity_constraint_name ? item2.activity_constraint_name : item2.task_constraint_name)) {
            return 1;
          }
          if ((item1.activity_constraint_name ? item1.activity_constraint_name : item1.task_constraint_name)
          > (item2.activity_constraint_name ? item2.activity_constraint_name : item2.task_constraint_name)) {
            return -1;
          }
          return 0;
        }
        if (this.state.column === 'activity_name') {
          if (this.state.order === 'asc') {
            if ((item1.delayed_artifact === 'task_constraint' ? actA : actB) < (item2.delayed_artifact === 'task_constraint' ? actC : actD)) {
              return -1;
            }
            if ((item1.delayed_artifact === 'task_constraint' ? actA : actB) > (item2.delayed_artifact === 'task_constraint' ? actC : actD)) {
              return 1;
            }
            return 0;
          }
          if ((item1.delayed_artifact === 'task_constraint' ? actA : actB) < (item2.delayed_artifact === 'task_constraint' ? actC : actD)) {
            return 1;
          }
          if ((item1.delayed_artifact === 'task_constraint' ? actA : actB) > (item2.delayed_artifact === 'task_constraint' ? actC : actD)) {
            return -1;
          }
          return 0;
        }
        if (this.state.column === 'responsible_company_name') {
          if (this.state.order === 'asc') {
            if ((item1.responsible_company_name ? itemA : itemB) < (item2.responsible_company_name ? itemC : itemD)) {
              return -1;
            }
            if ((item1.responsible_company_name ? itemA : itemB) > (item2.responsible_company_name ? itemC : itemD)) {
              return 1;
            }
            return 0;
          }
          if ((item1.responsible_company_name ? itemA : itemB) < (item2.responsible_company_name ? itemC : itemD)) {
            return 1;
          }
          if ((item1.responsible_company_name ? itemA : itemB) > (item2.responsible_company_name ? itemC : itemD)) {
            return -1;
          }
          return 0;
        }
        if (this.state.order === 'asc') {
          if (item1[this.state.column] < item2[this.state.column]) {
            return -1;
          }
          if (item1[this.state.column] > item2[this.state.column]) {
            return 1;
          }
          return 0;
        }
        if (item1[this.state.column] < item2[this.state.column]) {
          return 1;
        }
        if (item1[this.state.column] > item2[this.state.column]) {
          return -1;
        }
        return 0;
      },
    );
    if (this.props.data.chartData.length === 0 && this.props.data.loading === false) {
      return <span style={styles.noData}>No data available</span>;
    }
    return (
      <Fragment>
        { this.props.week
          ? <div style={{ marginBottom: '3.3rem' }}>
              <div />
              {/* <ExportButton projectId={this.props.project.id} startDate={this.props.week} widgetName="constraint_delay" /> */}
            </div> : null
        }
        {filterData.length === 0
          ? <span style={styles.noData}>No constraint delays</span>
          : <div className="grid-container_constraint">
              <div className="header" onClick={this.onSortChange.bind(this, 'activity_constraint_name')}>
                Constraint name
                {this.state.column === 'activity_constraint_name' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </div>
              <div className="header" onClick={this.onSortChange.bind(this, 'activity_name')}>
                { this.props.week ? 'Activity/Task name' : 'Activity name'}
                {this.state.column === 'activity_name' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </div>
              <div className="header" onClick={this.onSortChange.bind(this, 'delay')}>
                Delay
                {this.state.column === 'delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
                <br />
                (days)
              </div>
              <div className="header" onClick={this.onSortChange.bind(this, 'project_delay')}>
                Project
                {this.state.column === 'project_delay' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
                <br />
                Delay
                <br />
                (days)
              </div>
              <div className="header" onClick={this.onSortChange.bind(this, 'delayed_on')}>
                Recorded on
                {this.state.column === 'delayed_on' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </div>
              <div className="header" onClick={this.onSortChange.bind(this, 'delayed_by')}>
                Recorded by
                {this.state.column === 'delayed_by' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </div>
              <div className="header" onClick={this.onSortChange.bind(this, 'variance')}>
                Reason
                {this.state.column === 'variance' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </div>
              <div className="header" onClick={this.onSortChange.bind(this, 'responsible_company_name')}>
                Responsible party
                {this.state.column === 'responsible_company_name' ? <span style={this.state.order === 'asc' ? styles.arrowUp : styles.arrowDown} /> : null}
              </div>
              {sortedData.map(
                (artifact: ConstraintDelay, index: number): Node => (
                  <Fragment key={index}>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`} style={{ wordBreak: 'break-all' }}>
                      <a
                        onClick={
                          artifact.variance === 'first_lookahead' || artifact.variance === 'do_list_confirmation' || artifact.variance === 'change_in_dependency' || artifact.variance === 'creation_of_dependency' || artifact.variance === 'schedule_error'
                            ? undefined : this.showDelayDetailModal.bind(this, artifact.id)
                        }
                        className={
                          artifact.variance === 'first_lookahead' || artifact.variance === 'do_list_confirmation' || artifact.variance === 'change_in_dependency' || artifact.variance === 'creation_of_dependency' || artifact.variance === 'schedule_error'
                            ? 'deletedGrey' : 'underline-hover'
                        }
                      >
                        {artifact.activity_constraint_name
                          ? <span>{artifact.activity_constraint_name}</span>
                          : <span>{artifact.task_constraint_name}</span>
                        }
                        <br />
                        <span>
                          <span style={{ fontWeight: 'Bold' }}>Desc: </span>
                          {artifact.activity_constraint_name ? artifact.activity_constraint_desc : artifact.task_constraint_desc}
                        </span>
                      </a>
                    </div>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`} style={{ wordBreak: 'break-all' }}>
                      {artifact.delayed_artifact === 'task_constraint'
                        ? <span>
                            <span style={{ fontWeight: 'bold' }}>Task - </span>
                            <span>{artifact.task_name}</span>
                          </span>
                        : <span>
                            { this.props.week ? <span style={{ fontWeight: 'bold' }}>Activity - </span> : null }
                            <span>{artifact.activity_name}</span>
                          </span>
                        }
                        <br />
                        { artifact.delayed_artifact === 'task_constraint'
                          ? <span style={{ fontSize: '0.7rem' }}>
                              <span style={{ fontWeight: 'bold' }}>Activity - </span>
                              <span>{artifact.activity_name}</span>
                            </span>
                          : null
                        }
                        <div style={styles.parentHierarchy}>{getParentHierarchy(artifact)}</div>
                    </div>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>{artifact.delay}</div>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>{artifact.project_delay === null ? 'NA' : artifact.project_delay}</div>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>{moment(artifact.delayed_on).format('DD MMM YYYY')}</div>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`} style={{ wordBreak: 'break-all' }}>{artifact.delayed_by}</div>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>
                      <a
                        onClick={
                          artifact.variance === 'first_lookahead' || artifact.variance === 'do_list_confirmation' || artifact.variance === 'change_in_dependency' || artifact.variance === 'creation_of_dependency' || artifact.variance === 'schedule_error'
                            ? undefined : this.showDelayDetailModal.bind(this, artifact.id)
                        }
                        className={
                          artifact.variance === 'first_lookahead' || artifact.variance === 'do_list_confirmation' || artifact.variance === 'change_in_dependency' || artifact.variance === 'creation_of_dependency' || artifact.variance === 'schedule_error'
                            ? 'deletedGrey'
                            : 'underline-hover'
                        }
                      >
                        {this.getReasons(artifact.variance)}
                      </a>
                    </div>
                    <div className={`cell ${index % 2 === 1 ? 'odd-row' : ''}`}>
                      {artifact.responsible_company_name ? artifact.responsible_company_name : artifact.responsible_entity}
                    </div>
                  </Fragment>
                ),
              )}
            </div>
        }
        <div style={styles.note} />
      </Fragment>
    );
  }

  render(): Node {
    /* eslint max-len: ["error", { "ignoreStrings": true, "code": 150}] */
    return (
      <section style={styles.widget}>
        {this.state.showDetail && <DelayDetail onClose={this.closeDetailView.bind(this)} workdata={this.getDetailViewData()} />}
        <h1 className="header-widget-common">
          Constraint Delays
          <img
            data-tip="This table shows the list of constraint delays that have caused a project delay along with the total days of delay it has gone through and the reasons of these delays"
            width="14"
            src={infoIcon}
            alt="i"
          />
        </h1>
        <ReactTooltip place="top" type="dark" effect="solid" border multiline />
        {this.props.data.loading === null ? <Loader /> : this.renderTable()}
      </section>
    );
  }
}

const component: any = connect(
  (state: Reducers): { data: ConstraintDelayState } => ({
    data: state.constraintDelay,
  }),
)(ConstraintDelayWidget);

component.style = {
  width: '98%',
};

export default component;
