// @flow

import axios from 'axios';
import { getBaseUrl } from '../config/config';


export function getDelayCommentsApi(projectId: string, delayId: string, planId: number): Promise<Object> {
  if (window.__r2d2) {
    return axios.get(`${getBaseUrl()}projects/${projectId}/plans/${planId}/comments`,
      {
        params: {
          artifactID: delayId,
          artifactType: 'schedulechange',
          getUserInfo: true,
        },
      });
  }
  return axios.get(`${getBaseUrl()}v1/projects/${projectId}/delay/${delayId}/comments`);
}

export function postDelayCommentsApi(projectId: string, delayId: string, planId: number, payload: Object): Promise<Object> {
  if (window.__r2d2) {
    return axios.post(`${getBaseUrl()}projects/${projectId}/plans/${planId}/comments`,
      {
        artifactID: delayId,
        artifactType: 'schedulechange',
        text: payload.comment,
      });
  }
  return axios.post(`${getBaseUrl()}v1/projects/${projectId}/delay/${delayId}/comments`, payload);
}

export function getDelayHistoryApi(projectId: string, delayId: string): Promise<Object> {
  if (window.__r2d2) {
    return axios.get(`${getBaseUrl()}projects/${projectId}/insights/delays/${delayId}/edit_history?cmd=get_delay_history`);
  }
  return axios.get(`${getBaseUrl()}projects/${projectId}/delays/${delayId}/edit_history?cmd=get_delay_history`);
}

export function updateDelayHistoryApi(projectId: string, delayId: string, planId: number, payload: Object): Promise<Object> {
  if (window.__r2d2) {
    return axios.post(`${getBaseUrl()}projects/${projectId}/plans/${planId}/scheduleChanges/${delayId}`,
      {
        newReason: payload.variance,
        newDescription: payload.description,
        NewAccountablePartyID: payload.newAccountableEntity ? null : payload.responsible_party,
        NewAccountableEntity: payload.newAccountableEntity === 'unexpected' ? 'unexpectedConditions' : payload.newAccountableEntity,
      });
  }
  return axios.put(`${getBaseUrl()}projects/${projectId}/delays/${delayId}`, payload);
}

export function updateDelayHistoryStatusApi(projectId: string, delayId: string, planId: number, status: boolean, sheduleChangeId: string): Promise<Object> {
  return axios.post(`${getBaseUrl()}projects/${projectId}/plans/${planId}/scheduleChanges/${delayId}/approve`,
    {
      status,
      schedule_change_history_id: sheduleChangeId,
    });
}
